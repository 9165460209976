import React, { Component, Fragment } from 'react'
import { DialogContentText, Button, DialogContent, TextField, Grid } from '@material-ui/core'

class DialogRevokeBurialReservation extends Component {

    showStatus = false

    vals = {}

    constructor(props) {
        super(props)
        this.initVals()
    }

    initVals() {
        this.vals = {
            revokeDate: '',      /* when the reservation ends */
            annotation: null     /* notes related to the end of the reservation */
        }
    }


    /** It says if all the required infromation has been collected */
    canProcede() {
        return Boolean(this.vals.revokeDate)
    }

    /** Performs the recording of revoke */
    procede() {
        if (this.canProcede()) {
            let dataPack = {
                revokeDate: this.vals.revokeDate,
                annotation: this.vals.annotation
            }
            this.props.appMng.props.JBLClient.revokeBurialReservation(this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd].key, dataPack)
                .then(response => {
                    if (response) {   //if the response is a success
                        this.close()  //close the dialog
                    }
                });
        } else {
            this.props.appMng.notifyMsgToGui({
                'kind': 'error',
                'text': "Una o più informazioni necessarie per poter procedere risultano mancanti. Per favore compila i dati mancanti."
            })
        }
    }

    /** Set the value of a field
     * @param fname name of the field
     * @param fvalue new value of the field
     */
    setField(fname, fvalue) {
        switch (fname) {
            case 'annotation':                      //notes
                this.vals.annotation = (fvalue ? fvalue : null)
                break
            case 'revokeDate':
                this.vals.revokeDate = (fvalue ? fvalue : '')
                break;
            default:
                return false
        }
        return this.show(this.props.open)
    }

    show(status) {
        if (status) {
            this.props.appMng.openDialog('Revoca prenotazione sepoltura', (
                <Fragment>
                    <DialogContent>
                        <DialogContentText id="main-dialog-description">
                            <b>{this.props.sourceGrave.location}, {this.props.sourceGrave.myNameIs}</b>.
                        </DialogContentText>
                        <DialogContentText id="main-dialog-description">
                            La revoca conclude la prenotiazione senza contestuale inumazione.
                            Puoi specificare quando la revoca è avvenuta oppure avverrà.
                        </DialogContentText>
                        <Grid container spacing={1} direction="column" justify="flex-start" alignItems="stretch">
                            <Grid item>
                                <TextField
                                    label="Data revoca"
                                    value={this.vals.revokeDate}
                                    onChange={(event) => this.setField('revokeDate', event.target.value)}
                                    type="date"
                                    autoFocus
                                    required
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    label="Annotazioni"
                                    multiline
                                    fullWidth
                                    rowsMax="6"
                                    onChange={(event) => this.setField('annotation', event.target.value)}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Fragment>),
                (<Fragment>
                    <Button aria-label="procedi" disabled={!this.canProcede()} onClick={() => this.procede()}>Procedi</Button>
                    <Button aria-label="annulla" onClick={() => this.close()}>Annulla</Button>
                </Fragment>))
        } else {
            this.props.appMng.closeDialog()
        }
        this.showStatus = status
    }

    close() {
        if (typeof this.props.onClose === "function") { this.props.onClose() }
        this.showStatus = false
        this.props.appMng.closeDialog()
    }

    render() {
        if (this.props.open !== this.showStatus) {
            this.show(this.props.open)
        }
        return null
    }

}

export default DialogRevokeBurialReservation