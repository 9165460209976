import React, { Component } from 'react'
import '../styles/App.css';
import JBLClient from './JBLClient';
import { SnackbarProvider } from 'notistack';
import MainView from './MainView';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import theme from './Theme';

class App extends Component {

  constructor(props) {
    super(props)

    this.JBLClient = new JBLClient({ uri: (process.env.REACT_APP_GQL_ENTRYPOINT) });
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider maxSnack={12}>
          <MainView JBLClient={this.JBLClient} />
        </SnackbarProvider>
      </ThemeProvider>
    )
  }
}
export default App;
