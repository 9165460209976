import { createStyles } from "@material-ui/core";

const AppActionOrgStyle = ((theme) => createStyles({
    headMedia: {
        position: "relative",
        height: '160px',
        color: theme.palette.primary.contrastText
    },
    hiddenElement: {
        display: 'none'
    },
    itemsHeader: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText
    },
    itemsHeaderText: {
        background: "linear-gradient(180deg, rgba(0,0,0,0.6194852941176471) 0%, rgba(0,0,0,0.09287464985994398) 88%, rgba(0,0,0,0) 100%)",
        color: theme.palette.primary.contrastText
    },
    itemsHeaderReservation: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.contrastText
    },
    fabEdit: {
        position: "absolute",
        right: theme.spacing(2),
        bottom: -1 * theme.spacing(2),
    },
    fabMenu: {
        position: "absolute",
        right: theme.spacing(10),
        bottom: -1 * theme.spacing(2),
    },
    entityLine: {
        marginTop: theme.spacing(4),
        borderBottom: "1px solid lightgray"
    },
    avatarActive: {
        backgroundColor: theme.palette.primary.main
    },
}))

export default AppActionOrgStyle