import React, { Component, Fragment } from 'react'
import { Card, CardContent, Typography, CardActions, Button, CardMedia, Box, Grid, ListItemText, DialogContentText, Divider, Menu, MenuItem, Fab, Link, ListItemIcon, Popper, Fade, Slider, CircularProgress, Container, Tabs, Tab, AppBar, Chip} from '@material-ui/core'
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import EventNoteRoundedIcon from '@material-ui/icons/EventNoteRounded';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import AddLocationRoundedIcon from '@material-ui/icons/AddLocationRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import MapRoundedIcon from '@material-ui/icons/MapRounded';
import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import Skeleton from '@material-ui/lab/Skeleton';
import AppActionGraveyardStyle from './AppActionGraveyardStyle';
import GYSearch from '../../../GYSearch'
import mediaHeader from '../../../../images/cemetery_1248.webp'
import LayersRoundedIcon from '@material-ui/icons/LayersRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SearchIcon from '@material-ui/icons/Search';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { withStyles } from '@material-ui/styles';

class AppActionGraveyard extends Component {

    state = {
        levelShown: 0,
        treeExpansion: [],
        treeSelected: '',
        elementLabel: {
            target: null
        },
        actionsMenu: {
            target: null
        },
        gyspaceMenu: {
            mouseX: null,
            mouseY: null
        },
        loaded: false,
        data: {
            myNameIs: '',
            sys_name: '',
            fullAddress: '',
            geoPosition: {},
            opening: '',
            unnormalizedDiagram: null,
            organizationalUnit: {},
            relatedGravespace: []
        },
        showTab: 'planimetry'
    }

    highlightGrave = {
        level: 0
    }

    planimetry = {}

    levelsMarks = []

    constructor(props) {
        super(props)
        console.log('initiating ' + this.props.id)
        this.actionData = this.props.appMng.actionsStack[this.props.id]
        this.props.appMng.setTitle('Cimitero')
        this.getData()
    }

    componentDidMount(){
        const navState = JSON.parse(sessionStorage.getItem('nav:'+this.props.id))
        if(navState) {
            if(this.actionData.params.highlightGrave){
                navState.levelShown=this.actionData.params.highlightGrave.level
                navState.showTab='planimetry'
            }
            this.setState({...navState})
        }
    }

    initPlanimetry() {
        this.planimetry = {
            base: {},
            levels: {}
        }
    }

    getData() {
        if (this.actionData.params) {
            if(this.actionData.params.highlightGrave) {
                this.highlightGrave = this.actionData.params.highlightGrave
            } else {
                this.highlightGrave = {
                    level: 0
                }
            }
            this.props.appMng.props.JBLClient.getGraveyard(this.actionData.params.key)
            .then(response => {
                this.initPlanimetry()
                this.preparePlanimetry(response)
                this.setState({ loaded: true, data: response, levelShown:  (this.highlightGrave.level ? this.highlightGrave.level:this.state.levelShown)},this.storeSessionNav)
            });
        }
    }

    /** Shows the label of a graveyard area
     * @param event e
     * @param string label
    */
    showElementLabel(e, label) {
        e.stopPropagation()
        this.setState({
            elementLabel: {
                target: e.target,
                content: label
            }
        })
    }

    /** Hide the label of a graveyard area
     *
    */
    hideElementLabel() {
        this.setState({
            elementLabel: {
                ...this.state.elementLabel,
                target: null
            }
        })
    }

    /** Prepare the date to show the planimetry and to interact with it
     * @param object gData
    */
    preparePlanimetry(gData) {

        const diag = gData.unnormalizedDiagram       /** The graveyard unnormalized structure */

        /** Converts a path to a string as a SVG path
         * @param Array path
         * @returns string
        */
        const pathToPoints = (path) => {
            let strPath = []
            for (let vertex of path) {
                strPath.push(vertex.x + ' ' + vertex.y)
            }
            return strPath.join(', ')
        }

        /** given the path, returns a structure's item
        * @param string path
        * @returns object
        */
        const getItem = (path) => {
            let items = diag.items                  //layer 0 list of items
            let item = null                         //no current item
            let labelSteps = []                     //steps to make the label
            for (const step of path.split('.')) {   //explode the ids path to the item
                const parentItem = item             //current item will be the parent of the next step item
                item = items[step]                  //new current item is the n in the current layer
                item.parent = parentItem            //remember its parent
                labelSteps.push(diag.models[item.model].name + ' ' + item.name) //add current name in the label path
                if(item.hasOwnProperty('items')) {  //if the current item has sub-items
                    items = item.items              //next layer items
                }
            }
            if(item !== null) {
                item.label = labelSteps.join(', ')                      //add the full-path label
                item.shortLabel = labelSteps[labelSteps.length - 1]     //add the last path step as short label
            }
            return item
        }

        /** Make a polygon and metadata from graveyard item
         *
         */
        const mkDataPoly = (item, events) => {
            const id = item.type.toUpperCase() + item.id
            return {
                name: item.name,
                item: item,
                poly: (events === 'bg' || (this.highlightGrave.key && item.type === 'g' && this.highlightGrave.key !== item.id))? <polygon
                    key={id}
                    id={id}
                    points={pathToPoints(item.plan.path)}
                    fill="gray"
                    fillOpacity="10%"
                /> : <polygon
                    key={id}
                    id={id}
                    points={pathToPoints(item.plan.path)}
                    fill={(item.type !== 'g' || item.available)? item.plan.color : 'red'}
                    stroke="gray"
                    strokeWidth="0.1"
                    className={this.props.classes.element}
                    onMouseEnter={(e) => events.mouseEnter(e)}
                    onMouseLeave={() => this.hideElementLabel()}
                    onClick={(e) => events.click(e)}
                />
            }
        }

        try {
            this.planimetry.base = mkDataPoly({                 //make the base planimetry
                    name: diag.name,
                    type: 'b',
                    id: gData.key,
                    plan: diag.plan
                }, {
                    mouseEnter: (e) => this.showElementLabel(e, diag.name),
                    click: (e) => this.showGySpaceMenu(e, null)
                }
            )

            this.levelsMarks = []                                               //reset level marks before repopulate it
            const levelsKeysList = Object.keys(diag.levels)                     //list of keys of the levels
            levelsKeysList.sort((a,b) => parseInt(a) < parseInt(b)? -1 : 1)     //sort levels from lower to higher
            for (const idxLevelID in levelsKeysList) {                  //for each level of the graveyard
                const levelID = levelsKeysList[idxLevelID]              //current levelID
                if (diag.levels.hasOwnProperty(levelID)) {
                    this.levelsMarks.push({value: parseInt(levelID), label: `L${levelID}`})                 //adds the level to the marks for the slider
                    if(Array.isArray(diag.levels[levelID])) {
                        for (const itemPath of diag.levels[levelID]) {                      //for each area in in the current level of the graveyard
                            const item = getItem(itemPath)                                  //gets the area item
                            if(typeof this.planimetry.levels[levelID] === "undefined") {    //if not yet set, adds a level structure
                                this.planimetry.levels[levelID] = []
                            }
                            this.planimetry.levels[levelID].push(mkDataPoly(item, {         //put the area item data in the level
                                mouseEnter: (e) => this.showElementLabel(e, item.label),
                                click: item.type === 'g' ? (e) => this.props.appMng.openAction("grave", { key: item.id }) : (e) => this.showGySpaceMenu(e, item)   //if it is a grave, on click, open it. Open menu instead.
                            }))
                            if(parseInt(levelID) === 0) {
                                if(typeof this.planimetry.level0 === "undefined") {    //if not yet set, adds a level structure
                                    this.planimetry.level0 = []
                                }
                                this.planimetry.level0.push(mkDataPoly(item,'bg'))
                            }
                            item.pElement = this.planimetry.levels[levelID][this.planimetry.levels[levelID].length - 1] //make the item to remember its planimetry element
                            item.level = levelID                                            //and remember its level
                        }
                    }
                }
            }
        } catch(Error) {
            console.error(Error)
            return this.props.appMng.notifyMsgToGui({
                'kind': 'error',
                'text': "C'è un problema con le informazioni ricevute dal servizio remoto. Non posso preparare la planimetria."
            })
        }
    }

    /** Draws the planimetry of the current level
     * @returns Array
     */
    drawPlanimetry() {
        const pl = [this.planimetry.base.poly]
        if(this.state.levelShown !== 0 && Array.isArray(this.planimetry.level0)) {
            pl.push(this.planimetry.level0.map(item => item.poly))
        }
        if(Array.isArray(this.planimetry.levels[this.state.levelShown])) {
            pl.push(this.planimetry.levels[this.state.levelShown].map(item => item.poly))
        }
        return pl
    }

    forgetHighlightGrave = () => {
        this.actionData.params.highlightGrave = undefined
        this.getData(true)
    }

    /** Draws the tree view of the graveyard
     * @returns Array
     */
    drawTreeView() {
        const diag = this.state.data.unnormalizedDiagram

        const handleToggle = (event, nodeIds) => {
            this.setState({treeExpansion: nodeIds},this.storeSessionNav)
        }

        const handleSelect = (event, nodeIds) => {
            this.setState({treeSelected: nodeIds},this.storeSessionNav)
        }

        function walkProps(item, key, element) {
            return <TreeItem
                key={key}
                nodeId={item.type + item.id}
                label={<div className={element.props.classes.treeItemElement}>
                    <div style={{background: (item.pElement ? item.pElement.poly.props.fill : 'grey')}}></div>
                    {item.shortLabel}
                </div>}
                onLabelClick={item.pElement ? item.pElement.poly.props.onClick : null}
            >{
                (item.hasOwnProperty('items')) ? item.items.map((item, key) => walkProps(item, key, element)) : null
            }</TreeItem>
        }
        return <TreeView
            defaultExpanded={['root']}
            defaultExpandIcon={<ExpandMoreIcon/>}
            defaultCollapseIcon={<ChevronRightIcon/>}
            expanded={this.state.treeExpansion }
            selected={this.state.treeSelected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
        >
            <TreeItem
                nodeId="root"
                label={<div className={this.props.classes.treeItemElement} id="treeRoot">{diag.name}</div>}
            >
                {diag.items.map((item, key) => walkProps(item, key, this))}
            </TreeItem>
        </TreeView>
    }


    /** Call the Graveyard editor
     *
     */
    editMe(bgPolys) {
        this.props.appMng.openAction('graveyardEditor', { key: this.actionData.params.key, bgPolys: bgPolys.splice(1)}) //call the action passing the graveyard key and the list of background polys without the first (itself)
    }

    deleteMe = () => {
        this.hideActionsMenu()
        if(Array.isArray(this.state.data.relatedGravespace) && this.state.data.relatedGravespace.length > 0){
            this.props.appMng.notifyMsgToGui({
                'kind': 'error',
                'text': "Per poter procedere è prima necessario rimovere tutte le sotto-aree cimiteriali."
            })
        } else {
            this.props.appMng.openDialog('Eliminazione cimitero', (
                <Fragment>
                    <DialogContentText id="main-dialog-description">
                        Confermi di voler eliminare definitivamente la scheda del cimitero?
                    </DialogContentText>
                </Fragment>),
                (<Fragment>
                    <Button aria-label="rimuovi" onClick={() => {
                        this.props.appMng.props.JBLClient.deleteGraveyard(this.actionData.params.key)
                            .then(response => {
                                if (response) {
                                    this.props.appMng.closeDialog()
                                    this.closeMe()
                                }
                                return response
                            });
                        this.props.appMng.closeDialog()
                    }} >Conferma</Button>
                    <Button aria-label="Mantieni" onClick={() => this.props.appMng.closeDialog()}>Annulla</Button>
                </Fragment>))
        }
    }

    /** Shows a dialog to change which level it must show
     *
     */
    changeViewLevel = () => {
        this.props.appMng.openDialog('Livello visualizzato', (
            <Fragment>
                <DialogContentText id="main-dialog-description">
                    Scegli il livello della planimetria da visualizzare
                </DialogContentText>
            </Fragment>),
            (<Grid container spacing={1}>
                <Grid item xs={12}>
                    <Container>
                        <Slider
                            value={this.state.levelShown}
                            onChange={(e, newLevel) => {
                                this.setState({levelShown: newLevel},this.storeSessionNav)
                                this.props.appMng.closeDialog()
                            }}
                            marks={this.levelsMarks}
                            step={null}
                            min={this.levelsMarks[0].value}
                            max={this.levelsMarks[this.levelsMarks.length - 1].value}
                            aria-labelledby="main-dialog-description"
                        />
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    <Button aria-label="Mantieni" onClick={() => this.props.appMng.closeDialog()}>Ignora</Button>
                </Grid>
            </Grid>))
    }

    storeSessionNav = ()=>{
        sessionStorage.setItem('nav:'+this.props.id,JSON.stringify({
            showTab: this.state.showTab,
            levelShown: this.state.levelShown,
            treeExpansion: this.state.treeExpansion,
            treeSelected: this.state.treeSelected,
        }))
    }

    closeMe = () => {
        sessionStorage.removeItem('nav:'+this.props.id)
        this.props.appMng.closeAction(this.props.id)
    }

    /** Delete the given graveyard space
     * @param object item
     */
    deleteGySpace = (item) => {
        this.hideGySpaceMenu()
        if((Array.isArray(item.relatedGravespace) && item.relatedGravespace.length > 0) || (Array.isArray(item.relatedGrave) && item.relatedGrave.length > 0)){
            this.props.appMng.notifyMsgToGui({
                'kind': 'error',
                'text': "Per poter procedere è prima necessario rimovere tutte le sotto-aree cimiteriali compresi i sepolcri."
            })
        } else {
            this.props.appMng.openDialog('Eliminazione area cimiteriale', (
                <Fragment>
                    <DialogContentText id="main-dialog-description">
                        Confermi di voler eliminare definitivamente l'area cimiteriale?
                    </DialogContentText>
                </Fragment>),
                (<Fragment>
                    <Button aria-label="rimuovi" onClick={() => {
                        this.props.appMng.props.JBLClient.deleteGraveyardSpace(item.id)
                            .then(response => {
                                if (response) {
                                    this.getData()
                                    this.props.appMng.closeDialog()
                                }
                                return response
                            });
                        this.props.appMng.closeDialog()
                    }} >Conferma</Button>
                    <Button aria-label="Mantieni" onClick={() => this.props.appMng.closeDialog()}>Annulla</Button>
                </Fragment>))
        }
    }

    showActionsMenu(e) {
        let items = []
        items.push(
        <MenuItem key="1" onClick={() => this.editMe(this.makeBaseBgPoly())}><ListItemIcon><EditRoundedIcon /></ListItemIcon><ListItemText>Modifica dati</ListItemText></MenuItem>,
        <MenuItem key="3" onClick={this.deleteMe}><ListItemIcon><DeleteForeverRoundedIcon/></ListItemIcon><ListItemText>Elimina</ListItemText></MenuItem>,
        <MenuItem key="300" onClick={this.closeMe}><ListItemIcon><HighlightOffRoundedIcon /></ListItemIcon><ListItemText>Chiudi</ListItemText></MenuItem>
        )
        this.setState({
            actionsMenu: {
                target: e.target,
                items: items,
            }
        })
    }

    /** Prepare the array of background polygons for the base
     * @returns Array
     */
    makeBaseBgPoly() {
        const bgPolys = this.planimetry.base.poly?[{...this.planimetry.base.poly.props, fillOpacity: "0.5", strokeOpacity: "0.7", mask: "url(#checkers-mask)"}]:[]   //put the base planimetry itself into the list of background polygons (when edit, it will be removed)
        if(this.planimetry.levels[0]){                                //if the level 0 exists
            for (const pElement of this.planimetry.levels[0]) {       //put the each polygon of the level into the list of the polygons to show as background
                bgPolys.push({...pElement.poly.props, fillOpacity: "0.3", strokeOpacity: "0.5"})
            }
        }
        return bgPolys
    }

    /** Shows the menu of the selected graveyard area
     * @param event e
     * @param object data
     * */
    showGySpaceMenu(e, data, navStack = []) {
        const bgPolys = []                  //list of background SVG object to show
        const items = []                    //list of items of the menu
        let k = 0                           //menu items index
        e.persist()                         //keep the event for reopening of the menu when back to the parent
        e.stopPropagation()

        this.setState({                     //shows the menu attached to the SVG object. It shows it is waiting for data
            gyspaceMenu: {
                mouseX: e.clientX - 2,
                mouseY: e.clientY - 4,
                name: 'Apertura menu...',
                items: [<MenuItem key="loading"><ListItemIcon><CircularProgress size="3rem"/></ListItemIcon><ListItemText>attendere...</ListItemText></MenuItem>],
            }
        })

        //Defines the items to put in the menu accordingly with the kind of graveyard area
        if(data === null) {                    //if it is the base SVG polygon, add "modify and add sub-area" to the menu
            const bgPolys = this.makeBaseBgPoly()
            items.push(<MenuItem key={k++} onClick={() => this.editMe(bgPolys)}><ListItemIcon><EditRoundedIcon/></ListItemIcon><ListItemText>Modifica</ListItemText></MenuItem>)
            items.push(<MenuItem key={k++} onClick={() => this.props.appMng.openAction("graveyardSpaceEditor", { parentGraveyardKey: this.actionData.params.key, bgPolys: bgPolys })}><ListItemIcon><AddLocationRoundedIcon/></ListItemIcon><ListItemText>Aggiungi nuova area</ListItemText></MenuItem>)
            if(navStack.length > 0) {                   //if there are navigation steps
                const nextStep = navStack[navStack.length - 1]
                items.push(<MenuItem key={k++} onClick={() => this.showGySpaceMenu(e, nextStep, navStack.slice(0,navStack.length - 1))}><ListItemIcon><ArrowForwardIosRoundedIcon/></ListItemIcon><ListItemText>{nextStep.shortLabel}</ListItemText></MenuItem>)
            }
            this.setState((state) => state.gyspaceMenu = {...state.gyspaceMenu, name: this.state.data.myNameIs, items: items})      //update the menu
        } else {                                    //if it is a sub-area SVG polygon, add "modify, add sub-area, add grave and delete" to the menu
            this.props.appMng.props.JBLClient.getSpaceModel(data.model)     //load data of the space model associated to the graveyard space
            .then(model => {
                const levelsToShow = new Set()              //list of the levels to show
                let idxCurrent = null                       //index of the current area in the list of background polygons
                if(data.parent) {                           //if the given graveyard area has a parent (if it isn't a layer 0 and it parent is the graveyard itself)
                    levelsToShow.add(data.parent.level)     //the vertical level of the parent must be shown
                }
                levelsToShow.add(data.level)                //the vertical level of the given graveyard area must be shown too (off course)
                bgPolys.push({...this.planimetry.base.poly.props, fillOpacity: "0.3", strokeOpacity: "0.5"})        //adds the base planimetry
                levelsToShow.forEach((levelID) => {                                                                 //for each level to show
                    for (const pElement of this.planimetry.levels[levelID]) {       //put the each polygon of the level into the list of the polygons to show as background
                        if(data.parent && pElement.item === data.parent) {          //if the current area has a parent and it is the one now considered in the loop
                            bgPolys.push({...pElement.poly.props, stroke: "black", fillOpacity: "0.5", strokeOpacity: "0.7", mask: "url(#checkers-mask)"})     //highlight the parent polygon
                        } else {
                            bgPolys.push({...pElement.poly.props, fillOpacity: "0.3", strokeOpacity: "0.5"})
                            if(pElement.item === data) {        //if the planimetry element
                                idxCurrent = bgPolys.length -1  //remember where the current area polygon is inside the list. In edit mode will remove it from the list
                                bgPolys[idxCurrent]={...bgPolys[idxCurrent], stroke: "black", fillOpacity: "0.5", strokeOpacity: "0.7", mask: "url(#checkers-mask)"}    //and highlight the its polygon
                            }
                        }
                    }
                })
                items.push(<MenuItem key={k++} onClick={() => this.props.appMng.openAction("graveyardSpaceEditor", { key: data.id, bgPolys: bgPolys.filter((data, idx) => idx !== idxCurrent) })}><ListItemIcon><EditRoundedIcon/></ListItemIcon><ListItemText>Modifica</ListItemText></MenuItem>)
                if (model.allowSpaces) { items.push(<MenuItem key={k++} onClick={() => this.props.appMng.openAction("graveyardSpaceEditor", { parentSpaceKey: data.id, bgPolys: bgPolys })}><ListItemIcon><AddLocationRoundedIcon/></ListItemIcon><ListItemText>Aggiungi nuova sotto-area</ListItemText></MenuItem>) }
                if (model.allowGraves) { items.push(<MenuItem key={k++} onClick={() => this.props.appMng.openAction("graveEditor", { parentSpaceKey: data.id, bgPolys: bgPolys })}><ListItemIcon><AddBoxRoundedIcon/></ListItemIcon><ListItemText>Aggiungi nuovo sepolcro</ListItemText></MenuItem>) }
                items.push(<MenuItem key={k++} onClick={() => this.deleteGySpace(data)}><ListItemIcon><DeleteForeverRoundedIcon/></ListItemIcon><ListItemText>Elimina</ListItemText></MenuItem>)
                items.push(<Divider key={k++}/>)
                items.push(<MenuItem key={k++} onClick={() => this.showGySpaceMenu(e, data.parent, navStack.concat([data]))}><ListItemIcon><ArrowBackIosRoundedIcon/></ListItemIcon><ListItemText>{data.parent ? data.parent.shortLabel : this.state.data.myNameIs}</ListItemText></MenuItem>)
                if(navStack.length > 0) {                   //if there are navigation steps
                    const nextStep = navStack[navStack.length - 1]
                    items.push(<MenuItem key={k++} onClick={() => this.showGySpaceMenu(e, nextStep, navStack.slice(0,navStack.length - 1))}><ListItemIcon><ArrowForwardIosRoundedIcon/></ListItemIcon><ListItemText>{nextStep.shortLabel}</ListItemText></MenuItem>)
                }
                this.setState((state) => state.gyspaceMenu = {...state.gyspaceMenu, name: data.shortLabel, items: items})      //update the menu
            })
        }
    }

    hideActionsMenu() {
        this.setState({ actionsMenu: { target: null } })
    }

    hideGySpaceMenu() {
        this.setState({ gyspaceMenu: { mouseX: null, mouseY: null } })
    }

    render() {
        return (
            <Fragment>
                <Card>
                    <CardMedia className={this.props.classes.headMedia} image={mediaHeader}>
                        <Box p={3} className={this.props.classes.itemsHeaderText}>
                            <Typography variant="h4" color="inherit">
                                {this.state.loaded ? this.state.data.myNameIs : <Skeleton height="32px" width="100px" />}
                            </Typography>
                        </Box>
                        {this.state.loaded ?
                            <Fab aria-label="modifica dati cimitero" title="modifica i dati" color="secondary" className={this.props.classes.fabEdit} onClick={() => this.editMe(this.makeBaseBgPoly())}>
                                <EditRoundedIcon/>
                            </Fab>:<Fragment></Fragment>
                        }
                        {(this.state.loaded)?
                            <Fab aria-label="più opzioni" title="più opzioni" color="primary" className={this.props.classes.fabMenu} onClick={(e) => this.showActionsMenu(e)}>
                                <MenuRoundedIcon/>
                            </Fab>:<Fragment></Fragment>
                        }
                    </CardMedia>
                    {this.state.loaded ?
                        <Fragment>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid container item xs={12} md={12} lg={12} className={this.props.classes.entityLine} alignItems="flex-start">
                                        <Grid item xs={10} md={10} lg={11}>
                                            gestito da: <Typography component="span" variant="body1" className={this.props.classes.parentLink} onClick={() => this.props.appMng.openAction('OUnit', { orgKey: this.state.data.organizationalUnit.organization.key, key: this.state.data.organizationalUnit.key })}>
                                                {this.state.data.organizationalUnit.myNameIs} <OpenInNewRoundedIcon />
                                            </Typography> di <Typography component="span" variant="body1" className={this.props.classes.parentLink} onClick={() => this.props.appMng.openAction('org', { key: this.state.data.organizationalUnit.organization.key })}>
                                                {this.state.data.organizationalUnit.organization.myNameIs} <OpenInNewRoundedIcon />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <LocationOnRoundedIcon/> {this.state.data.fullAddress || (this.state.data.geoPosition && this.state.data.geoPosition.lat && this.state.data.geoPosition.long) ?
                                        <Link variant="body1" target="_blank" rel="noopener noreferrer" href={"http://maps.google.com/?q=" + ((this.state.data.geoPosition && this.state.data.geoPosition.lat && this.state.data.geoPosition.long)? (this.state.data.geoPosition.lat + ',' + this.state.data.geoPosition.long) : encodeURIComponent(this.state.data.fullAddress).replace(/['()]/g, escape).replace(/\*/g, '%2A').replace(/%(?:7C|60|5E)/g, unescape))}>
                                            {this.state.data.fullAddress? this.state.data.fullAddress : (this.state.data.geoPosition.lat + ', ' + this.state.data.geoPosition.long)}
                                        </Link>:<Typography variant="caption">indirizzo mancante</Typography>}
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <EventNoteRoundedIcon/> {this.state.data.opening ?
                                        <Typography variant="body1" component="span" style={{whiteSpace: "pre-line"}}>{this.state.data.opening}</Typography>
                                        :<Typography variant="caption">orari per il pubblico mancanti</Typography>}
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <AppBar color="transparent" position="static">
                                <Tabs value={this.state.showTab} onChange={(e,value) => this.setState({showTab: value},this.storeSessionNav)} aria-label="Visulizzazioni">
                                    <Tab value="planimetry" label={<Typography><MapRoundedIcon/> Mappa</Typography>} id="tabPlanimetry" aria-controls="tabpanelPlanimetry"/>
                                    <Tab value="treeView" label={<Typography><AccountTreeRoundedIcon/> Schema</Typography>} id="tabTreeView" aria-controls="tabpanelTreeView"/>
                                    <Tab value="search" label={<Typography><SearchIcon/> Ricerca</Typography>} id="tabSearch" aria-controls="tabpanelSearch"/>
                                </Tabs>
                            </AppBar>
                            <CardContent>
                                { this.state.showTab === "planimetry" && <div>
                                        { this.levelsMarks.length > 0 && !this.highlightGrave.key &&
                                            <Typography id="levelsSelector" variant="body1">
                                                Piano verticale:&nbsp;<Button color="primary" variant="contained" startIcon={<LayersRoundedIcon/>} onClick={this.changeViewLevel}>L{this.state.levelShown}</Button>
                                                <br/><Typography variant="caption">(di {this.levelsMarks.length} {this.levelsMarks.length === 1 ? 'totale' : 'totali'})</Typography>
                                            </Typography>
                                        }
                                        {
                                            this.highlightGrave.key && <Chip label={this.highlightGrave.label} color="primary" onDelete={this.forgetHighlightGrave} icon={<GpsFixedIcon/>} />
                                        }
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox={`0 0 1000 1000`}
                                        >
                                            {this.drawPlanimetry()}
                                        </svg>
                                    </div>
                                }
                                { this.state.showTab === "treeView" && <>{this.drawTreeView()}</>}
                                { this.state.showTab === "search" && <GYSearch appMng={this.props.appMng}/> }
                            </CardContent>
                            <CardActions>
                                <Button onClick={this.closeMe}><HighlightOffRoundedIcon /> Chiudi scheda</Button>
                            </CardActions>
                        </Fragment>
                        :
                        <CardContent>
                            <Skeleton height="60px" width="100%" />
                        </CardContent>
                    }
                </Card >
                <Menu
                    anchorEl={this.state.actionsMenu.target}
                    open={Boolean(this.state.actionsMenu.target)}
                    keepMounted
                    onClose={() => this.hideActionsMenu()}
                >
                    {this.state.actionsMenu.items}
                </Menu>
                <Menu
                    id="gyspaceMenu"
                    anchorReference="anchorPosition"
                    anchorPosition={
                        this.state.gyspaceMenu.mouseY !== null && this.state.gyspaceMenu.mouseX !== null
                            ? { top: this.state.gyspaceMenu.mouseY, left: this.state.gyspaceMenu.mouseX }
                            : undefined
                    }
                    open={Boolean(this.state.gyspaceMenu.mouseY !== null && this.state.gyspaceMenu.mouseX !== null)}
                    keepMounted
                    onClose={() => this.hideGySpaceMenu()}
                >
                    <Box m={2}>
                        <Typography variant="body1"><b>{this.state.gyspaceMenu.name}</b></Typography>
                    </Box>
                    <Divider/>
                    {this.state.gyspaceMenu.items}
                </Menu>
                <Popper
                    anchorEl={this.state.elementLabel.target}
                    open={Boolean(this.state.elementLabel.target)}
                    placement="top-start"
                    transition
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={500}>
                            <div className={this.props.classes.elementLabel}>{this.state.elementLabel.content}</div>
                        </Fade>
                    )}
                </Popper>
            </Fragment>
        )
    }
}

export default withStyles(AppActionGraveyardStyle)(AppActionGraveyard)