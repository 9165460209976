import React, { Component, Fragment } from 'react'
import { Card, CardContent, Typography, CardActions, Button, List, CardMedia, Box, Grid, ListItem, ListItemText, DialogContentText, Divider, Menu, MenuItem, Fab, Avatar, ListItemAvatar, Link, ListItemIcon } from '@material-ui/core'
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import HomeWorkRoundedIcon from '@material-ui/icons/HomeWorkRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import AppActionOrgStyle from './AppActionOrgStyle';
import mediaHeader from '../../../../images/companies_1248.webp'
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import { withStyles } from '@material-ui/styles';

class AppActionOrg extends Component {

    state = {
        actionsMenu: {
            target: null
        },
        loaded: false,
        data: {
            myNameIs: '',
            name: '',
            relatedOrganizationalUnit: null
        }
    }

    constructor(props) {
        super(props)
        console.log('initiating ' + this.props.id)
        this.actionData = this.props.appMng.actionsStack[this.props.id]
        this.props.appMng.setTitle('Ente')
        this.getData()
    }

    getData() {
        if (this.actionData.params) {
            this.props.appMng.props.JBLClient.getOrg(this.actionData.params.key)
                .then(response => {
                    this.setState({ loaded: true, data: response })
                });
        }
    }

    editMe = () => {this.props.appMng.openAction('orgEditor', { key: this.actionData.params.key })}

    deleteMe = () => {
        this.setState((state => {
            state.actionsMenu.target = null
            return state
        }));
        if(Array.isArray(this.state.data.relatedOrganizationalUnit) && this.state.data.relatedOrganizationalUnit.length > 0){
            this.props.appMng.notifyMsgToGui({
                'kind': 'error',
                'text': "Per poter procedere è prima necessario rimovere tutte le unità organizzative dell'ente."
            })
        } else {
            this.props.appMng.openDialog('Eliminazione ente', (
                <Fragment>
                    <DialogContentText id="main-dialog-description">
                        Confermi di voler eliminare definitivamente la scheda dell'ente?
                    </DialogContentText>
                </Fragment>),
                (<Fragment>
                    <Button aria-label="rimuovi" onClick={() => {
                        this.props.appMng.props.JBLClient.deleteOrg(this.actionData.params.key)
                            .then(response => {
                                if (response) {
                                    this.props.appMng.closeDialog()
                                    this.closeMe()
                                }
                                return response
                            });
                        this.props.appMng.closeDialog()
                    }} >Conferma</Button>
                    <Button aria-label="Mantieni" onClick={() => this.props.appMng.closeDialog()}>Annulla</Button>
                </Fragment>))
        }
    }

    closeMe = () => {this.props.appMng.closeAction(this.props.id)}

    addOUnit = () => {this.props.appMng.openAction('OUEditor', { orgKey: this.actionData.params.key })}

    showActionsMenu(e) {
        let items = []
        items.push(
        <MenuItem key="1" onClick={this.editMe}><ListItemIcon><EditRoundedIcon/></ListItemIcon><ListItemText>Modifica dati</ListItemText></MenuItem>,
        <MenuItem key="2" onClick={this.addOUnit}><ListItemIcon><AddBoxRoundedIcon/></ListItemIcon><ListItemText>Aggiungi unità organizzativa</ListItemText></MenuItem>,
        <MenuItem key="3" onClick={this.deleteMe}><ListItemIcon><DeleteForeverRoundedIcon/></ListItemIcon><ListItemText>Elimina</ListItemText></MenuItem>,
        <MenuItem key="300" onClick={this.closeMe}><ListItemIcon><HighlightOffRoundedIcon/></ListItemIcon><ListItemText>Chiudi</ListItemText></MenuItem>
        )
        this.setState({
            actionsMenu: {
                target: e.target,
                items: items,
            }
        })
    }

    hideActionsMenu() {
        this.setState({ actionsMenu: { target: null } })
    }

    drawOUnit(item, pos) {
        return <Fragment key={pos}>
            {pos > 0 ? <Divider variant="inset" component="li" /> : ''}
            <ListItem button onClick={() => this.props.appMng.openAction('OUnit', { orgKey: this.actionData.params.key, key: item.key })}>
                <ListItemAvatar>
                    <Avatar className={this.props.classes.avatarActive}>
                        <HomeWorkRoundedIcon/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={item.name}/>
            </ListItem>
        </Fragment>
    }

    render() {
        return (
            <Fragment>
                <Card>
                    <CardMedia className={this.props.classes.headMedia} image={mediaHeader}>
                        <Box p={3} className={this.props.classes.itemsHeaderText}>
                            <Typography variant="h4" color="inherit">
                                {this.state.loaded ? this.state.data.myNameIs : <Skeleton height="32px" width="100px" />}
                            </Typography>
                        </Box>
                        {this.state.loaded ?
                            <Fab aria-label="modifica dati ente" title="modifica i dati" color="secondary" className={this.props.classes.fabEdit} onClick={this.editMe}>
                                <EditRoundedIcon/>
                            </Fab>:<Fragment></Fragment>
                        }
                        {(this.state.loaded)?
                            <Fab aria-label="più opzioni" title="più opzioni" color="primary" className={this.props.classes.fabMenu} onClick={(e) => this.showActionsMenu(e)}>
                                <MenuRoundedIcon/>
                            </Fab>:<Fragment></Fragment>
                        }
                    </CardMedia>
                    {this.state.loaded ?
                        <Fragment>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <LocationOnRoundedIcon/> {this.state.data.mainFullAddress?
                                        <Link variant="body1" target="_blank" rel="noopener noreferrer" href={"http://maps.google.com/?q=" + encodeURIComponent(this.state.data.mainFullAddress).replace(/['()]/g, escape).replace(/\*/g, '%2A').replace(/%(?:7C|60|5E)/g, unescape)}>
                                            {this.state.data.mainFullAddress}
                                        </Link>:<Typography variant="caption">indirizzo principale mancante</Typography>}
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={2}>
                                            <PhoneRoundedIcon/> {this.state.data.mainPhone?
                                            <Link variant="body1" href={"tel:" + encodeURIComponent(this.state.data.mainPhone)}>
                                                {this.state.data.mainPhone}
                                            </Link>:<Typography variant="caption">numero telefonico per il pubblico mancante</Typography>}
                                    </Grid>
                                    <Grid item xs={12} md={9} lg={10}>
                                        <EmailRoundedIcon/> {this.state.data.mainEmail?
                                        <Link variant="body1" href={"mailto:" + this.state.data.mainEmail}>
                                            {this.state.data.mainEmail}
                                        </Link>:<Typography variant="caption">indirizzo email per il pubblico mancante</Typography>}
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent>
                                <Typography variant="h6" component="h4">Unità organizzative dell'ente:</Typography>
                                {(Array.isArray(this.state.data.relatedOrganizationalUnit) && this.state.data.relatedOrganizationalUnit.length > 0) ?
                                    <Card elevation={1}>
                                        <List>{this.state.data.relatedOrganizationalUnit.map((item, pos) => this.drawOUnit(item, pos))}</List>
                                        <CardActions>
                                            <Button onClick={this.addOUnit}><AddBoxRoundedIcon /> Aggiungi altra unità organizzativa</Button>
                                        </CardActions>
                                    </Card> :
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <Typography color="primary" variant="h5">Non ci sono ancora unità organizzative.</Typography>
                                        </Grid>
                                        <Grid item>
                                        <Button onClick={this.addOUnit}><AddBoxRoundedIcon /> Aggiungi la prima unità organizzativa</Button>
                                        </Grid>
                                    </Grid>
                                }
                            </CardContent>
                            <CardActions>
                                <Button onClick={this.closeMe}><HighlightOffRoundedIcon /> Chiudi scheda</Button>
                            </CardActions>
                        </Fragment>
                        :
                        <CardContent>
                            <Skeleton height="60px" width="100%" />
                        </CardContent>
                    }
                </Card >
                <Menu
                    anchorEl={this.state.actionsMenu.target}
                    open={Boolean(this.state.actionsMenu.target)}
                    keepMounted
                    onClose={() => this.hideActionsMenu()}
                >
                    {this.state.actionsMenu.items}
                </Menu>
            </Fragment>
        )
    }
}

export default withStyles(AppActionOrgStyle)(AppActionOrg)