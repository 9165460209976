import React, { Component, Fragment } from 'react'
import { Card, CardContent, Typography, CardHeader, CardActions, Button, List, CardMedia, Box, Grid, Accordion, AccordionSummary, AccordionDetails, ListItem, ListItemText, ListItemSecondaryAction, IconButton, DialogContentText, Divider, Menu, MenuItem, Fab, ListItemIcon, DialogContent, CircularProgress} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import AppActionGraveStyle from './AppActionGraveStyle';
import mediaHeader from '../../../../images/grave_1248.webp'
import { withStyles } from '@material-ui/styles';
import schemaReferent from '../../DataSchemas/schemaReferent';
import schemaExtRef from '../../DataSchemas/schemaBurialExtRef';
import FormReferent from '../../Forms/FormReferent';
import FormExtRef from '../../Forms/FormBurialExtRef';
import DialogRevokeBurialReservation from '../../Dialogs/DialogRevokeBurialReservation';
import DialogExhumation from '../../Dialogs/DialogExhumation';
import DialogPlanExhumation from '../../Dialogs/DialogPlanExhumation';
import DialogReservationToBurial from '../../Dialogs/DialogReservationToBurial';
import DialogEditDeceased from '../../Dialogs/DialogEditDeceased';
import DialogEditBurial from '../../Dialogs/DialogEditBurial';
import DialogEditReferent from '../../Dialogs/DialogEditReferent';
import FormGraveyardNav from "../../Forms/FormGraveyardNav";
import Filter2RoundedIcon from '@material-ui/icons/Filter2Rounded';
import FlipToFrontIcon from '@material-ui/icons/FlipToFront';
import HelperFileTransfer from '../../Helpers/HelperFileTransfer';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import FileUploadIcon from '../../../../images/file-upload-opt.svg'
import PersonIcon from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import JFile from '../../../JFile'

class AppActionGrave extends Component {

    fileTransfer = []

    state = {
        actionsMenu: {
            target: null
        },
        burialActionsMenu: {
            target: null
        },
        loaded: false,
        addingReferent: {},
        editingExtRef: {},
        expandExtRef: {},
        data: {
            myNameIs: '',
            location: '',
            gravespace: {},
            relatedBurial: null
        },
        uploadingFile: false,
        dialogs: {
            reservationToBurial: {
                open: false,
                sourceGrave: null,
                sourceBurialOrd: null
            },
            revokeBurialReservation: {
                open: false,
                sourceGrave: null,
                sourceBurialOrd: null
            },
            exhumation: {
                open: false,
                sourceGrave: null,
                sourceBurialOrd: null
            },
            planExhumation: {
                open: false,
                sourceGrave: null,
                sourceBurialOrd: null
            },
            editDeceased: {
                open: false,
                deceased: null
            },
            editBurial: {
                open: false,
                burial: null
            },
            editReferent: {
                open: false,
                referent: null
            }
        }
    }

    constructor(props) {
        super(props)
        this.actionData = this.props.appMng.actionsStack[this.props.id]
        this.props.appMng.setTitle('Sepolcro')
        this.getData()
    }

    getData() {
        if (this.actionData.params) {
            this.props.appMng.props.JBLClient.getGrave(this.actionData.params.key, 5)
                .then(response => {
                    if(Array.isArray(response.relatedBurial)) {
                        response.relatedBurial.forEach((element, index) => {
                            if(typeof element.external_references === "string") {
                                response.relatedBurial[index].external_references = JSON.parse(element.external_references)
                            }
                        })
                    }
                    this.setState({ loaded: true, data: {...response} })
                })
        }
    }

    editMe = () => {this.props.appMng.openAction('graveEditor', { key: this.actionData.params.key })}

    deleteMe = () => {
        this.hideActionsMenu()
        if((Array.isArray(this.state.data.relatedBurial) && this.state.data.relatedBurial.length > 0)){
            this.props.appMng.notifyMsgToGui({
                'kind': 'error',
                'text': "Non è possibile cancellare sepolcri che sono già stati utilizzati"
            })
        } else {
            this.props.appMng.openDialog('Eliminazione sepolcro', (
                <Fragment>
                    <DialogContentText id="main-dialog-description">
                        Confermi di voler eliminare definitivamente il sepolcro?
                    </DialogContentText>
                </Fragment>),
                (<Fragment>
                    <Button aria-label="rimuovi" onClick={() => {
                        this.props.appMng.props.JBLClient.deleteGrave(this.actionData.params.key)
                            .then(response => {
                                if (response) {
                                    this.props.appMng.closeDialog()
                                    this.closeMe()
                                }
                                return response
                            });
                        this.props.appMng.closeDialog()
                    }} >Conferma</Button>
                    <Button aria-label="Mantieni" onClick={() => this.props.appMng.closeDialog()}>Annulla</Button>
                </Fragment>))
        }
    }

    mergeMe = () => {
        this.setState({mergeTo: null})
        this.hideActionsMenu()
        if((Array.isArray(this.state.data.relatedBurial) && this.state.data.relatedBurial.length > 0)){
            this.props.appMng.notifyMsgToGui({
                'kind': 'error',
                'text': "Non è possibile unire sepolcri correntemente in uso"
            })
        } else {
            this.props.appMng.openDialog('Unione sepolcri', (
                <Fragment>
                    <DialogContent>
                        <DialogContentText id="main-dialog-description">
                            Vuoi unire il sepolcro ad uno o più adiacenti?
                            Seleziona il sepolcro con il quale devi unire quello corrente.
                        </DialogContentText>
                        <FormGraveyardNav
                            appMng={this.props.appMng}
                            graveyardKey={this.state.data.gravespace.graveyard.key}
                            onChange={(value) => this.setState({mergeTo: value})}
                            siblingsOf={{kind: 'g', key: this.actionData.params.key}}
                            availableOnly={true}
                        />
                    </DialogContent>
                </Fragment>),
                (<Fragment>
                    <Button aria-label="unisci" onClick={() => {
                        if(this.state.mergeTo) {
                            this.props.appMng.props.JBLClient.mergeGraveWith(this.actionData.params.key, this.state.mergeTo)
                            .then(response => {
                                if (response) {
                                    this.getData()
                                    this.props.appMng.closeDialog()
                                }
                                return response
                            });
                            this.props.appMng.closeDialog()
                        } else {
                            this.props.appMng.notifyMsgToGui({
                                'kind': 'error',
                                'text': "Scegli il sepolcro con il quale unire quello corrente"
                            })
                        }
                    }} >Conferma</Button>
                    <Button aria-label="annulla" onClick={() => {
                        this.setState({mergeTo: null})
                        this.props.appMng.closeDialog()
                    }}>Annulla</Button>
                </Fragment>))
        }
    }

    unmergeMe = () => {
        this.hideActionsMenu()
        if((Array.isArray(this.state.data.mergeGroup) && this.state.data.relatedBurial.length > 0)){
            this.props.appMng.notifyMsgToGui({
                'kind': 'error',
                'text': "Non è possibile sepolcri sepolcri correntemente in uso"
            })
        } else {
            this.props.appMng.openDialog('Separazione sepolcri', (
                <Fragment>
                    <DialogContent>
                        <DialogContentText id="main-dialog-description">
                            Vuoi separare l'unione di sepolcri che precedentemente erano stati uniti in uno solo?
                        </DialogContentText>
                    </DialogContent>
                </Fragment>),
                (<Fragment>
                    <Button aria-label="separa" onClick={() => {
                            this.props.appMng.props.JBLClient.unmergeGraves(this.actionData.params.key)
                            .then(response => {
                                if (response) {
                                    this.getData()
                                    this.props.appMng.closeDialog()
                                }
                                return response
                            });
                            this.props.appMng.closeDialog()
                    }} >Conferma</Button>
                    <Button aria-label="annulla" onClick={() => {
                        this.setState({mergeTo: null})
                        this.props.appMng.closeDialog()
                    }}>Annulla</Button>
                </Fragment>))
        }
    }

    closeMe = () => {this.props.appMng.closeAction(this.props.id)}

    remExtRef(buriallistPos, reflistPos) {
        if (Array.isArray(this.state.data.relatedBurial) && this.state.data.relatedBurial[buriallistPos] && Array.isArray(this.state.data.relatedBurial[buriallistPos].external_references) && this.state.data.relatedBurial[buriallistPos].external_references[reflistPos]) {
                const burialKey = this.state.data.relatedBurial[buriallistPos].key
                if (burialKey) {
                    this.props.appMng.openDialog('Rimozione riferimento esterno', (
                        <Fragment>
                            <DialogContentText id="main-dialog-description">
                                Confermi di voler rimuovere il riferimento esterno?
                            </DialogContentText>
                        </Fragment>),
                        (<Fragment>
                            <Button aria-label="rimuovi" onClick={() => {
                                const reduced = this.state.data.relatedBurial[buriallistPos].external_references
                                reduced.splice(reflistPos, 1)
                                this.props.appMng.props.JBLClient.updateExtRefBurial(burialKey, reduced)
                                    .then(response => {
                                        if (response) {
                                            this.setState((state) => {
                                                state.data.relatedBurial[buriallistPos].external_references = reduced
                                                state.editingExtRef['k' + buriallistPos] = undefined
                                                return state
                                            },this.getData())
                                        }
                                        return response
                                    });
                                this.props.appMng.closeDialog()
                            }} >Rimuovi</Button>
                            <Button aria-label="Mantieni" onClick={() => this.props.appMng.closeDialog()}>Mantieni</Button>
                        </Fragment>))
                } else {
                    this.props.appMng.notifyMsgToGui({
                        'kind': 'error',
                        'text': "Impossiblile individuare il riferimento esterno da rimuovere."
                    })
                }
        } else {
            this.props.appMng.notifyMsgToGui({
                'kind': 'error',
                'text': "Impossiblile individuare il riferimento esterno da rimuovere."
            })
        }
    }

    remReferent(buriallistPos, reflistPos) {
        if (Array.isArray(this.state.data.relatedBurial) && this.state.data.relatedBurial[buriallistPos] && Array.isArray(this.state.data.relatedBurial[buriallistPos].relatedReferent) && this.state.data.relatedBurial[buriallistPos].relatedReferent[reflistPos]) {
            if (this.state.data.relatedBurial[buriallistPos].relatedReferent.length > (this.state.addingReferent['k' + buriallistPos] ? 2 : 1)) {
                const burialKey = this.state.data.relatedBurial[buriallistPos].key
                const referentKey = this.state.data.relatedBurial[buriallistPos].relatedReferent[reflistPos].key
                if (burialKey && referentKey) {
                    this.props.appMng.openDialog('Rimozione referente', (
                        <Fragment>
                            <DialogContentText id="main-dialog-description">
                                Confermi di voler rimuovere il referente dall'elenco?
                            </DialogContentText>
                        </Fragment>),
                        (<Fragment>
                            <Button aria-label="rimuovi" onClick={() => {
                                this.props.appMng.props.JBLClient.removeReferentFromBurial(burialKey, referentKey)
                                    .then(response => {
                                        if (response) {
                                            this.setState((state) => {
                                                state.data.relatedBurial[buriallistPos].relatedReferent.splice(reflistPos, 1)
                                                return state
                                            })
                                        }
                                        return response
                                    });
                                this.props.appMng.closeDialog()
                            }} >Rimuovi</Button>
                            <Button aria-label="Mantieni" onClick={() => this.props.appMng.closeDialog()}>Mantieni</Button>
                        </Fragment>))
                } else {
                    this.props.appMng.notifyMsgToGui({
                        'kind': 'error',
                        'text': "Impossiblile individuare il referente da rimuovere."
                    })
                }
            } else {
                this.props.appMng.notifyMsgToGui({
                    'kind': 'error',
                    'text': "Non è possibile rimuovere l'unico referente presente. Deve esserci almeno un referente per i contatti."
                })
            }
        } else {
            this.props.appMng.notifyMsgToGui({
                'kind': 'error',
                'text': "Impossiblile individuare il referente da rimuovere."
            })
        }
    }

    addReferent(burialKey) {
        if (!this.state.addingReferent['k' + burialKey]) {
            this.setState((state) => {
                state.data.relatedBurial[burialKey].relatedReferent.push(new schemaReferent())
                state.addingReferent['k' + burialKey] = true
                return state
            })
        } else {
            this.props.appMng.notifyMsgToGui({
                'kind': 'error',
                'text': "L'aggiunta di un referente è già in corso. Prima di aggiungerne uno nuovo completare l'operazione."
            })
        }
    }

    editExtRef(burialKey, lPos) {
        if (this.state.editingExtRef['k' + burialKey] === undefined) {
            this.setState((state) => {
                if(!Array.isArray(state.data.relatedBurial[burialKey].external_references)){
                    state.data.relatedBurial[burialKey].external_references = []
                }
                const extRefItem = new schemaExtRef()
                if(typeof lPos === "number") {
                    if(state.data.relatedBurial[burialKey].external_references[lPos]) {
                        const origItem = state.data.relatedBurial[burialKey].external_references[lPos]
                        extRefItem.id = origItem.id
                        extRefItem.label = origItem.label
                        extRefItem.notes = origItem.notes
                        state.editingExtRef['k' + burialKey] = lPos
                    } else {
                        this.props.appMng.notifyMsgToGui({
                            'kind': 'error',
                            'text': "La posizione del riferimento esterno da modificare non è valida."
                        })
                    }
                } else {
                    extRefItem.isNew = true
                    state.data.relatedBurial[burialKey].external_references.push(extRefItem)
                    state.editingExtRef['k' + burialKey] = state.data.relatedBurial[burialKey].external_references.length - 1
                }
                return state
            })
        } else {
            this.props.appMng.notifyMsgToGui({
                'kind': 'error',
                'text': "L'editazione di un riferimento esterno è già in corso. Prima di aggiungerne uno nuovo completare l'operazione."
            })
        }
    }

    showBurialActionsMenu(e, data, pos) {
        let items = []
        if (data.isReservation) {
            if (!data.actual_exhumation_date) {
                items.push(<MenuItem key="1" onClick={() => {
                    this.setState((state => {
                        state.burialActionsMenu.target = null
                        state.dialogs.reservationToBurial.open = true
                        state.dialogs.reservationToBurial.sourceBurialOrd = pos
                        return state
                    }));
                }}>Registra inumazione</MenuItem>)
                items.push(<MenuItem key="2" onClick={() => {
                    this.setState((state => {
                        state.burialActionsMenu.target = null
                        state.dialogs.planExhumation.open = true
                        state.dialogs.planExhumation.sourceBurialOrd = pos
                        return state
                    }));
                }}>Cambia termine contratto</MenuItem>)
                items.push(<MenuItem key="3" onClick={() => {
                    this.setState((state => {
                        state.burialActionsMenu.target = null
                        state.dialogs.revokeBurialReservation.open = true
                        state.dialogs.revokeBurialReservation.sourceBurialOrd = pos
                        return state
                    }));
                }}>Revoca prenotazione</MenuItem>)
            }
        } else {
            if (!data.actual_exhumation_date) {
                items.push(<MenuItem key="1" onClick={() => {
                    this.setState((state => {
                        state.burialActionsMenu.target = null
                        state.dialogs.exhumation.open = true
                        state.dialogs.exhumation.sourceBurialOrd = pos
                        return state
                    }));
                }}>Rigistra esumazione</MenuItem>)
                items.push(<MenuItem key="2" onClick={() => {
                    this.setState((state => {
                        state.burialActionsMenu.target = null
                        state.dialogs.planExhumation.open = true
                        state.dialogs.planExhumation.sourceBurialOrd = pos
                        return state
                    }));
                }}>Cambia termine per esumazione</MenuItem>)
                items.push(<MenuItem key="3" onClick={() => {
                    this.setState((state => {
                        state.burialActionsMenu.target = null
                        state.dialogs.editDeceased.open = true
                        state.dialogs.editDeceased.deceased = this.state.data.relatedBurial[pos].deceased
                        return state
                    }));
                }}>Correggi dati deceduto</MenuItem>)
                items.push(<MenuItem key="4" onClick={() => {
                    this.setState((state => {
                        state.burialActionsMenu.target = null
                        state.dialogs.editBurial.open = true
                        state.dialogs.editBurial.burial = this.state.data.relatedBurial[pos]
                        return state
                    }));
                }}>Correggi dati sepoltura</MenuItem>)
            }
        }
        this.setState({
            burialActionsMenu: {
                target: e.target,
                items: items,
            }
        })
    }

    hideBurialActionsMenu() {
        this.setState({ burialActionsMenu: { target: null } })
    }

    drawBurial(item, pos) {
        const burial_date = (item.burial_date ? new Date(item.burial_date) : null)
        const exhumation_date = (item.actual_exhumation_date ? new Date(item.actual_exhumation_date) : null)
        const planned_exhumation_date = (item.planned_exhumation_date ? new Date(item.planned_exhumation_date) : null)
        const deathdate = ((item.deceased && item.deceased.deathdate) ? new Date(item.deceased.deathdate) : null)
        const birthday = ((item.deceased && item.deceased.birthday) ? new Date(item.deceased.birthday) : null)
        const classes = this.props.classes
        this.fileTransfer[pos] = new HelperFileTransfer(this.props.appMng)
        this.fileTransfer[pos].setUploadURIGenerator(file => this.props.appMng.props.JBLClient.getBurialUploadLink(item.key,{name: file.name}))
        return <Card key={pos} elevation={1}>
            {item.isReservation ?
                <Fragment>
                    <CardHeader
                        title={<em>Contratto di prenotazione</em>}
                        classes={{ root: (item.actual_exhumation_date? this.props.classes.exumatedHeader : this.props.classes.itemsHeaderReservation) }}
                        action={item.actual_exhumation_date? null :
                            <IconButton aria-label="menu azioni" color="inherit" onClick={(e) => this.showBurialActionsMenu(e, item, pos)}>
                                <MenuRoundedIcon />
                            </IconButton>
                        }
                    >
                    </CardHeader>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item container spacing={2} md={6}>
                                <Grid item md={4} sm={6}><b>Riservato dal</b></Grid>
                                <Grid item md={8} sm={6}>{(burial_date ? burial_date.toLocaleDateString('it-IT', { dateStyle: "full" }) : 'non ancora fissato')}</Grid>
                            </Grid>
                            <Grid item container spacing={2} md={6}>
                                <Grid item md={4} sm={6}><b>Scadenza</b></Grid>
                                <Grid item md={8} sm={6}>{(planned_exhumation_date ? planned_exhumation_date.toLocaleDateString('it-IT', { dateStyle: "full" }) : 'mai')}</Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Fragment>
                :
                <Fragment>
                    <CardHeader
                        title={item.deceased.myNameIs}
                        subheader={<Fragment>
                            <Typography variant="body1" component="span">α</Typography> <Typography variant="caption" component="span"><b>{(birthday ? (item.deceased.birthdayMask === 'yearOnly' ? birthday.getFullYear(): birthday.toLocaleDateString('it-IT', { dateStyle: "full" })) : 'sconosciuta')}</b></Typography><Typography variant="body1" component="span"> - ω</Typography> <Typography variant="caption" component="span"><b>{(deathdate ? ( item.deceased.deathdateMask === 'yearOnly' ? deathdate.getFullYear() : deathdate.toLocaleDateString('it-IT', { dateStyle: "full" })) : 'sconosciuta')}</b></Typography>
                        </Fragment>}
                        classes={{
                            root: (item.actual_exhumation_date ? this.props.classes.exumatedHeader : this.props.classes.itemsHeader),
                            subheader: this.props.classes.itemsSubHeader,
                         }}
                        action={item.actual_exhumation_date? null :
                            <IconButton aria-label="menu azioni" color="inherit" onClick={(e) => this.showBurialActionsMenu(e, item, pos)}>
                                <MenuRoundedIcon />
                            </IconButton>
                        }
                    >
                    </CardHeader>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item container spacing={2} md={6}>
                                <Grid item md={4} sm={6}><b>Data inumazione</b></Grid>
                                <Grid item md={8} sm={6}>{(burial_date ? burial_date.toLocaleDateString('it-IT', { dateStyle: "full" }) : 'sconosciuta')}</Grid>
                            </Grid>
                            <Grid item container spacing={2} md={6}>
                                <Grid item md={4} sm={6}><b>Data esumazione</b></Grid>
                                <Grid item md={8} sm={6}>{(exhumation_date ? exhumation_date.toLocaleDateString('it-IT', { dateStyle: "full" }) : 'ancora non avvenuta')}</Grid>
                            </Grid>
                            <Grid item container spacing={2} md={6}>
                                <Grid item md={4} sm={6}><b>Previsione esumazione</b></Grid>
                                <Grid item md={8} sm={6}>{(planned_exhumation_date ? planned_exhumation_date.toLocaleDateString('it-IT', { dateStyle: "full" }) : 'mai')}</Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Fragment>
            }
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="extRefs-content"
                    id="extRefs-header"
                >
                    <Typography>Riferimenti esterni ({Array.isArray(item.external_references) ? item.external_references.length : 0})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List aria-label="lista dei riferimenti a registrazioni esterne" style={{ flex: "1" }}>
                        {item.external_references ?
                            item.external_references.map((refItem, refPos) => {
                                return (
                                    <Fragment key={refPos}>
                                        {(this.state.editingExtRef['k' + pos] === refPos) ?
                                            <ListItem>
                                                <Divider></Divider>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item><Typography variant="h6">Riferimento esterno</Typography></Grid>
                                                    <Grid item><FormExtRef
                                                        appMng={this.props.appMng}
                                                        data={this.state.data.relatedBurial[pos].external_references[refPos]}
                                                        onChange={e=>{
                                                            const iData = {...this.state.data}
                                                            iData.relatedBurial[pos].external_references[refPos] = e.data
                                                            this.setState({data: iData})
                                                        }}
                                                    ></FormExtRef></Grid>
                                                    <Grid container spacing={1}>
                                                        <Grid item>
                                                            <Button onClick={() => {
                                                                this.props.appMng.props.JBLClient.updateExtRefBurial(this.state.data.relatedBurial[pos].key, this.state.data.relatedBurial[pos].external_references)
                                                                    .then(() => {
                                                                        this.setState((state) => {
                                                                            state.editingExtRef['k' + pos] = undefined
                                                                            return state
                                                                        }, this.getData())
                                                                    })
                                                            }} startIcon={<CheckCircleRoundedIcon/>}>Conferma</Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button onClick={() => {
                                                                this.setState((state) => {
                                                                    state.editingExtRef['k' + pos] = undefined
                                                                    if(refItem.isNew) {
                                                                        state.data.relatedBurial[pos].external_references.splice(refPos,1)
                                                                    }
                                                                    return state
                                                                })
                                                            }} startIcon={<CancelRoundedIcon/>}>Annulla</Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            :
                                            <ListItem button onClick={e => this.setState((state) => {
                                                    state.expandExtRef[refPos] = !state.expandExtRef[refPos]
                                                    return state
                                                })}>
                                                <ListItemText
                                                    primary={<>{refItem.label} : <b>{refItem.id}</b></>}
                                                    secondary={this.state.expandExtRef[refPos]?(refItem.notes?refItem.notes:<i>nessuna annotazione</i>):null}
                                                />
                                                <ListItemSecondaryAction>
                                                    <IconButton edge="end" aria-label="modifica" onClick={() => this.editExtRef(pos, refPos)}><EditIcon/></IconButton>
                                                    <IconButton edge="end" aria-label="rimuovi" onClick={() => this.remExtRef(pos, refPos)}><DeleteRoundedIcon /></IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        }
                                    </Fragment>
                                )
                            })
                        : null }
                        {!this.state.addingReferent['k' + pos] && !item.actual_exhumation_date &&
                            <ListItem button key="addNew">
                                <ListItemIcon><AddCircleIcon/></ListItemIcon>
                                <ListItemText primary={<em>aggiungi riferimento</em>} onClick={() => this.editExtRef(pos)} />
                            </ListItem>
                        }
                    </List>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="attachedFiles-content"
                    id="attachedFiles-header"
                >
                    <Typography>Documenti allegati ({item.relatedAttachedFile ? item.relatedAttachedFile.length : 0})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>{
                        item.relatedAttachedFile.map((fileItem, filePos) => <Grid item key={filePos} xs={12} sm={6} md={2}>
                            <JFile data={fileItem} appMng={this.props.appMng}
                                onChange={() => this.props.appMng.props.JBLClient.getBurial(this.state.data.relatedBurial[pos].key, {noCache: true}).then(()=>this.getData())}
                                onDelete={(fileKey)=>this.props.appMng.props.JBLClient.burialUnattchFile(item.key,fileKey).then(() => this.props.appMng.props.JBLClient.getBurial(this.state.data.relatedBurial[pos].key, {noCache: true}).then(()=>this.getData()))}/>
                        </Grid>)}
                        <Grid item key="upload" xs={12} sm={6} md={2} component="label" style={{position: "relative"}}>
                            <input type="file" style={{ display: "none" }} onChange={e => {
                                this.setState({uploadingFile: true})
                                this.fileTransfer[pos].upload(e.target.files[0]).then(() => {
                                    setTimeout(() => this.props.appMng.props.JBLClient.getBurial(this.state.data.relatedBurial[pos].key, {noCache: true}).then(
                                        ()=>{
                                            this.getData()
                                            this.setState({uploadingFile: false})
                                        })
                                        , 1000)
                                    }
                                )
                            }}/>
                            {this.state.uploadingFile && <CircularProgress size={24} className={classes.fileWaiter}/>}
                            <div className={classes.box}>
                                <Grid container spacing={2} direction="column" alignItems="center" className={classes.boxContent}>
                                    <Grid item>
                                        <img src={FileUploadIcon} alt='upload file' style={{width: "100%"}}/>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.filename}><i><b>carica documento</b></i></Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Referenti ({item.relatedReferent.length})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List aria-label="lista delle persone di riferimento" style={{ flex: "1" }}>
                        {
                            item.relatedReferent.map((refItem, refPos) => {
                                return (
                                    <Fragment key={refPos}>
                                        {(this.state.addingReferent['k' + pos] && refPos === (item.relatedReferent.length - 1)) ?
                                            <ListItem>
                                                <Divider></Divider>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item><Typography variant="h6">Dati referente</Typography></Grid>
                                                    <Grid item><FormReferent
                                                        appMng={this.props.appMng}
                                                        data={this.state.data.relatedBurial[pos].relatedReferent[refPos]}
                                                        onChange={e=>{
                                                            const iData = {...this.state.data}
                                                            iData.relatedBurial[pos].relatedReferent[refPos] = e.data
                                                            this.setState({data: iData})
                                                        }}
                                                    ></FormReferent></Grid>
                                                    <Grid container spacing={1}>
                                                        <Grid item>
                                                            <Button onClick={() => {
                                                                this.props.appMng.props.JBLClient.addReferentToBurial(this.state.data.relatedBurial[pos].key, this.state.data.relatedBurial[pos].relatedReferent[refPos])
                                                                    .then(response => {
                                                                        this.setState((state) => {
                                                                            state.addingReferent['k' + pos] = false
                                                                            return state
                                                                        })
                                                                    });
                                                                }} startIcon={<CheckCircleRoundedIcon/>}>Conferma</Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button onClick={() => {
                                                                this.setState((state) => {
                                                                    state.addingReferent['k' + pos] = false
                                                                    state.data.relatedBurial[pos].relatedReferent.splice(refPos, 1)
                                                                    return state
                                                                })
                                                            }} startIcon={<CancelRoundedIcon/>}>Annulla</Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            :
                                            <ListItem button onClick={()=>this.setState((state => {
                                                    state.dialogs.editReferent.open = true
                                                    state.dialogs.editReferent.referent = refItem
                                                    return state
                                                }))}>
                                                <ListItemIcon>{refItem.legalPerson?<BusinessIcon/>:<PersonIcon/>}</ListItemIcon>
                                                <ListItemText
                                                    primary={refItem.name + ' ' + (refItem.legalPerson ? '' :refItem.surname)}
                                                />
                                                {item.actual_exhumation_date? null :
                                                <ListItemSecondaryAction>
                                                    <IconButton edge="end" aria-label="rimuovi" onClick={() => this.remReferent(pos, refPos)}><DeleteRoundedIcon /></IconButton>
                                                </ListItemSecondaryAction>}
                                            </ListItem>
                                        }
                                    </Fragment>
                                )
                            })
                        }
                        {!this.state.addingReferent['k' + pos] && !item.actual_exhumation_date &&
                            <ListItem button key="addNew">
                                <ListItemIcon><AddCircleIcon/></ListItemIcon>
                                <ListItemText primary={<em>aggiungi referente</em>} onClick={() => this.addReferent(pos)} />
                            </ListItem>
                        }
                    </List>
                </AccordionDetails>
            </Accordion>
        </Card>
    }

    showActionsMenu(e) {
        let items = []
        items.push(
        <MenuItem key="1" onClick={this.editMe}><ListItemIcon><EditRoundedIcon /></ListItemIcon><ListItemText>Modifica</ListItemText></MenuItem>,
        ((!Array.isArray(this.state.data.relatedBurial) || this.state.data.relatedBurial.length === 0) ? <MenuItem key="2" onClick={this.mergeMe}><ListItemIcon><Filter2RoundedIcon/></ListItemIcon><ListItemText>Unisci</ListItemText></MenuItem> : null),
        ((this.state.data.mergeGroup && this.state.data.mergeGroup.rid) ? <MenuItem key="4" onClick={this.unmergeMe}><ListItemIcon><FlipToFrontIcon/></ListItemIcon><ListItemText>Separa</ListItemText></MenuItem> : null),
        <MenuItem key="3" onClick={this.deleteMe}><ListItemIcon><DeleteForeverRoundedIcon/></ListItemIcon><ListItemText>Elimina</ListItemText></MenuItem>,
        <MenuItem key="300" onClick={this.closeMe}><ListItemIcon><HighlightOffRoundedIcon /></ListItemIcon><ListItemText>Chiudi</ListItemText></MenuItem>
        )
        this.setState({
            actionsMenu: {
                target: e.target,
                items: items,
            }
        })
    }

    hideActionsMenu() {
        this.setState({ actionsMenu: { target: null } })
    }

    render() {
        return (
            <Fragment>
                <Card>
                    <CardMedia className={this.props.classes.headMedia} image={mediaHeader}>
                        <Box p={3} className={this.props.classes.itemsHeaderText}>
                            <Typography variant="h4" color="inherit">
                                {this.state.loaded ? this.state.data.myNameIs + (this.state.data.mergeGroup? ' (unione di '+this.state.data.mergeGroup.graves.length+ ' spazi)':'') : <Skeleton height="32px" width="100px" />}
                            </Typography>
                        </Box>
                        {this.state.loaded ? <>
                            <Fab aria-label="modifica dati sepoltura" title="modifica i dati" color="secondary" className={this.props.classes.fabEdit} onClick={this.editMe}>
                                <EditRoundedIcon/>
                            </Fab><Fab aria-label="più opzioni" title="più opzioni" color="primary" className={this.props.classes.fabMenu} onClick={(e) => this.showActionsMenu(e)}>
                                <MenuRoundedIcon/>
                            </Fab><Fab aria-label="mostra sulla mappa" title="localizza" color="primary" className={this.props.classes.fabMap} onClick={(e) => this.props.appMng.openAction('graveyard', { key: this.state.data.gravespace.graveyard.key, highlightGrave: {key: this.state.data.key, level: this.state.data.gravespace.level, label: this.state.data.location + ', ' + this.state.data.myNameIs }})}>
                                <LocationOnIcon/>
                            </Fab>
                            </>:<Fragment></Fragment>
                        }
                    </CardMedia>
                    {this.state.loaded ?
                        <Fragment>
                            <CardContent>
                                <Grid container item xs={12} md={12} lg={12} className={this.props.classes.entityLine} alignItems="flex-start">
                                        <Grid item xs={10} md={10} lg={11}>
                                            presso: <Typography component="span" variant="body1" className={this.props.classes.parentLink} onClick={() => this.props.appMng.openAction('graveyard', { key: this.state.data.gravespace.graveyard.key })}>
                                            <b>{this.state.data.gravespace.graveyard.myNameIs}</b> <OpenInNewRoundedIcon />
                                            </Typography> - <Typography component="span" variant="body1" className={this.props.classes.parentLink} onClick={() => this.props.appMng.openAction('org', { key: this.state.data.gravespace.graveyard.organizationalUnit.organization.key })}>
                                                {this.state.data.gravespace.graveyard.organizationalUnit.organization.myNameIs} <OpenInNewRoundedIcon />
                                            </Typography>, <Typography component="span" variant="body1" className={this.props.classes.parentLink} onClick={() => this.props.appMng.openAction('OUnit', {orgKey: this.state.data.gravespace.graveyard.organizationalUnit.organization.key, key: this.state.data.gravespace.graveyard.organizationalUnit.key})}>
                                                {this.state.data.gravespace.graveyard.organizationalUnit.myNameIs} <OpenInNewRoundedIcon />
                                            </Typography>
                                        </Grid>
                                </Grid>
                                <Typography variant="body1">
                                    <b><i>{this.state.data.location}</i></b>
                                </Typography>
                                <Typography variant="body1">
                                    <b>Numero massimo di spoglie tumulabili: <i>{this.state.data.burialLimit}</i></b>
                                </Typography>
                            </CardContent>
                            <CardContent>
                                {(Array.isArray(this.state.data.relatedBurial) && this.state.data.relatedBurial.length > 0) ?
                                    <List>{this.state.data.relatedBurial.map((item, pos) => this.drawBurial(item, pos))}</List> :
                                    <Typography color="primary" variant="h4">Il sepolcro è vuoto</Typography>
                                }
                            </CardContent>
                            <CardActions>
                                {this.state.data.canAcceptNewBurial && <Button onClick={() => this.props.appMng.openAction('burialEditor', { destGraveKey: this.actionData.params.key, defaultGrantDuration: this.state.data.spaceModel.defaultGrantDuration })}><AddBoxRoundedIcon /> Nuova sepoltura</Button>}
                                <Button onClick={() => this.props.appMng.closeAction(this.props.id)}><HighlightOffRoundedIcon /> Chiudi</Button>
                            </CardActions>
                        </Fragment>
                        :
                        <CardContent>
                            <Skeleton height="60px" width="100%" />
                        </CardContent>
                    }
                </Card >
                <Menu
                    anchorEl={this.state.actionsMenu.target}
                    open={Boolean(this.state.actionsMenu.target)}
                    keepMounted
                    onClose={() => this.hideActionsMenu()}
                >
                    {this.state.actionsMenu.items}
                </Menu>
                <Menu
                    anchorEl={this.state.burialActionsMenu.target}
                    open={Boolean(this.state.burialActionsMenu.target)}
                    keepMounted
                    onClose={() => this.hideBurialActionsMenu()}
                >
                    {this.state.burialActionsMenu.items}
                </Menu>
                <DialogReservationToBurial
                    appMng={this.props.appMng}
                    open={this.state.dialogs.reservationToBurial.open}
                    sourceGrave={this.state.data}
                    sourceBurialOrd={this.state.dialogs.reservationToBurial.sourceBurialOrd}
                    onClose={() => {
                        this.getData()
                        this.setState((state => {
                            state.dialogs.reservationToBurial.open = false
                            return state
                        }));
                    }}
                />
                <DialogRevokeBurialReservation
                    appMng={this.props.appMng}
                    open={this.state.dialogs.revokeBurialReservation.open}
                    sourceGrave={this.state.data}
                    sourceBurialOrd={this.state.dialogs.revokeBurialReservation.sourceBurialOrd}
                    onClose={() => {
                        this.getData()
                        this.setState((state => {
                            state.dialogs.revokeBurialReservation.open = false
                            return state
                        }));
                    }}
                />
                <DialogExhumation
                    appMng={this.props.appMng}
                    open={this.state.dialogs.exhumation.open}
                    sourceGrave={this.state.data}
                    sourceBurialOrd={this.state.dialogs.exhumation.sourceBurialOrd}
                    onClose={() => {
                        this.getData()
                        this.setState((state => {
                            state.dialogs.exhumation.open = false
                            return state
                        }));
                    }}
                />
                <DialogPlanExhumation
                    appMng={this.props.appMng}
                    open={this.state.dialogs.planExhumation.open}
                    sourceGrave={this.state.data}
                    sourceBurialOrd={this.state.dialogs.planExhumation.sourceBurialOrd}
                    onClose={() => {
                        this.getData()
                        this.setState((state => {
                            state.dialogs.planExhumation.open = false
                            return state
                        }));
                    }}
                />
                <DialogEditDeceased
                    appMng={this.props.appMng}
                    open={this.state.dialogs.editDeceased.open}
                    deceased={this.state.dialogs.editDeceased.deceased}
                    onClose={() => {
                        this.getData()
                        this.setState((state => {
                            state.dialogs.editDeceased.open = false
                            return state
                        }));
                    }}
                />
                <DialogEditBurial
                    appMng={this.props.appMng}
                    open={this.state.dialogs.editBurial.open}
                    burial={this.state.dialogs.editBurial.burial}
                    onClose={() => {
                        this.getData()
                        this.setState((state => {
                            state.dialogs.editBurial.open = false
                            return state
                        }));
                    }}
                />
                <DialogEditReferent
                    appMng={this.props.appMng}
                    open={this.state.dialogs.editReferent.open}
                    referent={this.state.dialogs.editReferent.referent}
                    onClose={() => {
                        this.getData()
                        this.setState((state => {
                            state.dialogs.editReferent.open = false
                            return state
                        }));
                    }}
                />
            </Fragment>
        )
    }
}

export default withStyles(AppActionGraveStyle)(AppActionGrave)