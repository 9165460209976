import React from 'react'

const JPolyIconGrid = (props) => {
    const defColor = props.color ? props.color : 'black'
    const bgColor = props.bgColor ? props.bgColor : 'white'
    return <g onClick={(e) => props.onClick(e)} transform={`translate(${props.x || 0} ${props.y || 0})scale(${((props.size || 24) / 1000)})`} vectorEffect="non-scaling-stroke">
        <circle cx="500" cy="500" r="500" fill={bgColor} fillOpacity="0.6" stroke={defColor} strokeLinejoin="round" strokeWidth="90" paintOrder="markers stroke fill" />
        <path d="m338.47 129.58v105.83h52.918v-105.83zm270.13 0v105.83h52.916v-105.83zm-479.03 208.89v52.918h105.83v-52.918zm211.67 0v2.7755h-2.775v105.83h52.918v-55.691h55.691v-52.918zm211.67 0v52.918h55.694v55.691h52.916v-105.83h-2.7756v-2.7755zm211.67 0v52.918h105.83v-52.918zm-426.11 214.44v105.83h2.775v2.775h105.83v-52.916h-55.691v-55.694zm270.13 0v55.694h-55.694v52.916h105.83v-2.775h2.7756v-105.83zm-479.03 55.694v52.916h105.83v-52.916zm635 0v52.916h105.83v-52.916zm-426.11 155.97v105.83h52.918v-105.83zm270.13 0v105.83h52.916v-105.83z"
            fill={defColor}
            paintOrder="markers stroke fill"
        />
    </g>
}

export default JPolyIconGrid