import React, { Component, Fragment } from 'react'
import { DialogContentText, Button, DialogContent, Grid, TextField, Link } from '@material-ui/core'
import VpnKeyRoundedIcon from '@material-ui/icons/VpnKeyRounded';

class DialogChangePWD extends Component {

    showStatus = false

    fields = {
        password: null,
        repeatPassword: null
    }

    /** It says if all the required infromation has been collected */
    canProcede() {
        return (this.fields.password !== null && this.fields.repeatPassword !== null && this.fields.password === this.fields.repeatPassword)
    }

    /** Performs the recording of exhumation */
    procede() {
        if (this.canProcede()) {
            this.props.appMng.props.JBLClient.changeMyPassword(this.fields.password).then(
                (result) => {
                    if(result){
                        this.fields = {
                            password: null,
                            repeatPassword: null
                        }
                        this.close()
                    }
                }
            );
        } else {
            this.props.appMng.notifyMsgToGui({
                'kind': 'error',
                'text': "Una o più informazioni necessarie per poter procedere risultano mancanti oppure la password non coincide con quella ripetuta."
            })
        }
    }

    /** Set the value of a field
     * @param fname name of the field
     * @param fvalue new value of the field
     */
    setField(fname, fvalue) {
        switch (fname) {
            case 'password':
                this.fields.password = (fvalue ? fvalue : null)
                break
            case 'repeatPassword':
                this.fields.repeatPassword = (fvalue ? fvalue : null)
                break
            default:
                return false
        }
        return this.show(this.props.open)
    }

    /** Show or hide the content of the dialog box
     * @param status dialog opened o closed
    */
    show(status) {
        if (status) {
            this.props.appMng.openDialog(<Fragment>Cambio password&nbsp;<VpnKeyRoundedIcon/></Fragment>, (
                <Fragment>
                    <DialogContent>
                        <DialogContentText id="main-dialog-description">
                            La nuova password deve essere lunga <b>almeno 8 caratteri</b>, contenere <b>almeno una lettera maiuscola, una minusola e una cifra numerica</b>.<br/>
                            Scegli una password sicura.
                            <Link variant="body1" target="_blank" href="https://www.wikihow.it/Scegliere-una-Password-Sicura">Guarda qualche sugerimento</Link>
                        </DialogContentText>
                        <Grid container spacing={1} direction="column" justify="flex-start" alignItems="stretch">
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Nuova password"
                                    type="password"
                                    id="password"
                                    autoComplete="off"
                                    value={this.fields.password?this.fields.password:''}
                                    onChange={e => this.setField('password', e.target.value )}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="newpassword"
                                    label="Ripeti password"
                                    type="password"
                                    id="repeatPassword"
                                    autoComplete="off"
                                    value={this.fields.repeatPassword?this.fields.repeatPassword:''}
                                    onChange={e => this.setField('repeatPassword', e.target.value )}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Fragment>),
                (<Fragment>
                    <Button aria-label="procedi" disabled={!this.canProcede()} onClick={() => this.procede()}>Procedi</Button>
                    <Button aria-label="annulla" onClick={() => this.close()}>Annulla</Button>
                </Fragment>))
        } else {
            this.props.appMng.closeDialog()
        }
        this.showStatus = status
        return true
    }

    close() {
        if (typeof this.props.onClose === "function") { this.props.onClose() }
        this.showStatus = false
        this.props.appMng.closeDialog()
    }

    render() {
        if (this.props.open !== this.showStatus) {
            this.show(this.props.open)
        }
        return null
    }

}

export default DialogChangePWD