import React, { Component, Fragment } from 'react'
import { DialogContentText, Button, DialogContent, RadioGroup, FormControlLabel, Radio, Chip, Grid, TextField, Switch, Divider } from '@material-ui/core'
import RotateLeftRoundedIcon from '@material-ui/icons/RotateLeftRounded';
import FormGraveyardNav from '../../Forms/FormGraveyardNav';
import FormGraveyardSelector, { FormGraveyardSelectorNIL } from '../../Forms/FormGraveyardSelector';

class DialogExhumation extends Component {

    showStatus = false

    destination = {}

    variants = [
        { k: "same_graveyard", label: "nello stesso cimitero" },
        { k: "other_graveyard", label: "in un altro cimitero" },
        { k: "private_house", label: "ceneri affidate ai familiari" },
        { k: "reservedArea_dispersion", label: "dispersione delle ceneri" }
    ]

    constructor(props) {
        super(props)
        this.initDestination()
    }

    initDestination() {
        this.destination = {
            variant: null,              /* name of the destination variant */
            variantPos: null,           /* position in the array of optional variant */
            destGraveyardID: null,      /* identifier of the destination graveyard (diferent from the source) */
            foreignGraveyard: null,     /* name of the destination graveyard not managed by the system (diferent from the source) */
            destGraveID: null,          /* identifier of the destination grave */
            annotation: null,           /* annotation text */
            exhumationDate: null,       /* date when the remains have been exhumated */
            relocationDate: null,       /* date when the remains have been placed to their destination */
            destBurialDuration: 10,     /* planned years in the destination burial */
            infiniteDestBurial: false,  /* the destination burial will never end */
            showDest: true              /* when true, at end of the execution shows the destionetion burial */
        }
    }

    /** It says if all the required infromation has been collected */
    canProcede() {
        switch (this.destination.variant) {
            case "same_graveyard":
                return (this.destination.destGraveID !== null && this.destination.exhumationDate !== null && this.destination.relocationDate !== null)
            case "other_graveyard":
                return (
                    this.destination.destGraveyardID !== null && (
                        (this.destination.destGraveyardID === FormGraveyardSelectorNIL && this.destination.foreignGraveyard !== null)
                        || (this.destination.destGraveyardID !== FormGraveyardSelectorNIL && this.destination.destGraveID !== null)
                    ) && this.destination.exhumationDate !== null && this.destination.relocationDate !== null)
            case "reservedArea_dispersion":
            case "private_house":
                return (this.destination.annotation !== null && this.destination.exhumationDate !== null && this.destination.relocationDate !== null)
            default:
                return false
        }
    }

    /** Performs the recording of exhumation */
    procede() {
        if (this.canProcede()) {
            let dataPack = null

            const plannedExhumationDate = (burial_date, years, infinite) => {
                if (burial_date && years && !infinite) {
                    const planned_exhumation_date = new Date(burial_date)
                    planned_exhumation_date.setFullYear(parseInt(planned_exhumation_date.getFullYear(), 10) + parseInt(years, 10))
                    const day = planned_exhumation_date.getDate().toString()
                    const month = (planned_exhumation_date.getMonth() + 1).toString()
                    const year = planned_exhumation_date.getFullYear().toString()
                    return year.padStart(4, '0') + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0')
                } else {
                    return null
                }
            }

            switch (this.destination.variant) {
                case "same_graveyard":
                    dataPack = {
                        destGraveID: this.destination.destGraveID,
                        exhumationDate: this.destination.exhumationDate,
                        relocationDate: this.destination.relocationDate,
                        destBurialPlannedExhumationDate: plannedExhumationDate(this.destination.relocationDate, this.destination.destBurialDuration, this.infiniteDestBurial),
                        annotation: this.destination.annotation

                    }
                    break
                case "other_graveyard":
                    dataPack = (this.destination.destGraveyardID === FormGraveyardSelectorNIL ? {
                        foreignGraveyard: this.destination.foreignGraveyard,
                        exhumationDate: this.destination.exhumationDate,
                        relocationDate: this.destination.relocationDate,
                        annotation: this.destination.annotation
                    } : {
                            destGraveyardID: this.destination.destGraveyardID,
                            destGraveID: this.destination.destGraveID,
                            exhumationDate: this.destination.exhumationDate,
                            relocationDate: this.destination.relocationDate,
                            destBurialPlannedExhumationDate: plannedExhumationDate(this.destination.relocationDate, this.destination.destBurialDuration, this.infiniteDestBurial),
                            annotation: this.destination.annotation
                        })
                    break
                case "reservedArea_dispersion":
                    dataPack = {
                        noDestGrave: 'ashesInReservdArea',
                        exhumationDate: this.destination.exhumationDate,
                        relocationDate: this.destination.relocationDate,
                        annotation: this.destination.annotation
                    }
                    break
                case "private_house":
                    dataPack = {
                        noDestGrave: 'ashesEntrustedToFamily',
                        exhumationDate: this.destination.exhumationDate,
                        relocationDate: this.destination.relocationDate,
                        annotation: this.destination.annotation
                    }
                    break
                default:
                    return false
            }
            if (dataPack) { //it there are data to send to the service
                this.props.appMng.props.JBLClient.recordExhumation(this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd].key, dataPack)  //request the recording of the exhumation
                    .then(response => {
                        if (typeof response === "object" && response.success) {   //if the response is a success
                            this.close()                                        //close the dialog
                            if (this.destination.showDest && response.destBurial !== null && response.destBurial.grave !== null) {    //and if reqired show the destination grave's action
                                Promise.resolve(response.destBurial.grave.key).then(val => {
                                    this.props.appMng.openAction("grave", { key: val })
                                })
                            }
                        }
                    });
            }
        } else {
            this.props.appMng.notifyMsgToGui({
                'kind': 'error',
                'text': "Una o più informazioni necessarie per poter procedere risultano mancanti. Per favore compila i dati mancanti."
            })
        }
    }

    /** Set the value of a field
     * @param fname name of the field
     * @param fvalue new value of the field
     */
    setField(fname, fvalue) {
        switch (fname) {
            case 'variant':                         //chosen variant of the destination of the deceased remains
                this.initDestination()
                let found = false
                for (let opPos in this.variants) {    //verify if it is a valid one
                    let opValue = this.variants[opPos]
                    if (opValue.k === fvalue) {
                        this.destination.variant = fvalue
                        this.destination.variantPos = opPos
                        found = true
                        break;
                    }
                    if (!found) {                     //if not, none is selected
                        this.destination.variant = null
                        this.destination.variantPos = null
                    }
                }
                break
            case 'destGraveyardID':                 //destination grave identifier if different from the source one
                this.destination.destGraveyardID = (fvalue > 0 || fvalue === FormGraveyardSelectorNIL ? fvalue : null)
                break
            case 'destGraveID':                     //destination grave identifier
                this.destination.destGraveID = (fvalue > 0 ? fvalue : null)
                if(this.destination.destGraveID > 0) {
                    this.props.appMng.props.JBLClient.getGrave(this.destination.destGraveID).then(result => {
                        if(result.spaceModel.defaultGrantDuration > 0) {
                            this.setField('destBurialDuration', result.spaceModel.defaultGrantDuration)
                        } else {
                            this.setField('infiniteDestBurial', true)
                        }
                    })
                }
                break
            case 'annotation':                      //notes
                this.destination.annotation = (fvalue ? fvalue : null)
                break
            case 'foreignGraveyard':                //not in list graveyard name
                this.destination.foreignGraveyard = (fvalue ? fvalue : null)
                break
            case 'exhumationDate':
                this.destination.exhumationDate = (fvalue ? fvalue : null)
                break
            case 'relocationDate':
                this.destination.relocationDate = (fvalue ? fvalue : null)
                break
            case 'destBurialDuration':
                this.destination.destBurialDuration = (fvalue >= 0 ? fvalue : null)
                break
            case 'infiniteDestBurial':
                this.destination.infiniteDestBurial = Boolean(fvalue)
                break
            case 'showDest':
                this.destination.showDest = Boolean(fvalue)
                break
            default:
                return false
        }
        return this.show(this.props.open)
    }

    /** Show or hide the content of the dialog box
     * @param status dialog opened o closed
    */
    show(status) {
        if (status) {
            let destVariantElements = []
            let graveyardKey = null
            let fields = []
            switch (this.destination.variant) {
                case "same_graveyard":
                    graveyardKey = this.props.sourceGrave.gravespace.graveyard.key
                    fields = ['gyNav', 'dates', 'duration', 'notes', 'divider', 'showDest']
                    break
                case "other_graveyard":
                    fields = ['gySel']
                    if (this.destination.destGraveyardID) {
                        if (this.destination.destGraveyardID === FormGraveyardSelectorNIL) {
                            this.destination.destGraveID = null
                            fields.push('foreignGraveyard', 'dates', 'notes')
                        } else {
                            graveyardKey = this.destination.destGraveyardID
                            this.destination.foreignGraveyard = null
                            fields.push('gyNav', 'dates', 'duration', 'notes', 'divider', 'showDest')
                        }
                    }
                    break
                case "reservedArea_dispersion":
                case "private_house":
                    fields = ['dates', 'notes']
                    break
                default:
                    fields = []
            }
            for (let fName of fields) {
                switch (fName) {
                    case 'gyNav':
                        destVariantElements.push(<FormGraveyardNav
                            appMng={this.props.appMng}
                            graveyardKey={graveyardKey}
                            onChange={(value) => {
                                this.setField('destGraveID', value)
                            }}
                        />)
                        break
                    case 'gySel':
                        destVariantElements.push(<FormGraveyardSelector
                            appMng={this.props.appMng}
                            onChange={(value) => this.setField('destGraveyardID', value)}
                        />)
                        break
                    case 'notes':
                        destVariantElements.push(<TextField
                            label="Annotazioni"
                            multiline
                            fullWidth
                            rowsMax="6"
                            onChange={(event) => this.setField('annotation', event.target.value)}
                            variant="outlined"
                        />)
                        break
                    case 'foreignGraveyard':
                        destVariantElements.push(<TextField
                            label="Nome cimitero"
                            required
                            fullWidth
                            onChange={(event) => this.setField('foreignGraveyard', event.target.value)}
                            variant="outlined"
                        />)
                        break
                    case 'dates':
                        destVariantElements.push(<Grid container spacing={1} direction="row" justify="space-between" alignItems="center">
                            <Grid item>
                                <TextField
                                    label="Data esumazione"
                                    onChange={(event) => this.setField('exhumationDate', event.target.value)}
                                    type="date"
                                    required
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    label="Data ricollocazione"
                                    onChange={(event) => this.setField('relocationDate', event.target.value)}
                                    type="date"
                                    required
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>)
                        break
                    case 'duration':
                        destVariantElements.push(<Grid container spacing={1} direction="row" justify="space-between" alignItems="center">
                            <Grid item>
                                <TextField
                                    label="Anni in nuova locazione"
                                    value={this.destination.destBurialDuration}
                                    disabled={this.destination.infiniteDestBurial}
                                    type="number"
                                    margin="normal"
                                    variant="outlined"
                                    inputProps={{
                                        min: 1
                                    }}
                                    onChange={(event) => this.setField('destBurialDuration', event.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.destination.infiniteDestBurial}
                                            onChange={(event) => this.setField('infiniteDestBurial', event.target.checked)}
                                        />
                                    }
                                    label="senza termine"
                                />
                            </Grid>
                        </Grid>
                        )
                        break
                    case 'showDest':
                        destVariantElements.push(
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.destination.showDest}
                                        onChange={(event) => this.setField('showDest', event.target.checked)}
                                    />
                                }
                                label="al termine mostra la nuova sepoltura"
                            />)
                        break
                    case 'divider':
                        destVariantElements.push(<Divider />)
                        break
                    default:
                }
            }

            this.props.appMng.openDialog('Esumazione di ' + this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd].deceased.myNameIs, (
                <Fragment>
                    <DialogContent>
                        <DialogContentText id="main-dialog-description">
                            I suoi resti si trovano qui: <b>{this.props.sourceGrave.location}, {this.props.sourceGrave.myNameIs}</b>.
                        </DialogContentText>
                        <DialogContentText>
                            <b>Dove e quando devono essere spostati?</b>
                        </DialogContentText>
                        <Grid container spacing={1} direction="column" justify="flex-start" alignItems="stretch">
                            <Grid item>
                                {this.destination.variant ? <Chip
                                    color="primary"
                                    label={this.variants[this.destination.variantPos].label}
                                    onDelete={() => this.setField('variant', 'unset')}
                                    deleteIcon={<RotateLeftRoundedIcon />}
                                /> : <RadioGroup aria-label="tipo di disposizione dei resti" onChange={(event) => this.setField('variant', event.target.value)}>
                                        {this.variants.map((varItem) => <FormControlLabel key={varItem.k} value={varItem.k} control={<Radio />} label={varItem.label} />)}
                                    </RadioGroup>
                                }
                            </Grid>
                            {destVariantElements.map((element, pos) => <Grid item key={pos}>{element}</Grid>)}
                        </Grid>
                    </DialogContent>
                </Fragment>),
                (<Fragment>
                    <Button aria-label="procedi" disabled={!this.canProcede()} onClick={() => this.procede()}>Procedi</Button>
                    <Button aria-label="annulla" onClick={() => this.close()}>Annulla</Button>
                </Fragment>))
        } else {
            this.props.appMng.closeDialog()
        }
        this.showStatus = status
        return true
    }

    close() {
        if (typeof this.props.onClose === "function") { this.props.onClose() }
        this.showStatus = false
        this.props.appMng.closeDialog()
    }

    render() {
        if (this.props.open !== this.showStatus) {
            this.show(this.props.open)
        }
        return null
    }

}

export default DialogExhumation