import { createStyles } from "@material-ui/core"
import BgImage from "../../images/path.jpg"
import BgImageSvg from "../../images/bgtop_opt.svg"

const SignInStyle = ((theme) => createStyles({
    '@global': {
        html: {
            height: "100%"
        },
        body: {
            backgroundImage: 'url("' + BgImageSvg + '"),  url("' + BgImage + '")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center, right bottom"
        },
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0),
        }
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(0),
        backgroundColor: "rgba(255,255,255,0.7)",
        backdropFilter: "blur(5px)",
        [theme.breakpoints.down('xs')]: {
            boxShadow: "none",
            padding: theme.spacing(2)
        },
        [theme.breakpoints.between('xs','md')]: {
            marginTop: theme.spacing(4),
            padding: theme.spacing(3),
        },
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(8),
            padding: theme.spacing(5),
        }
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default SignInStyle