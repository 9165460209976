class schemaBurialExtRef {
    _label = ''
    _id = ''
    _notes = null
    set label(value) {
        if(typeof value === "string" && value.trim().length >= 0 && value.trim().length <= 256){
            this._label = value
        }
    }
    set id(value) {
        if(typeof value === "string" && value.trim().length >= 0 && value.trim().length <= 256){
            this._id = value
        }
    }
    set notes(value) {
        if(typeof value === "string" && value.trim().length >= 0 && value.trim().length <= 5000){
            this._notes = value
        }
    }
    get label() {
        return this._label
    }
    get id() {
        return this._id
    }
    get notes() {
        return this._notes
    }
    minComplete() {
        return (this._id.trim().length > 0 && this._label.trim().length > 0)
    }
}

export default schemaBurialExtRef