import React, { Component } from 'react'
import { Chip } from '@material-ui/core'
import RotateLeftRoundedIcon from '@material-ui/icons/RotateLeftRounded';
import JSelect from '../../../JForm/controls/JSelect';

const GraveyardNotInListID = 'nil'

/** Draw a selector of pubbible graveyard */
class FormGraveyardSelector extends Component {

    state = {
        graveyardKey: null,
        graveyardPos: null,
        graveyardsOptions: [],
    }

    componentDidMount() {
        this.props.appMng.props.JBLClient.getGraveyards()
            .then(response => {
                let list = []
                let graveyardKey = null
                let graveyardPos = null
                if (Array.isArray(response)) {
                    list = response
                    if (this.props.value) {
                        for (let pos in list) {        //search value in option list
                            let graveyard = list[pos]
                            if (graveyard.key === this.props.value) {
                                graveyardKey = graveyard.key
                                graveyardPos = pos
                                break
                            }
                        }
                    }
                }
                this.setState({ graveyardKey: graveyardKey, graveyardPos: graveyardPos, graveyardsOptions: list })
            });
    }

    /** Set the new value */
    setValue(newValue) {
        let graveyardKey = null
        let graveyardPos = null
        if (this.state.graveyardsOptions[newValue]) {
            graveyardKey = this.state.graveyardsOptions[newValue].key
            graveyardPos = newValue
        } else if (newValue === GraveyardNotInListID) {
            graveyardKey = newValue
            graveyardPos = newValue
        }
        this.setState({ graveyardKey: graveyardKey, graveyardPos: graveyardPos })
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(graveyardKey)
        }
    }

    /** Render the path */
    render() {
        let opList = [                              //add an empty option for the select
            <option key="none" value={null}></option>
        ]
        for (let pos in this.state.graveyardsOptions) {        //add them to option list
            let graveyard = this.state.graveyardsOptions[pos]
            opList.push(<option key={pos} value={pos}>{graveyard.myNameIs}</option>)
        }
        opList.push(<option key="nil" value="nil" style={{ fontStyle: "italic" }}>altro non in elenco</option>)
        return (this.state.graveyardKey !== null ?
            <Chip
                color={this.state.graveyardPos === GraveyardNotInListID ? "secondary" : "primary"}
                label={this.state.graveyardPos === GraveyardNotInListID ? 'cimitero non in elenco' : this.state.graveyardsOptions[this.state.graveyardPos].myNameIs}
                onDelete={() => this.setValue(null)}
                deleteIcon={<RotateLeftRoundedIcon />}
            />
            : <JSelect
                value={this.state.graveyardPos}
                label='Cimitero'
                onChange={value => this.setValue(value)}
            >
                {opList}
            </JSelect>)
    }
}

export { FormGraveyardSelector as default, GraveyardNotInListID as FormGraveyardSelectorNIL }