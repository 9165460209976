import React, { Component, Fragment } from 'react'
import { DialogContentText, Button, DialogContent, RadioGroup, FormControlLabel, Radio, Chip, Grid, TextField, Switch } from '@material-ui/core'
import RotateLeftRoundedIcon from '@material-ui/icons/RotateLeftRounded';
import schemaDeceased from '../../DataSchemas/schemaDeceased';
import FormDeceased from '../../Forms/FormDeceased';
import schemaReferent from '../../DataSchemas/schemaReferent';
import FormReferent from '../../Forms/FormReferent';
import JSelect from '../../../JForm/controls/JSelect';

class DialogReservationToBurial extends Component {

    showStatus = false

    vals = {}

    constructor(props) {
        super(props)
        this.initVals()
    }

    initVals() {
        this.vals = {
            deceasedWasReferent: null,          /* is the deceased a former referent? */
            deceasedWasReferentPOS: null,       /* position in the list of the former referent now deceased */
            deceasedWasReferentID: null,        /* identifier of the former referent now deceased */
            newDeceased: new schemaDeceased(),  /* new deceased data (not former referent) */
            deathdate: '',                      /* date of death */
            burialDate: '',                     /* date when the deceased is/will be buried */
            burialDuration: 10,                 /* planned years in the new burial */
            infiniteBurial: false,              /* the burial will never end */
            newReferent: new schemaReferent(),  /* new primary referent data */
            annotation: null                    /* optional annotations */
        }
    }

    /** It says if all the required infromation has been collected */
    canProcede() {
        switch (this.vals.deceasedWasReferent) {
            case true:
                return (this.vals.deceasedWasReferentID !== null && this.vals.burialDate !== '' && this.vals.deathdate !== '' && (this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd].relatedReferent.length > 1 || this.vals.newReferent.minComplete()))
            case false:
                return (this.vals.newDeceased.minComplete() && this.vals.burialDate !== '')
            default:
                return false
        }
    }

    /** Performs the recording of exhumation */
    procede() {

        const plannedExhumationDate = (burial_date, years, infinite) => {
            if (burial_date && years && !infinite) {
                const planned_exhumation_date = new Date(burial_date)
                planned_exhumation_date.setFullYear(parseInt(planned_exhumation_date.getFullYear(), 10) + parseInt(years, 10))
                const day = planned_exhumation_date.getDate().toString()
                const month = (planned_exhumation_date.getMonth() + 1).toString()
                const year = planned_exhumation_date.getFullYear().toString()
                return year.padStart(4, '0') + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0')
            } else {
                return null
            }
        }

        if (this.canProcede()) {
            let referents = []
            if(this.vals.deceasedWasReferentID){
                if (this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd].relatedReferent.length > 0) {               //if there are referents
                    for (let spos in this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd].relatedReferent) {         //add them to option list
                        let space = this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd].relatedReferent[spos]
                        if(space.key !== this.vals.deceasedWasReferentID){
                            referents.push(space)
                        } else {
                            this.vals.newDeceased.name = space.name
                            this.vals.newDeceased.surname = space.surname
                            this.vals.newDeceased.deathdate = this.vals.deathdate
                        }
                    }
                }
                if(this.vals.newReferent.minComplete()){
                    referents.push(this.vals.newReferent)
                }
            } else {
                if (Array.isArray(this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd].relatedReferent)){
                    referents = this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd].relatedReferent
                }
            }
            let dataPack = {
                deceased: {
                    key: this.vals.newDeceased.key,
                    name: this.vals.newDeceased.name,
                    surname: this.vals.newDeceased.surname,
                    birthday: this.vals.newDeceased.birthday,
                    deathdate: this.vals.newDeceased.deathdate
                },
                burial_date: this.vals.burialDate,
                planned_exhumation_date: plannedExhumationDate(this.vals.burialDate, this.vals.burialDuration, this.vals.infiniteBurial),
                referents: referents,
                annotation: this.vals.annotation
            }
            if (dataPack) { //it there are data to send to the service
                this.props.appMng.props.JBLClient.reservationToBurial(this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd].key, dataPack)  //request the recording of data
                    .then(response => {
                        console.log('got async response')
                        if (response) {     //if the response is a success
                            this.close()    //close the dialog
                        }
                    });
            }
        } else {
            this.props.appMng.notifyMsgToGui({
                'kind': 'error',
                'text': "Una o più informazioni necessarie per poter procedere risultano mancanti. Per favore compila i dati mancanti."
            })
        }
    }

    /** Set the value of a field
     * @param fname name of the field
     * @param fvalue new value of the field
     */
    setField(fname, fvalue) {
        switch (fname) {
            case 'deceasedWasReferent':
                this.initVals()
                this.vals.deceasedWasReferent = (fvalue === "y" ? true : (fvalue === "n" ? false : null))
                if(this.vals.deceasedWasReferent){
                    if(this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd].relatedReferent.length === 1){
                        this.vals.deceasedWasReferentPOS = 0
                        this.vals.deceasedWasReferentID = this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd].relatedReferent[0].key
                    }
                }
                break
            case 'deceasedWasReferentPOS':
                if (this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd].relatedReferent.length > 0
                    && fvalue >= 0
                    && this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd].relatedReferent[fvalue]
                    && this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd].relatedReferent[fvalue].key) {       //if the former referent is valid
                    this.vals.deceasedWasReferentPOS = fvalue
                    this.vals.deceasedWasReferentID = this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd].relatedReferent[fvalue].key
                } else {
                    this.vals.deceasedWasReferentPOS = null
                    this.vals.deceasedWasReferentID = null
                }
                break
            case 'annotation':                      //notes
                this.vals.annotation = (fvalue ? fvalue : null)
                break
            case 'burialDate':
                this.vals.burialDate = (fvalue ? fvalue : null)
                break
            case 'deathdate':
                this.vals.deathdate = (fvalue ? fvalue : null)
                break
            case 'burialDuration':
                this.vals.burialDuration = (fvalue >= 0 ? fvalue : null)
                break
            case 'infiniteBurial':
                this.vals.infiniteBurial = Boolean(fvalue)
                break
            case 'none':
                break;
            default:
                return false
        }
        return this.show(this.props.open)
    }

    /** Show or hide the content of the dialog box
     * @param status dialog opened o closed
    */
    show(status) {
        if (status) {
            let variantElements = []
            let fields = []

            switch (this.vals.deceasedWasReferent) {
                case true:
                    fields = ['referents','burialDate', 'duration']
                    if(this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd].relatedReferent.length < 2 && this.vals.deceasedWasReferentID > 0){
                        fields.push('newReferent')
                    }
                    fields.push('notes')
                    break
                case false:
                    fields = ['newDeceased', 'burialDate', 'duration', 'notes']
                    break
                default:
                    fields = []
            }
            for (let fName of fields) {
                switch (fName) {
                    case 'referents':
                        let opList = [                              //add an empty option for the select
                            <option key="none" value={null}></option>
                        ]
                        if (this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd].relatedReferent.length > 0) {               //if there are referents
                            for (let spos in this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd].relatedReferent) {         //add them to option list
                                let space = this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd].relatedReferent[spos]
                                opList.push(<option key={spos} value={spos}>{space.name + ' ' + space.surname}</option>)
                            }
                        }
                        variantElements.push(this.vals.deceasedWasReferentPOS !== null ?
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Chip
                                        color="primary"
                                        label={this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd].relatedReferent[this.vals.deceasedWasReferentPOS].name + ' ' + this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd].relatedReferent[this.vals.deceasedWasReferentPOS].surname}
                                        onDelete={() => this.setField('deceasedWasReferentPOS', null)}
                                        deleteIcon={<RotateLeftRoundedIcon />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <TextField
                                        label="Data di morte"
                                        value={this.vals.deathdate}
                                        type="date"
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={e => {this.setField('deathdate', e.target.value)}}
                                        onBlur={this.checkExistingObjet}
                                    />
                                </Grid>
                            </Grid> :
                            <JSelect
                                value={this.vals.deceasedWasReferentPOS}
                                label='Defunto'
                                onChange={(value) => this.setField('deceasedWasReferentPOS', value)}
                            >
                                {opList}
                            </JSelect>)
                        break
                    case 'newDeceased':
                        variantElements.push(<FormDeceased
                            appMng={this.props.appMng}
                            data={this.vals.newDeceased}
                            onChange={()=>this.setField('none',null)} />);
                        break
                    case 'newReferent':
                        variantElements.push(
                            <Fragment>
                                <b>Nuovo referente</b>
                                <FormReferent
                                    appMng={this.props.appMng}
                                    data={this.vals.newReferent}
                                    onChange={()=>this.setField('none',null)}/>
                            </Fragment>
                        );
                        break
                    case 'notes':
                        variantElements.push(<TextField
                            label="Annotazioni"
                            multiline
                            fullWidth
                            rowsMax="6"
                            onChange={(event) => this.setField('annotation', event.target.value)}
                            variant="outlined"
                        />)
                        break
                    case 'burialDate':
                        variantElements.push(<TextField
                            label="Data inumazione"
                            onChange={(event) => this.setField('burialDate', event.target.value)}
                            type="date"
                            required
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />)
                        break
                    case 'duration':
                        variantElements.push(<Grid container spacing={1} direction="row" justify="space-between" alignItems="center">
                            <Grid item>
                                <TextField
                                    label="Anni all'esumazione"
                                    value={this.vals.burialDuration}
                                    disabled={this.vals.infiniteBurial}
                                    type="number"
                                    margin="normal"
                                    variant="outlined"
                                    inputProps={{
                                        min: 1
                                    }}
                                    onChange={(event) => this.setField('burialDuration', event.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.vals.infiniteDestBurial}
                                            onChange={(event) => this.setField('infiniteBurial', event.target.checked)}
                                        />
                                    }
                                    label="senza termine"
                                />
                            </Grid>
                        </Grid>
                        )
                        break
                    default:
                }
            }

            this.props.appMng.openDialog("Registrazione di una nuova inumazione in un sepolcro prenotato", (
                <Fragment>
                    <DialogContent>
                        <DialogContentText id="main-dialog-description">
                            <b>Dati del defunto e della sepoltura</b>
                        </DialogContentText>
                        <DialogContentText>
                            Locazione: <b>{this.props.sourceGrave.location}, {this.props.sourceGrave.myNameIs}</b>.
                        </DialogContentText>
                        <Grid container spacing={1} direction="column" justify="flex-start" alignItems="stretch">
                            <Grid item>
                                {typeof this.vals.deceasedWasReferent === "boolean" ? <Chip
                                    color={this.vals.deceasedWasReferent ? "primary" : "secondary"}
                                    label={this.vals.deceasedWasReferent ? "La persona defunta era referente per la prenotazione" : "La persona defunta non era referente per la prenotazione"}
                                    onDelete={() => this.setField('deceasedWasReferent', null)}
                                    deleteIcon={<RotateLeftRoundedIcon />}
                                /> :
                                    <Fragment>
                                        <DialogContentText>
                                            La persona defunta era referente per la prenotazione?
                                        </DialogContentText>
                                        <RadioGroup aria-label="il defunto era un referente" onChange={(event) => this.setField('deceasedWasReferent', event.target.value)}>
                                            <FormControlLabel key="y" value="y" control={<Radio />} label="sì" />
                                            <FormControlLabel key="n" value="n" control={<Radio />} label="no" />
                                        </RadioGroup>
                                    </Fragment>
                                }
                            </Grid>
                            {variantElements.map((element, pos) => <Grid item key={pos}>{element}</Grid>)}
                        </Grid>
                    </DialogContent>
                </Fragment>),
                (<Fragment>
                    <Button aria-label="procedi" disabled={!this.canProcede()} onClick={() => this.procede()}>Procedi</Button>
                    <Button aria-label="annulla" onClick={() => this.close()}>Annulla</Button>
                </Fragment>))
        } else {
            this.props.appMng.closeDialog()
        }
        this.showStatus = status
        return true
    }

    close() {
        if (typeof this.props.onClose === "function") { this.props.onClose() }
        this.showStatus = false
        this.props.appMng.closeDialog()
    }

    render() {
        if (this.props.open !== this.showStatus) {
            this.show(this.props.open)
        }
        return null
    }

}

export default DialogReservationToBurial