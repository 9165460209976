import React, { Component, Fragment } from 'react'
import ReactDOM from "react-dom";
import { CompactPicker } from 'react-color';
import ReactCursorPosition from 'react-cursor-position'
import JPolyIconAdd from './icons/add';
import JPolyIconDone from './icons/done';
import JPolyIconPan from './icons/pan';
import JPolyIconDel from './icons/del';
import JPolyIconBullet from './icons/bullet';
import JPolyIconMove from './icons/move';
import JPolyIconGrid from './icons/grid';
import JPolyIconZoomIn from './icons/zoomin';
import JPolyIconZoomOut from './icons/zoomout';
import JPolyIconZoomReset from './icons/zoomreset';

class JPolySVG extends Component {

    state = {
        mounted: false,
        elementDimensions: {
            width: 0,
            height: 0
        },
        editing: false,
        editMode: 'none',
        snapToGrid: true,
        gridTick: 10,
        gridDensity: 10,
        selectedVertex: null,
        overVertex: null,
        coloPicker: {
            show: false,
            x: 0,
            y: 0
        },
        dragPoly: {
            start: {
                x: null,
                y: null
            }
        },
        zoom: 1,
        zoomSteps: 0,
        viewPanning: false,
        viewPanningPos: {
            x: 0,
            y: 0
        },
        viewPanningOrigin: {
            x: 0,
            y: 0,
            x0: 0,
            y0: 0
        }

    }

    view = {
        size: {
            x: 1000,
            y: 1000
        },
        grid: {
            tick: 10,
            density: 10
        },
        panLimits: {
            x0: 0,
            y0: 0,
            x1: (newZoom) => (Math.floor((this.view.size.x / (newZoom ? newZoom : this.state.zoom)) - this.view.size.x)),
            y1: (newZoom) => (Math.floor((this.view.size.y / (newZoom ? newZoom : this.state.zoom)) - this.view.size.y))

        },
        zoomIncrement: 0.5,
        pixelScale: 1
    }

    constructor(props) {
        super(props)
        this.initDraw()
    }

    initDraw() {
        this.polyEdit = []
        this.bgPolys = []
        this.settingsID = (typeof this.props.settings.id === 'string' ? this.props.settings.id : 'initial')
        this.polyColor = (typeof this.props.settings.color === 'string' ? this.polyColor = this.props.settings.color : 'green')
        if (Array.isArray(this.props.settings.path)) {
            for (let vertex of this.props.settings.path) {
                this.polyEdit.push({ x: vertex.x, y: vertex.y })
            }
        }
        if (Array.isArray(this.props.settings.bgPolys)) {
            const scalePixel = this.scalePixel(1)
            this.bgPolys = this.props.settings.bgPolys.map((poly, key) => <polygon {...poly} key={key} strokeWidth={scalePixel}/>)
        }
    }

    backgroundMap() {
        if(typeof this.props.settings.map === "object" && typeof this.props.settings.map.image === "string"){
            return <image x={this.props.settings.map.x} y={this.props.settings.map.y} href={this.props.settings.map.image} width={this.view.size.x} height={this.view.size.y} opacity={this.props.settings.map.alpha} transform={`scale(${this.props.settings.map.scale})`} preserveAspectRatio="xMidYMid meet"></image>
        }
    }

    componentDidMount() {
        this.updateComponentSizes()
        window.addEventListener('resize', () => this.updateComponentSizes());
        window.addEventListener('mouseup', () => this.drag('release'));
        window.addEventListener('ontouchend', () => this.drag('release'));
        document.addEventListener('keydown', (e) => this.keyboardActions(e));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.updateComponentSizes());
        window.removeEventListener('mouseup', () => this.drag('release'));
        window.removeEventListener('ontouchend', () => this.drag('release'));
        document.removeEventListener('keydown', (e) => this.keyboardActions(e));
    }

    updateComponentSizes() {
        const DOMNode = ReactDOM.findDOMNode(this.svgNode);
        if (DOMNode) {
            this.setState({ elementDimensions: DOMNode.getBoundingClientRect() })
        }
    }

    toggleGrid(event) {
        event.stopPropagation()
        this.setState({ snapToGrid: !Boolean(this.state.snapToGrid) })
    }

    toggleEdit(event, mode) {
        event.stopPropagation()
        const newEditState = !Boolean(this.state.editing)
        let selectedVertex = null
        if (newEditState && mode === 'addVertex' && this.polyEdit.length > 0) {
            selectedVertex = this.polyEdit.length - 1
        }
        this.setState({ editing: newEditState, editMode: mode, selectedVertex: selectedVertex })
    }

    toggleColorPicker(event, status = null) {
        event.stopPropagation()
        this.setState({ coloPicker: { show: (status !== null ? Boolean(status) : !this.state.coloPicker.show), x: event.pageX, y: event.pageY } })
    }

    drag(direction, params) {
        if (direction === 'catch') {
            if (this.state.editing) {
                if (this.state.editMode === 'moveVertex' && this.state.selectedVertex === null && params) {
                    this.setState({ selectedVertex: params.id })
                } else if (this.state.editMode === 'movePoly') {
                    this.setState({ dragPoly: { ...this.state.dragPoly, start: { x: this.scalePixel(this.getPos('x')), y: this.scalePixel(this.getPos('y')) } } })
                }
            } else {
                this.setState({ viewPanning: true, viewPanningOrigin: { x: this.props.position.x, y: this.props.position.y, x0: this.state.viewPanningPos.x, y0: this.state.viewPanningPos.y } })
            }
        } else {
            if (this.state.editing) {
                if (this.state.editMode === 'moveVertex') {
                    if (this.state.editMode === 'moveVertex' && this.state.selectedVertex !== null) {
                        this.polyEdit[this.state.selectedVertex] = { x: this.scalePixel(this.getPos('x')), y: this.scalePixel(this.getPos('y')) }
                        this.dataChanged()
                    }
                    this.setState({ selectedVertex: null })
                } else if (this.state.editMode === 'movePoly') {
                    const dragPoly = this.state.dragPoly
                    if (dragPoly.start.x !== null) {
                        let offset = { x: dragPoly.start.x - this.scalePixel(this.getPos('x')), y: dragPoly.start.y - this.scalePixel(this.getPos('y')) }
                        for (let vertexID in this.polyEdit) {
                            this.polyEdit[vertexID] = { x: (this.polyEdit[vertexID].x - offset.x), y: (this.polyEdit[vertexID].y - offset.y) }
                        }
                        this.dataChanged()
                    }
                    this.setState({ dragPoly: { ...this.state.dragPoly, start: { x: null, y: null } } })
                }
            } else {
                this.setState({ viewPanning: false })
            }

        }
    }

    zoom(event, direction) {
        event.stopPropagation()
        let newZoom = 1
        let newZoomSteps = this.state.zoomSteps
        let viewPanningPos = this.state.viewPanningPos
        switch (direction) {
            case 'in':
                newZoomSteps++
                break;
            case 'out':
                if (newZoomSteps > 0) { newZoomSteps-- }
                break;
            default:
                newZoomSteps = 0
        }
        newZoom = 1 + (this.view.zoomIncrement * newZoomSteps)
        if (viewPanningPos.x < this.view.panLimits.x1(newZoom)) { viewPanningPos.x = this.view.panLimits.x1(newZoom) }
        if (viewPanningPos.y < this.view.panLimits.y1(newZoom)) { viewPanningPos.y = this.view.panLimits.y1(newZoom) }
        const densityB = Math.floor(this.view.grid.density * Math.floor(newZoom))
        const tickB = (this.view.grid.density * this.view.grid.tick) / densityB
        this.setState({ zoom: newZoom, zoomSteps: newZoomSteps, gridTick: tickB, gridDensity: densityB, viewPanningPos: viewPanningPos })
    }

    getPos(what) {
        const viewPosition = (this.props.position[what] / this.state.zoom) - this.keepPixel(this.state.viewPanningPos[what])
        return (this.state.snapToGrid ? (this.keepPixel(Math.round(this.scalePixel(viewPosition) / this.state.gridTick) * this.state.gridTick)) : viewPosition)
    }

    move() {
        if (this.state.editing) {
        } else if (this.state.viewPanning) {
            let newX = this.state.viewPanningOrigin.x0 + (this.scalePixel(this.props.position.x - this.state.viewPanningOrigin.x) / this.state.zoom)
            let newY = this.state.viewPanningOrigin.y0 + (this.scalePixel(this.props.position.y - this.state.viewPanningOrigin.y) / this.state.zoom)
            if (newX > this.view.panLimits.x0) { newX = this.view.panLimits.x0 }
            if (newY > this.view.panLimits.y0) { newY = this.view.panLimits.y0 }
            if (newX < this.view.panLimits.x1()) { newX = this.view.panLimits.x1() }
            if (newY < this.view.panLimits.y1()) { newY = this.view.panLimits.y1() }
            this.setState({
                viewPanningPos: { x: newX, y: newY }
            })
        }
    }

    editEvent() {
        if (this.state.editing) {
            switch (this.state.editMode) {
                case 'addVertex':
                    this.polyEdit.push({ x: this.scalePixel(this.getPos('x')), y: this.scalePixel(this.getPos('y')) })
                    this.dataChanged()
                    return this.setState({ selectedVertex: (this.polyEdit.length - 1) })
                case 'moveVertex':
                    return true;
                case 'removeVertex':
                    return this.setState({ selectedVertex: null })
                case 'movePoly':
                    return true
                default:
                    console.error('Unknown edit mode "' + this.state.editMode + '" when executing event')
                    this.setState({ editing: false })
                    return false
            }
        }
    }

    vertexOver(id, direction) {
        if (direction === 'in') {
            if (this.state.editing && this.state.selectedVertex === null) {
                this.setState({ overVertex: id })
            }
        } else {
            this.setState({ overVertex: null })
        }
    }

    vertexClick(id) {
        if (this.state.editing && this.state.editMode === 'removeVertex') {
            this.polyEdit.splice(id, 1)
            this.dataChanged()
            this.setState({ selectedVertex: null })
        }
    }

    dataChanged() {
        this.props.retData({ path: this.polyEdit, color: this.polyColor })
    }

    draw() {
        let path = []
        for (let vertex of this.polyEdit) {
            path.push(vertex.x + ' ' + vertex.y)
        }
        if (this.state.editing) {
            let pathGhost = path.slice(0)
            switch (this.state.editMode) {
                case 'addVertex':
                    pathGhost.push(this.scalePixel(this.getPos('x')) + ' ' + this.scalePixel(this.getPos('y')))
                    break;
                case 'moveVertex':
                    if (this.state.selectedVertex !== null) {
                        pathGhost[this.state.selectedVertex] = this.scalePixel(this.getPos('x')) + ' ' + this.scalePixel(this.getPos('y'))
                    }
                    break;
                case 'removeVertex':
                    break;
                case 'movePoly':
                    if (this.state.dragPoly.start.x !== null) {
                        let offset = { x: this.state.dragPoly.start.x - this.scalePixel(this.getPos('x')), y: this.state.dragPoly.start.y - this.scalePixel(this.getPos('y')) }
                        pathGhost = []
                        for (let vertex of this.polyEdit) {
                            pathGhost.push((vertex.x - offset.x) + ' ' + (vertex.y - offset.y))
                        }
                    }
                    break;
                default:
                    console.error('Unknown edit mode "' + this.state.editMode + '" when drawing')
                    this.setState({ editing: false })
                    return <Fragment></Fragment>
            }
            return (
                <Fragment>
                    {
                        this.polyEdit.length &&
                        <Fragment>
                            <polygon points={path.join(', ')} fillOpacity="0.5" fill={this.polyColor} />
                            <path d={'M ' + pathGhost.join(' L ') + ' Z'} fillOpacity="0.4" fill="azure" strokeOpacity="0.5" strokeDasharray={this.scaleZoomPixel(6)} stroke="gray" strokeWidth={this.scaleZoomPixel(2)} onTouchStart={() => this.drag('catch')} onMouseDown={() => this.drag('catch')} />
                            {this.state.editMode !== 'movePoly' &&
                                this.polyEdit.map((vertex, key) => {
                                    return <circle key={key} cx={vertex.x} cy={vertex.y} r={this.scaleZoomPixel(this.state.overVertex === key ? 6 : 3)} stroke="#6c8dad" strokeOpacity="0.4" fill="#46627d" fillOpacity="0.6" strokeWidth={this.scalePixel(1)} onMouseOver={() => this.vertexOver(key, 'in')} onMouseOut={() => this.vertexOver(key, 'out')} onTouchStart={() => this.drag('catch', { id: key })} onMouseDown={() => this.drag('catch', { id: key })} onClick={() => this.vertexClick(key)} />
                                })
                            }
                        </Fragment>
                    }
                    {(this.state.selectedVertex !== null || this.state.editMode === 'addVertex') &&
                        <circle cx={this.scalePixel(this.getPos('x'))} cy={this.scalePixel(this.getPos('y'))} r={this.scaleZoomPixel(4)} stroke="#c89dbd" strokeOpacity="0.4" fill="#9b5689" fillOpacity="0.6" strokeWidth={this.scalePixel(1)} />
                    }
                </Fragment>
            )
        } else {
            return (this.polyEdit.length &&
                <polygon points={path.join(', ')} fillOpacity="0.80" fill={this.polyColor} stroke="black" strokeWidth={this.scaleZoomPixel(1)} />
            )
        }
    }

    updateScale() {
        const pixelScale = Math.min(this.view.size.x / this.state.elementDimensions.width, this.view.size.y / this.state.elementDimensions.height)
        this.view.pixelScale = isFinite(pixelScale) ? pixelScale : 1
    }

    keepPixel(pixels) {
        return pixels / this.view.pixelScale
    }

    scalePixel(pixels) {
        return pixels * this.view.pixelScale
    }

    scaleZoomPixel(pixels) {
        return (pixels * this.view.pixelScale) / this.state.zoom
    }

    keyboardActions(e) {
        switch(e.code) {
            case "KeyP":        //copy x and y to clipboard
                navigator.clipboard.writeText(JSON.stringify({x: this.scalePixel(this.getPos('x')), y: this.scalePixel(this.getPos('y'))}));
                console.log('coordinates copied to clipboard')
                break;
            case "KeyK":        //item key to clipboard
                navigator.clipboard.writeText(this.props.settings.iKey);
                console.log('key copied to clipboard')
                break;
            case "KeyJ":        //copy polygon to clipboard
            navigator.clipboard.writeText(JSON.stringify(this.polyEdit));
            console.log('polygon copied to clipboard')
            break;

            default:
        }
    }

    render() {
        if (this.props.settings.id && this.settingsID !== this.props.settings.id) {
            this.initDraw()
            this.dataChanged()
        }
        const toolsMenu = {
            posY: 25,
            posX: 5,
            iconSize: 28,
            iconSpace: 10,
            color: 'gray',
            toggledColor: 'red'
        }
        let menuIconN = 0
        this.updateScale()
        let bgPattern = []
        for (let i = 1; i <= this.state.gridDensity; i++) {
            bgPattern.push(<line key={i + 'h'} x1={this.state.gridTick * i} y1="0" x2={this.state.gridTick * i} y2={this.state.gridTick * this.state.gridDensity} stroke={i === this.state.gridDensity ? "#777" : "#aaa"} strokeWidth={i === this.state.gridDensity ? this.scaleZoomPixel(1.5) : this.scaleZoomPixel(0.5)} />)
            bgPattern.push(<line key={i + 'v'} y1={this.state.gridTick * i} x1="0" y2={this.state.gridTick * i} x2={this.state.gridTick * this.state.gridDensity} stroke={i === this.state.gridDensity ? "#777" : "#aaa"} strokeWidth={i === this.state.gridDensity ? this.scaleZoomPixel(1.5) : this.scaleZoomPixel(0.5)} />)
        }
        return (
            < Fragment >
                <svg
                    onTouchStart={() => this.drag('catch')}
                    onMouseDown={() => this.drag('catch')}
                    onTouchMove={() => this.move()}
                    onMouseMove={() => this.move()}
                    ref={r => (this.svgNode = r)}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox={`0 0 ${this.view.size.x} ${this.view.size.y}`}
                    onClick={() => this.editEvent()}
                >
                    <defs>
                        <pattern id="backgroung" x="0" y="0" width={this.state.gridTick * this.state.gridDensity} height={this.state.gridTick * this.state.gridDensity} patternUnits="userSpaceOnUse" >
                            {bgPattern}
                        </pattern>
                        <pattern id="checkers" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse" >
                            <rect x="0" y="0" width="10" height="10" fill="white"/>
                            <rect x="10" y="10" width="10" height="10" fill="white"/>
                            <rect x="0" y="10" width="10" height="10" fill="lightgray"/>
                            <rect x="10" y="0" width="10" height="10" fill="lightgray"/>
                        </pattern>
                        <mask id="checkers-mask" x="0" y="0" width="1" height="1" >
                            <rect x="0" y="0" width={this.view.size.x} height={this.view.size.x} fill="url(#checkers)" />
                        </mask>
                    </defs>
                    <g transform={`scale(${this.state.zoom}) translate(${this.state.viewPanningPos.x} ${this.state.viewPanningPos.y})`}>
                        <rect x="0" y="0" width={this.view.size.x} height={this.view.size.y} fill="white" fillOpacity="0.4" />
                        {this.backgroundMap()}
                        <rect x="0" y="0" width={this.view.size.x} height={this.view.size.y} fill="url(#backgroung)" />
                        {this.bgPolys}
                        {this.draw()}
                    </g>
                    {this.state.editing && <JPolyIconDone x={this.scalePixel(toolsMenu.posX)} y={this.scalePixel(toolsMenu.posY + ((toolsMenu.iconSize + toolsMenu.iconSpace) * menuIconN++))} size={this.scalePixel(toolsMenu.iconSize)} color={toolsMenu.color} onClick={(e) => this.toggleEdit(e)} />}
                    {!this.state.editing && <JPolyIconAdd x={this.scalePixel(toolsMenu.posX)} y={this.scalePixel(toolsMenu.posY + ((toolsMenu.iconSize + toolsMenu.iconSpace) * menuIconN++))} size={this.scalePixel(toolsMenu.iconSize)} color={toolsMenu.color} onClick={(e) => this.toggleEdit(e, 'addVertex')} />}
                    {!this.state.editing && this.polyEdit.length > 0 && <JPolyIconDel x={this.scalePixel(toolsMenu.posX)} y={this.scalePixel(toolsMenu.posY + ((toolsMenu.iconSize + toolsMenu.iconSpace) * menuIconN++))} size={this.scalePixel(toolsMenu.iconSize)} color={toolsMenu.color} onClick={(e) => this.toggleEdit(e, 'removeVertex')} />}
                    {!this.state.editing && this.polyEdit.length > 0 && <JPolyIconPan x={this.scalePixel(toolsMenu.posX)} y={this.scalePixel(toolsMenu.posY + ((toolsMenu.iconSize + toolsMenu.iconSpace) * menuIconN++))} size={this.scalePixel(toolsMenu.iconSize)} color={toolsMenu.color} onClick={(e) => this.toggleEdit(e, 'moveVertex')} />}
                    {!this.state.editing && this.polyEdit.length > 2 && <JPolyIconMove x={this.scalePixel(toolsMenu.posX)} y={this.scalePixel(toolsMenu.posY + ((toolsMenu.iconSize + toolsMenu.iconSpace) * menuIconN++))} size={this.scalePixel(toolsMenu.iconSize)} color={toolsMenu.color} onClick={(e) => this.toggleEdit(e, 'movePoly')} />}
                    <JPolyIconGrid x={this.scalePixel(toolsMenu.posX)} y={this.scalePixel(toolsMenu.posY + ((toolsMenu.iconSize + toolsMenu.iconSpace) * menuIconN++))} size={this.scalePixel(toolsMenu.iconSize)} color={this.state.snapToGrid ? toolsMenu.toggledColor : toolsMenu.color} onClick={(e) => this.toggleGrid(e)} />
                    <JPolyIconBullet x={this.scalePixel(toolsMenu.posX)} y={this.scalePixel(toolsMenu.posY + ((toolsMenu.iconSize + toolsMenu.iconSpace) * menuIconN++))} size={this.scalePixel(toolsMenu.iconSize)} color={toolsMenu.color} bulletColor={this.polyColor} onClick={(e) => this.toggleColorPicker(e)} />
                    <JPolyIconZoomIn x={this.scalePixel(toolsMenu.posX)} y={this.scalePixel(toolsMenu.posY + ((toolsMenu.iconSize + toolsMenu.iconSpace) * menuIconN++))} size={this.scalePixel(toolsMenu.iconSize)} color={toolsMenu.color} bulletColor={this.polyColor} onClick={(e) => this.zoom(e, 'in')} />
                    <JPolyIconZoomReset x={this.scalePixel(toolsMenu.posX)} y={this.scalePixel(toolsMenu.posY + ((toolsMenu.iconSize + toolsMenu.iconSpace) * menuIconN++))} size={this.scalePixel(toolsMenu.iconSize)} color={toolsMenu.color} bulletColor={this.polyColor} onClick={(e) => this.zoom(e, 'reset')} />
                    <JPolyIconZoomOut x={this.scalePixel(toolsMenu.posX)} y={this.scalePixel(toolsMenu.posY + ((toolsMenu.iconSize + toolsMenu.iconSpace) * menuIconN++))} size={this.scalePixel(toolsMenu.iconSize)} color={toolsMenu.color} bulletColor={this.polyColor} onClick={(e) => this.zoom(e, 'out')} />
                </svg>
                {
                    this.state.coloPicker.show ? <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 10000 }} onClick={(e) => this.toggleColorPicker(e, false)}>
                        <div style={{ position: 'absolute', zIndex: 10001, top: (this.state.coloPicker.y + (toolsMenu.iconSize / 2)) + 'px', left: this.state.coloPicker.x + 'px' }} onClick={e => e.stopPropagation()}>
                            <CompactPicker
                                disableAlpha={true}
                                color={this.polyColor}
                                onChange={color => {
                                    this.polyColor = color.hex
                                    this.dataChanged()
                                }}
                            />
                        </div>
                    </div > : null
                }
            </Fragment >
        )
    }
}

class JPolyDraw extends Component {

    retData(data) {
        this.props.onChange(data)
    }

    render() {
        return (
            <ReactCursorPosition>
                <JPolySVG retData={data => this.retData(data)} settings={this.props.settings} />
            </ReactCursorPosition>
        )
    }
}

export default JPolyDraw