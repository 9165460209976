import React from 'react'

const JPolyIconPan = (props) => {
    const defColor = props.color ? props.color : 'black'
    const bgColor = props.bgColor ? props.bgColor : 'white'
    return <g onClick={(e) => props.onClick(e)} transform={`translate(${props.x || 0} ${props.y || 0})scale(${((props.size || 24) / 1000)})`} vectorEffect="non-scaling-stroke">
        <circle cx="500" cy="500" r="500" fill={bgColor} fillOpacity="0.6" stroke={defColor} strokeLinejoin="round" strokeWidth="90" paintOrder="markers stroke fill" />
        <path d="m746.1 127.26c-21.617-16.241-52.095-11.913-68.336 9.7042l-202.28 269.24-10.89-8.1815 155.37-206.81c16.241-21.617 11.912-52.095-9.705-68.335l-3.9022-2.9317c-21.617-16.241-52.094-11.912-68.335 9.7046l-170.03 226.32c-2.4326 3.238-4.4002 6.6756-5.921 10.233-3.245 2.611-6.1935 5.7037-8.7363 9.276l-76.959 108.12 8.9412-73.699c3.2563-26.841-15.731-51.071-42.572-54.327l-4.8455-0.58803c-26.841-3.2563-51.071 15.731-54.327 42.572l-34.092 281.01c-0.46798 3.8576-0.4742 7.6606-0.0657 11.356-4.3571 29.682 2.168 63.708 38.875 91.285l179.75 136.41c21.538 16.345 146.29-8.888 162.53-30.505l181.76-241.92c1.7749-2.3624 3.304-4.8304 4.5918-7.3742 4.3017-2.943 8.2073-6.6326 11.52-11.042l117.26-156.08c16.241-21.617 11.912-52.094-9.7046-68.335l-3.9026-2.932c-21.617-16.241-52.094-11.912-68.335 9.7046l-102.6 136.57-10.89-8.1819 164.17-218.51c16.241-21.617 11.912-52.094-9.7046-68.335l-3.9022-2.9317c-21.617-16.241-52.094-11.912-68.335 9.7046l-164.17 218.51-10.89-8.1819 202.28-269.24c16.241-21.617 11.912-52.094-9.7046-68.335z"
            fill={defColor}
            paintOrder="markers stroke fill"
        />
    </g>
}

export default JPolyIconPan