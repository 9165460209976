import React, { Component } from 'react'
import { Grid, TextField } from '@material-ui/core'
import schemaBurial from '../../DataSchemas/schemaBurial'

class FormBurial extends Component {

    state = {
        data: {
            key: null,
            burial_date: ''
        },
        metadata: {},
        initialData: {}
    }

    constructor(props){
        super(props)
        if(this.props.data){
            this.state.data = this.props.data
            this.state.initialData = {...this.props.data}
        } else {
            this.state.data = schemaBurial
        }
    }

    setField(name, value) {
        let data = this.state.data
        let metadata = this.state.metadata
        let stepData = data
        let stepMetadata = metadata
        let path = (Array.isArray(name)?name:[name])
        let lastName = path.splice(-1,1)
        for(let step of path){
            stepData = stepData[step]
            if(typeof stepMetadata[step] === 'undefined') {
                stepMetadata[step] = {}
            }
            stepMetadata = stepMetadata[step]
        }
        if (stepData[lastName] !== value) {
            stepData[lastName] = value
            if(typeof stepMetadata[lastName] === 'undefined') {
                stepMetadata[lastName] = {}
            }
            stepMetadata[lastName].changed = true
            this.setState({ data: data })
            if(typeof this.props.onChange === 'function'){
                this.props.onChange({ data: this.state.data, metadata: this.state.metadata })
            }
        }
    }

    render() {
        return (
            <Grid container spacing={1} item alignItems="center">
                <TextField
                    label="Data iniziale"
                    value={this.state.data.burial_date}
                    type="date"
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={e => this.setField('burial_date', e.target.value)}
                />
            </Grid>
        )
    }

}

export default FormBurial