import React, { Component, Fragment } from 'react'
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Divider, Fab, withStyles, Card, CardMedia, Box, Typography, CardContent } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import AppActionsysUsersStyle from './AppActionsysUsersStyle';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import mediaHeader from '../../../../images/operators_1248.webp'

class AppActionsysUsers extends Component {

    state = {
        orgList: (
            <div>Recupero elenco</div>
        )
    }

    constructor(props) {
        super(props)
        this.props.appMng.setTitle('Gestione Enti')
        this.updateList()
    }

    updateList() {
        this.props.appMng.props.JBLClient.getSysUsers().then(items => {
            let listItems = (
                <List>
                    {items.map((itemData, itemPos) =>
                        (<Fragment key={itemData.key} >
                            {itemPos > 0 ? <Divider variant="inset" component="li" /> : ''}
                            < ListItem button onClick={() => this.props.appMng.openAction('sysUser', { key: itemData.key })}>
                                <ListItemAvatar>
                                    <Avatar className={itemData.status?this.props.classes.avatarUserActive:this.props.classes.avatarUserInactive}>
                                        <PersonRoundedIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={itemData.myNameIs} secondary={itemData.email} />
                            </ListItem>
                        </Fragment>
                        ))}
                </List>
            )
            this.setState({ orgList: listItems })
        })
    }

    render() {
        return (
            <Card >
                <CardMedia className={this.props.classes.headMedia} image={mediaHeader} title="Elenco degli operatori auorizzati per il sistema">
                    <Box p={3} className={this.props.classes.itemsHeaderText}>
                        <Typography variant="h4" color="inherit">
                            Elenco degli operatori di sistema
                        </Typography>
                    </Box>
                    <Fab aria-label="registra nuovo operatore" title="registra nuovo operatore" color="secondary" className={this.props.classes.fabAdd} onClick={() => this.props.appMng.openAction('sysUserEditor')}>
                        <AddIcon/>
                    </Fab>
                </CardMedia>
                <CardContent>
                    {this.state.orgList}
                </CardContent>
            </Card>
        )
    }
}

export default withStyles(AppActionsysUsersStyle)(AppActionsysUsers)