class schemaBurial {
    _burial_date = '';
    set burial_date(value) {
        if(typeof value === "string" && /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(value)){
            this._burial_date = value
        }
    }
    get burial_date() {
        return this._burial_date
    }
    minComplete() {
        return (this._burial_date.trim().length > 0)
    }
}

export default schemaBurial