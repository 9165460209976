import React, { Component, Fragment } from 'react'
import { withStyles, TextField, Button, CardContent, Typography, CardActions, Card, CardMedia, Grid, Box, InputAdornment, FormControl, InputLabel, OutlinedInput, FormHelperText, Link, Slider, Switch, CircularProgress } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import EventNoteRoundedIcon from '@material-ui/icons/EventNoteRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import MyLocationRoundedIcon from '@material-ui/icons/MyLocationRounded';
import CloudUploadRoundedIcon from '@material-ui/icons/CloudUploadRounded';
import SaveIcon from '@material-ui/icons/Save'
import AppActionGraveyardEditorStyle from './AppActionGraveyardEditorStyle';
import mediaHeader from '../../../../images/editor_opt.svg'
import JPolyDraw from '../../../JPoly';
import FormOUnitSelector from '../../Forms/FormOUnitSelector';
import HelperGraveyardMapImage from '../../Helpers/HelperGraveyardMapImage';

class AppActionGraveyardEditor extends Component {

    state = {
        loaded: false,
        isNew: false,
        data: {
            name: '',
            fullAddress: '',
            geoPosition: null,
            opening: '',
            planimetry: null,
            unnormalizedDiagram: null,
            owner: {
                orgKey: '',
                unitKey: ''
            },
            map: {
                type: 'none',
                origin: {
                    x: 0.0,
                    y: 0.0,
                },
                scale: 1.0,
                alpha: 1.0
            }
        },
        mapImage: null,
        uploadingMap: false
    }

    mapImage = null

    constructor(props) {
        super(props)
        this.actionData = this.props.appMng.actionsStack[this.props.id]
        if (typeof this.actionData.params === 'object' && this.actionData.params.key) {
            this.getData()
        } else {
            this.state.isNew = true
        }
        this.mapImage = new HelperGraveyardMapImage(this.props.appMng)
        this.props.appMng.setTitle((this.state.isNew ? 'Nuovo' : 'Modifica') + ' cimitero')
    }

    componentDidMount() {
        if(this.actionData.params && this.actionData.params.orgKey && this.actionData.params.unitKey){
            this.setState({ data: {...this.state.data, owner: {orgKey: this.actionData.params.orgKey, unitKey: this.actionData.params.unitKey}} })
        }
    }

    getData() {
        if (this.actionData.params.key) {
            this.props.appMng.props.JBLClient.getGraveyard(this.actionData.params.key)
            .then(response => {
                this.setState({ loaded: true, data: {...this.state.data, ...response} })
                this.mapImage.setKey(this.actionData.params.key)
                this.mapImage.load().then(objurl => this.setState({mapImage: objurl}))
            });
        }
    }

    persistsData() {
        if(this.state.isNew && (!this.state.data.owner.orgKey || !this.state.data.owner.unitKey)) {
            this.props.appMng.notifyMsgToGui({
                'kind': 'error',
                'text': "Prima di poter registrare il nuovo cimitero è necessario specificare da quale unità organizzativa dipende"
            })
            return false
        }
        let peristPromise = (this.state.isNew ? this.props.appMng.props.JBLClient.newGraveyard(this.state.data.owner.orgKey, this.state.data.owner.unitKey, this.state.data) : this.props.appMng.props.JBLClient.updateGraveyard(this.actionData.params.key, this.state.data))
        peristPromise
            .then(result => {
                if (result !== null) {
                    this.props.appMng.setActionPersistence(this.props.id, true)
                    this.props.appMng.openAction('graveyard', { key: result })
                }
            })
            .catch(error => {
                console.error(error)
                this.props.appMng.notifyMsgToGui({
                    'kind': 'error',
                    'text': "Si è verificato un errore imprevisto durante la registrazione dei dati."
                })
            })
    }

    setField(name, value) {
        let data = this.state.data
        let stepData = data
        let path = (Array.isArray(name)?name:[name])
        let lastName = path.splice(-1,1)
        for(let step of path){
            stepData = stepData[step]
        }
        if (stepData[lastName] !== value) {
            this.props.appMng.setActionPersistence(this.props.id, false)
            stepData[lastName] = value
            this.setState({ data: data })
        }
    }

    updateMapDefs() {
        let peristPromise = (this.state.isNew ? this.props.appMng.props.JBLClient.newGraveyard(this.state.data.owner.orgKey, this.state.data.owner.unitKey, this.state.data) : this.props.appMng.props.JBLClient.updateGraveyardMapDefinition(this.actionData.params.key, this.state.data))
        peristPromise
            .then(result => {
                if (result !== null) {
                    if(this.state.isNew){
                        this.actionData.params.key = result
                        this.setState({isNew: false})
                    }
                    this.getData()
                }
            })
            .catch(error => {
                console.error(error)
                this.props.appMng.notifyMsgToGui({
                    'kind': 'error',
                    'text': "Si è verificato un errore imprevisto durante la registrazione dei dati."
                })
            })
    }

    render() {
        const polyEditorParams = (this.state.data.planimetry !== null ? { iKey: this.actionData.params.key, color: this.state.data.planimetry.color, path: this.state.data.planimetry.path } : { color: "gray", path: [] })
        polyEditorParams.bgPolys = this.actionData.params.bgPolys
        polyEditorParams.map = {
            image: this.state.mapImage,
            x: this.state.data.map.origin.x,
            y: this.state.data.map.origin.y,
            scale: this.state.data.map.scale,
            alpha: this.state.data.map.alpha,
        }

        return (
            <Card >
                <CardMedia className={this.props.classes.headMedia} image={mediaHeader}>
                    <Box p={3} className={this.props.classes.itemsHeaderText}>
                        <Typography variant="h4" color="inherit">
                            { this.state.isNew ? 'Nuova scheda cimitero' : 'Modifica ' + this.state.data.name }
                        </Typography>
                    </Box>
                </CardMedia>
                {(this.state.isNew || this.state.loaded) ?
                    <Fragment>
                        <CardContent>
                            <Grid container spacing={2} direction="column">
                                <Grid container item spacing={1}>
                                    <Grid item xs={12} md={3} lg={2}>
                                        <Typography variant="h6">Dati</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9} lg={10}>
                                        <Grid container spacing={1}>
                                            {(this.state.isNew && (!this.actionData.params || !this.actionData.params.unitKey))?
                                            <Grid item xs={12} md={12} lg={12}>
                                                    <FormOUnitSelector
                                                        value={this.state.data.owner.unitKey}
                                                        appMng={this.props.appMng}
                                                        retOrgAndUnit={true}
                                                        label="Unità organizzativa responsabile *"
                                                        onChange={value => this.setField('owner', value)}
                                                    />
                                            </Grid>:null}
                                            <Grid item xs={12} md={12} lg={12}>
                                                <TextField
                                                    label="Denominazione"
                                                    value={this.state.data.name ? this.state.data.name : ''}
                                                    margin="normal"
                                                    variant="outlined"
                                                    helperText="nome esteso del cimitero"
                                                    placeholder="es. Cimitero Sant’Arialdo"
                                                    fullWidth
                                                    required
                                                    onChange={e => this.setField('name', e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12} lg={12}>
                                                <FormControl fullWidth variant="outlined" margin="normal">
                                                    <InputLabel htmlFor="outlined-adornment-address">Indirizzo stradale</InputLabel>
                                                    <OutlinedInput
                                                        id="outlined-adornment-address"
                                                        placeholder="es. Piazza Rimembranze 1 - 20088 Rosate"
                                                        value={this.state.data.fullAddress? this.state.data.fullAddress : ''}
                                                        onChange={e => this.setField('fullAddress', e.target.value)}
                                                        startAdornment={<InputAdornment position="start"><LocationOnRoundedIcon /></InputAdornment>}
                                                        labelWidth={150}
                                                    />
                                                    <FormHelperText>indirizzo scritto per esteso</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={3} lg={4} style={{alignSelf:"center"}}>
                                                <MyLocationRoundedIcon /> <Typography component="span"><b>Coordinate in <Link target="_black" href="https://it.wikipedia.org/wiki/Coordinate_geografiche">gradi decimanli</Link></b></Typography>
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <TextField
                                                    type="number"
                                                    label="Latitudine"
                                                    value={(this.state.data.geoPosition && this.state.data.geoPosition.lat) ? this.state.data.geoPosition.lat : ''}
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    placeholder="es. 41.8902300"
                                                    onChange={e => this.setField(['geoPosition','lat'], e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={4}>
                                                <TextField
                                                    type="number"
                                                    label="Longitudine"
                                                    value={(this.state.data.geoPosition && this.state.data.geoPosition.long) ? this.state.data.geoPosition.long : ''}
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    placeholder="es. 12.4922260"
                                                    onChange={e => this.setField(['geoPosition','long'], e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12} lg={12}>
                                                <FormControl fullWidth variant="outlined" margin="normal">
                                                    <InputLabel htmlFor="outlined-adornment-opening">Aperture al pubblico</InputLabel>
                                                    <OutlinedInput
                                                        id="outlined-adornment-opening"
                                                        placeholder="es. Lunedì dalle 9 alle 12:30, Martedì-Venerdì dalle 8:30 alle 19:30. Sabato e Domenica dalle 8 alle 20."
                                                        multiline={true}
                                                        value={this.state.data.opening? this.state.data.opening : ''}
                                                        onChange={e => this.setField('opening', e.target.value)}
                                                        startAdornment={<InputAdornment position="start"><EventNoteRoundedIcon /></InputAdornment>}
                                                        labelWidth={150}
                                                    />
                                                    <FormHelperText>informazioni delle aperture per il pubblico</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={2}>
                                        <Typography variant="h6">Mappa</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9} lg={10}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={this.state.data.map.type === 'systemHostedImage'?5:12} lg={this.state.data.map.type === 'systemHostedImage'?4:12}>
                                                <Switch
                                                    checked={this.state.data.map.type === 'systemHostedImage'}
                                                    onChange={e => {
                                                        this.setField(['map','type'], e.target.checked ? 'systemHostedImage' : 'none')
                                                        this.updateMapDefs()
                                                    }}
                                                    inputProps={{
                                                        'aria-label': "abilita mappa sotto planimetria",
                                                        'title': "abilita mappa sotto la planimetria",
                                                    }}
                                                    aria-labelledby="enable-map"
                                                />
                                                <Typography id="enable-map" component="span">mappa sotto la planimetria</Typography>
                                            </Grid>
                                            {this.state.data.map.type === 'systemHostedImage' ?<Fragment>
                                                <Grid item xs={12} md={7} lg={8}>
                                                    <Button
                                                        component="label"
                                                        startIcon={<CloudUploadRoundedIcon/>}
                                                        disabled={this.state.uploadingMap}
                                                    >
                                                        Carica nuova mappa
                                                        <input
                                                            accept="image/png, image/jpeg"
                                                            type="file"
                                                            style={{ display: "none" }}
                                                            onChange={e => {
                                                                this.setState({uploadingMap: true})
                                                                this.mapImage.upload(e).then(objurl => this.setState({mapImage: objurl, uploadingMap: false}))
                                                            }}
                                                        />
                                                        {this.state.uploadingMap && <CircularProgress size={24} style={{position: 'absolute'}}/>}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} md={2} lg={2}>
                                                    <TextField
                                                        type="number"
                                                        label="Origine X"
                                                        value={this.state.data.map.origin.x?this.state.data.map.origin.x:0}
                                                        inputProps={{min: -1000, max: 1000}}
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        onChange={e => this.setField(['map','origin','x'], (e.target.value < -1000 ? -1000 : (e.target.value > 1000 ? 1000 : (!e.target.value ? 0 : e.target.value))))}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={2} lg={2}>
                                                    <TextField
                                                        type="number"
                                                        label="Origine Y"
                                                        value={this.state.data.map.origin.y?this.state.data.map.origin.y:0}
                                                        inputProps={{min: -1000, max: 1000}}
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        onChange={e => this.setField(['map','origin','y'], (e.target.value < -1000 ? -1000 : (e.target.value > 1000 ? 1000 : (!e.target.value ? 0 : e.target.value))))}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4} lg={4}>
                                                    <div>
                                                        <Typography id="scale-slider" gutterBottom>
                                                            Scala
                                                        </Typography>
                                                        <Slider
                                                            value={this.state.data.map.scale}
                                                            onChange={(e, value) => this.setField(['map','scale'], value)}
                                                            step={0.1}
                                                            min={0.5}
                                                            max={3}
                                                            valueLabelDisplay="auto"
                                                            aria-labelledby="scale-slider"
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4} lg={4}>
                                                    <div>
                                                        <Typography id="alpha-slider" gutterBottom>
                                                            Opacità
                                                        </Typography>
                                                        <Slider
                                                            value={this.state.data.map.alpha}
                                                            onChange={(e, value) => this.setField(['map','alpha'], value)}
                                                            step={0.1}
                                                            min={0.1}
                                                            max={1}
                                                            valueLabelDisplay="auto"
                                                            aria-labelledby="scale-slider"
                                                        />
                                                    </div>
                                                </Grid>
                                            </Fragment>: null}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography variant="h6">Planimetria</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <JPolyDraw onChange={data => this.setField('planimetry', data)} settings={polyEditorParams} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Box m={1}>
                                <Button onClick={() => this.persistsData()}><SaveIcon />{(this.state.isNew ? 'Registra' : 'Salva modifiche')}</Button>
                                <Button onClick={() => this.props.appMng.closeAction(this.props.id)}><CancelRoundedIcon />Annulla</Button>
                            </Box>
                        </CardActions>
                    </Fragment >
                    :
                    <CardContent>
                        <Skeleton height="25pt" width="100%" />
                    </CardContent>
                }
            </Card>
        )
    }
}

export default withStyles(AppActionGraveyardEditorStyle)(AppActionGraveyardEditor)