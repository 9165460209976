class schemaDeceased {
    _name = '';
    _surname = '';
    _birthday = '';
    _deathdate = '';
    set name(value) {
        if(typeof value === "string" && value.trim().length >= 0){
            this._name = value
        }
    }
    get name() {
        return this._name
    }
    set surname(value) {
        if(typeof value === "string" && value.trim().length >= 0){
            this._surname = value
        }
    }
    get surname() {
        return this._surname
    }
    set birthday(value) {
        if(typeof value === "string" && /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(value)){
            this._birthday = value
        }
    }
    get birthday() {
        return this._birthday
    }
    set deathdate(value) {
        if(typeof value === "string" && /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(value)){
            this._deathdate = value
        }
    }
    get deathdate() {
        return this._deathdate
    }
    minComplete() {
        return (this._name.trim().length > 0 && this._surname.trim().length > 0 && this._deathdate.trim().length > 0)
    }
}

export default schemaDeceased