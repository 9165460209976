import React, { Component } from 'react'
import { DialogContentText, Button, DialogContent, Grid} from '@material-ui/core'
import FormDeceased from '../../Forms/FormDeceased';

class DialogEditDeceased extends Component {

    showStatus = false

    vals = {}

    constructor(props) {
        super(props)
        this.initVals()
    }

    initVals(load) {
        this.vals = {
            deceasedObj: null,               /* object representing the burial */
        }
        if (load) {
            this.vals.deceasedObj = {...this.props.deceased}
        }
    }

    /** Performs the recording*/
    goOn() {
        this.props.appMng.props.JBLClient.updateDeceased(this.vals.deceasedObj.key, this.vals.deceasedObj)  //request to set the data
            .then(response => {
                if (response) {   //if the response is a success
                    this.close()  //close the dialog
                }
            });
    }


    /** Show or hide the content of the dialog box
     * @param status dialog opened o closed
    */
    show(status) {
        if (status) {
            if (!this.showStatus) {         //if it wasn't show
                this.initVals(true)         //initialize the values loading them from the burial
            }
            this.props.appMng.openDialog('Correzione dati di ' + this.vals.deceasedObj.myNameIs, (
                <>
                    <DialogContent>
                        <DialogContentText id="main-dialog-description">
                            Correggi se i dati sono stati registrati incompleti o errati
                        </DialogContentText>
                        <Grid container spacing={1} direction="column" justify="flex-start" alignItems="stretch">
                        <FormDeceased
                            appMng={this.props.appMng}
                            data={this.vals.deceasedObj}
                            onChange={e=>this.vals.deceasedObj = e.data}
                        />
                        </Grid>
                    </DialogContent>
                </>),
                (<>
                    <Button aria-label="procedi" onClick={() => this.goOn()}>Procedi</Button>
                    <Button aria-label="annulla" onClick={() => this.close()}>Annulla</Button>
                </>))
        } else {
            this.props.appMng.closeDialog()
        }
        this.showStatus = status
        return true
    }

    close() {
        if (typeof this.props.onClose === "function") { this.props.onClose() }
        this.showStatus = false
        this.props.appMng.closeDialog()
    }

    render() {
        if (this.props.open !== this.showStatus) {
            this.show(this.props.open)
        }
        return null
    }

}

export default DialogEditDeceased