import React from 'react'

const JPolyIconZoomReset = (props) => {
    const defColor = props.color ? props.color : 'black'
    const bgColor = props.bgColor ? props.bgColor : 'white'
    return <g onClick={(e) => props.onClick(e)} transform={`translate(${props.x || 0} ${props.y || 0})scale(${((props.size || 24) / 1000)})`} vectorEffect="non-scaling-stroke">
        <circle cx="500" cy="500" r="500" fill={bgColor} fillOpacity="0.6" stroke={defColor} strokeLinejoin="round" strokeWidth="90" paintOrder="markers stroke fill" />
        <path d="m183.55 172.82c-5.9428 0-10.727 3.1896-10.727 7.1515v187.53h91.022v-96.512c0-3.9619 4.7842-7.1515 10.727-7.1515h92.937v-91.022zm448.94 0v91.022h92.937c5.9428 0 10.728 3.1896 10.728 7.1515v96.512h91.022v-187.53c0-3.9619-4.7842-7.1515-10.727-7.1515zm-132.49 234.47a92.708 92.708 0 0 0-92.708 92.708 92.708 92.708 0 0 0 92.708 92.708 92.708 92.708 0 0 0 92.708-92.708 92.708 92.708 0 0 0-92.708-92.708zm-327.18 225.2v187.53c0 3.9619 4.7842 7.1515 10.727 7.1515h183.96v-91.022h-92.937c-5.9428 0-10.727-3.1896-10.727-7.1515v-96.512zm563.33 0v96.512c0 3.9619-4.7847 7.1515-10.728 7.1515h-92.937v91.022h183.96c5.9428 0 10.727-3.1896 10.727-7.1515v-187.53z"
            fill={defColor}
            paintOrder="markers stroke fill"
        />
    </g>
}

export default JPolyIconZoomReset