import React, { Component, Fragment } from 'react'
import { withStyles, TextField, Button, CardContent, Typography, CardActions, Card, CardMedia, Grid, Box, InputAdornment, FormControl, InputLabel, OutlinedInput, FormHelperText, Switch } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import PhoneRounded from '@material-ui/icons/PhoneRounded';
import EmailRounded from '@material-ui/icons/EmailRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import SaveIcon from '@material-ui/icons/Save'
import AppActionOUEditorStyle from './AppActionOUEditorStyle';
import mediaHeader from '../../../../images/editor_opt.svg'

class AppActionOUEditor extends Component {

    state = {
        loaded: false,
        isNew: false,
        data: {
            name: '',
            isGraveyardOwner: false,
            inheritReferences: {
                mainFullAddress: true,
                mainPhone: true,
                mainEmail: true,
            },
            mainFullAddress: '',
            mainPhone: '',
            mainEmail: '',
            directReferent: '',
            directPhone: '',
            directEmail: '',
        },
        orgData: {
            name: ''
        }
    }

    constructor(props) {
        super(props)
        this.actionData = this.props.appMng.actionsStack[this.props.id]
        if (typeof this.actionData.params === 'object' && this.actionData.params.key) {
            this.getData()
        } else {
            this.state.isNew = true
        }
        this.props.appMng.props.JBLClient.getOrg(this.actionData.params.orgKey)
        .then(response => {
            this.setState({ orgData: response })
        });
        this.props.appMng.setTitle((this.state.isNew ? 'Nuovo' : 'Modifica') + ' unità organizzativa')
    }

    getData() {
        if (this.actionData.params.key) {
            this.props.appMng.props.JBLClient.getOUnit(this.actionData.params.orgKey, this.actionData.params.key)
                .then(response => {
                    this.setState({ loaded: true, data: {...response} })
                });
        }
    }

    persistsData() {
        let peristPromise = (this.state.isNew ? this.props.appMng.props.JBLClient.newOUnit(this.actionData.params.orgKey, this.state.data) : this.props.appMng.props.JBLClient.updateOUnit(this.actionData.params.orgKey, this.actionData.params.key, this.state.data))
        peristPromise
            .then(result => {
                if (result !== null) {
                    this.props.appMng.setActionPersistence(this.props.id, true)
                    this.props.appMng.openAction('OUnit', { orgKey: this.actionData.params.orgKey, key: result })
                }
            })
            .catch(error => {
                console.error(error)
                this.props.appMng.notifyMsgToGui({
                    'kind': 'error',
                    'text': "Si è verificato un errore imprevisto durante la registrazione dei dati."
                })
            })
    }

    setField(name, value) {
        let data = this.state.data
        let stepData = data
        let path = (Array.isArray(name)?name:[name])
        let lastName = path.splice(-1,1)[0]
        for(let step of path){
            stepData = stepData[step]
        }
        if (stepData[lastName] !== value) {
            this.props.appMng.setActionPersistence(this.props.id, false)
            stepData[lastName] = value
            this.setState({ data: data })
        }
    }

    render() {
        const mainFullAddress = this.state.data.inheritReferences.mainFullAddress ? this.state.orgData.mainFullAddress : this.state.data.mainFullAddress
        const mainPhone = this.state.data.inheritReferences.mainPhone ? this.state.orgData.mainPhone : this.state.data.mainPhone
        const mainEmail = this.state.data.inheritReferences.mainEmail ? this.state.orgData.mainEmail : this.state.data.mainEmail
        const directPhone = this.state.data.directPhone
        const directEmail = this.state.data.directEmail
        const directReferent = this.state.data.directReferent

        return (
            <Card >
                <CardMedia className={this.props.classes.headMedia} image={mediaHeader}>
                    <Box p={3} className={this.props.classes.itemsHeaderText}>
                        <Typography variant="h4" color="inherit">
                            { this.state.isNew ? 'Nuova scheda unità organizzativa' : 'Modifica ' + this.state.data.name }
                        </Typography>
                        <Typography variant="subtitle1" color="inherit">
                            { 'di ' + this.state.orgData.name }
                        </Typography>
                    </Box>
                </CardMedia>
                {(this.state.isNew || this.state.loaded) ?
                    <Fragment>
                        <CardContent>
                            <Grid container spacing={2} direction="column">
                                <Grid container item spacing={1}>
                                    <Grid item xs={12} md={3} lg={2}>
                                        <Typography variant="h6">Identità</Typography>
                                    </Grid>
                                    <Grid item container xs={12} md={9} lg={10} spacing={1} alignItems="center">
                                        <Grid item xs={12} md={12} lg={12}>
                                            <TextField
                                                label="Nome unità"
                                                value={this.state.data.name ? this.state.data.name : ''}
                                                margin="normal"
                                                variant="outlined"
                                                helperText="nome dell'unità organizzativa"
                                                placeholder="es. Ufficio Servizi Funebri e Cimiteriali"
                                                fullWidth
                                                required
                                                onChange={e => this.setField('name', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={2} md={2} lg={1} className={this.props.classes.inheritSwitch}>
                                            <Switch
                                                checked={this.state.data.isGraveyardOwner}
                                                onChange={e => this.setField('isGraveyardOwner', e.target.checked)}
                                                inputProps={{
                                                    'aria-label': "diretta responsabilità di uno o più cimiteri",
                                                    'title': "diretta responsabilità di uno o più cimiteri",
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={10} md={10} lg={11}>
                                            <Typography>ha diretta responsabilità su uno o più cimiteri</Typography>
                                        </Grid>
                                        <Grid item xs={2} md={2} lg={1} className={this.props.classes.inheritSwitch}>
                                            <Switch
                                                checked={this.state.data.inheritReferences.mainFullAddress}
                                                onChange={e => this.setField(['inheritReferences','mainFullAddress'], e.target.checked)}
                                                inputProps={{
                                                    'aria-label': "eredita dall'ente",
                                                    'title': "eredita dall'ente",
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={11}>
                                            <FormControl fullWidth variant="outlined" margin="normal">
                                                <InputLabel htmlFor="outlined-adornment-address">Indirizzo stradale</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-address"
                                                    placeholder="es. Via G. Marconi 12 - 20088 Rosate"
                                                    disabled={this.state.data.inheritReferences.mainFullAddress}
                                                    value={mainFullAddress ? mainFullAddress : ''}
                                                    onChange={e => this.setField('mainFullAddress', e.target.value)}
                                                    startAdornment={<InputAdornment position="start"><LocationOnRoundedIcon /></InputAdornment>}
                                                    labelWidth={150}
                                                />
                                                <FormHelperText>indirizzo stradale completo dell'unità organizzativa</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={2}>
                                        <Typography variant="h6">Recapiti</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9} lg={10} container spacing={1} alignItems="center">
                                        <Grid item xs={2} md={2} lg={1} className={this.props.classes.inheritSwitch}>
                                            <Switch
                                                checked={this.state.data.inheritReferences.mainPhone}
                                                onChange={e => this.setField(['inheritReferences','mainPhone'], e.target.checked)}
                                                inputProps={{
                                                    'aria-label': "eredita dall'ente",
                                                    'title': "eredita dall'ente",
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={4}>
                                            <FormControl fullWidth variant="outlined" margin="normal">
                                                <InputLabel htmlFor="outlined-adornment-pubphone">Telefono per il pubblico</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-pubphone"
                                                    placeholder="es. +390244456123"
                                                    value={mainPhone ? mainPhone : ''}
                                                    disabled={this.state.data.inheritReferences.mainPhone}
                                                    onChange={e => this.setField('mainPhone', e.target.value)}
                                                    startAdornment={<InputAdornment position="start"><PhoneRounded /></InputAdornment>}
                                                    labelWidth={150}
                                                />
                                                <FormHelperText>numero di telefono per il pubblico</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2} md={2} lg={1} className={this.props.classes.inheritSwitch}>
                                            <Switch
                                                checked={this.state.data.inheritReferences.mainEmail}
                                                onChange={e => this.setField(['inheritReferences','mainEmail'], e.target.checked)}
                                                inputProps={{
                                                    'aria-label': "eredita dall'ente",
                                                    'title': "eredita dall'ente",
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={6}>
                                            <FormControl fullWidth variant="outlined" margin="normal">
                                                <InputLabel htmlFor="outlined-adornment-pubemail">eMail per il pubblico</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-pubemail"
                                                    placeholder="es. info@example.org"
                                                    value={mainEmail ? mainEmail : ''}
                                                    disabled={this.state.data.inheritReferences.mainEmail}
                                                    onChange={e => this.setField('mainEmail', e.target.value)}
                                                    startAdornment={<InputAdornment position="start"><EmailRounded /></InputAdornment>}
                                                    labelWidth={150}
                                                />
                                                <FormHelperText>indirizzo email per il pubblico</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2} md={2} lg={1} className={this.props.classes.inheritSwitch}></Grid>
                                        <Grid item xs={12} md={12} lg={11}>
                                            <FormControl fullWidth variant="outlined" margin="normal">
                                                <InputLabel htmlFor="outlined-adornment-ref">Referente principale</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-ref"
                                                    placeholder="es. Maria Rossi"
                                                    value={directReferent ? directReferent : ''}
                                                    onChange={e => this.setField('directReferent', e.target.value)}
                                                    startAdornment={<InputAdornment position="start"><PersonRoundedIcon /></InputAdornment>}
                                                    labelWidth={150}
                                                />
                                                <FormHelperText>nome del referente principale (non pubblico)</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2} md={2} lg={1} className={this.props.classes.inheritSwitch}></Grid>
                                        <Grid item xs={12} md={12} lg={4}>
                                            <FormControl fullWidth variant="outlined" margin="normal">
                                                <InputLabel htmlFor="outlined-adornment-dirphone">Telefono diretto</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-dirphone"
                                                    placeholder="es. +390244456321"
                                                    value={directPhone ? directPhone : ''}
                                                    onChange={e => this.setField('directPhone', e.target.value)}
                                                    startAdornment={<InputAdornment position="start"><PhoneRounded /></InputAdornment>}
                                                    labelWidth={150}
                                                />
                                                <FormHelperText>numero di telefono diretto (non pubblico)</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2} md={2} lg={1} className={this.props.classes.inheritSwitch}></Grid>
                                        <Grid item xs={12} md={12} lg={6}>
                                            <FormControl fullWidth variant="outlined" margin="normal">
                                                <InputLabel htmlFor="outlined-adornment-diremail">eMail diretta</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-diremail"
                                                    placeholder="es. a.salander@example.org"
                                                    value={directEmail ? directEmail : ''}
                                                    onChange={e => this.setField('directEmail', e.target.value)}
                                                    startAdornment={<InputAdornment position="start"><EmailRounded /></InputAdornment>}
                                                    labelWidth={150}
                                                />
                                                <FormHelperText>indirizzo email diretta (non pubblica)</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Box m={1}>
                                <Button onClick={() => this.persistsData()}><SaveIcon />{(this.state.isNew ? 'Registra' : 'Salva modifiche')}</Button>
                                <Button onClick={() => this.props.appMng.closeAction(this.props.id)}><CancelRoundedIcon />Annulla</Button>
                            </Box>
                        </CardActions>
                    </Fragment >
                    :
                    <CardContent>
                        <Skeleton height="25pt" width="100%" />
                    </CardContent>
                }
            </Card>
        )
    }
}

export default withStyles(AppActionOUEditorStyle)(AppActionOUEditor)