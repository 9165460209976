import React, { Component, Fragment } from 'react'
import { DialogContentText, Button, DialogContent, FormControlLabel, Grid, TextField, Switch } from '@material-ui/core'

class DialogPlanExhumation extends Component {

    showStatus = false

    vals = {}

    constructor(props) {
        super(props)
        this.initVals()
    }

    initVals(load) {
        this.vals = {
            burialObj: null,               /* object representing the burial */
            burialDuration: 10,             /* planned years in the burial */
            infiniteBurial: false,          /* the burial will never expire */
            plannedExhumationDate: '',      /* planned date for burial exipiration */
            annotation: null                /* notes related to the burial */
        }
        if (load) {
            this.vals.burialObj = this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd]
            this.vals.burialDuration = this.plannedDateToYears(this.vals.burialObj.planned_exhumation_date, this.vals.burialObj.burial_date, this.vals.burialDuration)
            this.vals.infiniteBurial = this.vals.burialObj.isReservation ? false : !Boolean(this.vals.burialObj.planned_exhumation_date)
            this.vals.plannedExhumationDate = this.vals.burialObj.planned_exhumation_date ? this.vals.burialObj.planned_exhumation_date : (this.vals.burialObj.isReservation ? this.yearsToplannedDate(this.vals.burialObj.burial_date, this.vals.burialDuration, false) : '')
        }
    }

    plannedDateToYears(plannedDate, burialDate, defaultValue) {
        if (burialDate && plannedDate) {
            const burialODate = new Date(burialDate)
            const plannedODate = new Date(plannedDate)
            return Math.round((((plannedODate.getTime() - burialODate.getTime()) / 1000) / (60 * 60 * 24)) / 365.25);
        } else {
            return defaultValue
        }
    }

    yearsToplannedDate = (burial_date, years, infinite) => {
        if (burial_date && years && !infinite) {
            const planned_exhumation_date = new Date(burial_date)
            planned_exhumation_date.setFullYear(parseInt(planned_exhumation_date.getFullYear(), 10) + parseInt(years, 10))
            const day = planned_exhumation_date.getDate().toString()
            const month = (planned_exhumation_date.getMonth() + 1).toString()
            const year = planned_exhumation_date.getFullYear().toString()
            return year.padStart(4, '0') + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0')
        } else {
            return ''
        }
    }

    /** It says if all the required infromation has been collected */
    canProcede() {
        return ((this.vals.plannedExhumationDate || this.vals.infiniteBurial) && this.vals.burialObj.planned_exhumation_date !== this.vals.plannedExhumationDate)
    }

    /** Performs the recording of exhumation */
    procede() {
        if (this.canProcede()) {
            let dataPack = {
                plannedExhumationDate: this.vals.infiniteBurial ? null : this.vals.plannedExhumationDate,
                annotation: this.vals.annotation
            }
            this.props.appMng.props.JBLClient.planExhumation(this.props.sourceGrave.relatedBurial[this.props.sourceBurialOrd].key, dataPack)  //request to set the new planned exhumation date of the exhumation
                .then(response => {
                    if (response) {   //if the response is a success
                        this.close()  //close the dialog
                    }
                });
        } else {
            this.props.appMng.notifyMsgToGui({
                'kind': 'error',
                'text': "Una o più informazioni necessarie per poter procedere risultano mancanti. Per favore compila i dati mancanti."
            })
        }
    }

    /** Set the value of a field
     * @param fname name of the field
     * @param fvalue new value of the field
     */
    setField(fname, fvalue) {
        switch (fname) {
            case 'annotation':                      //notes
                this.vals.annotation = (fvalue ? fvalue : null)
                break
            case 'burialDuration':
                this.vals.burialDuration = (fvalue >= 0 ? fvalue : null)
                this.vals.plannedExhumationDate = this.yearsToplannedDate(this.vals.burialObj.burial_date, this.vals.burialDuration, this.vals.infiniteBurial)
                break
            case 'infiniteBurial':
                this.vals.infiniteBurial = Boolean(fvalue)
                if (!this.vals.infiniteBurial && !this.vals.plannedExhumationDate) {
                    this.vals.plannedExhumationDate = this.yearsToplannedDate(this.vals.burialObj.burial_date, this.vals.burialDuration, this.vals.infiniteBurial)
                } else {
                    this.vals.plannedExhumationDate = ''
                }
                break
            case 'plannedExhumationDate':
                this.vals.plannedExhumationDate = (fvalue ? fvalue : null)
                this.vals.burialDuration = this.plannedDateToYears(this.vals.plannedExhumationDate, this.vals.burialObj.burial_date, this.vals.burialDuration)
                break;
            default:
                return false
        }
        return this.show(this.props.open)
    }

    /** Show or hide the content of the dialog box
     * @param status dialog opened o closed
    */
    show(status) {
        if (status) {
            if (!this.showStatus) {         //if it wasn't show
                this.initVals(true)         //initialize the values loading them from the burial
            }
            this.props.appMng.openDialog(this.vals.burialObj.isReservation ? 'Variazione termine della prenotazione' : 'Pianificazione esumazione di ' + this.vals.burialObj.deceased.myNameIs, (
                <Fragment>
                    <DialogContent>
                        <DialogContentText id="main-dialog-description">
                            {this.vals.burialObj.isReservation ? 'Prenotazione per' : 'I suoi resti si trovano qui'}: <b>{this.props.sourceGrave.location}, {this.props.sourceGrave.myNameIs}</b>.
                        </DialogContentText>
                        <DialogContentText>
                            <b>{this.vals.burialObj.isReservation ? 'Quando fissare la scadenza della prenotazione?' : "Per quando è prevista l'esumazione?"}</b>
                        </DialogContentText>
                        <Grid container spacing={1} direction="column" justify="flex-start" alignItems="stretch">
                            <Grid item>
                                <Grid container spacing={1} direction="row" justify="space-between" alignItems="center">
                                    <Grid item>
                                        <TextField
                                            label={this.vals.burialObj.isReservation ? 'Anni dalla prenotazione' : "Anni dall'inumazione"}
                                            value={this.vals.burialDuration}
                                            disabled={this.vals.infiniteBurial}
                                            type="number"
                                            margin="normal"
                                            variant="outlined"
                                            inputProps={{
                                                min: 1
                                            }}
                                            onChange={(event) => this.setField('burialDuration', event.target.value)}
                                        />
                                    </Grid>
                                    { !this.vals.burialObj.isReservation?
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={this.vals.infiniteBurial}
                                                        onChange={(event) => this.setField('infiniteBurial', event.target.checked)}
                                                    />
                                                }
                                                label="senza termine"
                                            />
                                        </Grid>:null
                                    }
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={this.vals.burialObj.isReservation ? 'Data termine' : "Data pianificata"}
                                        onChange={(event) => this.setField('plannedExhumationDate', event.target.value)}
                                        disabled={this.vals.infiniteBurial}
                                        value={this.vals.plannedExhumationDate}
                                        type="date"
                                        required={!this.vals.infiniteBurial}
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label="Annotazioni"
                                        multiline
                                        fullWidth
                                        rowsMax="6"
                                        onChange={(event) => this.setField('annotation', event.target.value)}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Fragment>),
                (<Fragment>
                    <Button aria-label="procedi" disabled={!this.canProcede()} onClick={() => this.procede()}>Procedi</Button>
                    <Button aria-label="annulla" onClick={() => this.close()}>Annulla</Button>
                </Fragment>))
        } else {
            this.props.appMng.closeDialog()
        }
        this.showStatus = status
        return true
    }

    close() {
        if (typeof this.props.onClose === "function") { this.props.onClose() }
        this.showStatus = false
        this.props.appMng.closeDialog()
    }

    render() {
        if (this.props.open !== this.showStatus) {
            this.show(this.props.open)
        }
        return null
    }

}

export default DialogPlanExhumation