import React, { Component } from 'react'
import { Chip } from '@material-ui/core'
import RotateLeftRoundedIcon from '@material-ui/icons/RotateLeftRounded';
import JSelect from '../../../JForm/controls/JSelect';

/** Draw a selector of pubbible graveyard */
class FormOUnitSelector extends Component {

    state = {
        selectedKey: null,
        selectedOrgKey: null,
        selectedPos: null,
        listOptions: []
    }

    componentDidMount() {
        this.props.appMng.props.JBLClient.getOrgs()
            .then(response => {
                let list = []
                let selectedKey = null
                let selectedPos = null
                if (Array.isArray(response)){
                    let pos = 0
                    for (let org of response) {
                        for (let OUnit of org.relatedOrganizationalUnit) {
                            list[pos] = {key: OUnit.key, orgKey: org.key, name: org.myNameIs + ' - ' + OUnit.name}
                            if(OUnit.key === this.props.value){
                                selectedKey = OUnit.key
                                selectedPos = pos
                            }
                            pos++
                        }
                    }
                }
                this.setState({ selectedKey: selectedKey, selectedPos: selectedPos, listOptions: list })
            });
    }

    /** Set the new value */
    setValue(newValue) {
        let selectedKey = null
        let selectedOrgKey = null
        let selectedPos = null
        if (this.state.listOptions[newValue]) {
            selectedKey = this.state.listOptions[newValue].key
            selectedOrgKey = this.state.listOptions[newValue].orgKey
            selectedPos = newValue
        } else {
            selectedKey = null
            selectedOrgKey = null
            selectedPos = null
        }
        this.setState({ selectedKey: selectedKey, selectedOrgKey: selectedOrgKey, selectedPos: selectedPos })
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(this.props.retOrgAndUnit? {orgKey: selectedOrgKey, unitKey: selectedKey} : selectedKey)
        }
    }

    /** Render the path */
    render() {
        let opList = [                              //add an empty option for the select
            <option key="none" value={null}></option>
        ]
        for (let pos in this.state.listOptions) {        //add them to option list
            let option = this.state.listOptions[pos]
            opList.push(<option key={pos} value={pos}>{option.name}</option>)
        }
        return (this.state.selectedPos !== null ?
            <Chip
                color="primary"
                label={this.state.listOptions[this.state.selectedPos].name}
                onDelete={() => this.setValue(null)}
                deleteIcon={<RotateLeftRoundedIcon />}
            />
            : <JSelect
                value={this.state.selectedPos}
                label={this.props.label?this.props.label:'Unità organizzativa'}
                onChange={value => this.setValue(value)}
            >
                {opList}
            </JSelect>)
    }
}

export { FormOUnitSelector as default }