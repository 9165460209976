import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import { useScrollTrigger, Dialog, AppBar, Toolbar, Typography, IconButton, Drawer, Divider, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Zoom, Container, Menu, Grid} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import SettingsApplicationsRoundedIcon from '@material-ui/icons/SettingsApplicationsRounded';
import LoggedInStyle from './LoggedInStyle';
import Logo from '../../images/logo_mono.svg'
import AppActionMain from './Actions/AppActionMain';
import AppActionAccount from './Actions/AppActionAccount';
import AppActionOrgs from './Actions/AppActionOrgs';
import AppActionOrg from './Actions/AppActionOrg';
import AppActionOrgEditor from './Actions/AppActionOrgEditor';
import AppActionOUEditor from './Actions/AppActionOUEditor';
import AppActionOU from './Actions/AppActionOU';
import AppActionGraveyardEditor from './Actions/AppActionGraveyardEditor';
import AppActionGraveyard from './Actions/AppActionGraveyard';
import AppActionGraveyards from './Actions/AppActionGraveyards';
import AppActionGraveyardSpaceEditor from './Actions/AppActionGraveyardSpaceEditor';
import AppActionGraveEditor from './Actions/AppActionGraveEditor';
import AppActionGrave from './Actions/AppActionGrave';
import AppActionBurialEditor from './Actions/AppActionBurialEditor';
import AppActionSpcModels from './Actions/AppActionSpcModels';
import AppActionSpcModelEditor from './Actions/AppActionSpcModelEditor';
import AppActionSysUserEditor from './Actions/AppActionSysUserEditor';
import AppActionSysUser from './Actions/AppActionSysUser';
import AppActionsysUsers from './Actions/AppActionsysUsers';

const DialogTransition = React.forwardRef(function Transition(props, ref) {
    return <Zoom ref={ref} {...props} />;
});

function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

class AppActionUndefined extends Component {
    render() {
        return (<div>Undefined action</div>)
    }
}

class LoggedInApp extends Component {

    actionDefs = {
        main: {
            component: AppActionMain,
            makeId: () => { return 'main' },
            protected: true
        },
        account: {
            component: AppActionAccount,
            makeId: () => { return 'me' },
            closeWhenLeaving: true
        },
        orgsList: {
            component: AppActionOrgs,
            makeId: () => { return 'orgsList' }
        },
        org: {
            component: AppActionOrg,
            makeId: (params) => { return 'org_' + params.key }
        },
        orgEditor: {
            component: AppActionOrgEditor,
            makeId: (params) => { return 'orgEdt_' + ((typeof params === 'object' && params.key) ? params.key : 'new') },
            closeWhenLeaving: true
        },
        OUEditor: {
            component: AppActionOUEditor,
            makeId: (params) => { return 'OUEdt_' + ((typeof params === 'object' && params.key) ? params.key : 'new') },
            closeWhenLeaving: true
        },
        OUnit: {
            component: AppActionOU,
            makeId: (params) => { return 'OU_' + params.key }
        },
        graveyardEditor: {
            component: AppActionGraveyardEditor,
            makeId: (params) => { return 'gyardEdt_' + ((typeof params === 'object' && params.key) ? params.key : 'new') },
            closeWhenLeaving: true
        },
        graveyard: {
            component: AppActionGraveyard,
            makeId: (params) => { return 'gyard_' + params.key }
        },
        graveyardsList: {
            component: AppActionGraveyards,
            makeId: () => { return 'gyardList' }
        },
        graveyardSpaceEditor: {
            component: AppActionGraveyardSpaceEditor,
            makeId: (params) => { return 'gyardSpEdt_' + ((typeof params === 'object' && params.key) ? params.key : 'new') },
            closeWhenLeaving: true
        },
        graveEditor: {
            component: AppActionGraveEditor,
            makeId: (params) => { return 'graveEdt_' + ((typeof params === 'object' && params.key) ? params.key : 'new') },
            closeWhenLeaving: true
        },
        grave: {
            component: AppActionGrave,
            makeId: (params) => { return 'grave_' + params.key }
        },
        burialEditor: {
            component: AppActionBurialEditor,
            makeId: (params) => { return 'burialEdt_' + ((typeof params === 'object' && params.key) ? params.key : 'new') },
            closeWhenLeaving: true
        },
        spaceModelsList: {
            component: AppActionSpcModels,
            makeId: () => { return 'smList' }
        },
        spaceModelEditor: {
            component: AppActionSpcModelEditor,
            makeId: (params) => { return 'smEdt_' + ((typeof params === 'object' && params.key) ? params.key : 'new') },
            closeWhenLeaving: true
        },
        sysUser: {
            component: AppActionSysUser,
            makeId: (params) => { return 'sysUser_' + params.key },
        },
        sysUserEditor: {
            component: AppActionSysUserEditor,
            makeId: (params) => { return 'sysUserEdt_' + ((typeof params === 'object' && params.key) ? params.key : 'new') },
            closeWhenLeaving: true
        },
        sysUsersList: {
            component: AppActionsysUsers,
            makeId: () => { return 'sysUsersList' }
        }
    };

    actionsStack = {}

    state = {
        accountMenu: {
            target: null
        },
        account: {
            loaded: false,
            data: null
        },
        drawerOpen: true,
        dialogViewsCount: 0,
        dialogOpen: false,
        title: 'App',
        drawrContent: (<Fragment />),
        currentDialog: {},
        currentAction: {}
    }

    resetDialog(){
        this.setState({
            dialogOpen: false,
            currentDialog: {
                title: null,
                content: null,
                actions: <Button onClick={() => this.closeDialog()} color="primary">Done</Button>
            }
        })
    }

    componentDidMount() {
        this.resetDialog()
        if (!this.state.currentAction.id) {
            this.openAction('main')
        }
    }

    notifyMsgToGui(details) {
        document.body.dispatchEvent(new CustomEvent('notifyMsgToGui', { detail: details }));
    }

    handleLogout() {
        this.props.JBLClient.logout();
    }

    openAction(workerName, params) {
        if(workerName === 'sysUser' && sessionStorage.getItem('myKey') === params.key){
            workerName = 'account'
        }
        if (typeof this.actionDefs[workerName] === 'object') {
            const id = this.actionDefs[workerName].makeId(params)
            let actionsStack = this.actionsStack
            console.log('Request to open action ' + id)
            if (this.state.currentAction.id !== id) {  //if the requested action is the one on stage, skip
                if (this.state.currentAction.id && actionsStack[this.state.currentAction.id] && this.actionDefs[actionsStack[this.state.currentAction.id].worker].closeWhenLeaving) { //if the current action on stage asks to be closed when it is replced by another action
                    this.closeAction(this.state.currentAction.id, { do: 'openAction', params: { workerName: workerName, params: params } })   //close the current action and open the requested one
                } else {
                    if (typeof actionsStack[id] !== 'object') {
                        console.log('Opening new action ' + id)
                        actionsStack[id] = {
                            worker: workerName,
                            params: params
                        }
                    } else {
                        actionsStack[id].params = params
                    }
                    actionsStack[id].prevId = this.state.currentAction.id   //remember which action was on stage before calling this one
                    console.log('Showing action ' + id)
                    this.actionsStack = actionsStack
                    this.setState({ currentAction: { id: id } })
                }
            } else {
                console.log('Skipping action ' + id + ' already on stage')
            }
        } else {
            console.error('Unexpected action module '.actionName)
            this.notifyMsgToGui({
                'kind': 'error',
                'text': "Il modulo richiesto non esiste. Prego contattare l'assistenza"
            })
        }
    }

    setActionPersistence(id, status = true) {
        if (this.actionsStack[id]) {
            this.actionsStack[id].notPersisted = !Boolean(status)
        }
    }

    closeAction(id, options = {}) {
        console.log('Request to close action ' + id)
        let actionsStack = this.actionsStack
        if (actionsStack[id]) {
            const wannaCloseAction = actionsStack[id]
            if (this.actionDefs[wannaCloseAction.worker].protected) {
                console.error('The action ' + id + " is protected and can't be closed")
                return false
            }
            if (wannaCloseAction.notPersisted) {
                console.warn('The data in the action ' + id + ' is not persisted: close abort')
                return this.openDialog('Dati non salvati',
                    (<Fragment>
                        <DialogContentText id="main-dialog-description">
                            Stai lasciando alcuni dati non ancora registrati.<br />
                            Proseguendo, le informazioni non salvate andranno perse.
                        </DialogContentText>
                        <DialogContentText>
                            Vuoi proseguire senza salvare?
                        </DialogContentText>
                    </Fragment>),
                    (<Fragment>
                        <Button aria-label="prosegui" onClick={() => {
                            this.setActionPersistence(id, true)
                            this.closeDialog()
                            this.closeAction(id, options)
                        }} >Continua senza salvare</Button>
                        <Button aria-label="annulla" onClick={() => this.closeDialog()}>Resta qui</Button>
                    </Fragment>)
                )
            }
            delete this.actionsStack[id]
            console.log('Action ' + id + ' closed')
            if (options.do) {
                switch (options.do) {
                    case 'openAction':
                        return this.openAction(options.params.workerName, options.params.params)
                    default:
                        return this.openAction(wannaCloseAction.prevId)
                }
            } else {
                console.log('Back to action ' + wannaCloseAction.prevId)
                if (actionsStack[wannaCloseAction.prevId]) {
                    this.openAction(actionsStack[wannaCloseAction.prevId].worker, actionsStack[wannaCloseAction.prevId].params)
                } else {
                    console.warn('The previous action ' + id + ' is not in the stack. Jump to main action.')
                    this.openAction('main')
                }
            }
        } else {
            console.error('There is no action ' + id + ' to close')
        }
    }

    /** It opens the dialog box asynchronously
     * @param title title of the dialog box
     * @param content elements to show inside the box
     * @param actions eactions to perform as user interaction
     */
    openDialog(title, content, actions) {
        Promise.resolve(content).then(val => {
            this.setState({ dialogOpen: true, currentDialog: { title: title, content: val, actions: actions } })
        })
    }

    /** Hide the dialog box */
    closeDialog() {
        Promise.resolve('closing').then(() => {
            this.resetDialog()
        })
    }

    setTitle(title) {
        this.setState({ title: title })
    }

    fillDrawer(content) {
        this.setState({ drawrContent: content })
    }

    drawAccountMenu() {
        return <Menu
            anchorEl={this.state.accountMenu.target}
            open={Boolean(this.state.accountMenu.target)}
            keepMounted
            onClose={() => this.hideAccountMenu()}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Container maxWidth="sm" className={this.props.classes.accountMenuContainer}>
                <Grid container direction="column" spacing={2} alignItems="center">
                    {this.state.account.loaded?
                        <Grid item style={{textAlign: "center"}}>
                            <AccountCircleRoundedIcon style={{fontSize: "4rem"}} color="primary"/>
                            <Typography variant="body1">
                                <b>{this.state.account.data.myNameIs}</b>
                            </Typography>
                            <Typography variant="caption">
                                {this.state.account.data.email}
                            </Typography><br/>
                            <Button onClick={() => {
                                this.openAction('account')
                                this.hideAccountMenu()
                                }}><SettingsApplicationsRoundedIcon/> Gestisci</Button>
                        </Grid> : null
                    }
                    <Grid item>
                        <Button onClick={() => this.handleLogout()}><ExitToAppRoundedIcon/> Termina sessione</Button>
                    </Grid>
                </Grid>
            </Container>
        </Menu>
    }

    showAccountMenu(e) {
        e.persist()
        this.props.JBLClient.getMe().then(
            response => {
                this.setState({
                    account: { loaded: true, data: response },
                    accountMenu: {target: e.target}
                })})
    }

    hideAccountMenu() {
        this.setState({ accountMenu: { target: null } })
    }

    render() {
        const classes = this.props.classes
        const theme = this.props.theme
        const Action = this.state.currentAction.id ? this.actionDefs[this.actionsStack[this.state.currentAction.id].worker || 'main'].component : AppActionUndefined
        return (
            <Fragment>
                <Dialog
                    open={this.state.dialogOpen}
                    viewsCount={this.state.dialogViewsCount}
                    TransitionComponent={DialogTransition}
                    onClose={() => this.closeDialog()}
                    aria-labelledby="main-dialog-title"
                    aria-describedby="main-dialog-description"
                >
                    <DialogTitle id="main-dialog-title">{this.state.currentDialog.title || 'Dialog'}</DialogTitle>
                    <DialogContent>
                        {this.state.currentDialog.content || <DialogContentText id="alert-dialog-slide-description">No action required</DialogContentText>}
                    </DialogContent>
                    <DialogActions>
                        {this.state.currentDialog.actions}
                    </DialogActions>
                </Dialog>
                {this.state.accountMenu.target?this.drawAccountMenu():null}
                {
                    this.state.currentAction.id ?
                        <Fragment>
                            <ElevationScroll {...this.props}>
                                <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: this.state.drawerOpen, })}>
                                    <Toolbar>
                                        <IconButton
                                            color="inherit"
                                            aria-label="open drawer"
                                            onClick={() => this.setState({ drawerOpen: true })}
                                            edge="start"
                                            className={clsx(classes.menuButton, this.state.drawerOpen && classes.hide)}>
                                            <MenuIcon />
                                        </IconButton>
                                        <Typography component="h1" variant="h6" className={classes.appTitle} noWrap>
                                            {this.state.title}
                                        </Typography>
                                        <IconButton color="inherit" aria-label="account" onClick={(e) => this.showAccountMenu(e)}>
                                            <AccountCircleRoundedIcon/>
                                        </IconButton>
                                    </Toolbar>
                                </AppBar>
                            </ElevationScroll>
                            <Drawer className={classes.drawer} variant="persistent" anchor="left" open={this.state.drawerOpen} classes={{ paper: classes.drawerPaper, }}>
                                <div className={classes.drawerHeader}>
                                    <Typography component="h1" variant="h6" className={classes.appTitle}>
                                        <img src={Logo} alt="Koiman" style={{width: "100%"}}/>
                                    </Typography>
                                    <IconButton onClick={() => this.setState({ drawerOpen: false })}>
                                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                                    </IconButton>
                                </div>
                                <Divider />
                                {this.state.drawrContent}
                            </Drawer>
                            <main className={clsx(classes.content, { [classes.contentShift]: this.state.drawerOpen, })}>
                                <div className={classes.drawerHeader} />
                                <Container maxWidth="lg" className={classes.mainContainer} >
                                    <Action key={this.state.currentAction.id} appMng={this} id={this.state.currentAction.id} />
                                </Container>
                            </main>
                        </Fragment>
                        : <Fragment>Waiting for action to start</Fragment>

                }
            </Fragment>
        )
    }
}

export default withStyles(LoggedInStyle, { withTheme: true })(LoggedInApp)