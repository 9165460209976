import React, { Component, Fragment } from 'react'
import { withStyles, TextField, Button, CardContent, Typography, CardActions, Card, CardMedia, Grid, Box, InputAdornment, FormControl, InputLabel, OutlinedInput, FormHelperText } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import PhoneRounded from '@material-ui/icons/PhoneRounded';
import EmailRounded from '@material-ui/icons/EmailRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import SaveIcon from '@material-ui/icons/Save'
import AppActionOrgEditorStyle from './AppActionOrgEditorStyle';
import mediaHeader from '../../../../images/editor_opt.svg'

class AppActionOrgEditor extends Component {

    state = {
        loaded: false,
        isNew: false,
        data: {
            name: '',
            mainFullAddress: '',
            mainPhone: '',
            mainEmail: '',
        }
    }

    constructor(props) {
        super(props)
        this.actionData = this.props.appMng.actionsStack[this.props.id]
        if (typeof this.actionData.params === 'object' && this.actionData.params.key) {
            this.getData()
        } else {
            this.state.isNew = true
        }
        this.props.appMng.setTitle((this.state.isNew ? 'Nuovo' : 'Modifica') + ' ente')
    }

    getData() {
        if (this.actionData.params.key) {
            this.props.appMng.props.JBLClient.getOrg(this.actionData.params.key)
                .then(response => {
                    this.setState({ loaded: true, data: {...response} })
                });
        }
    }

    persistsData() {
        let peristPromise = (this.state.isNew ? this.props.appMng.props.JBLClient.newOrg(this.state.data) : this.props.appMng.props.JBLClient.updateOrg(this.actionData.params.key, this.state.data))
        peristPromise
            .then(result => {
                if (result !== null) {
                    this.props.appMng.setActionPersistence(this.props.id, true)
                    this.props.appMng.openAction('org', { key: result })
                }
            })
            .catch(error => {
                console.error(error)
                this.props.appMng.notifyMsgToGui({
                    'kind': 'error',
                    'text': "Si è verificato un errore imprevisto durante la registrazione dei dati."
                })
            })
    }

    setField(name, value) {
        let data = this.state.data
        let stepData = data
        let path = (Array.isArray(name)?name:[name])
        let lastName = path.splice(-1,1)
        for(let step of path){
            stepData = stepData[step]
        }
        if (stepData[lastName] !== value) {
            this.props.appMng.setActionPersistence(this.props.id, false)
            stepData[lastName] = value
            this.setState({ data: data })
        }
    }

    render() {
        return (
            <Card >
                <CardMedia className={this.props.classes.headMedia} image={mediaHeader}>
                    <Box p={3} className={this.props.classes.itemsHeaderText}>
                        <Typography variant="h4" color="inherit">
                            { this.state.isNew ? 'Nuova scheda ente' : 'Modifica ' + this.state.data.name }
                        </Typography>
                    </Box>
                </CardMedia>
                {(this.state.isNew || this.state.loaded) ?
                    <Fragment>
                        <CardContent>
                            <Grid container spacing={2} direction="column">
                                <Grid container item spacing={1}>
                                    <Grid item xs={12} md={3} lg={2}>
                                        <Typography variant="h6">Identità</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9} lg={10}>
                                        <TextField
                                            label="Denominazione"
                                            value={this.state.data.name ? this.state.data.name : ''}
                                            margin="normal"
                                            variant="outlined"
                                            helperText="ragione sociale, nome dell'ente"
                                            placeholder="es. Comune di Rosate"
                                            fullWidth
                                            required
                                            onChange={e => this.setField('name', e.target.value)}
                                        />
                                        <FormControl fullWidth variant="outlined" margin="normal">
                                            <InputLabel htmlFor="outlined-adornment-address">Indirizzo principale</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-address"
                                                placeholder="es. Via G. Marconi 12 - 20088 Rosate"
                                                value={this.state.data.mainFullAddress? this.state.data.mainFullAddress : ''}
                                                onChange={e => this.setField('mainFullAddress', e.target.value)}
                                                startAdornment={<InputAdornment position="start"><LocationOnRoundedIcon /></InputAdornment>}
                                                labelWidth={150}
                                            />
                                            <FormHelperText>indirizzo principale scritto per esteso</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={2}>
                                        <Typography variant="h6">Recapiti</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9} lg={10} container spacing={1}>
                                        <Grid item xs={12} md={5} lg={5}>
                                            <FormControl fullWidth variant="outlined" margin="normal">
                                                <InputLabel htmlFor="outlined-adornment-pubphone">Telefono principale</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-pubphone"
                                                    placeholder="es. +390244456321"
                                                    value={this.state.data.mainPhone? this.state.data.mainPhone : ''}
                                                    onChange={e => this.setField('mainPhone', e.target.value)}
                                                    startAdornment={<InputAdornment position="start"><PhoneRounded /></InputAdornment>}
                                                    labelWidth={150}
                                                />
                                                <FormHelperText>numero di telefono per il pubblico</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={7} lg={7}>
                                            <FormControl fullWidth variant="outlined" margin="normal">
                                                <InputLabel htmlFor="outlined-adornment-pubemail">eMail principale</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-pubemail"
                                                    placeholder="es. info@example.org"
                                                    value={this.state.data.mainEmail? this.state.data.mainEmail : ''}
                                                    onChange={e => this.setField('mainEmail', e.target.value)}
                                                    startAdornment={<InputAdornment position="start"><EmailRounded /></InputAdornment>}
                                                    labelWidth={150}
                                                />
                                                <FormHelperText>indirizzo email per il pubblico</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Box m={1}>
                                <Button onClick={() => this.persistsData()}><SaveIcon />{(this.state.isNew ? 'Registra' : 'Salva modifiche')}</Button>
                                <Button onClick={() => this.props.appMng.closeAction(this.props.id)}><CancelRoundedIcon />Annulla</Button>
                            </Box>
                        </CardActions>
                    </Fragment >
                    :
                    <CardContent>
                        <Skeleton height="25pt" width="100%" />
                    </CardContent>
                }
            </Card>
        )
    }
}

export default withStyles(AppActionOrgEditorStyle)(AppActionOrgEditor)