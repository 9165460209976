import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const useStyles = makeStyles(theme => ({
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        minWidth: theme.spacing(15),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

/** A rounded select field  */
export default function JSelect(props) {
    const classes = useStyles();
    const [state, setState] = useState({
        value: (props.value ? props.value : '')
    });
    const mounted = useRef(false)
    const inputLabel = useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);

    useEffect(() => {
        mounted.current = true
        setLabelWidth(inputLabel.current.offsetWidth);
        return () => {
            mounted.current = false
        }
    }, []);

    const handleChange = (event) => {
        const newValue = props.onChange(event.target.value)
        Promise.resolve(newValue).then(val => {
            if(mounted.current){
                setState({ value: val })
            }
        })
    };

    const uniqueid = (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase()

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={inputLabel} htmlFor={uniqueid}>{props.label ? props.label : ''}</InputLabel>
            <Select
                native
                value={state.value}
                onChange={handleChange}
                labelWidth={labelWidth}
                inputProps={{ id: uniqueid }}
                fullWidth
            >
                {props.children}
            </Select>
        </FormControl>
    )
}
