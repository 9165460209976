import React, { Component, Fragment } from 'react'
import { Grid, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment, FormHelperText, DialogContentText, Typography, Button, Switch, FormControlLabel, FormGroup } from '@material-ui/core'
import PhoneRounded from '@material-ui/icons/PhoneRounded';
import EmailRounded from '@material-ui/icons/EmailRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import schemaReferent from '../../DataSchemas/schemaReferent'

class FormReferent extends Component {

    state = {
        metadata: {},
        initialData: {}
    }

    constructor(props){
        super(props)
        if(this.props.data){
            this.state.data = this.props.data
        } else {
            this.state.data = new schemaReferent()
        }
    }

    setField(name, value) {
        let data = this.state.data
        let metadata = this.state.metadata
        let stepData = data
        let stepMetadata = metadata
        let path = (Array.isArray(name)?name:[name])
        let lastName = path.splice(-1,1)
        for(let step of path){
            stepData = stepData[step]
            if(typeof stepMetadata[step] === 'undefined') {
                stepMetadata[step] = {}
            }
            stepMetadata = stepMetadata[step]
        }
        if (stepData[lastName] !== value) {
            stepData[lastName] = value
            if(typeof stepMetadata[lastName] === 'undefined') {
                stepMetadata[lastName] = {}
            }
            stepMetadata[lastName].changed = true
            this.setState({ data: data })
            if(typeof this.props.onChange === 'function'){
                this.props.onChange({ data: this.state.data, metadata: this.state.metadata })
            }
        }
    }

    checkExistingObjet = () => {
        const initialTaxCode = this.state.initialData._tax_code ? this.state.initialData._tax_code : this.state.initialData.tax_code

        if(this.state.data.tax_code && initialTaxCode !== this.state.data.tax_code) {

            const conds = {
                tax_code: this.state.data.tax_code,
            }

            const findRefs = (conds) => {
                this.props.appMng.props.JBLClient.getReferents(conds).then((result)=>{
                    if(typeof result === "object" && result.length > 0) {
                        this.setState({data: {...result[0]}, initialData: {...result[0]}})
                        if(typeof this.props.onChange === 'function'){
                            this.props.onChange({ data: this.state.data, metadata: this.state.metadata })
                        }
                    } else {
                        this.setState({initialData: {...this.state.data}})
                    }
                })
            }

            if(this.state.data.key){
                this.props.appMng.openDialog('Modifica codice fiscale', (
                    <Fragment>
                        <DialogContentText id="main-dialog-description">
                            <Typography variant="body1" component="span" style={{display: "block", marginBottom: '0.5em'}}>
                                <b>Stai correnggendo il codice fiscale errato della persona referente oppure vuoi sostitire l'intera scheda con un'alra persona?</b>
                            </Typography><br/>
                            <Typography variant="body1" component="span">Fai attenzione alla scelta che stai prendendo.<br/>
                            Potresti sovrascarivere la scheda di un referente con quella di qulacun'altra.</Typography>
                        </DialogContentText>
                    </Fragment>),
                    (<Fragment>
                        <Button aria-label="correggi" onClick={() => {
                            conds.notRid = this.state.data.key
                            findRefs(conds)
                            this.props.appMng.closeDialog()
                        }}>Correzione</Button>
                        <Button aria-label="sostituisci" onClick={() => {
                            this.setState({data: {...this.state.data, key: null}})
                            findRefs(conds)
                            this.props.appMng.closeDialog()
                        }}>Sostituzione</Button>
                    </Fragment>))
            } else {
                findRefs(conds)
            }
        }
    }

    render() {
        return (
            <Grid container spacing={1} item>
                <Grid item xs={12} md={12}>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.data.legalPerson}
                                    onChange={e => this.setField('legalPerson', e.target.checked)}
                                    name="legalPerson"
                                    inputProps={{ 'aria-label': 'è una persona giuridica' }}
                                />
                            }
                            label="persona giurdica"
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        label="Codice fiscale"
                        value={this.state.data.tax_code}
                        margin="normal"
                        variant="outlined"
                        required
                        inputProps={{size: 16}}
                        onChange={e => this.setField('tax_code', e.target.value)}
                        helperText="necessario per identifcare univocamente il referente"
                        onBlur={this.checkExistingObjet}
                    />
                </Grid>
                {!this.state.data.legalPerson?
                    <>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Nome"
                                value={this.state.data.name}
                                margin="normal"
                                variant="outlined"
                                required
                                fullWidth
                                onChange={e => this.setField('name', e.target.value)}
                                helperText="compresi eventuali secondi nomi"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Cognome"
                                value={this.state.data.surname}
                                margin="normal"
                                variant="outlined"
                                required
                                fullWidth
                                onChange={e => this.setField('surname', e.target.value)}
                                helperText="compresi eventuali cognomi multipli"
                            />
                        </Grid>
                    </> : <Grid item xs={12} md={12}>
                            <TextField
                                label="Denominazione"
                                value={this.state.data.name}
                                margin="normal"
                                variant="outlined"
                                required
                                fullWidth
                                onChange={e => this.setField('name', e.target.value)}
                                helperText="ragione sociale, nome dell'ente"
                            />
                    </Grid>
                }
                <Grid item xs={12} md={5} lg={5}>
                    <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel htmlFor="outlined-adornment-phone" required>Recapito telefonico</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-phone"
                            placeholder="es. +390244456321"
                            value={this.state.data.phone? this.state.data.phone : ''}
                            onChange={e => this.setField('phone', e.target.value)}
                            startAdornment={<InputAdornment position="start"><PhoneRounded /></InputAdornment>}
                            labelWidth={150}
                        />
                        <FormHelperText>dove essere contattato telefonicamente</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel htmlFor="outlined-adornment-email">eMail</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-email"
                            placeholder="es. m.rossi@example.org"
                            value={this.state.data.email? this.state.data.email : ''}
                            onChange={e => this.setField('email', e.target.value)}
                            startAdornment={<InputAdornment position="start"><EmailRounded /></InputAdornment>}
                            labelWidth={50}
                        />
                        <FormHelperText>indirizzo posta elettronica</FormHelperText>
                    </FormControl>
                </Grid>
                <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="outlined-adornment-address" required>Indirizzo stradale</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-address"
                        placeholder="es. Via G. Marconi 12 - 20088 Rosate"
                        value={this.state.data.fullAddress? this.state.data.fullAddress : ''}
                        onChange={e => this.setField('fullAddress', e.target.value)}
                        startAdornment={<InputAdornment position="start"><LocationOnRoundedIcon /></InputAdornment>}
                        labelWidth={150}
                    />
                    <FormHelperText>recapito postale</FormHelperText>
                </FormControl>
            </Grid>
        )
    }

}

export default FormReferent