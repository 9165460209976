import { createStyles } from "@material-ui/core";

const AppActionBurialEditorStyle = ((theme) => createStyles({
    headMedia: {
        height: '160px',
        color: theme.palette.primary.contrastText
    },
    hiddenElement: {
        display: 'none'
    },
    itemsHeader: {
        backgroundColor: theme.palette.secondary.light
    }
}))

export default AppActionBurialEditorStyle