import React, { Component } from 'react'
import { Grid, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment, FormHelperText } from '@material-ui/core'
import LabelIcon from '@material-ui/icons/Label';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import schemaBurialExtRef from '../../DataSchemas/schemaBurialExtRef'

class FormBurialExtRef extends Component {

    state = {
        metadata: {},
        initialData: {}
    }

    constructor(props){
        super(props)
        if(this.props.data){
            this.state.data = this.props.data
        } else {
            this.state.data = new schemaBurialExtRef()
        }
    }

    setField(name, value) {
        let data = this.state.data
        let metadata = this.state.metadata
        let stepData = data
        let stepMetadata = metadata
        let path = (Array.isArray(name)?name:[name])
        let lastName = path.splice(-1,1)
        for(let step of path){
            stepData = stepData[step]
            if(typeof stepMetadata[step] === 'undefined') {
                stepMetadata[step] = {}
            }
            stepMetadata = stepMetadata[step]
        }
        if (stepData[lastName] !== value) {
            stepData[lastName] = value
            if(typeof stepMetadata[lastName] === 'undefined') {
                stepMetadata[lastName] = {}
            }
            stepMetadata[lastName].changed = true
            this.setState({ data: data })
            if(typeof this.props.onChange === 'function'){
                this.props.onChange({ data: this.state.data, metadata: this.state.metadata })
            }
        }
    }

    render() {
        return (
            <Grid container spacing={1} item>
                <Grid item xs={12} md={4}>
                    <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel htmlFor="outlined-adornment-label" required>Etichietta</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-label"
                            placeholder="Protocollo comunale"
                            value={this.state.data.label? this.state.data.label : ''}
                            onChange={e => this.setField('label', e.target.value)}
                            startAdornment={<InputAdornment position="start"><LabelIcon/></InputAdornment>}
                            labelWidth={150}
                        />
                        <FormHelperText>nome del registro esterno</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={8}>
                    <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel htmlFor="outlined-adornment-id" required>Identificativo</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-id"
                            placeholder="es. ABC-455435-2023"
                            value={this.state.data.id? this.state.data.id : ''}
                            onChange={e => this.setField('id', e.target.value)}
                            startAdornment={<InputAdornment position="start"><FingerprintIcon/></InputAdornment>}
                            labelWidth={150}
                        />
                        <FormHelperText>identificativo univoco nel registro esterno</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        id="notes"
                        label="Annotazioni"
                        multiline
                        value={this.state.data.notes? this.state.data.notes : ''}
                        onChange={e => this.setField('notes', e.target.value)}
                    />
                </Grid>
            </Grid>
        )
    }

}

export default FormBurialExtRef