import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.register({
    onUpdate: reg => {
        if(reg.waiting) {
            console.log('Replacing with version')
            reg.waiting.postMessage({ type: 'SKIP_WAITING' })
            window.location.reload(true)
        }
    }
})