import React, { Component, Fragment } from 'react'
import { withStyles, TextField, Button, CardContent, Typography, CardActions, Card, DialogContentText, CardMedia, Grid, Box } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import JPolyDraw from '../../../JPoly';
import AppActionGraveEditorStyle from './AppActionGraveEditorStyle';
import JSelect from '../../../JForm/controls/JSelect';
import mediaHeader from '../../../../images/editor_opt.svg'
import HelperGraveyardMapImage from '../../Helpers/HelperGraveyardMapImage';

class AppActionGraveEditor extends Component {

    state = {
        loaded: false,
        isNew: false,
        spaceModels: null,
        planimetryID: 'initial',
        data: {
            name: '',
            planimetry: null,
            spaceModel: {
                name: '',
                key: null
            }
        },
        graveyard: {},
        mapImage: null
    }

    mapImage = null

    spaceModelsList = [<option key="-1" value=""></option>]

    constructor(props) {
        super(props)
        this.actionData = this.props.appMng.actionsStack[this.props.id]
        this.mapImage = new HelperGraveyardMapImage(this.props.appMng)
        if (this.actionData.params.key) {
            this.getData()
        } else {
            this.state.isNew = true
            this.props.appMng.props.JBLClient.getGraveyardSpace(this.actionData.params.parentSpaceKey)
            .then(psData => {
                this.props.appMng.props.JBLClient.getGraveyard(psData.graveyard.key)
                .then(gyData => {
                    this.prepareBGPlanimetry(gyData.unnormalizedDiagram, psData.level)
                    this.setState({graveyard: gyData})
                    this.mapImage.setKey(psData.graveyard.key)
                    this.mapImage.load().then(objurl => this.setState({mapImage: objurl}))
                })
            })
        }
        this.getSpaceModels()
        this.props.appMng.setTitle((this.state.isNew ? 'Nuovo' : 'Modifica') + ' sepolcro')
    }

    /** Prepare the date to show the background planimetry
     * @param object gData
    */
   prepareBGPlanimetry(gData, level) {
        const pathToPoints = (path) => {
            let strPath = []
            for (let vertex of path) {
                strPath.push(vertex.x + ' ' + vertex.y)
            }
            return strPath.join(', ')
        }
        /** given the path, returns a structure's item
        * @param string path
        * @returns object
        */
       const getItem = (path) => {
            let items = gData.items                  //layer 0 list of items
            let item = null                         //no current item
            let labelSteps = []                     //steps to make the label
            for (const step of path.split('.')) {   //explode the ids path to the item
                const parentItem = item             //current item will be the parent of the next step item
                item = items[step]                  //new current item is the n in the current layer
                item.parent = parentItem            //remember its parent
                labelSteps.push(gData.models[item.model].name + ' ' + item.name) //add current name in the label path
                if(item.hasOwnProperty('items')) {  //if the current item has sub-items
                    items = item.items              //next layer items
                }
            }
            if(item !== null) {
                item.label = labelSteps.join(', ')                      //add the full-path label
                item.shortLabel = labelSteps[labelSteps.length - 1]     //add the last path step as short label
            }
            return item
        }

        this.bgPolys = [{
            key: "base",
            points: pathToPoints(gData.plan.path),
            fill: gData.plan.color,
            fillOpacity: "0.5",
            strokeOpacity: "0.7",
            mask: "url(#checkers-mask)"
        }]
        if (gData.levels.hasOwnProperty(level) && Array.isArray(gData.levels[level])) {
            for (const itemPath of gData.levels[level]) {                      //for each area in in the current level of the graveyard
                const item = getItem(itemPath)                                  //gets the area item
                this.bgPolys.push({
                    key: item.id,
                    points: pathToPoints(item.plan.path),
                    fill: item.plan.color,
                    stroke: "grey",
                    fillOpacity: "0.5",
                    strokeOpacity: "0.7"
                })
            }
        }
    }

    getData() {
        if (this.actionData.params.key) {
            this.props.appMng.props.JBLClient.getGrave(this.actionData.params.key)
                .then(response => {
                    this.props.appMng.props.JBLClient.getGraveyard(response.gravespace.graveyard.key)
                    .then(gyData => {
                        this.prepareBGPlanimetry(gyData.unnormalizedDiagram, response.gravespace.level)
                        this.setState({ loaded: true, data: {...response}, graveyard: gyData})
                        this.mapImage.setKey(response.gravespace.graveyard.key)
                        this.mapImage.load().then(objurl => this.setState({mapImage: objurl}))
                    })
                })
        }
    }

    getSpaceModels() {
        if (!this.state.spaceModels) {
            this.props.appMng.props.JBLClient.getSpaceModels()
                .then(sm => {
                    for (let pos in sm) {
                        let val = sm[pos]
                        if (val.isGrave) {
                            this.spaceModelsList.push(<option key={pos} value={val.key}>{val.myNameIs}</option>)
                        }
                    }
                    this.setState({ spaceModels: sm })
                })
        }
    }

    persistsData() {
        let peristPromise = (this.state.isNew ? this.props.appMng.props.JBLClient.newGrave(this.actionData.params.parentSpaceKey, this.state.data) : this.props.appMng.props.JBLClient.updateGrave(this.actionData.params.key, this.state.data))
        peristPromise
            .then(result => {
                if (result !== null) {
                    this.props.appMng.setActionPersistence(this.props.id, true)
                    this.props.appMng.closeAction(this.props.id)
                }
            })
            .catch(error => {
                console.error(error)
                this.props.appMng.notifyMsgToGui({
                    'kind': 'error',
                    'text': "Si è verificato un errore imprevisto durante la registrazione dei dati."
                })
            })
    }

    setField(name, value) {
        const data = this.state.data
        if (data[name] !== value) {
            this.props.appMng.setActionPersistence(this.props.id, false)
            data[name] = value
            this.setState({ data: data })
        }
    }

    async setSpaceModel(id) {
        if (this.state.spaceModels) {
            for (let item of this.state.spaceModels) {
                if (item.key === id) {
                    await this.props.appMng.props.JBLClient.getSpaceModel(item.key)
                        .then(selSM => {
                            if (this.state.data.planimetry && Array.isArray(this.state.data.planimetry.path) && this.state.data.planimetry.path.length > 0) {
                                this.props.appMng.openDialog('Sostituzione della geometria', (
                                    <Fragment>
                                        <DialogContentText id="main-dialog-description">
                                            Cambiare il tipo di sepolcro permette di sostituire la geometria esistente con quella predefinita dal modello scelto.
                                    </DialogContentText>
                                        <DialogContentText>
                                            <em>Vuoi sostituire la geometria corrente con quella del modello?</em>
                                        </DialogContentText>
                                    </Fragment>),
                                    (<Fragment>
                                        <Button aria-label="Sostituisci" onClick={() => {
                                            this.setField('planimetry', selSM.planimetry)
                                            this.setState({ planimetryID: (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase() })
                                            this.props.appMng.closeDialog()
                                        }} >Sostituisci</Button>
                                        <Button aria-label="Mantieni" onClick={() => this.props.appMng.closeDialog()}>Mantieni</Button>
                                    </Fragment>))
                            } else {
                                this.setField('planimetry', selSM.planimetry)
                                this.setState({ planimetryID: (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase() })
                            }
                        })
                    this.setField('spaceModel', item)
                    return this.state.data.spaceModel.key
                }
            }
        }
        this.setField('spaceModel', this.state.data.spaceModel)
        return this.state.data.spaceModel.key
    }

    render() {
        const polyEditorParams = (this.state.data.planimetry !== null ? { iKey: this.actionData.params.key, id: this.state.planimetryID, color: this.state.data.planimetry.color, path: this.state.data.planimetry.path } : { id: this.state.planimetryID, color: "green", path: [] })
        if (this.bgPolys) {
            polyEditorParams.bgPolys = this.bgPolys
        }
        if(this.state.graveyard && this.state.graveyard.map){
            polyEditorParams.map = {
                image: this.state.mapImage,
                x: this.state.graveyard.map.origin.x,
                y: this.state.graveyard.map.origin.y,
                scale: this.state.graveyard.map.scale,
                alpha: this.state.graveyard.map.alpha,
            }
        }

        return (
            <Card >
                <CardMedia className={this.props.classes.headMedia} image={mediaHeader} title="Editor di aree cimiteriali">
                    <Box p={3} className={this.props.classes.itemsHeaderText}>
                        <Typography variant="h4" color="inherit">
                            {this.state.data.spaceModel.name + ' ' + this.state.data.name}
                        </Typography>
                    </Box>
                </CardMedia>
                {(this.state.isNew || this.state.loaded) && this.state.spaceModels ?
                    <Fragment>
                        <CardContent>
                            <Grid container spacing={2} direction="column">
                                <Grid container item spacing={1}>
                                    <Grid item xs={12} md={3} lg={2}>
                                        <Typography variant="h6">Definizione</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9} lg={10}>
                                        <Grid container spacing={1} alignItems="stretch">
                                            <Grid item xs={12} md={3} lg={3}>
                                                <JSelect
                                                    value={this.state.data.spaceModel.key}
                                                    label='Tipo'
                                                    onChange={value => this.setSpaceModel(value)}
                                                >
                                                    {this.spaceModelsList}
                                                </JSelect>
                                            </Grid>
                                            <Grid item xs={12} md={3} lg={3}>
                                                <TextField
                                                    id="name"
                                                    label="Nome del sepolcro"
                                                    value={this.state.data.name}
                                                    margin="normal"
                                                    variant="outlined"
                                                    name="name"
                                                    onChange={e => this.setField('name', e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={3} lg={3}>
                                                <TextField
                                                    id="burialLimit"
                                                    label="n° massimio di spoglie"
                                                    value={this.state.data.burialLimit}
                                                    margin="normal"
                                                    variant="outlined"
                                                    name="burialLimit"
                                                    onChange={e => this.setField('burialLimit', e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardContent>
                            <Grid container spacing={2} direction="column">
                                <Grid container item spacing={1} direction="column">
                                    <Grid item>
                                        <Typography variant="h6">Geometria grafica</Typography>
                                        <Typography variant="body1">Disengna e posiziona il perimetro del sepolcro</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box m={1}>
                                <JPolyDraw onChange={data => this.setField('planimetry', data)} settings={polyEditorParams} />
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Box m={1}>
                                <Button onClick={() => this.persistsData()}><SaveIcon />{(this.state.isNew ? 'Crea' : 'Salva modifiche')}</Button>
                                <Button onClick={() => this.props.appMng.closeAction(this.props.id)}><CancelRoundedIcon />Annulla</Button>
                            </Box>
                        </CardActions>
                    </Fragment>
                    :
                    <CardContent>
                        <Skeleton height="25pt" width="100%" />
                    </CardContent>}
            </Card>
        )
    }

}

export default withStyles(AppActionGraveEditorStyle)(AppActionGraveEditor)