import { createStyles } from "@material-ui/core";

const AppActionSpcModelEditorStyle = ((theme) => createStyles({
    headMedia: {
        height: '160px',
        color: theme.palette.primary.contrastText
    },
    hiddenElement: {
        display: 'none'
    }
}))

export default AppActionSpcModelEditorStyle