class schemaReferent {
    _name = ''
    _surname = ''
    _key = null
    _tax_code = ''
    _phone = ''
    _email = ''
    _fullAddress = ''
    set tax_code(value) {
        if(typeof value === "string" && /^[A-Z0-9]{0,16}$/i.test(value)){
            this._tax_code = value.toUpperCase()
        }
    }
    get tax_code() {
        return this._tax_code
    }
    set name(value) {
        if(typeof value === "string" && value.trim().length >= 0){
            this._name = value
        }
    }
    get name() {
        return this._name
    }
    set surname(value) {
        if(typeof value === "string" && value.trim().length >= 0){
            this._surname = value
        }
    }
    get surname() {
        return this._surname
    }
    set phone(value) {
        if(typeof value === "string" &&  /^\+?[0-9 .-]{0,30}$/i.test(value)){
            this._phone = value
        }
    }
    get phone() {
        return this._phone
    }
    set email(value) {
        if(typeof value === "string" &&  /^[0-9a-z!#$%&'*+\-/=?^_`{|}~.]{0,64}@?[0-9a-z!#$%&'*+\-/=?^_`{|}~.]{0,255}$/i.test(value)){
            this._email = value
        }
    }
    get email() {
        return this._email
    }
    set fullAddress(value) {
        if(typeof value === "string" && value.trim().length >= 0){
            this._fullAddress = value
        }
    }
    get fullAddress() {
        return this._fullAddress
    }
    minComplete() {
        return (this._name.trim().length > 0 && this._surname.trim().length > 0 && this._tax_code.trim().length === 16)
    }
}

export default schemaReferent