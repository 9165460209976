import React, { Component } from 'react'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import Logo from '../../../../images/logo_mono.svg'

class AppActionMain extends Component {

    constructor(props) {
        super(props)
        this.props.appMng.setTitle('Gestione cimiteri')
        this.props.appMng.fillDrawer(this.drawerContent())
    }

    drawerContent() {
        return (
            <List>
                <ListItem button key="Enti" onClick={() => this.props.appMng.openAction('orgsList')} >
                    <ListItemIcon><BusinessRoundedIcon /></ListItemIcon>
                    <ListItemText primary="Enti" />
                </ListItem>
                <ListItem button key="Cimiteri" onClick={() => this.props.appMng.openAction('graveyardsList')} >
                    <ListItemIcon><AccountBalanceRoundedIcon /></ListItemIcon>
                    <ListItemText primary="Cimiteri" />
                </ListItem>
                <ListItem button key="Modelli di aree" onClick={() => this.props.appMng.openAction('spaceModelsList')} >
                    <ListItemIcon><FormatShapesIcon /></ListItemIcon>
                    <ListItemText primary="Modelli di aree" />
                </ListItem>
                <ListItem button key="Operatori" onClick={() => this.props.appMng.openAction('sysUsersList')} >
                    <ListItemIcon><PeopleAltRoundedIcon /></ListItemIcon>
                    <ListItemText primary="Operatori" />
                </ListItem>
            </List>
        )
    }

    render() {
        return (
            <img src={Logo} alt="Koiman" style={{marginTop:"30%", width: "80%"}}/>
        )
    }
}

export default AppActionMain