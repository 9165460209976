import React, { Component, Fragment } from 'react'
import { withStyles, TextField, Button, CardContent, Typography, CardActions, Card, CardMedia, Grid, Box, FormControlLabel, Switch, IconButton } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import QueueRoundedIcon from '@material-ui/icons/QueueRounded';
import SaveIcon from '@material-ui/icons/Save'
import AppActionBurialEditorStyle from './AppActionBurialEditorStyle';
import mediaHeader from '../../../../images/editor_opt.svg'
import FormReferent from '../../Forms/FormReferent';
import FormDeceased from '../../Forms/FormDeceased';
import schemaReferent from '../../DataSchemas/schemaReferent';

class AppActionBurialEditor extends Component {

    state = {
        loaded: false,
        isNew: false,
        uploadingFile: false,
        data: {
            isReservation: false,
            deceased: {
                key: null,
                name: '',
                nameUnknown: false,
                surname: '',
                surnameUnknown: false,
                birthday: '',
                birthdayMask: 'full',
                deathdate: '',
                deathdateMask: 'full'
            },
            referents: [],
            burial_date: '',
            planned_exhumation_date: ''
        },
        planned_exhumation_years: 20,
    }

    constructor(props) {
        super(props)
        this.actionData = this.props.appMng.actionsStack[this.props.id]
        if (this.actionData.params.key) {
            this.getData()
        } else {
            this.state.isNew = true
        }
        this.state.planned_exhumation_years = (typeof this.actionData.params.defaultGrantDuration === "number" ? this.actionData.params.defaultGrantDuration : 20)
        this.props.appMng.setTitle((this.state.isNew ? 'Nuova' : 'Modifica') + ' sepoltura')
    }

    componentDidMount(){
        this.addReferent()
    }

    getData() {
        if (this.actionData.params.key) {
            this.props.appMng.props.JBLClient.getGrave(this.actionData.params.key)
                .then(response => {
                    this.setState({ loaded: true, data: {...response} })
                });
        }
    }

    persistsData() {
        if(!this.state.isNew) {
            return false
        }
        if(!this.state.data.burial_date) {
            return this.props.appMng.notifyMsgToGui({
                'kind': 'error',
                'text': 'Manca la data di ' + (this.state.data.isReservation ? "inizio prenotazione" : "inumazione")
            })
        }
        if(!this.state.data.isReservation){
            if(!this.state.data.deceased.deathdate && this.state.data.deceased.deathdateMask !== 'unknown') {
                return this.props.appMng.notifyMsgToGui({
                    'kind': 'error',
                    'text': 'Manca la data di morte della persona deceduta'
                })
            } else {
                const bDate = new Date(this.state.data.burial_date)
                const dDate = new Date(this.state.data.deceased.deathdate)
                if(this.state.data.deceased.deathdateMask !== 'unknown' && bDate.valueOf() < dDate.valueOf()) {
                    return this.props.appMng.notifyMsgToGui({
                        'kind': 'error',
                        'text': 'La data di inumazione non può essere precedente alla data di morte'
                    })
                }
            }
        }
        let peristPromise = this.props.appMng.props.JBLClient.newBurial(this.actionData.params.destGraveKey, this.state.data)
        peristPromise
            .then(result => {
                console.warn(result)
                if(typeof result === "object"){
                    if(result.decesedKey !== null) {
                        this.props.appMng.props.JBLClient.getDeceased(result.decesedKey).then(
                            (decResult) => {
                                const data = {...this.state.data}
                                data.deceased = decResult
                                this.setState({data: data})
                            }
                        )
                        this.setState({data:{...this.state.data, deceased: {...this.state.data.deceased, key: result.decesedKey}}})
                    }
                    if(typeof result.referentsKeys === "object" && result.referentsKeys.length > 0) {
                        for (const pos in result.referentsKeys) {
                            if (result.referentsKeys.hasOwnProperty(pos)) {
                                this.props.appMng.props.JBLClient.getReferent(result.referentsKeys[pos]).then(
                                    (refResult) => {
                                        const data = {...this.state.data}
                                        data.referents[pos] = refResult
                                        this.setState({data: data})
                                    }
                                )
                            }
                        }
                    }
                    if (result.burialKey !== null) {
                        this.props.appMng.setActionPersistence(this.props.id, true)
                        this.props.appMng.closeAction(this.props.id)
                    }
                }
            })
            .catch(error => {
                console.error(error)
                this.props.appMng.notifyMsgToGui({
                    'kind': 'error',
                    'text': "Si è verificato un errore imprevisto durante la registrazione dei dati."
                })
            })
    }

    setField(name, value) {
        let data = this.state.data
        let stepData = data
        let path = (Array.isArray(name)?name:[name])
        let lastName = path.splice(-1,1)
        for(let step of path){
            stepData = stepData[step]
        }
        if (stepData[lastName] !== value) {
            this.props.appMng.setActionPersistence(this.props.id, false)
            stepData[lastName] = value
            this.setState({ data: data })
        }
    }

    setPlannedExhumationDate(years = null) {
        if (years === null) {
            years = this.state.planned_exhumation_years
        } else {
            this.setState({ planned_exhumation_years: years })
        }
        if (this.state.data.burial_date && ((typeof years === 'string' && years.trim().length > 0 && parseInt(years) > 0) || (typeof years === 'number' && years > 0))) {
            const planned_exhumation_date = new Date(this.state.data.burial_date)
            planned_exhumation_date.setFullYear(parseInt(planned_exhumation_date.getFullYear(), 10) + parseInt(years, 10))
            const day = planned_exhumation_date.getDate().toString()
            const month = (planned_exhumation_date.getMonth() + 1).toString()
            const year = planned_exhumation_date.getFullYear().toString()
            this.setField('planned_exhumation_date', year.padStart(4, '0') + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0'))
        } else {
            this.setField('planned_exhumation_date', '')
        }
    }

    addReferent(){
        this.setState((state)=>{
            state.data.referents.push(new schemaReferent())
            return {data: state.data}
        })
    }

    delReferent(key){
        if(this.state.data.referents.length < 2){
            return this.props.appMng.notifyMsgToGui({
                'kind': 'error',
                'text': "Almeno un referente deve essere fornito."
            })
        }
        this.setState((state)=>{
            state.data.referents.splice(key, 1)
            return {data: state.data}
        })
        this.props.appMng.setActionPersistence(this.props.id, false)
    }

    render() {
        return (
            <Card >
                <CardMedia className={this.props.classes.headMedia} image={mediaHeader}>
                    <Box p={3}>
                        <Typography variant="h4" color="inherit">
                            Sepoltura
                        </Typography>
                    </Box>
                </CardMedia>
                {(this.state.isNew || this.state.loaded) ?
                    <Fragment>
                        <CardContent>
                            <Grid container spacing={2} direction="column">
                                <Grid container item spacing={1}>
                                    <Grid item xs={12} md={3} lg={2}>
                                        <Typography variant="h6">{this.state.data.isReservation ? "Dati della prenotazione" : "Dati della sepoltura"}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9} lg={10} container spacing={1} justify="space-between">
                                        <Grid item xs={12} md={12} lg={12}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={this.state.data.isReservation}
                                                        onChange={e => this.setField('isReservation', e.target.checked)}
                                                    />
                                                }
                                                label="è una prenotazione"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                label={this.state.data.isReservation ? "Inizio prenotazione" : "Data inumazione"}
                                                value={this.state.data.burial_date}
                                                type="date"
                                                autoFocus
                                                required
                                                margin="normal"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={e => {
                                                    this.setField('burial_date', e.target.value)
                                                    this.setPlannedExhumationDate()
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={4}>
                                            <TextField
                                                label={this.state.data.isReservation ? "Anni di contratto" : "Anni pianificati"}
                                                value={this.state.planned_exhumation_years}
                                                type="number"
                                                margin="normal"
                                                variant="outlined"
                                                inputProps={{
                                                    min: 1
                                                }}
                                                onChange={e => {
                                                    this.setPlannedExhumationDate(e.target.value)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                label={this.state.data.isReservation ? "Scadenza prenotazione" : "Esumazione pianificata"}
                                                value={this.state.data.planned_exhumation_date}
                                                type="date"
                                                readOnly
                                                margin="normal"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={1} className={this.state.data.isReservation ? this.props.classes.hiddenElement : ''}>
                                    <Grid item xs={12} md={3} lg={2}>
                                        <Typography variant="h6">Dati del defunto</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9} lg={10}>
                                        <Grid container spacing={1} direction="column" alignItems="stretch">
                                            <Grid container spacing={1} item>
                                            <FormDeceased
                                                appMng={this.props.appMng}
                                                data={this.state.data.deceased}
                                                onChange={e=>this.setField('deceased', e.data)}
                                            />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={1}>
                                    <Grid item xs={12} md={3} lg={2}>
                                        <Typography variant="h6">Dati dei referenti</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9} lg={10} container spacing={1} direction="column" alignItems="stretch">
                                        {this.state.data.referents.map((item, pos) =>
                                            <Grid item key={pos}>
                                                <Card>
                                                    <CardActions className={this.props.classes.itemsHeader}>
                                                        <IconButton aria-label="rimuovi referente" onClick={() => this.delReferent(pos)}><DeleteRoundedIcon/></IconButton>
                                                    </CardActions>
                                                    <CardContent>
                                                        <Grid container spacing={1} item>
                                                            <FormReferent
                                                                appMng={this.props.appMng}
                                                                data={this.state.data.referents[pos]}
                                                                onChange={e=>{this.setField(['referents',pos], e.data)}}
                                                            />
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        )}
                                        <Grid item><Button onClick={() => this.addReferent()}><QueueRoundedIcon />Aggiungi referente</Button></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Box m={1}>
                                <Button onClick={() => this.persistsData()}><SaveIcon />{(this.state.isNew ? 'Registra' : 'Salva modifiche')}</Button>
                                <Button onClick={() => this.props.appMng.closeAction(this.props.id)}><CancelRoundedIcon />Annulla</Button>
                            </Box>
                        </CardActions>
                    </Fragment >
                    :
                    <CardContent>
                        <Skeleton height="25pt" width="100%" />
                    </CardContent>
                }
            </Card>
        )
    }
}

export default withStyles(AppActionBurialEditorStyle)(AppActionBurialEditor)