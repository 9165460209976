import React from 'react'

const JPolyIconBullet = (props) => {
    const defColor = props.color ? props.color : 'black'
    const bgColor = props.bgColor ? props.bgColor : 'white'
    const bulletColor = props.bulletColor ? props.bulletColor : 'blue'
    return <g onClick={(e) => props.onClick(e)} transform={`translate(${props.x || 0} ${props.y || 0})scale(${((props.size || 24) / 1000)})`} vectorEffect="non-scaling-stroke">
        <circle cx="500" cy="500" r="500" fill={bgColor} fillOpacity="0.6" stroke={defColor} strokeLinejoin="round" strokeWidth="90" paintOrder="markers stroke fill" />
        <circle cx="500" cy="500" r="406.78" fill={bulletColor} paintOrder="markers stroke fill" />
        />
    </g>
}

export default JPolyIconBullet