import React, { Component } from 'react'
import { Chip } from '@material-ui/core'
import RotateLeftRoundedIcon from '@material-ui/icons/RotateLeftRounded';
import JSelect from '../../../JForm/controls/JSelect';

/** Draw a selector of pubbible graveyard */
class FormRoleSelector extends Component {

    state = {
        selectedKey: null,
        selectedPos: null,
        listOptions: []
    }

    componentDidMount() {
        this.props.appMng.props.JBLClient.getSysUsersRoles()
            .then(response => {
                let list = []
                let selectedKey = null
                let selectedPos = null
                if (Array.isArray(response)){
                    let pos = 0
                    for (let role of response) {
                        list[pos] = {key: role.key, name: role.myNameIs}
                        if(role.key === this.props.value){
                            selectedKey = role.key
                            selectedPos = pos
                        }
                        pos++
                    }
                }
                this.setState({ selectedKey: selectedKey, selectedPos: selectedPos, listOptions: list })
            });
    }

    /** Set the new value */
    setValue(newValue) {
        let selectedKey = null
        let selectedPos = null
        if (this.state.listOptions[newValue]) {
            selectedKey = this.state.listOptions[newValue].key
            selectedPos = newValue
        } else {
            selectedKey = null
            selectedPos = null
        }
        this.setState({ selectedKey: selectedKey, selectedPos: selectedPos })
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(selectedKey)
        }
    }

    /** Render the path */
    render() {
        let opList = [                              //add an empty option for the select
            <option key="none" value={null}></option>
        ]
        for (let pos in this.state.listOptions) {        //add them to option list
            let option = this.state.listOptions[pos]
            opList.push(<option key={pos} value={pos}>{option.name}</option>)
        }
        return (this.state.selectedPos !== null ?
            <Chip
                color="primary"
                label={this.state.listOptions[this.state.selectedPos].name}
                onDelete={() => this.setValue(null)}
                deleteIcon={<RotateLeftRoundedIcon />}
            />
            : <JSelect
                value={this.state.selectedPos}
                label={this.props.label?this.props.label:'Ruolo operatore'}
                onChange={value => this.setValue(value)}
            >
                {opList}
            </JSelect>)
    }
}

export { FormRoleSelector as default }