import React, { Component, Fragment } from 'react'
import { Grid, TextField, Typography, DialogContentText, Button, Switch, FormGroup, FormControlLabel } from '@material-ui/core'
import schemaDeceased from '../../DataSchemas/schemaDeceased'
import JSelect from '../../../JForm/controls/JSelect';

class FormDeceased extends Component {

    state = {
        data: {
            key: null,
            name: '',
            nameUnknown: false,
            surname: '',
            surnameUnknown: false,
            birthday: '',
            birthdayMask: 'full',
            deathdate: '',
            deathdateMask: 'full'
        },
        metadata: {},
        initialData: {}
    }

    constructor(props){
        super(props)
        if(this.props.data){
            this.state.data = this.props.data
            this.state.initialData = {...this.props.data}
        } else {
            this.state.data = schemaDeceased
        }
    }

    setField(name, value) {
        let data = this.state.data
        let metadata = this.state.metadata
        let stepData = data
        let stepMetadata = metadata
        let path = (Array.isArray(name)?name:[name])
        let lastName = path.splice(-1,1)
        for(let step of path){
            stepData = stepData[step]
            if(typeof stepMetadata[step] === 'undefined') {
                stepMetadata[step] = {}
            }
            stepMetadata = stepMetadata[step]
        }
        if (stepData[lastName] !== value) {
            stepData[lastName] = value
            if(typeof stepMetadata[lastName] === 'undefined') {
                stepMetadata[lastName] = {}
            }
            stepMetadata[lastName].changed = true
            this.setState({ data: data })
            if(typeof this.props.onChange === 'function'){
                this.props.onChange({ data: this.state.data, metadata: this.state.metadata })
            }
        }
    }

    checkExistingObjet = () => {
        if(this.state.data.name && this.state.data.surname && this.state.data.deathdate){
            const conds = {
                name: this.state.data.name,
                surname: this.state.data.surname,
                deathdate: this.state.data.deathdate.match(/^\d{4}$/)? this.state.data.deathdate + '-01-01' : this.state.data.deathdate
            }
            if(this.state.data.birthday){
                conds.birthday = this.state.data.birthday.match(/^\d{4}$/)? this.state.data.birthday + '-01-01' : this.state.data.birthday
            }
            if(this.state.data.key){
                conds.notRid = this.state.data.key
            }
            this.props.appMng.props.JBLClient.getDeceaseds(conds,{relatedBurial: true}).then((result)=>{
                if(typeof result === "object" && result.length > 0) {
                    let pathText = ''
                    if(result.length === 1){
                        if(!this.state.data.key && result[0].relatedBurial.length === 0){
                            return this.setState({data: {...result[0]}, initialData: {...result[0]}})
                        } else {
                            const burial = result[0].relatedBurial[0]
                            pathText = <Fragment><Typography variant="body1" component="span" style={{display: "block", marginBottom: '0.5em'}}><i>{burial.foreignGraveyard? burial.foreignGraveyard : (burial.grave ? burial.grave.gravespace.graveyard.myNameIs + ': ' + burial.grave.location + ', ' + burial.grave.myNameIs : null )}</i></Typography><br/></Fragment>
                        }
                    }
                    this.props.appMng.openDialog('Potenziale duplicazione', (
                        <Fragment>
                            <DialogContentText id="main-dialog-description">
                                <Typography variant="body1" component="span" style={{display: "block", marginBottom: '0.5em'}}><b>{pathText?"Una persona defunta che corrisponde ai dati riportati è già presente e risulta essere già stata inumata.":"Più persone decedute corrispondenti ai dati riportati sono già presenti e risultano essere già state inumate."}</b></Typography><br/>
                                {pathText}
                                <Typography variant="body1" component="span">Proseguendo verrà registrata una nuova scheda per la persona defunta e questo potrebbe corrispondere ad una duplicazione.</Typography><br/>
                                <Typography variant="body1" component="span"><b>Se si tratta di un trasferimento, per favore effettua l'operazione partendo dall'ultima inumazione.</b></Typography>
                            </DialogContentText>
                        </Fragment>),
                        (<Fragment>
                            <Button aria-label="Mantieni" onClick={() => {
                                this.setState({initialData: {...this.state.data}})
                                this.props.appMng.closeDialog()
                            }}>Ho compreso</Button>
                        </Fragment>))
                } else {
                    this.setState({initialData: {...this.state.data}})
                }
            })
        }
    }

    dateMaskSelector(fName, label) {
        let element = null
        const maskName = fName + 'Mask'
        switch(this.state.data[maskName]) {
            case 'yearOnly':
                label = label.replace('Data','Anno')
                element = <TextField
                    label={label}
                    value={this.state.data[fName].substring(0,4)}
                    margin="normal"
                    variant="outlined"
                    style={{width: "140px"}}
                    required
                    onChange={e => {
                        if(e.target.value.match(/^\d{0,4}$/)) {
                            this.setField(fName, e.target.value)
                        }
                    }}
                    onBlur={this.checkExistingObjet}
                />
                break;
            case 'unknown':   //unknown
                break;
            case 'full':
            default:
                element = <TextField
                    label={label}
                    value={this.state.data[fName]?(this.state.data[fName].match(/^\d{4}$/)? this.state.data[fName] + '-01-01':this.state.data[fName]):null}
                    type="date"
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={e => this.setField(fName, e.target.value)}
                    onBlur={this.checkExistingObjet}
                />
        }
        return <>
            {element}
            <JSelect
                label={element?'':label}
                value={this.state.data[maskName]}
                onChange={value => this.setField(maskName, value)}
            >
                <option value="full">completa</option>
                <option value="yearOnly">solo anno</option>
                <option value="unknown">sconosciuta</option>
            </JSelect>
        </>
    }

    nameMaskSelector(fName, label){
        const maskName = fName + 'Unknown'
        return <>
            { !this.state.data[maskName] && <Grid item xs={10} md={5}>
                <TextField
                    label={label}
                    value={this.state.data[fName]}
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={e => this.setField(fName, e.target.value)}
                    onBlur={this.checkExistingObjet}
                />
            </Grid>}
            <Grid item xs={this.state.data[maskName]? 6 : 2} md={this.state.data[maskName]? 4 : 1}>
                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.state.data[maskName]}
                                onChange={e => {
                                    this.setField(maskName, e.target.checked)
                                    if(e.target.checked) {
                                        this.setField(fName, 'ignoto')
                                    } else {
                                        this.setField(fName, '')
                                    }
                                }}
                                inputProps={{ 'aria-label': label }}
                            />
                        }
                        label={this.state.data[maskName]? label+ ' ignoto' : ''}
                    />
                </FormGroup>
            </Grid>
        </>
    }

    render() {
        return (
            <Grid container spacing={1} item alignItems="center">
                {this.nameMaskSelector('name','Nome')}
                {this.nameMaskSelector('surname','Cognome')}
                <Grid container item xs={12} wrap="wrap" spacing={1}>
                    <Grid item>
                        {this.dateMaskSelector('birthday','Data di nascita')}
                    </Grid>
                    <Grid item>
                        {this.dateMaskSelector('deathdate','Data di morte')}
                    </Grid>
                </Grid>
            </Grid>
        )
    }

}

export default FormDeceased