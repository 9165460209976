import React, { Component } from 'react'
import { Grid, Typography, TextField, Switch, AccordionSummary, Accordion, AccordionDetails, Button, TableContainer, TableHead, TableRow, TableCell, TableBody, Table, TablePagination, IconButton, Paper } from '@material-ui/core'
import JSelect from '../JForm/controls/JSelect'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const opList = {
    ignore: "Ignora",
    exact: "È esattamente",
    contains: "Contiene",
    startWith: "Inizia per",
    unknownOnly: "Solo ignoto",
    since: "A partire da",
    until: "Entro il",
    between: "Tra"
}

class GYSearch extends Component {

    state = {
        fields: {
            isReservation: false,
            dNameFilter: 'contains',
            dName: '',
            dSurnameFilter: 'contains',
            dSurname: '',
            dBirthdayFilter: 'exact',
            dBirthdayA: '',
            dBirthdayB: '',
            dDeathdayFilter: 'exact',
            dDeathdayA: '',
            dDeathdayB: '',
            rNameFilter: 'contains',
            rName: '',
            rSurnameFilter: 'contains',
            rSurname: '',
            rTaxCodeFilter: 'exact',
            rTaxCode: '',
            rPhoneFilter: 'startWith',
            rPhone: '',
            rEmailFilter: 'startWith',
            rEmail: '',
            rSAddressFilter: 'contains',
            rSAddress: '',
            bDateFilter: 'exact',
            bDateA: '',
            bDateB: '',
            bExhumationDateFilter: 'between',
            bExhumationDateA: '',
            bExhumationDateB: '',
            bPlannedExhumationDateFilter: 'between',
            bPlannedExhumationDateA: '',
            bPlannedExhumationDateB: '',
            bExtIDFilter: 'exact',
            bExtID: ''
        },
        data: [],
        count: 0,
    }

    paginator = {
        rowsPerPage: 10,
        page: 0
    }

    ssKey = 'gwSearchQuery'

    componentDidMount(){
        const rState = JSON.parse(sessionStorage.getItem(this.ssKey))
        if(rState && rState.state) {
            this.paginator = rState.paginator
            this.setState(rState.state,this.submitQuery)
        }
    }

    storeSessionState = ()=>{
        sessionStorage.setItem(this.ssKey,JSON.stringify({state: this.state, paginator: this.paginator}))
    }

    /** Set a field of the search engine form */
    setField(fName,fVal) {
        if(this.state.fields.hasOwnProperty(fName)) {
           this.setState(cState => {
               cState.fields[fName] = fVal
               return cState
           },this.storeSessionState)
           return fVal;
        }
    }

    /** Generate options for select */
    makeOptions(opKList) {
        const rList = []
        let ck=0
        for (const opK of opKList) {
            if(opList.hasOwnProperty(opK) && typeof opList[opK] === "string") {
                rList.push(<option key={ck++} value={opK}>{opList[opK]}</option>)
            }
        }
        return rList
    }


    /** Submit the query */
    submitQuery(){
        this.props.appMng.props.JBLClient.getBurials({conds: this.state.fields}, true, this.paginator.page * this.paginator.rowsPerPage, this.paginator.rowsPerPage)
        .then(res => {
            if(typeof res === "object") {
                if(Array.isArray(res.list)) {
                    this.setState({data: res.list, count: res.count},this.storeSessionState)
                }
            }
        })
    }

    /** Change number of rows per page */
    handleChangeRowsPerPage = (e) => {
        this.paginator.rowsPerPage = e.target.value
        this.paginator.page = 0
        this.submitQuery()
    }

    handleChangePage = (e, newPage) => {
        this.paginator.page = newPage
        this.submitQuery()
    }

    drawData(){
        let dateDisplay = (date,mask) => {
            var dt = new Date(date);
            switch(mask) {
                case 'unknown':
                    return "ignota"
                case 'yearOnly':
                    return dt.getFullYear()
                default:
                    return dt.toLocaleDateString(undefined, {year: 'numeric', month: 'long', day: 'numeric' })
            }
        }
        return  <Paper>
            <TableContainer>
                <Table aria-label="tabella dei dati">
                <TableHead>
                    <TableRow>
                    <TableCell>Persona deceduta</TableCell>
                    <TableCell align="right">Data nascita</TableCell>
                    <TableCell align="right">Data decesso</TableCell>
                    <TableCell align="right">Locazione</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.state.data.map((row,k) => (
                    <TableRow key={k}>
                        <TableCell component="th" scope="row">
                            <IconButton aria-label="apri scheda" size="small" onClick={() => this.props.appMng.openAction("grave", { key: row.grave.key})}>
                                <OpenInNewIcon fontSize="inherit" />
                            </IconButton>&nbsp;{row.deceased.myNameIs}
                        </TableCell>
                        <TableCell align="right">{dateDisplay(row.deceased.birthday,row.deceased.birthdayMask)}</TableCell>
                        <TableCell align="right">{dateDisplay(row.deceased.deathdate,row.deceased.deathdateMask)}</TableCell>
                        <TableCell align="right">{(row.actual_exhumation_date? "Esumazione: " + dateDisplay(row.actual_exhumation_date,'full')+". Era in " : "")+(row.grave.location + ', ' + row.grave.myNameIs)}</TableCell>
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20]}
                component="div"
                count={this.state.count}
                rowsPerPage={this.paginator.rowsPerPage}
                page={this.paginator.page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
        </Paper>
    }

    /** Draws the search engine form
     */
    drawForm() {
        return <Grid container spacing={2}>
                    <Grid item xs={12}><Typography variant="h6">Opzioni</Typography></Grid>
                    <Grid item xs={12} container>
                        <Grid item xs={2} sm={1}>
                            <Switch
                                checked={this.state.fields.isReservation}
                                onChange={e => this.setField('isReservation', e.target.checked)}
                                inputProps={{
                                    'aria-label': "è una prenotazione"
                                }}
                            />
                        </Grid>
                        <Grid item>prenotazioni</Grid>
                    </Grid>
                    {this.state.fields.isReservation !== true && <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="filtraggio dati defunti"
                        >
                            <Typography variant="h6">Defunti</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid item container xs={12}>
                                <Grid item xs={12}><Typography>nome e cognome</Typography></Grid>
                                <Grid item xs={4} sm={3} md={2} container justifyContent="flex-end">
                                    <JSelect
                                            value={this.state.fields.dNameFilter}
                                            onChange={value => this.setField('dNameFilter', value)}
                                        >
                                            {this.makeOptions(["ignore","exact","contains","startWith","unknownOnly"])}
                                    </JSelect>
                                </Grid>
                                <Grid item xs={8} sm={9} md={4}>
                                        <TextField
                                            label="Nome"
                                            value={this.state.fields.dName}
                                            variant="outlined"
                                            margin="normal"
                                            disabled={this.state.fields.dNameFilter === "unknownOnly"}
                                            fullWidth
                                            onChange={e => this.setField('dName', e.target.value)}
                                        />
                                </Grid>
                                <Grid item xs={4} sm={3} md={2} container justifyContent="flex-end">
                                    <JSelect
                                            value={this.state.fields.dSurnameFilter}
                                            onChange={value => this.setField('dSurnameFilter', value)}
                                        >
                                            {this.makeOptions(["ignore","exact","contains","startWith","unknownOnly"])}
                                    </JSelect>
                                </Grid>
                                <Grid item xs={8} sm={9} md={4}><TextField
                                            label="Cognome"
                                            value={this.state.fields.dSurname}
                                            variant="outlined"
                                            margin="normal"
                                            disabled={this.state.fields.dSurnameFilter === "unknownOnly"}
                                            fullWidth
                                            onChange={e => this.setField('dSurname', e.target.value)}
                                        />
                                </Grid>
                                <Grid item xs={12}><Typography>data di nascita</Typography></Grid>
                                <Grid item xs={4} sm={3} md={2} container justifyContent="flex-end">
                                    <JSelect
                                            value={this.state.fields.dBirthdayFilter}
                                            onChange={value => this.setField('dBirthdayFilter', value)}
                                        >
                                            {this.makeOptions(["ignore","exact","since","until","between","unknownOnly"])}
                                    </JSelect>
                                </Grid>
                                <Grid item xs={8} sm={9} md={10}><TextField
                                            value={this.state.fields.dBirthdayA}
                                            variant="outlined"
                                            margin="normal"
                                            type="date"
                                            disabled={this.state.fields.dBirthdayFilter === "unknownOnly"}
                                            onChange={e => this.setField('dBirthdayA', e.target.value)}
                                        />
                                        { this.state.fields.dBirthdayFilter === "between" && <> <TextField
                                            value={this.state.fields.dBirthdayB}
                                            variant="outlined"
                                            margin="normal"
                                            type="date"
                                            onChange={e => this.setField('dBirthdayB', e.target.value)}
                                        /></>}
                                </Grid>
                                <Grid item xs={12}><Typography>data di morte</Typography></Grid>
                                <Grid item xs={4} sm={3} md={2} container justifyContent="flex-end">
                                    <JSelect
                                            value={this.state.fields.dDeathdayFilter}
                                            onChange={value => this.setField('dDeathdayFilter', value)}
                                        >
                                            {this.makeOptions(["ignore","exact","since","until","between","unknownOnly"])}
                                    </JSelect>
                                </Grid>
                                <Grid item xs={8} sm={9} md={10}><TextField
                                            value={this.state.fields.dDeathdayA}
                                            variant="outlined"
                                            margin="normal"
                                            type="date"
                                            disabled={this.state.fields.dDeathdayFilter === "unknownOnly"}
                                            onChange={e => this.setField('dDeathdayA', e.target.value)}
                                        />
                                        { this.state.fields.dDeathdayFilter === "between" && <> <TextField
                                            value={this.state.fields.deathdayB}
                                            variant="outlined"
                                            margin="normal"
                                            type="date"
                                            onChange={e => this.setField('deathdayB', e.target.value)}
                                        /></>}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                </Accordion>}
                <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="filtraggio dati referenti"
                        >
                            <Typography variant="h6">Referenti</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid item container xs={12}>
                                <Grid item xs={12}><Typography>Identità</Typography></Grid>
                                <Grid item xs={5} sm={3} container justifyContent="flex-end">
                                    <JSelect
                                            value={this.state.fields.rTaxCodeFilter}
                                            onChange={value => this.setField('rTaxCodeFilter', value)}
                                        >
                                            {this.makeOptions(["ignore","exact","contains","startWith"])}
                                    </JSelect>
                                </Grid>
                                <Grid item xs={8} sm={9}>
                                        <TextField
                                            label="Codice fiscale"
                                            value={this.state.fields.rTaxCode}
                                            variant="outlined"
                                            margin="normal"
                                            onChange={e => this.setField('rTaxCode', e.target.value)}
                                        />
                                </Grid>
                                <Grid item xs={4} sm={3} md={2} container justifyContent="flex-end">
                                    <JSelect
                                            value={this.state.fields.rNameFilter}
                                            onChange={value => this.setField('rNameFilter', value)}
                                        >
                                            {this.makeOptions(["ignore","exact","contains","startWith"])}
                                    </JSelect>
                                </Grid>
                                <Grid item xs={8} sm={9} md={4}>
                                        <TextField
                                            label="Nome o Ragione sociale"
                                            value={this.state.fields.rName}
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            onChange={e => this.setField('rName', e.target.value)}
                                        />
                                </Grid>
                                <Grid item xs={4} sm={3} md={2} container justifyContent="flex-end">
                                    <JSelect
                                            value={this.state.fields.rSurnameFilter}
                                            onChange={value => this.setField('rSurnameFilter', value)}
                                        >
                                            {this.makeOptions(["ignore","exact","contains","startWith"])}
                                    </JSelect>
                                </Grid>
                                <Grid item xs={8} sm={9} md={4}><TextField
                                            label="Cognome"
                                            value={this.state.fields.rSurname}
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            onChange={e => this.setField('rSurname', e.target.value)}
                                        />
                                </Grid>
                                <Grid item xs={12}><Typography>Recapiti</Typography></Grid>
                                <Grid item xs={4} sm={3} md={2} container justifyContent="flex-end">
                                    <JSelect
                                            value={this.state.fields.rPhoneFilter}
                                            onChange={value => this.setField('rPhoneFilter', value)}
                                        >
                                            {this.makeOptions(["ignore","exact","contains","startWith"])}
                                    </JSelect>
                                </Grid>
                                <Grid item xs={8} sm={9} md={4}><TextField
                                            label="Telefono"
                                            value={this.state.fields.rPhone}
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            onChange={e => this.setField('rPhone', e.target.value)}
                                        />
                                </Grid>
                                <Grid item xs={4} sm={3} md={2} container justifyContent="flex-end">
                                    <JSelect
                                            value={this.state.fields.rEmailFilter}
                                            onChange={value => this.setField('rEmailFilter', value)}
                                        >
                                            {this.makeOptions(["ignore","exact","contains","startWith"])}
                                    </JSelect>
                                </Grid>
                                <Grid item xs={8} sm={9} md={4}><TextField
                                            label="eMail"
                                            value={this.state.fields.rEmail}
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            onChange={e => this.setField('rEmail', e.target.value)}
                                        />
                                </Grid>
                                <Grid item xs={4} sm={3} md={2} container justifyContent="flex-end">
                                    <JSelect
                                            value={this.state.fields.rSAddressFilter}
                                            onChange={value => this.setField('rSAddressFilter', value)}
                                        >
                                            {this.makeOptions(["ignore","contains"])}
                                    </JSelect>
                                </Grid>
                                <Grid item xs={8} sm={9} md={10}><TextField
                                            label="Indirizzo stradale"
                                            value={this.state.fields.rSAddress}
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            onChange={e => this.setField('rSAddress', e.target.value)}
                                        />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                </Accordion>
                <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={"filtraggio dati " + (this.state.fields.isReservation?'prenotazioni':'sepolture')}
                        >
                            <Typography variant="h6">{this.state.fields.isReservation?'Prenotazioni':'Sepolture'}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid item sx={12} container>
                                <Grid item xs={12}><Typography>{"data " + (this.state.fields.isReservation?'inizio':'sepoltura')}</Typography></Grid>
                                <Grid item xs={4} sm={3} md={2} container justifyContent="flex-end">
                                    <JSelect
                                            value={this.state.fields.bDateFilter}
                                            onChange={value => this.setField('bDateFilter', value)}
                                        >
                                            {this.makeOptions(["ignore","exact","since","until","between"])}
                                    </JSelect>
                                </Grid>
                                <Grid item xs={8} sm={9} md={10}><TextField
                                            value={this.state.fields.bDateA}
                                            variant="outlined"
                                            margin="normal"
                                            type="date"
                                            onChange={e => this.setField('bDateA', e.target.value)}
                                        />
                                        { this.state.fields.bDateFilter === "between" && <> <TextField
                                            value={this.state.fields.bDateB}
                                            variant="outlined"
                                            margin="normal"
                                            type="date"
                                            onChange={e => this.setField('bDateB', e.target.value)}
                                        /></>}
                                </Grid>
                                <Grid item xs={12}><Typography>{"data " + (this.state.fields.isReservation?'termine pianificata':'esumazione pianificata')}</Typography></Grid>
                                <Grid item xs={4} sm={3} md={2} container justifyContent="flex-end">
                                    <JSelect
                                            value={this.state.fields.bPlannedExhumationDateFilter}
                                            onChange={value => this.setField('bPlannedExhumationDateFilter', value)}
                                        >
                                            {this.makeOptions(["ignore","exact","since","until","between"])}
                                    </JSelect>
                                </Grid>
                                <Grid item xs={8} sm={9} md={10}><TextField
                                            value={this.state.fields.bPlannedExhumationDateA}
                                            variant="outlined"
                                            margin="normal"
                                            type="date"
                                            onChange={e => this.setField('bPlannedExhumationDateA', e.target.value)}
                                        />
                                        { this.state.fields.bPlannedExhumationDateFilter === "between" && <> <TextField
                                            value={this.state.fields.bPlannedExhumationDateB}
                                            variant="outlined"
                                            margin="normal"
                                            type="date"
                                            onChange={e => this.setField('bPlannedExhumationDateB', e.target.value)}
                                        /></>}
                                </Grid>
                                <Grid item xs={12}><Typography>{"data " + (this.state.fields.isReservation?'termine registrata':'avvenuta esumazione')}</Typography></Grid>
                                <Grid item xs={4} sm={3} md={2} container justifyContent="flex-end">
                                    <JSelect
                                            value={this.state.fields.bExhumationDateFilter}
                                            onChange={value => this.setField('bExhumationDateFilter', value)}
                                        >
                                            {this.makeOptions(["ignore","exact","since","until","between"])}
                                    </JSelect>
                                </Grid>
                                <Grid item xs={8} sm={9} md={10}><TextField
                                            value={this.state.fields.bExhumationDateA}
                                            variant="outlined"
                                            margin="normal"
                                            type="date"
                                            onChange={e => this.setField('bExhumationDateA', e.target.value)}
                                        />
                                        { this.state.fields.bExhumationDateFilter === "between" && <> <TextField
                                            value={this.state.fields.bExhumationDateB}
                                            variant="outlined"
                                            margin="normal"
                                            type="date"
                                            onChange={e => this.setField('bExhumationDateB', e.target.value)}
                                        /></>}
                                </Grid>
                                <Grid item xs={12}><Typography>Altri parametri</Typography></Grid>
                                <Grid item xs={4} sm={3} md={2} container justifyContent="flex-end">
                                    <JSelect
                                            value={this.state.fields.bExtIDFilter}
                                            onChange={value => this.setField('bExtIDFilter', value)}
                                        >
                                            {this.makeOptions(["ignore","exact","contains","start"])}
                                    </JSelect>
                                </Grid>
                                <Grid item xs={8} sm={9} md={10}><TextField
                                            label="ID esterno"
                                            value={this.state.fields.bExtID}
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            onChange={e => this.setField('bExtID', e.target.value)}
                                        />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                </Accordion>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        aria-label="avvia ricerca"
                        onClick={() => this.submitQuery()}
                        startIcon={<SearchIcon/>}
                    >Cerca</Button>
                </Grid>
            </Grid>
    }

    render() {
        return <>
            {this.drawForm()}
            {this.state.data.length > 0 && this.drawData()}
        </>
    }

}

export default GYSearch
