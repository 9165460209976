import { createStyles } from "@material-ui/core";

const AppActionGraveyardsStyle = ((theme) => createStyles({
    fabAdd: {
        position: "absolute",
        right: theme.spacing(2),
        bottom: -1 * theme.spacing(2),
    },
    headMedia: {
        position: "relative",
        height: '160px',
        color: theme.palette.primary.contrastText
    },
    itemsHeaderText: {
        background: "linear-gradient(180deg, rgba(0,0,0,0.6194852941176471) 0%, rgba(0,0,0,0.09287464985994398) 88%, rgba(0,0,0,0) 100%)",
        color: theme.palette.primary.contrastText
    },
    hiddenElement: {
        display: 'none'
    },
    avatarActive: {
        backgroundColor: theme.palette.primary.main
    },
}))

export default AppActionGraveyardsStyle