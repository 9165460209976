import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { persistCache } from 'apollo-cache-persist';
import { setContext } from 'apollo-link-context';
import gql from 'graphql-tag'
import JGQLComposer from './JGQLComposer'

/** J Business Logic Client
 * @class JBLClient
 */
class JBLClient {

    listQueryes = {}

    sysUserRoleMap = {
        fields: {
            OUnit: {
                fields: {
                    key: {},
                    myNameIs: {},
                    organization: {
                        fields:{
                            key: {},
                            myNameIs: {}
                        }
                    }
                }
              },
            roles: {
                fields: {
                    key: {},
                    myNameIs: {},
                    description: {}
                }
            }
        }
    }

    sysUserGetMap = {
        key: {},
        status: {},
        myNameIs: {},
        name: {},
        email: {},
        phone: {},
        accessInfo: {
            fields: {
                last_login: {
                    fields: {
                        time: {}
                    }
                },
                login_count: {}
            }
        },
        rolesInOrganizationalUnit: this.sysUserRoleMap
    }

    planimetryGetMap = {
        fields: {
            color: {},
            path: { fields: { x: {}, y: {} } }
        }
    }

    spacemodelGetMap = {
        key: {},
        name: {},
        myNameIs: {},
        description: {},
        planimetry: this.planimetryGetMap,
        isGrave: {},
        allowGraves: {},
        allowSpaces: {},
        defaultGrantDuration: {}
    }

    graveyardMapDefGetMap = {
        fields: {
            type: {},
            source: {},
            origin: { fields: { x: {}, y: {} } },
            scale: {},
            alpha: {}
        }
    }

    graveyardUnnormalizedDiagramGetMap = {
        key: {},
        unnormalizedDiagram: {},
    }

    graveyardGetMap = {
        ...this.graveyardUnnormalizedDiagramGetMap,
        myNameIs: {},
        name: {},
        sys_name: {},
        fullAddress: {},
        geoPosition: { fields: { lat: {}, long: {}} },
        opening: {},
        planimetry: this.planimetryGetMap,
        map: this.graveyardMapDefGetMap,
        organizationalUnit: { fields: { key: {}, myNameIs: {}, organization: { fields: { myNameIs: {}, key: {} } } } }
    }

    graveyardSpaceGetMap = {
        key: {},
        spaceModel: { fields: { name: {}, key: {}, allowSpaces: {}, allowGraves: {} } },
        name: {},
        myNameIs: {},
        planimetry: this.planimetryGetMap,
        level: {},
        relatedGrave: { fields: { myNameIs: {}, key: {}, name: {}, planimetry: this.planimetryGetMap } }
    }

    deceasedGetMap = {
        key: {},
        myNameIs: {},
        name: {},
        nameUnknown: {},
        surname: {},
        surnameUnknown: {},
        birthday: {},
        birthdayMask: {},
        deathdate: {},
        deathdateMask: {}
    }

    referentGetMap = {
        key: {},
        myNameIs: {},
        legalPerson: {},
        tax_code: {},
        name: {},
        surname: {},
        phone: {},
        email: {},
        fullAddress: {}
    }

    fileGetMap = {
        key: {},
        name: {},
        metadata: {}
    }

    graveGetMap = {
        key: {},
        myNameIs: {},
        name: {},
        location: {},
        spaceModel: { fields: { key: {}, name: {}, defaultGrantDuration: {} } },
        planimetry: this.planimetryGetMap,
        burialLimit: {},
        mergeGroup: { fields: {
            rid: {},
            graves: {}
        }},
        gravespace: { fields: {
            myNameIs: {},
            key: {},
            level: {},
            graveyard: { fields: {
                myNameIs: {},
                key: {},
                organizationalUnit: { fields: {
                    myNameIs: {},
                    key: {},
                    organization: { fields: {
                        myNameIs: {},
                        key: {} } } } } } } } },
        relatedBurial: {
            args: { params: { conds: { current: true } } },
            fields: {
                isReservation: {},
                burial_date: {}, key: {},
                planned_exhumation_date: {},
                actual_exhumation_date: {},
                external_references: {},
                deceased: { fields: this.deceasedGetMap },
                relatedReferent: { fields: this.referentGetMap },
                relatedAttachedFile: { fields: this.fileGetMap } } },
        canAcceptNewBurial: {},
    }

    burialGetMap = {
        key: {},
        isReservation: {},
        burial_date: {},
        planned_exhumation_date: {},
        actual_exhumation_date: {},
        external_references: {},
        annotation: {},
        deceased: { fields: this.deceasedGetMap },
        relatedReferent: { fields: this.referentGetMap },
        grave: { fields: { key: {}, myNameIs: {}, location: {} } }
    }

    constructor(props) {
        this.props = props
        this.authTokenName = 'JBL' + this.props.uri
        const httpLink = createHttpLink({
            uri: this.props.uri
        })
        const authLink = setContext((_, { headers }) => {
            const token = sessionStorage.getItem(this.authTokenName);
            return {
                headers: {
                    ...headers,
                    authorization: token ? `Bearer ${token}` : "",
                }
            }
        });

        const cache = new InMemoryCache({
            dataIdFromObject: object => {
                const unNormalizePrefixes = ['Last_loginNested','ReturnNested','ReturnKVOf','InheritReferencesNested','Polygon','Coordinates2D']
                for (const prefix of unNormalizePrefixes) {
                    if(object.__typename.startsWith(prefix)){
                        return false
                    }
                }
                return (typeof object.key === "undefined")? false : object.__typename + '.' + object.key
            }
        });

        persistCache({
            cache,
            storage: window.localStorage,
        }).then(() => {
            this.GQLClient = new ApolloClient({
                link: authLink.concat(httpLink),
                cache: cache
            })
        })

    }

    filterTypename(obj){
        if(typeof obj === 'object'){
            for (let propName in obj) {
                if(propName === '__typename'){
                    delete obj[propName]
                } else if(typeof obj[propName] === 'object') {
                    obj[propName] = this.filterTypename(obj[propName])
                }
            }
        }
        return obj
    }

    notifyMsgToGui(details) {
        document.body.dispatchEvent(new CustomEvent('notifyMsgToGui', { detail: details }));
    }

    notifyLoginChange() {
        document.body.dispatchEvent(new CustomEvent('login'));
    }

    loginStatus() {
        return Boolean(sessionStorage.getItem(this.authTokenName))
    }

    logout() {
        sessionStorage.clear();
        this.GQLClient.clearStore()
        this.notifyLoginChange()
    }

    async login(userid, password) {
        const query = new JGQLComposer('mutation', 'JBLlogin', { userid: 'ID!', password: 'String!' })
        query.addField('authentication').addInlineFragment('AuthSysJlogin').addField('currentState').addInlineFragment('AuthSysJloginStateOut').addField('actions').addField('jIntroduceYourself', { id: '$userid', password: '$password' })
        query.addField('fetchMsgs').addFields({ type: [], content: { fields: ['text', 'errn'] } })
        const response = this.GQLClient.mutate({
            mutation: gql`${query.toString()}`,
            variables: {
                userid: userid,
                password: password
            },
            fetchPolicy: 'no-cache'
        }).then(response => {
            const retCodeList = []
            for (let message of response.data.fetchMsgs) {
                this.notifyMsgToGui({
                    'kind': message.type,
                    'text': message.content.text + '\n(' + message.content.errn + ')'
                })
                retCodeList.push(message.content.errn)
            }
            if (typeof response.data.authentication.currentState.actions.jIntroduceYourself === 'string') {
                sessionStorage.setItem(this.authTokenName, response.data.authentication.currentState.actions.jIntroduceYourself);
                this.getMe().then((me) => {
                    if(typeof me.key !== "undefined"){
                        sessionStorage.setItem('myKey', me.key);
                        this.notifyLoginChange()
                    }
                })
            }
            return retCodeList;
        }).catch(error => {
            for (let err of error.graphQLErrors) {
                this.notifyMsgToGui({
                    'kind': 'error',
                    'text': err.message
                })
            }
        })
        return response
    }

    async changePassword(userid, password, new_password, repeat_new_password) {
        const query = new JGQLComposer('mutation', 'JBLlogin', { userid: 'GenericIdentifier!', password: 'String!', new_password: 'String!', repeat_new_password: 'String!'})
        query.addField('authentication').addInlineFragment('AuthSysJlogin').addField('currentState').addInlineFragment('AuthSysJloginStateOut').addField('actions').addField('jChangePasswordAndCheckIn', { id: '$userid', password: '$password', new_password: '$new_password', repeat_new_password: '$repeat_new_password' })
        query.addField('fetchMsgs').addFields({ type: [], content: { fields: ['text', 'errn'] } })
        const response = this.GQLClient.mutate({
            mutation: gql`${query.toString()}`,
            variables: {
                userid: userid,
                password: password,
                new_password: new_password,
                repeat_new_password: repeat_new_password
            },
            fetchPolicy: 'no-cache'
        }).then(response => {
            const retCodeList = []
            for (let message of response.data.fetchMsgs) {
                this.notifyMsgToGui({
                    'kind': message.type,
                    'text': message.content.text + '\n(' + message.content.errn + ')'
                })
                retCodeList.push(message.content.errn)
            }
            if (typeof response.data.authentication.currentState.actions.jChangePasswordAndCheckIn === 'string') {
                sessionStorage.setItem(this.authTokenName, response.data.authentication.currentState.actions.jChangePasswordAndCheckIn);
                this.notifyLoginChange()
            }
            return retCodeList;
        }).catch(error => {
            for (let err of error.graphQLErrors) {
                this.notifyMsgToGui({
                    'kind': 'error',
                    'text': err.message
                })
            }
        })
        return response
    }

    async answerQuestion(userid, answer) {
        const query = new JGQLComposer('mutation', 'JBLlogin', { userid: 'GenericIdentifier!', answer: 'String!'})
        query.addField('authentication').addInlineFragment('AuthSysJlogin').addField('currentState').addInlineFragment('AuthSysJloginStateOut').addField('actions').addField('jAnswerToQuestion', { id: '$userid', answer: '$answer'})
        query.addField('fetchMsgs').addFields({ type: [], content: { fields: ['text', 'errn'] } })
        const response = this.GQLClient.mutate({
            mutation: gql`${query.toString()}`,
            variables: {
                userid: userid,
                answer: answer
            },
            fetchPolicy: 'no-cache'
        }).then(response => {
            const retCodeList = []
            for (let message of response.data.fetchMsgs) {
                this.notifyMsgToGui({
                    'kind': message.type,
                    'text': message.content.text + '\n(' + message.content.errn + ')'
                })
                retCodeList.push(message.content.errn)
            }
            return retCodeList;
        }).catch(error => {
            for (let err of error.graphQLErrors) {
                this.notifyMsgToGui({
                    'kind': 'error',
                    'text': err.message
                })
            }
        })
        return response
    }


    async getSysUsersRoles() {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile recuperare l'elenco dei ruoli utenze di sistema" })
            return []
        }
        try {
            let query
            let addRenewToken = true
            if(typeof this.listQueryes.getListOfSysUsersRoles !== 'undefined') {
                query = this.listQueryes.getListOfSysUsersRoles
                addRenewToken = false
            } else {
                query = new JGQLComposer('query', 'getSysUsersRoles')
                query.addField('getListOfSysUsersRoles')
                    .addFields(['key', 'myNameIs', 'name' , 'sys_name', 'description'])
                this.listQueryes.getListOfSysUsersRoles = query
            }
            let response = this.sendServerCommand({ query: query }, addRenewToken)
                .then(response => { return (response === null ? failResponse() : response.getListOfSysUsersRoles) })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async getMe() {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile recuperare i dati dell'account autenticato" })
            return {}
        }
        try {
            const query = new JGQLComposer('query', 'getMe')
            query.addField('me').addFields(this.sysUserGetMap)
            let response = this.sendServerCommand({ query: query })
                .then(response => {
                    return (response === null ? failResponse() : response.me) })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async updateMe(data) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Il tuo accont non è stato aggiornato"})
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'updateMe', {
                name: 'ProperName',
                phone: 'PhoneNumber',
                email: 'Email'
             })
            let oegQ = query.addField('me')
            oegQ.addFields({
                name: { args: { set: "$name" } },
                phone: { args: { set: "$phone" } },
                email: { args: { set: "$email" } },
             })
            oegQ.addField('persists').addInlineFragment('SysUser').addFields(this.sysUserGetMap)
            let response = this.sendServerCommand({ mutation: query, variables: {
                name: data.name,
                phone: data.phone,
                email: data.email
             } })
                .then(response => {
                    if (response === null || !response.me.persists.key) {
                        return failResponse()
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "Il tuo accont è stato aggiornato"
                        })
                        return response.me.persists.key
                    }
                })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async changeMyPassword(password) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "La tua password non è stata cambiata"})
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'changeMyPassword', { password: 'String!'})
            let oegQ = query.addField('me')
            oegQ.addField('setAuthData',{ method: "&jlogin", action: "&changePwd", data: {newPassword: "$password"}})
            let response = this.sendServerCommand({ mutation: query, variables: {password: password}, fetchPolicy: 'no-cache'})
                .then(response => {
                    if (response === null || !response.me || !response.me.setAuthData) {
                        return failResponse()
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "Il tua password è stata cambiata"
                        })
                        return true
                    }
                })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async getSysUsers(conds = {}) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile recuperare l'elenco degli operatori" })
            return []
        }
        try {
            let query
            let addRenewToken = true
            let varDeclare = {}
            let args = {params: {conds:{}}}
            let vars = {}
            if(conds.email){
                varDeclare = {...varDeclare, email: 'Email'}
                args = {...args, params: {...args.params, conds: {...args.params.conds, email: '$email'}}}
                vars = {...vars, email: conds.email}
            }
            query = new JGQLComposer('query', 'getSysUsers', varDeclare)
            query.addField('getListOfSysUsers', args).addFields(['key','myNameIs', 'email', 'status'])
            if(typeof this.listQueryes.getListOfSysUsers === 'undefined') {
                this.listQueryes.getListOfSysUsers = query
            }
            let response = this.sendServerCommand({ query: query, variables: vars }, addRenewToken)
                .then(response => { return (response === null ? failResponse() : response.getListOfSysUsers) })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async getSysUser(key) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile recuperare i dati dell'operatore" })
            return {}
        }
        try {
            const query = new JGQLComposer('query', 'getSysUser', { key: 'ID!' })
            query.addField('sysUser', { key: '$key' }).addFields(this.sysUserGetMap)
            let response = this.sendServerCommand({ query: query, variables: { key: key } })
                .then(response => {
                    return (response === null ? failResponse() : response.sysUser) })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async newSysUser(data, OUnitKey, roleKey) {
        let failResponse = (name, error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "L'utenza per " + name + ' non è stata creata' })
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'newSysUser', {
                name: 'ProperName',
                phone: 'PhoneNumber',
                email: 'Email',
                OUnitKey: 'ID',
                roleKey: 'ID',
            })
            let oegQ = query.addField('newSysUser')
            oegQ.addFields({
                name: { args: { set: "$name" } },
                phone: { args: { set: "$phone" } },
                email: { args: { set: "$email" } }
             })
            oegQ.addField('persists').addInlineFragment('SysUser').addFields({...this.sysUserGetMap, addRoleInOrganizationalUnit: { args: { OUnitKey: "$OUnitKey", roleKey: "$roleKey" } }});

            let response = this.sendServerCommand({ mutation: query, variables: {
                name: data.name,
                phone: data.phone,
                email: data.email,
                OUnitKey: OUnitKey,
                roleKey: roleKey,
             } })
                .then(response => {
                    if (response === null || !response.newSysUser.persists.key) {
                        return failResponse(data.name)
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "L'utenza per " + data.name + ' è stata aggiunta'
                        })
                        return response.newSysUser.persists.key
                    }
                })
                .catch(error => { return failResponse(data.name, error) })
            return response
        } catch (error) { return failResponse(data.name, error) }
    }

    async updateSysUser(key, data) {
        let failResponse = (name, error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "I dati dell'operatore " + name + ' non sono stati aggiornati' })
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'updateSysUser', {
                key: 'ID!',
                name: 'ProperName',
                phone: 'PhoneNumber',
                email: 'Email'
             })
            let oegQ = query.addField('sysUser', { key: '$key' })
            oegQ.addField('key')
            oegQ.addFields({
                name: { args: { set: "$name" } },
                phone: { args: { set: "$phone" } },
                email: { args: { set: "$email" } },
             })
            oegQ.addField('persists').addInlineFragment('SysUser').addFields(this.sysUserGetMap);
            let response = this.sendServerCommand({ mutation: query, variables: {
                key: key,
                name: data.name,
                phone: data.phone,
                email: data.email
             } })
                .then(response => {
                    if (response === null || !response.sysUser.persists || !response.sysUser.persists.key) {
                        return failResponse(data.name)
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "I dati di " + data.name + ' sono stati aggiornati'
                        })
                        return response.sysUser.persists.key
                    }
                })
                .catch(error => { return failResponse(data.name, error) })
            return response
        } catch (error) { return failResponse(data.name, error) }
    }

    async deleteSysUser(key){
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile eliminare i dati dell'operatore" })
            return {}
        }
        try {
            const query = new JGQLComposer('mutation', 'deleteSysUser', {
                key: 'ID!'
             })
            let oegQ = query.addField('sysUser', { key: '$key' })
            oegQ.addFields(['key','name', 'delete'])
            let response = this.sendServerCommand({ mutation: query, variables: {key: key} })
                .then(response => {
                    if (response === null || !response.sysUser.delete) {
                        return failResponse()
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "I dati di " + response.name + ' sono stati eliminati'
                        })
                        const CacheKey = 'SysUser.' + key
                        if(typeof this.listQueryes.getListOfSysUsers === "object"){
                            const qData = this.GQLClient.readQuery({ query: gql`${this.listQueryes.getListOfSysUsers.toString()}` });
                            for(var ikey in qData.getListOfSysUsers) {
                                if(qData.getListOfSysUsers[ikey].id === CacheKey){
                                    qData.getListOfSysUsers.splice(ikey,1)
                                    break;
                                }
                            }
                            this.GQLClient.writeQuery({
                                query: gql`${this.listQueryes.getListOfSysUsers.toString()}`,
                                data: {
                                    ...qData
                                }
                            })
                        }
                        this.GQLClient.cache.data.delete(CacheKey)
                        return true
                    }
                })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async remRoleSysUser(key, OUnitKey, roleKey) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "rimozione ruolo fallita" })
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'remRoleSysUser', {
                key: 'ID!',
                OUnitKey: 'ID',
                roleKey:  'ID'
             })
            let oegQ = query.addField('sysUser', { key: '$key' })
            oegQ.addField('key')
            oegQ.addFields({
                key: {},
                remRoleInOrganizationalUnit: { args: { OUnitKey: "$OUnitKey", roleKey: "$roleKey" } },
                rolesInOrganizationalUnit: this.sysUserRoleMap
             })
            let response = this.sendServerCommand({ mutation: query, variables: {
                key: key,
                OUnitKey: OUnitKey,
                roleKey:  roleKey
             } })
                .then(response => {
                    if (response === null || !response.sysUser.remRoleInOrganizationalUnit) {
                        return failResponse()
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "Rimozione effettuata"
                        })
                        const CacheKey = 'OrganizationalUnit.' + OUnitKey
                        for (const itemkey in this.GQLClient.cache.data.data[CacheKey].relatedSysUser) {
                            if (this.GQLClient.cache.data.data[CacheKey].relatedSysUser[itemkey].id === ('SysUser.' + key)) {
                                this.GQLClient.cache.data.data[CacheKey].relatedSysUser.splice(itemkey, 1)
                            }
                        }
                        return true
                    }
                })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async addRoleSysUser(key, OUnitKey, roleKey) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "aggiunta ruolo fallita" })
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'addRoleSysUser', {
                key: 'ID!',
                OUnitKey: 'ID',
                roleKey:  'ID'
            })
            let oegQ = query.addField('sysUser', { key: '$key' })
            oegQ.addField('key')
            oegQ.addFields({
                key: {},
                addRoleInOrganizationalUnit: { args: { OUnitKey: "$OUnitKey", roleKey: "$roleKey" } },
                rolesInOrganizationalUnit: this.sysUserRoleMap
            })
            let response = this.sendServerCommand({ mutation: query, variables: {
                key: key,
                OUnitKey: OUnitKey,
                roleKey:  roleKey
             } })
                .then(response => {
                    if (response === null || !response.sysUser.addRoleInOrganizationalUnit) {
                        return failResponse()
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "Assegnazione ruolo effettuata"
                        })
                        return true
                    }
                })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async resetPasswordSysUser(key) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "La password dell'operatore non è stata reimpostata" })
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'resetPasswordSysUser', {key: 'ID!'})
            let oegQ = query.addField('sysUser', { key: '$key' })
            oegQ.addField('setAuthData',{ method: "&jlogin", action: "&changePwd", data: {random: true}})
            let response = this.sendServerCommand({ mutation: query, variables: {key: key}, fetchPolicy: 'no-cache'})
                .then(response => {
                    if (response === null || !response.sysUser || !response.sysUser.setAuthData) {
                        return failResponse()
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "La password dell'operatore è stata reimpostata"
                        })
                        return true
                    }
                })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async enabDisabSysUser(key, status) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Impossibile modificare lo stato dell'operatore" })
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'enabDisabSysUser', {key: 'ID!', astatus: 'Boolean'})
            let oegQ = query.addField('sysUser', { key: '$key' })
            oegQ.addField('key')
            oegQ.addField('setEnabling',{ astatus: "$astatus"})
            oegQ.addField('status')
            let response = this.sendServerCommand({ mutation: query, variables: {key: key, astatus: status}})
                .then(response => {
                    if (response === null || !response.sysUser || typeof response.sysUser.setEnabling != "boolean") {
                        return failResponse()
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "Stato dell'operatore modificato"
                        })
                        return true
                    }
                })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async getSpaceModels() {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile recuperare l'elenco dei modelli" })
            return []
        }
        try {
            let query
            let addRenewToken = true
            if(typeof this.listQueryes.getSpaceModels !== 'undefined') {
                query = this.listQueryes.getSpaceModels
                addRenewToken = false
            } else {
                query = new JGQLComposer('query', 'getSpaceModels')
                query.addField('getListOfCoDec_spaceModels').addFields(['myNameIs', 'name', 'key', 'description', 'isGrave'])
                this.listQueryes.getSpaceModels = query
            }
            let response = this.sendServerCommand({ query: query }, addRenewToken)
                .then(response => { return (response === null ? failResponse() : response.getListOfCoDec_spaceModels) })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async newSpaceModel(data) {
        let failResponse = (name, error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Il modello " + name + ' non è stato creato' })
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'newSpaceModel', { name: 'ProperName', planimetry: 'InputPolygon', isGrave: "Boolean", allowGraves: "Boolean", allowSpaces: "Boolean", description: "ItalianText", defaultGrantDuration: "Int" })
            let oegQ = query.addField('newCoDec_spaceModel')
            oegQ.addFields({
                name: { args: { set: "$name" } },
                description: { args: { set: "$description" } },
                planimetry: { args: { set: "$planimetry" }, fields: { color: {} } },
                isGrave: { args: { set: "$isGrave" } },
                allowGraves: { args: { set: "$allowGraves" } },
                allowSpaces: { args: { set: "$allowSpaces" } },
                defaultGrantDuration: { args: { set: "$defaultGrantDuration" } },
            })
            oegQ.addField('persists').addInlineFragment('CoDec_spaceModel').addFields(this.spacemodelGetMap)

            let response = this.sendServerCommand({ mutation: query, variables: {
                name: data.name,
                planimetry: this.filterTypename(data.planimetry),
                isGrave: data.isGrave,
                allowGraves: data.allowGraves,
                allowSpaces: data.allowSpaces,
                description: data.description,
                defaultGrantDuration: data.defaultGrantDuration } })
                .then(response => {
                    if (response === null || !response.newCoDec_spaceModel.persists.key) {
                        return failResponse(data.name)
                    } else {
                        if(typeof this.listQueryes.getSpaceModels === "object"){
                            const qData = this.GQLClient.readQuery({ query: gql`${this.listQueryes.getSpaceModels.toString()}` });
                            this.GQLClient.writeQuery({
                                query: gql`${this.listQueryes.getSpaceModels.toString()}`,
                                data: {
                                    ...qData,
                                    getSpaceModels: [
                                        ...qData.getListOfCoDec_spaceModels,
                                        response.newCoDec_spaceModel.persists
                                    ]
                                }
                            })
                        }
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "Il modello " + data.name + ' è stato aggiunto'
                        })
                        return response.newCoDec_spaceModel.persists.key
                    }
                })
                .catch(error => { return failResponse(data.name, error) })
            return response
        } catch (error) { return failResponse(data.name, error) }
    }


    async getSpaceModel(key) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile recuperare i dati del modello" })
            return {}
        }
        try {
            const query = new JGQLComposer('query', 'getSpaceModel', { key: 'ID!' })
            query.addField('coDec_spaceModel', { key: '$key' }).addFields({
                ...this.spacemodelGetMap,
                getCountOf: { args: { target: ["grave", "gravespace"] }, fields: { key: {}, value: {} } } })
            let response = this.sendServerCommand({ query: query, variables: { key: key } })
                .then(response => { return (response === null ? failResponse() : response.coDec_spaceModel) })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async updateSpaceModel(key, data) {
        let failResponse = (name, error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Il modello " + name + ' non è stata aggiornato' })
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'updateSpaceModel', { key: 'ID!', name: 'ProperName', planimetry: 'InputPolygon', isGrave: "Boolean", allowGraves: "Boolean", allowSpaces: "Boolean", description: "ItalianText", defaultGrantDuration: "Int" })
            let oegQ = query.addField('coDec_spaceModel', { key: '$key' })
            oegQ.addFields({
                name: { args: { set: "$name" } },
                description: { args: { set: "$description" } },
                planimetry: { args: { set: "$planimetry" }, fields: { color: {} } },
                isGrave: { args: { set: "$isGrave" } },
                allowGraves: { args: { set: "$allowGraves" } },
                allowSpaces: { args: { set: "$allowSpaces" } },
                defaultGrantDuration: { args: { set: "$defaultGrantDuration" } },
            })
            oegQ.addField('persists').addInlineFragment('CoDec_spaceModel').addFields(this.spacemodelGetMap)

            let response = this.sendServerCommand({ mutation: query, variables: {
                key: key,
                name: data.name,
                planimetry: this.filterTypename(data.planimetry),
                isGrave: data.isGrave,
                allowGraves: data.allowGraves,
                allowSpaces: data.allowSpaces,
                description: data.description,
                defaultGrantDuration: data.defaultGrantDuration } })
                .then(response => {
                    if (response === null || !response.coDec_spaceModel.persists.key) {
                        return failResponse(data.name)
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "Il modello " + data.name + ' è stata aggiornato'
                        })
                        return response.coDec_spaceModel.persists.key
                    }
                })
                .catch(error => { return failResponse(data.name, error) })
            return response
        } catch (error) { return failResponse(data.name, error) }
    }

    async getOrgs() {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile recuperare l'elenco degli enti" })
            return []
        }
        try {
            let query
            let addRenewToken = true
            if(typeof this.listQueryes.getListOfOrganizations !== 'undefined') {
                query = this.listQueryes.getListOfOrganizations
                addRenewToken = false
            } else {
                query = new JGQLComposer('query', 'getOrgs')
                query.addField('getListOfOrganizations')
                .addFields({myNameIs: {}, sys_name: {}, key: {}, mainFullAddress: {}, relatedOrganizationalUnit: { fields: ['key', 'name', 'sys_name'] }})
                this.listQueryes.getListOfOrganizations = query
            }
            let response = this.sendServerCommand({ query: query }, addRenewToken)
                .then(response => { return (response === null ? failResponse() : response.getListOfOrganizations) })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async getOrg(key) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile recuperare i dati dell'ente" })
            return {}
        }
        try {
            const query = new JGQLComposer('query', 'getOrg', { key: 'ID!' })
            query.addField('organization', { key: '$key' }).addFields({
                key: {},
                myNameIs: {},
                name: {},
                sys_name: {},
                mainFullAddress: {},
                mainPhone: {},
                mainEmail: {},
                relatedOrganizationalUnit: { fields: ['key', 'name', 'sys_name'] } })
            let response = this.sendServerCommand({ query: query, variables: { key: key } })
                .then(response => { return (response === null ? failResponse() : response.organization) })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async newOrg(data) {
        let failResponse = (name, error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "L'ente " + name + ' non è stato creato' })
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'newOrg', {
                name: 'ProperName',
                mainFullAddress: 'String',
                mainPhone: 'PhoneNumber',
                mainEmail: 'Email'
            })
            let oegQ = query.addField('newOrganization')
            oegQ.addFields({
                name: { args: { set: "$name" } },
                mainFullAddress: { args: { set: "$mainFullAddress" } },
                mainPhone: { args: { set: "$mainPhone" } },
                mainEmail: { args: { set: "$mainEmail" } },
             })
            oegQ.addField('persists').addInlineFragment('Organization').addFields(['key', 'myNameIs', 'name', 'sys_name', 'mainFullAddress', 'mainPhone', 'mainEmail']);
            let response = this.sendServerCommand({ mutation: query, variables: {
                name: data.name,
                mainFullAddress: data.mainFullAddress,
                mainPhone: data.mainPhone,
                mainEmail: data.mainEmail,
             } })
                .then(response => {
                    if (response === null || !response.newOrganization.persists.key) {
                        return failResponse(data.name)
                    } else {
                        if(typeof this.listQueryes.getListOfOrganizations === "object"){
                            const qData = this.GQLClient.readQuery({ query: gql`${this.listQueryes.getListOfOrganizations.toString()}` });
                            this.GQLClient.writeQuery({
                                query: gql`${this.listQueryes.getListOfOrganizations.toString()}`,
                                data: {
                                    ...qData,
                                    getListOfOrganizations: [
                                        ...qData.getListOfOrganizations,
                                        response.newOrganization.persists
                                    ]
                                }
                            })
                        }
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "L'ente " + data.name + ' è stato aggiunto'
                        })
                        return response.newOrganization.persists.key
                    }
                })
                .catch(error => { return failResponse(data.name, error) })
            return response
        } catch (error) { return failResponse(data.name, error) }
    }

    async deleteOrg(key){
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile eliminare i dati dell'ente" })
            return {}
        }
        try {
            const query = new JGQLComposer('mutation', 'deleteOrg', {
                key: 'ID!'
             })
            let oegQ = query.addField('organization', { key: '$key' })
            oegQ.addFields(['key','name', 'delete'])
            let response = this.sendServerCommand({ mutation: query, variables: {key: key} })
                .then(response => {
                    if (response === null || !response.organization.delete) {
                        return failResponse()
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "L'ente " + response.name + ' è stato eliminato'
                        })
                        const CacheKey = 'Organization.' + key
                        if(typeof this.listQueryes.getListOfOrganizations === "object"){
                            const qData = this.GQLClient.readQuery({ query: gql`${this.listQueryes.getListOfOrganizations.toString()}` });
                            for(var ikey in qData.getListOfOrganizations) {
                                if(qData.getListOfOrganizations[ikey].id === CacheKey){
                                    qData.getListOfOrganizations.splice(ikey,1)
                                    break;
                                }
                            }
                            this.GQLClient.writeQuery({
                                query: gql`${this.listQueryes.getListOfOrganizations.toString()}`,
                                data: {
                                    ...qData
                                }
                            })
                        }
                        this.GQLClient.cache.data.delete(CacheKey)
                        return true
                    }
                })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async updateOrg(key, data) {
        let failResponse = (name, error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "L'ente " + name + ' non è stato aggiornato' })
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'updateOrg', {
                key: 'ID!',
                name: 'ProperName',
                mainFullAddress: 'String',
                mainPhone: 'PhoneNumber',
                mainEmail: 'Email'
             })
            let oegQ = query.addField('organization', { key: '$key' })
            oegQ.addField('key')
            oegQ.addFields({
                name: { args: { set: "$name" } },
                mainFullAddress: { args: { set: "$mainFullAddress" } },
                mainPhone: { args: { set: "$mainPhone" } },
                mainEmail: { args: { set: "$mainEmail" } },
             })
            oegQ.addField('persists').addInlineFragment('Organization').addFields(['key', 'myNameIs', 'name', 'sys_name', 'mainFullAddress', 'mainPhone', 'mainEmail']);
            let response = this.sendServerCommand({ mutation: query, variables: {
                key: key,
                name: data.name,
                mainFullAddress: data.mainFullAddress,
                mainPhone: data.mainPhone,
                mainEmail: data.mainEmail
             } })
                .then(response => {
                    if (response === null || !response.organization.persists.key) {
                        return failResponse(data.name)
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "L'ente " + data.name + ' è stato aggiornato'
                        })
                        return response.organization.persists.key
                    }
                })
                .catch(error => { return failResponse(data.name, error) })
            return response
        } catch (error) { return failResponse(data.name, error) }
    }

    async newOUnit(pKey, data) {
        let failResponse = (name, error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "L'unità organizzativa " + name + ' non è stata creata' })
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'newUnit', {
                orgKey: 'ID!',
                name: 'ProperName',
                isGraveyardOwner: 'Boolean',
                inheritReferences: 'OUnitsInheritReferences',
                mainFullAddress: 'String',
                mainPhone: 'PhoneNumber',
                mainEmail: 'Email',
                directReferent: 'ProperName',
                directPhone: 'PhoneNumber',
                directEmail: 'Email',
            })
            let oegQ = query.addField('organization', { key: '$orgKey' }).addField('newUnit')
            oegQ.addFields({
                name: { args: { set: "$name" } },
                mainFullAddress: { args: { set: "$mainFullAddress" } },
                mainPhone: { args: { set: "$mainPhone" } },
                mainEmail: { args: { set: "$mainEmail" } },
                isGraveyardOwner: { args: { set: "$isGraveyardOwner" } },
                inheritReferences: { args: { set: "$inheritReferences" }, fields: ['mainFullAddress','mainPhone','mainEmail'] },
                directReferent: { args: { set: "$directReferent" } },
                directPhone: { args: { set: "$directPhone" } },
                directEmail: { args: { set: "$directEmail" } },
             })
            oegQ.addField('persists').addInlineFragment('OrganizationalUnit').addFields({
                key: {},
                myNameIs: {},
                name: {},
                sys_name: {},
                isGraveyardOwner: {},
                inheritReferences: {
                    fields: {
                        mainFullAddress: {},
                        mainPhone: {},
                        mainEmail: {}
                    }
                },
                mainFullAddress: {},
                mainPhone: {},
                mainEmail: {},
                directReferent: {},
                directPhone: {},
                directEmail: {}
            });


            let response = this.sendServerCommand({ mutation: query, variables: {
                orgKey: pKey,
                name: data.name,
                mainFullAddress: data.mainFullAddress,
                mainPhone: data.mainPhone,
                mainEmail: data.mainEmail,
                isGraveyardOwner: data.isGraveyardOwner,
                inheritReferences: this.filterTypename(data.inheritReferences),
                directReferent: data.directReferent,
                directPhone: data.directPhone,
                directEmail: data.directEmail
            } })
                .then(response => {
                    if (response === null || !response.organization.newUnit.persists.key) {
                        return failResponse(data.name)
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "L'unità organizzativa " + data.name + " è stata aggiunta all'ente"
                        })
                        this.GQLClient.cache.data.data['Organization.' + pKey].relatedOrganizationalUnit.push({
                            type:"id",
                            id:"OrganizationalUnit." + response.organization.newUnit.persists.key,
                            typename:"OrganizationalUnit"
                        })
                        return response.organization.newUnit.persists.key
                    }

                })
                .catch(error => { return failResponse(data.name, error) })
            return response
        } catch (error) { return failResponse(data.name, error) }
    }

    async getOUnit(orgKey, unitKey) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile recuperare i dati dell'unità organizzativa'" })
            return {}
        }
        try {
            const query = new JGQLComposer('query', 'getOUnit', { orgKey: 'ID!', unitKey: 'ID' })
            query.addField('organization', { key: '$orgKey' }).addFields(['key']).addField('relatedOrganizationalUnit', { params: { conds: { rid: '$unitKey' } } }).addFields({
                key: {},
                myNameIs: {},
                name: {},
                sys_name: {},
                isGraveyardOwner: {},
                inheritReferences: {
                    fields: {
                        mainFullAddress: {},
                        mainPhone: {},
                        mainEmail: {}
                    }
                },
                mainFullAddress: {},
                mainPhone: {},
                mainEmail: {},
                directReferent: {},
                directPhone: {},
                directEmail: {},
                organization: { fields: ['key', 'myNameIs'] }, relatedGraveyard: { fields: ['key', 'name', 'sys_name'] },
                relatedSysUser: { fields: ['key', 'name', 'email', 'status'] } })
            let response = this.sendServerCommand({ query: query, variables: { orgKey: orgKey, unitKey: unitKey } })
                .then(response => {
                    if(response === null || !response.organization.relatedOrganizationalUnit[0]) {
                        return failResponse()
                    } else {

                        return response.organization.relatedOrganizationalUnit[0];
                    }})
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async deleteOUnit(orgKey, unitKey){
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile eliminare i dati dell'unità organizzativa" })
            return {}
        }
        try {
            const query = new JGQLComposer('mutation', 'deleteOUnit', { orgKey: 'ID!', unitKey: 'ID' })
            query.addField('organization', { key: '$orgKey' }).addField('relatedOrganizationalUnit', { params: { conds: { rid: '$unitKey' } } }).addFields(['key','name','delete'])
            let response = this.sendServerCommand({ mutation: query, variables: { orgKey: orgKey, unitKey: unitKey } })
                .then(response => {
                    if (response === null || !response.organization.relatedOrganizationalUnit[0] || !response.organization.relatedOrganizationalUnit[0].delete) {
                        return failResponse()
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "L'unità organizzativa " + response.organization.relatedOrganizationalUnit[0].name + ' è stata eliminata'
                        })
                        const CacheKey = 'OrganizationalUnit.' + unitKey
                        this.GQLClient.cache.data.delete(CacheKey)
                        return true
                    }
                })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async updateOUnit(orgKey, unitKey, data) {
        let failResponse = (name, error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "L'unità organizzativa " + name + ' non è stata aggiornata' })
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'updateUnit', {
                orgKey: 'ID!',
                unitKey: 'ID',
                name: 'ProperName',
                isGraveyardOwner: 'Boolean',
                inheritReferences: 'OUnitsInheritReferences',
                mainFullAddress: 'String',
                mainPhone: 'PhoneNumber',
                mainEmail: 'Email',
                directReferent: 'ProperName',
                directPhone: 'PhoneNumber',
                directEmail: 'Email',
             })
            let oegQ = query.addField('organization', { key: '$orgKey' }).addField('relatedOrganizationalUnit', { params: { conds: { rid: '$unitKey' } } })
            oegQ.addField('key')
            oegQ.addFields({
                name: { args: { set: "$name" } },
                mainFullAddress: { args: { set: "$mainFullAddress" } },
                mainPhone: { args: { set: "$mainPhone" } },
                mainEmail: { args: { set: "$mainEmail" } },
                isGraveyardOwner: { args: { set: "$isGraveyardOwner" } },
                inheritReferences: { args: { set: "$inheritReferences" }, fields: ['mainFullAddress','mainPhone','mainEmail'] },
                directReferent: { args: { set: "$directReferent" } },
                directPhone: { args: { set: "$directPhone" } },
                directEmail: { args: { set: "$directEmail" } },
             })
            oegQ.addField('persists').addInlineFragment('OrganizationalUnit').addFields({
                key: {},
                myNameIs: {},
                name: {},
                sys_name: {},
                inheritReferences: {
                    fields: {
                        mainFullAddress: {},
                        mainPhone: {},
                        mainEmail: {}
                    }
                },
                mainFullAddress: {},
                mainPhone: {},
                mainEmail: {},
                directReferent: {},
                directPhone: {},
                directEmail: {}
            });

            let response = this.sendServerCommand({ mutation: query, variables: {
                orgKey: orgKey,
                unitKey: unitKey,
                name: data.name,
                mainFullAddress: data.mainFullAddress,
                mainPhone: data.mainPhone,
                mainEmail: data.mainEmail,
                isGraveyardOwner: data.isGraveyardOwner,
                inheritReferences: this.filterTypename(data.inheritReferences),
                directReferent: data.directReferent,
                directPhone: data.directPhone,
                directEmail: data.directEmail
             } })
                .then(response => {
                    if (response === null || !response.organization.relatedOrganizationalUnit[0] || !response.organization.relatedOrganizationalUnit[0].persists.key) {
                        return failResponse(data.name)
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "L'unità organizzativa " + data.name + " è stata aggiornata"
                        })
                        return response.organization.relatedOrganizationalUnit[0].persists.key
                    }
                })
                .catch(error => { return failResponse(data.name, error) })
            return response
        } catch (error) { return failResponse(data.name, error) }
    }

    async getGraveyards() {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile recuperare l'elenco dei cimiteri" })
            return []
        }
        try {
            let query
            let addRenewToken = true
            if(typeof this.listQueryes.getListOfGraveyards !== 'undefined') {
                query = this.listQueryes.getListOfGraveyards
                addRenewToken = false
            } else {
                query = new JGQLComposer('query', 'getGraveyards')
                query.addField('getListOfGraveyards')
                .addFields({
                    myNameIs: {},
                    sys_name: {},
                    key: {},
                    fullAddress: {}
                 })
                this.listQueryes.getListOfGraveyards = query
            }
            let response = this.sendServerCommand({ query: query }, addRenewToken)
                .then(response => {
                    return (response === null ? failResponse() : response.getListOfGraveyards) })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async getGraveyard(key) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile recuperare i dati del cimitero" })
            return {}
        }
        try {
            const query = new JGQLComposer('query', 'getGraveyard', { key: 'ID!' })
            query.addField('graveyard', { key: '$key' }).addFields(this.graveyardGetMap)
            let response = this.sendServerCommand({ query: query, variables: { key: key } })
                .then(response => {
                    if(response === null) {
                        failResponse()
                    } else {
                        if(typeof response.graveyard.unnormalizedDiagram === "string") {
                            response.graveyard.unnormalizedDiagram = JSON.parse(response.graveyard.unnormalizedDiagram)
                        }
                        return response.graveyard
                    }
                 })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async getGraveyardUploadMapLink(key, params) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Impossibile predisporre il caricamento dell'immagine" })
            return {}
        }
        try {
            const query = new JGQLComposer('mutation', 'getGraveyardUploadMapLink', { key: 'ID!', name: 'String', type: 'String', size: 'Int' })
            query.addField('graveyard', { key: '$key' }).addField('newUploadBackgroundPlanimetry',{name: "$name", type: "$type", size: "$size"})
            let response = this.sendServerCommand({ mutation: query, variables: {
                key: key,
                name: params.name,
                type: params.type,
                size: params.size
              }, fetchPolicy: 'no-cache' })
                .then(response => {
                    if(response === null || ! response.graveyard || ! response.graveyard.newUploadBackgroundPlanimetry ) {
                        failResponse()
                    } else {
                        return response.graveyard.newUploadBackgroundPlanimetry
                    }
                 })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async getGraveyardMapLink(key) {
        try {
            const query = new JGQLComposer('query', 'getBackgroundPlanimetryImage', { key: 'ID!'})
            query.addField('graveyard', { key: '$key' }).addField('backgroundPlanimetryImage')
            let response = this.sendServerCommand({ query: query, variables: {
                key: key
              }, fetchPolicy: 'no-cache' })
                .then(response => {
                    if(response && response.graveyard && response.graveyard.backgroundPlanimetryImage ) {
                        return response.graveyard.backgroundPlanimetryImage
                    }
                 })
                .catch(() => { return null })
            return response
        } catch (error) { return null }
    }

    async newGraveyard(orgKey, unitKey, data) {
        let failResponse = (name, error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "La scheda del cimitero " + name + ' non è stata creata' })
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'newGraveyard', {
                 orgKey: 'ID!',
                 unitKey: 'ID',
                 name: 'ProperName',
                 fullAddress: 'String',
                 geoPosition: 'InputGeoPoint',
                 opening: 'ItalianText',
                 planimetry: 'InputPolygon',
                 map: 'InputGeomap'
                })
            query.addField('organization', { key: '$orgKey' }).addField('relatedOrganizationalUnit', { params: { conds: { rid: '$unitKey' } } }).addField('newGraveyard').addFields({
                name: { args: { set: "$name" } },
                fullAddress: { args: { set: "$fullAddress" } },
                geoPosition: { args: { set: "$geoPosition" }, fields: { lat: {}, long: {} } },
                opening: { args: { set: "$opening" } },
                planimetry: { args: { set: "$planimetry" }, fields: { color: {} } },
                map: { args: { set: "$map" }, fields: { type: {} } },
                persists: { fields: ['key'] }
            })
            let response = this.sendServerCommand({ mutation: query, variables: {
                orgKey: orgKey,
                unitKey: unitKey,
                name: data.name,
                fullAddress: data.fullAddress,
                geoPosition: this.filterTypename(data.geoPosition),
                opening: data.opening,
                planimetry: this.filterTypename(data.planimetry),
                map: this.filterTypename(data.map)
            } })
                .then(response => {
                    if (response === null ||
                        !response.organization.relatedOrganizationalUnit[0] ||
                        !response.organization.relatedOrganizationalUnit[0].newGraveyard.persists ||
                        !response.organization.relatedOrganizationalUnit[0].newGraveyard.persists.key) {
                        return failResponse(data.name)
                    } else {
                        if(typeof this.listQueryes.getListOfGraveyards === "object"){
                            const qData = this.GQLClient.readQuery({ query: gql`${this.listQueryes.getListOfGraveyards.toString()}` });
                            this.GQLClient.writeQuery({
                                query: gql`${this.listQueryes.getListOfGraveyards.toString()}`,
                                data: {
                                    ...qData,
                                    getListOfGraveyards: [
                                        ...qData.getListOfGraveyards,
                                        response.organization.relatedOrganizationalUnit[0].newGraveyard.persists
                                    ]
                                }
                            })
                        }
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "La scheda del cimitero " + data.name + ' è stata creata'
                        })
                        return response.organization.relatedOrganizationalUnit[0].newGraveyard.persists.key
                    }
                })
                .catch(error => { return failResponse(data.name, error) })
            return response
        } catch (error) { return failResponse(data.name, error) }
    }

    async updateGraveyard(key, data) {
        let failResponse = (name, error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "La scheda del cimitero " + name + ' non è stata aggiornata' })
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'updateGraveyard', {
                key: 'ID!',
                name: 'ProperName',
                fullAddress: 'String',
                geoPosition: 'InputGeoPoint',
                opening: 'ItalianText',
                planimetry: 'InputPolygon',
                map: 'InputGeomap'
             })
             let oegQ = query.addField('graveyard', { key: '$key' })
             oegQ.addFields({
                name: { args: { set: "$name" } },
                planimetry: { args: { set: "$planimetry" }, fields: { color: {} } },
                fullAddress: { args: { set: "$fullAddress" } },
                geoPosition: { args: { set: "$geoPosition" }, fields: { lat: {}, long: {} } },
                opening: { args: { set: "$opening" } },
                map: { args: { set: "$map" }, fields: { type: {} } },
                persists: { fields: ['key'] }
            })
            oegQ.addField('persists').addInlineFragment('Graveyard').addFields(this.graveyardGetMap);
            let response = this.sendServerCommand({ mutation: query, variables: {
                key: key,
                name: data.name,
                fullAddress: data.fullAddress,
                geoPosition: this.filterTypename(data.geoPosition),
                opening: data.opening,
                planimetry: this.filterTypename(data.planimetry),
                map: this.filterTypename(data.map)
            } })
                .then(response => {
                    if (response === null || !response.graveyard.persists.key) {
                        return failResponse(data.name)
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "La scheda del cimitero " + data.name + ' è stata aggiornata'
                        })
                        return response.graveyard.persists.key
                    }
                })
                .catch(error => { return failResponse(data.name, error) })
            return response
        } catch (error) { return failResponse(data.name, error) }
    }

    async updateGraveyardMapDefinition(key, data) {
        let failResponse = (name, error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': 'I parametri della mappa non sono stati aggiornati' })
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'updateGraveyard', {
                key: 'ID!',
                map: 'InputGeomap'
             })
             let oegQ = query.addField('graveyard', { key: '$key' })
             oegQ.addFields({
                map: { args: { set: "$map" }, fields: { type: {} } },
                persists: { fields: ['key'] }
            })
            oegQ.addField('persists').addInlineFragment('Graveyard').addFields(this.graveyardGetMap);
            let response = this.sendServerCommand({ mutation: query, variables: {
                key: key,
                map: this.filterTypename(data.map)
            } })
                .then(response => {
                    if (response === null || !response.graveyard.persists.key) {
                        return failResponse(data.name)
                    } else {
                        return response.graveyard.persists.key
                    }
                })
                .catch(error => { return failResponse(data.name, error) })
            return response
        } catch (error) { return failResponse(data.name, error) }
    }

    async deleteGraveyard(key){
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile eliminare i dati del cimitero" })
            return {}
        }
        try {
            const query = new JGQLComposer('mutation', 'deleteGraveyard', { key: 'ID!'})
            query.addField('graveyard', { key: '$key' }).addFields(['key','name','delete'])
            let response = this.sendServerCommand({ mutation: query, variables: { key: key } })
                .then(response => {
                    if (response === null || !response.graveyard || !response.graveyard.delete) {
                        return failResponse()
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "Il cimitero è stato eliminato"
                        })
                        const CacheKey = 'Graveyard.' + key
                        this.GQLClient.cache.data.delete(CacheKey)
                        return true
                    }
                })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async mergeGraveWith(key, targetKey){
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile unire i sepolcri" })
            return {}
        }
        try {
            const query = new JGQLComposer('mutation', 'mergeGraveWith', { key: 'ID!', targetKey: 'ID!'})
            const startPoint = query.addField('grave', { key: '$key' })
            startPoint.addField('mergeWith',{key: '$targetKey'})
            startPoint.addFields({
                ...this.graveGetMap,
                gravespace: {fields: { key: {}, graveyard: {fields: this.graveyardUnnormalizedDiagramGetMap}, relatedGrave: { fields: { myNameIs: {}, key: {}, name: {}, planimetry: this.planimetryGetMap } }}}
            })
            let response = this.sendServerCommand({ mutation: query, variables: { key: key, targetKey: targetKey } })
                .then(response => {
                    if (response === null || !response.grave || !response.grave.mergeWith) {
                        return failResponse()
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "I sopolcri sono stati uniti"
                        })
                        return response.grave.mergeWith
                    }
                })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async unmergeGraves(key){
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile separare i sepolcri" })
            return {}
        }
        try {
            const query = new JGQLComposer('mutation', 'unmergeGraves', { key: 'ID!'})
            const startPoint = query.addField('grave', { key: '$key' })
            startPoint.addField('unmerge')
            startPoint.addFields({
                ...this.graveGetMap,
                gravespace: {fields: { key: {}, graveyard: {fields: this.graveyardUnnormalizedDiagramGetMap}, relatedGrave: { fields: { myNameIs: {}, key: {}, name: {}, planimetry: this.planimetryGetMap } }}}
            })
            let response = this.sendServerCommand({ mutation: query, variables: { key: key } })
                .then(response => {
                    if (response === null || !response.grave || !response.grave.unmerge) {
                        return failResponse()
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "I sopolcri sono stati separati"
                        })
                        return response.grave.unmerge
                    }
                })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async newGraveyardSpace(pGYardKey, pGYSpaceKey, data) {
        let failResponse = (name, error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "L'area cimiteriale " + name + ' non è stata aggiunta' })
            return null
        }
        const parentName = (pGYardKey ? 'graveyard' : 'gravespace')
        const getPersisted = {
            ...this.graveyardSpaceGetMap
        }
        getPersisted[parentName] = { fields: { key: {}, relatedGravespace: { fields: { myNameIs: {}, key: {}, name: {}, planimetry: this.planimetryGetMap } } } }
        try {
            const query = new JGQLComposer('mutation', 'newGraveyardSpace', {
                pKey: 'ID!',
                name: 'ProperName',
                planimetry: 'InputPolygon',
                level: 'Int',
                spaceModel: 'ID'
            })
            let oegQ = query.addField(parentName, { key: '$pKey' }).addField('newGravespace')
            oegQ.addFields({
                spaceModel: { args: { set: "$spaceModel" }, fields: { name: {}, key: {}, allowSpaces: {}, allowGraves: {} } },
                name: { args: { set: "$name" } },
                planimetry: { args: { set: "$planimetry" }, fields: { color: {} } },
                level: { args: { set: "$level" } }
            })
            const persistedData = oegQ.addField('persists').addInlineFragment('Gravespace')
            persistedData.addFields(getPersisted);
            persistedData.addField('graveyard').addFields(this.graveyardUnnormalizedDiagramGetMap)      //get updated graveyard unnoramilzed map

            let response = this.sendServerCommand({ mutation: query, variables: {
                pKey: (pGYardKey ? pGYardKey : pGYSpaceKey),
                name: data.name,
                planimetry: this.filterTypename(data.planimetry),
                level: data.level,
                spaceModel: data.spaceModel.key
             } })
                .then(response => {
                    if (response === null || !response[parentName].newGravespace.persists || !response[parentName].newGravespace.persists.key) {
                        return failResponse(data.name)
                    } else {
                        if(process.env.NODE_ENV !== 'production') {
                            console.log(response)
                        }
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "L'area cimiteriale " + data.name + " è stata aggiunta"
                        })
                        return response[parentName].newGravespace.persists.key
                    }
                })
                .catch(error => { return failResponse(data.name, error) })
            return response
        } catch (error) { return failResponse(data.name, error) }
    }

    async getGraveyardSpace(key) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile recuperare i dati dell'area cimiteriale" })
            return {}
        }
        try {
            const query = new JGQLComposer('query', 'getGraveSpace', { key: 'ID!' })
            query.addField('gravespace', { key: '$key' }).addFields({
                ...this.graveyardSpaceGetMap,
                gravespace: { fields: { myNameIs: {}, key: {} } },
                graveyard: { fields: { myNameIs: {}, key: {}, organizationalUnit: { fields: { myNameIs: {}, key: {}, organization: { fields: { myNameIs: {}, key: {} } } } } } },
                relatedGravespace: { fields: { myNameIs: {}, key: {}, name: {}, planimetry: this.planimetryGetMap } },
            })
            let response = this.sendServerCommand({ query: query, variables: { key: key } })
                .then(response => { return (response === null ? failResponse() : response.gravespace) })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async updateGraveyardSpace(key, data) {
        let failResponse = (name, error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "La scheda dell'area cimiteriale " + name + ' non è stata aggiornata' })
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'updateGraveyardSpace', {
                key: 'ID!',
                name: 'ProperName',
                planimetry: 'InputPolygon',
                level: 'Int',
                spaceModel: 'ID'
            })
            let oegQ = query.addField('gravespace', { key: '$key' })
            oegQ.addFields({
                spaceModel: { args: { set: "$spaceModel" }, fields: { name: {}, key: {}, allowSpaces: {}, allowGraves: {} } },
                name: { args: { set: "$name" } },
                planimetry: { args: { set: "$planimetry" }, fields: { color: {} } },
                level: { args: { set: "$level" } }
            })
            const persistedData = oegQ.addField('persists').addInlineFragment('Gravespace')
            persistedData.addFields(this.graveyardSpaceGetMap);
            persistedData.addField('graveyard').addFields(this.graveyardUnnormalizedDiagramGetMap)
            let response = this.sendServerCommand({ mutation: query, variables: {
                key: key,
                name: data.name,
                planimetry: this.filterTypename(data.planimetry),
                level: data.level,
                spaceModel: data.spaceModel.key } })
                .then(response => {
                    if (response === null || !response.gravespace.persists.key) {
                        return failResponse(data.name)
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "La scheda dell'area cimiteriale " + data.name + ' è stata aggiornata'
                        })
                        return response.gravespace.persists.key
                    }
                })
                .catch(error => { return failResponse(data.name, error) })
            return response
        } catch (error) { return failResponse(data.name, error) }
    }

    async deleteGraveyardSpace(key){
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile eliminare i dati dell'area cimiteriale" })
            return {}
        }
        try {
            const query = new JGQLComposer('mutation', 'deleteGraveyardSpace', { key: 'ID!'})
            const persistedData = query.addField('gravespace', { key: '$key' })
            persistedData.addFields(['key','name','delete'])
            persistedData.addField('graveyard').addFields(this.graveyardUnnormalizedDiagramGetMap)

            let response = this.sendServerCommand({ mutation: query, variables: { key: key } })
                .then(response => {
                    if (response === null || !response.gravespace || !response.gravespace.delete) {
                        return failResponse()
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "L'area cimiteriale è stata eliminata"
                        })
                        const CacheKey = 'Gravespace.' + key
                        this.GQLClient.cache.data.delete(CacheKey)
                        return true
                    }
                })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async newGrave(pGYSpaceKey, data) {
        let failResponse = (name, error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Il sepolcro " + name + ' non è stata aggiunto' })
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'newGrave', {
                pKey: 'ID!',
                name: 'ProperName',
                planimetry: 'InputPolygon',
                spaceModel: 'ID'
            })
            let oegQ = query.addField('gravespace', { key: '$pKey' }).addField('newGrave')
            oegQ.addFields({
                spaceModel: { args: { set: "$spaceModel" }, fields: { key: {}, name: {} } },
                name: { args: { set: "$name" } },
                planimetry: { args: { set: "$planimetry" }, fields: { color: {} } },
            })
            oegQ.addField('persists').addInlineFragment('Grave').addFields({
                ...this.graveGetMap,
                gravespace: {fields: { key: {}, graveyard: {fields: this.graveyardUnnormalizedDiagramGetMap}, relatedGrave: { fields: { myNameIs: {}, key: {}, name: {}, planimetry: this.planimetryGetMap } }}}
            });
            let response = this.sendServerCommand({ mutation: query, variables: {
                pKey: pGYSpaceKey,
                name: data.name,
                planimetry: this.filterTypename(data.planimetry),
                spaceModel: data.spaceModel.key
            } })
                .then(response => {
                    if (response === null || !response.gravespace.newGrave.persists || !response.gravespace.newGrave.persists.key) {
                        return failResponse(data.name)
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "Il sepolcro " + data.name + ' è stata aggiunto'
                        })
                        return response.gravespace.newGrave.persists.key
                    }
                })
                .catch(error => { return failResponse(data.name, error) })
            return response
        } catch (error) { return failResponse(data.name, error) }
    }

    async getGrave(key) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile recuperare i dati del sepolcro" })
            return {}
        }
        try {
            const query = new JGQLComposer('query', 'getGrave', { key: 'ID!' })
            query.addField('grave', { key: '$key' }).addFields(this.graveGetMap)
            let response = this.sendServerCommand({ query: query, variables: { key: key } })
                .then(response => { return (response === null ? failResponse() : response.grave) })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async updateGrave(key, data) {
        let failResponse = (name, error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "La scheda del sepolcro " + name + ' non è stata aggiornata' })
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'updateGrave', {
                key: 'ID!',
                name: 'ProperName',
                planimetry: 'InputPolygon',
                spaceModel: 'ID',
                burialLimit: 'Int'
            })
            let oegQ = query.addField('grave', { key: '$key' })
            oegQ.addFields({
                spaceModel: { args: { set: "$spaceModel" }, fields: { key: {}, name: {} } },
                name: { args: { set: "$name" } },
                planimetry: { args: { set: "$planimetry" }, fields: { color: {} } },
                burialLimit: { args: { set: "$burialLimit" } },
            })
            oegQ.addField('persists').addInlineFragment('Grave').addFields({
                ...this.graveGetMap,
                gravespace: {fields: { key: {}, graveyard: {fields: this.graveyardUnnormalizedDiagramGetMap}, relatedGrave: { fields: { myNameIs: {}, key: {}, name: {}, planimetry: this.planimetryGetMap } }}}
            })

            let response = this.sendServerCommand({ mutation: query, variables: {
                key: key,
                name: data.name,
                planimetry: this.filterTypename(data.planimetry),
                spaceModel: data.spaceModel.key,
                burialLimit: data.burialLimit
            } })
                .then(response => {
                    if (response === null || !response.grave || !response.grave.persists || !response.grave.persists.key) {
                        return failResponse(data.name)
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "La scheda del sepolcro " + data.name + ' è stata aggiornata'
                        })
                        return response.grave.persists.key
                    }
                })
                .catch(error => { return failResponse(data.name, error) })
            return response
        } catch (error) { return failResponse(data.name, error) }
    }

    async deleteGrave(key){
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile eliminare i dati del sepolcro" })
            return {}
        }
        try {
            const query = new JGQLComposer('mutation', 'deleteGrave', { key: 'ID!'})
            const persistedData = query.addField('grave', { key: '$key' })
            persistedData.addFields(['key','name','delete'])
            persistedData.addFields({gravespace: {fields: { key: {}, graveyard: {fields: this.graveyardUnnormalizedDiagramGetMap}}}})

            let response = this.sendServerCommand({ mutation: query, variables: { key: key } })
                .then(response => {
                    if (response === null || !response.grave || !response.grave.delete) {
                        return failResponse()
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "Il sepolcro è stato eliminato"
                        })
                        const CacheKey = 'Grave.' + key
                        this.GQLClient.cache.data.delete(CacheKey)
                        return true
                    }
                })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async getBurials(params = {}, getCount = true, start = 0, offset = 20) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile recuperare l'elenco di sepolutre o prenotazioni" })
            return []
        }
        try {
            const query = new JGQLComposer('query', 'getBurials', {params: 'GetListParametersOfBurial'})
            query.addField('getListOfBurials', {start: start, offset: offset, params: '$params'}).addFields(this.burialGetMap)
            if(getCount) {
                query.addField('me').addField('getCount',{entity: 'lastListTotalCount'})
            }
            let response = this.sendServerCommand({ query: query, variables: {params: params}, fetchPolicy: 'no-cache'}, true)
                .then(response => { return (response === null ? failResponse() : getCount ? {list: response.getListOfBurials, count: response.me.getCount} : response.getListOfBurials) })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async getBurial(key,params = {noCache: false}) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile recuperare i dati della sepoltura" })
            return {}
        }
        try {
            const query = new JGQLComposer('query', 'getBurial', { key: 'ID!' })
            query.addField('burial', { key: '$key' }).addFields({
                key: {},
                isReservation: {},
                burial_date: {},
                planned_exhumation_date: {},
                actual_exhumation_date: {},
                deceased: { fields: ['myNameIs', 'key'] },
                relatedReferent: { fields: { key: {}, name: {}, surname: {} } },
                relatedAttachedFile: { fields: { key: {}, name: {}, metadata: {} } },
                grave: { fields: { key: {}, myNameIs: {}, location: {} } }
            })
            let response = this.sendServerCommand({ query: query, variables: { key: key } }, true, params.noCache)
                .then(response => { return (response === null ? failResponse() : response.burial) })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async updateBurial(key, data) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "La scheda della sepoltura non è stata aggiornata" })
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'updateBurial', {
                key: 'ID!',
                burialDate: 'Date'
            })
            let oegQ = query.addField('burial', { key: '$key' })
            oegQ.addField('burial_date', { set: "$burialDate" })
            oegQ.addField('persists').addInlineFragment('Burial').addFields({
                ...this.burialGetMap,
            })

            let response = this.sendServerCommand({ mutation: query, variables: {
                key: key,
                burialDate: data.burial_date
            } })
                .then(response => {
                    if (response === null || !response.burial || !response.burial.persists || !response.burial.persists.key) {
                        return failResponse()
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "La scheda della sepoltura è stata aggiornata"
                        })
                        return response.burial.persists.key
                    }
                })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async getBurialUploadLink(key, params) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Impossibile predisporre il caricamento del file" })
            return {}
        }
        try {
            const query = new JGQLComposer('mutation', 'getBurialUploadLink', { key: 'ID!', name: 'String'})
            query.addField('burial', { key: '$key' }).addField('generateURIToUploadNewAttachedFile',{name: "$name"})
            let response = this.sendServerCommand({ mutation: query, variables: {
                key: key,
                name: params.name,
              }, fetchPolicy: 'no-cache' })
                .then(response => {
                    if(response === null || ! response.burial || ! response.burial.generateURIToUploadNewAttachedFile ) {
                        failResponse()
                    } else {
                        return response.burial.generateURIToUploadNewAttachedFile
                    }
                 })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async burialUnattchFile(key, fileKey) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Impossibile rimuovere l'allegato" })
            return {}
        }
        try {
            const query = new JGQLComposer('mutation', 'burialUnattchFile', { key: 'ID!', fileKey: 'ID!'})
            query.addField('burial', { key: '$key' }).addField('unattachFile',{key: "$fileKey"})
            let response = this.sendServerCommand({ mutation: query, variables: {
                key: key,
                fileKey: fileKey
              }, fetchPolicy: 'no-cache' })
                .then(response => {
                    if(response === null || ! response.burial || ! response.burial.unattachFile ) {
                        failResponse()
                    } else {
                        return response.burial.unattachFile
                    }
                 })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async newBurial(destGraveKey, data, retVals = []) {
        let failResponse = (name, error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': (name ? "La sepoltura di  " + name : 'La prenotazione della sepoltura') + ' non è stata registrata' })
            return retVals
        }
        try {
            const subjProms = []
            if (!data.isReservation) {
                if(typeof data.deceased.key === "string" && data.deceased.key !== null){
                    subjProms.push(this.updateDeceased(data.deceased.key, data.deceased))
                } else {
                    subjProms.push(this.newDeceased(data.deceased))
                }
            }
            if (Array.isArray(data.referents)) {
                for (let referent of data.referents) {
                    if (referent.key) {
                        subjProms.push(this.updateReferent(referent.key, referent))
                    } else {
                        subjProms.push(this.newReferent(referent))
                    }
                }
            }
            let response = Promise.all(subjProms)
                .then(pVals => {
                    const varNames = { pKey: 'ID!', burialDate: 'Date', plannedExhumationDate: 'Date' }
                    const varVals = { pKey: destGraveKey, burialDate: data.burial_date, plannedExhumationDate: data.planned_exhumation_date }
                    const retVals = {
                        decesedKey: null,
                        referentsKeys: [],
                        burialKey: null
                    }
                    let burialFields = {
                        key: {}
                    }
                    let d = 0
                    let cRef = 0;
                    let faild = false
                    if(!data.isReservation) {
                        if(pVals[0]) {
                            d = 1
                            varNames.deceasedID = 'ID'
                            varVals.deceasedID = pVals[0]
                            burialFields.assignDeceased = { args: { deceased: "$deceasedID" }, fields: ['myNameIs'] }
                            retVals.decesedKey = pVals[0]
                        } else {
                            this.notifyMsgToGui({ 'kind': 'error', 'text': "Operazione annullata siccome manca la persona defunta per l'inumazione" })
                            faild = true
                        }
                    }
                    if (Array.isArray(data.referents)) {
                        for (let referentPos in data.referents) {
                            if(pVals[parseInt(referentPos, 10) + d]){
                                cRef ++
                                varNames['referentID' + referentPos] = 'ID'
                                varVals['referentID' + referentPos] = pVals[parseInt(referentPos, 10) + d]
                                retVals.referentsKeys[referentPos] = pVals[parseInt(referentPos, 10) + d]
                            }
                        }
                    }
                    if(cRef === 0) {
                        this.notifyMsgToGui({ 'kind': 'error', 'text': "Operazione annullata siccome non ci sono referenti disponibili" })
                        faild = true

                    }
                    if(faild){
                        return retVals
                    }

                    const query = new JGQLComposer('mutation', 'newBurial', varNames)
                    const oegQ = query.addField('grave', { key: '$pKey' }).addField('newBurial')
                    oegQ.addField('burial_date', { set: "$burialDate" })
                    oegQ.addField('planned_exhumation_date', { set: "$plannedExhumationDate" })
                    const persBurial = oegQ.addField('persists').addInlineFragment('Burial').addFields(burialFields)

                    if (Array.isArray(data.referents)) {
                        for (let referentPos in data.referents) {
                            persBurial.addField('addReferent', { referent: "$referentID" + referentPos }, 'ref' + referentPos).addFields(['key','myNameIs'])
                        }
                    }
                    persBurial.addField('grave').addFields({key:{}, canAcceptNewBurial: {}, gravespace: {fields: {key: {}, graveyard: {fields: this.graveyardUnnormalizedDiagramGetMap}}}, relatedBurial: {args: { params: { conds: { current: true } } }, fields: {...this.burialGetMap }}})
                    let response = this.sendServerCommand({ mutation: query, variables: varVals })
                        .then(response => {
                            if (response === null || !response.grave || !response.grave.newBurial || !response.grave.newBurial.persists || !response.grave.newBurial.persists.key) {
                                return failResponse((data.isReservation ? null : data.deceased.name + '  ' + data.deceased.surname))
                            } else {
                                this.notifyMsgToGui({
                                    'kind': 'success',
                                    'text': (data.isReservation ? 'La prenotazione della sepoltura' : "La sepoltura di  " + data.deceased.name + '  ' + data.deceased.surname) + ' è stata registrata'
                                })
                                retVals.burialKey = response.grave.newBurial.persists.key
                                return retVals
                            }
                        })
                        .catch(error => { return failResponse(data.deceased.name + '  ' + data.deceased.surname, error, retVals) })
                    return response
                })
                .catch(error => { return failResponse(data.deceased.name + '  ' + data.deceased.surname, error) })
            return response
        } catch (error) { return failResponse(data.deceased.name, error) }
    }

    async getDeceaseds(conds = {}, params = {}) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile recuperare l'elenco delle persone defunte" })
            return []
        }
        try {
            let query
            let addRenewToken = true
            let varDeclare = {}
            let args = {params: {conds:{}}}
            let vars = {}
            if(conds.rid){
                varDeclare = {...varDeclare, rid: 'ID'}
                args = {...args, params: {...args.params, conds: {...args.params.conds, rid: '$rid'}}}
                vars = {...vars, rid: conds.rid}
            }
            if(conds.notRid){
                varDeclare = {...varDeclare, notRid: 'ID'}
                args = {...args, params: {...args.params, conds: {...args.params.conds, notRid: '$notRid'}}}
                vars = {...vars, notRid: conds.notRid}
            }
            if(conds.name){
                varDeclare = {...varDeclare, name: 'ProperName'}
                args = {...args, params: {...args.params, conds: {...args.params.conds, name: '$name'}}}
                vars = {...vars, name: conds.name}
            }
            if(conds.surname){
                varDeclare = {...varDeclare, surname: 'ProperName'}
                args = {...args, params: {...args.params, conds: {...args.params.conds, surname: '$surname'}}}
                vars = {...vars, surname: conds.surname}
            }
            if(conds.birthday){
                varDeclare = {...varDeclare, birthday: 'Date'}
                args = {...args, params: {...args.params, conds: {...args.params.conds, birthday: '$birthday'}}}
                vars = {...vars, birthday: conds.birthday}
            }
            if(conds.deathdate){
                varDeclare = {...varDeclare, deathdate: 'Date'}
                args = {...args, params: {...args.params, conds: {...args.params.conds, deathdate: '$deathdate'}}}
                vars = {...vars, deathdate: conds.deathdate}
            }
            query = new JGQLComposer('query', 'getDeceaseds', varDeclare)
            const fldQry = query.addField('getListOfDeceaseds', args).addFields(this.deceasedGetMap)

            if(params.relatedBurial){
                fldQry.addField('relatedBurial', { params: { conds: { current: true } } })
                .addFields({ key: {}, foreignGraveyard: {},	noDestGrave: {}, grave: { fields: { key: {}, myNameIs: {}, location: {}, gravespace: {fields: {key: {}, myNameIs: {}, graveyard: {fields: { key: {}, myNameIs: {} } } } } } } } )
            }

            let response = this.sendServerCommand({ query: query, variables: vars, fetchPolicy: 'no-cache'}, addRenewToken)
                .then(response => { return (response === null ? failResponse() : response.getListOfDeceaseds) })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async newDeceased(data) {
        let failResponse = (name, error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "La scheda della persona deceduta " + name + ' non è stata creata' })
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'newDeceased', {
                name: 'ProperName',
                nameUnknown: 'Boolean',
                surname: 'ProperName',
                surnameUnknown: 'Boolean',
                birthday: 'Date',
                birthdayMask: 'DateKnowledgeMask',
                deathdate: 'Date',
                deathdateMask: 'DateKnowledgeMask'
            })
            const oegQ = query.addField('newDeceased')
            oegQ.addFields({
                name: { args: { set: "$name" } },
                nameUnknown: { args: { set: "$nameUnknown" } },
                surname: { args: { set: "$surname" } },
                surnameUnknown: { args: { set: "$surnameUnknown" } },
                birthday: { args: { set: "$birthday" } },
                deathdate: { args: { set: "$deathdate" } },
                birthdayMask: { args: { set: "$birthdayMask" } },
                deathdateMask: { args: { set: "$deathdateMask" } }

            })
            oegQ.addField('persists').addInlineFragment('Deceased').addFields(this.deceasedGetMap)

            let response = this.sendServerCommand({ mutation: query, variables: {
                name: data.name,
                nameUnknown: data.nameUnknown,
                surname: data.surname,
                surnameUnknown: data.surnameUnknown,
                birthday: data.birthday.match(/^\d{4}$/)?data.birthday + '-01-01' : data.birthday,
                deathdate: data.deathdate.match(/^\d{4}$/)?data.deathdate + '-01-01' : data.deathdate,
                birthdayMask: data.birthdayMask,
                deathdateMask: data.deathdateMask
            } })
                .then(response => {
                    if (response === null || !response.newDeceased.persists.key) {
                        return failResponse(data.name + ' ' + data.surname)
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "La scheda della persona deceduta " + data.name + ' ' + data.surname + ' è stata creata'
                        })
                        return response.newDeceased.persists.key
                    }
                })
                .catch(error => { return failResponse(data.name, error) })
            return response
        } catch (error) { return failResponse(data.name, error) }
    }

    async getDeceased(key) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "La scheda della persona deceduta non è stata recuperata" })
            return null
        }
        try {
            const query = new JGQLComposer('query', 'getDeceased', {key: 'ID!'})
            const oegQ = query.addField('deceased', { key: '$key' })
            oegQ.addFields(this.deceasedGetMap)
            let response = this.sendServerCommand({ query: query, variables: { key: key} })
                .then(response => {
                    if (response === null || !response.deceased.key) {
                        return failResponse()
                    } else {
                        return response.deceased
                    }
                })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async updateDeceased(key, data) {
        let failResponse = (name, error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "La scheda della persona deceduta " + name + ' non è stata aggiornata' })
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'updateDeceased', {
                key: 'ID!',
                name: 'ProperName',
                nameUnknown: 'Boolean',
                surname: 'ProperName',
                surnameUnknown: 'Boolean',
                birthday: 'Date',
                birthdayMask: 'DateKnowledgeMask',
                deathdate: 'Date',
                deathdateMask: 'DateKnowledgeMask'
            })
            const oegQ = query.addField('deceased', { key: '$key' })
            oegQ.addFields({
                name: { args: { set: "$name" } },
                nameUnknown: { args: { set: "$nameUnknown" } },
                surname: { args: { set: "$surname" } },
                surnameUnknown: { args: { set: "$surnameUnknown" } },
                birthdayMask: { args: { set: "$birthdayMask" } },
                deathdateMask: { args: { set: "$deathdateMask" } },
                birthday: { args: { set: "$birthday" } },
                deathdate: { args: { set: "$deathdate" } },
            })
            oegQ.addField('persists').addInlineFragment('Deceased').addFields(this.deceasedGetMap)
            let response = this.sendServerCommand({ mutation: query, variables: {
                key: key,
                name: data.name,
                nameUnknown: data.nameUnknown,
                surname: data.surname,
                surnameUnknown: data.surnameUnknown,
                birthday: data.birthday,
                deathdate: data.deathdate,
                birthdayMask: data.birthdayMask,
                deathdateMask: data.deathdateMask
            } })
                .then(response => {
                    if (response === null || !response.deceased.persists.key) {
                        return failResponse(data.name + ' ' + data.surname)
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "La scheda della persona deceduta " + data.name + ' ' + data.surname + ' è stata aggiornata'
                        })
                        return response.deceased.persists.key
                    }
                })
                .catch(error => { return failResponse(data.name, error) })
            return response
        } catch (error) { return failResponse(data.name, error) }
    }

    async getReferents(conds = {}) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile recuperare l'elenco delle persone referenti" })
            return []
        }
        try {
            let query
            let addRenewToken = true
            let varDeclare = {}
            let args = {params: {conds:{}}}
            let vars = {}
            if(conds.rid){
                varDeclare = {...varDeclare, rid: 'ID'}
                args = {...args, params: {...args.params, conds: {...args.params.conds, rid: '$rid'}}}
                vars = {...vars, rid: conds.rid}
            }
            if(conds.notRid){
                varDeclare = {...varDeclare, notRid: 'ID'}
                args = {...args, params: {...args.params, conds: {...args.params.conds, notRid: '$notRid'}}}
                vars = {...vars, notRid: conds.notRid}
            }
            if(conds.tax_code){
                varDeclare = {...varDeclare, tax_code: 'PersonTaxCode'}
                args = {...args, params: {...args.params, conds: {...args.params.conds, tax_code: '$tax_code'}}}
                vars = {...vars, tax_code: conds.tax_code}
            }
            query = new JGQLComposer('query', 'getReferents', varDeclare)
            query.addField('getListOfReferents', args).addFields(this.referentGetMap)

            let response = this.sendServerCommand({ query: query, variables: vars, fetchPolicy: 'no-cache'}, addRenewToken)
                .then(response => { return (response === null ? failResponse() : response.getListOfReferents) })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async newReferent(data) {
        let failResponse = (name, error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "La scheda del referente " + name + ' non è stata creata' })
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'newReferent', {
                legalPerson: 'Boolean',
                tax_code: 'PersonTaxCode',
                name: 'ProperName',
                surname: 'ProperName',
                phone: 'PhoneNumber',
                email: 'Email',
                fullAddress: 'String',
             })
            const oegQ = query.addField('newReferent')
            oegQ.addFields({
                legalPerson: { args: { set: "$legalPerson"} },
                tax_code: { args: { set: "$tax_code" } },
                name: { args: { set: "$name" } },
                surname: { args: { set: "$surname" } },
                phone: { args: { set: "$phone" } },
                email: { args: { set: "$email" } },
                fullAddress: { args: { set: "$fullAddress" } },
            })
            oegQ.addField('persists').addInlineFragment('Referent').addFields(this.referentGetMap)
            let response = this.sendServerCommand({ mutation: query, variables: {
                legalPerson: data.legalPerson,
                tax_code: data.tax_code,
                name: data.name,
                surname: data.surname,
                phone: data.phone,
                email: data.email,
                fullAddress: data.fullAddress
            } })
                .then(response => {
                    if (response === null || !response.newReferent.persists || !response.newReferent.persists.key) {
                        return failResponse(data.name + ' ' + data.surname)
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "La scheda del referente " + data.name + ' ' + data.surname + ' è stata creata'
                        })
                        return response.newReferent.persists.key
                    }
                })
                .catch(error => { return failResponse(data.name, error) })
            return response
        } catch (error) { return failResponse(data.name, error) }
    }

    async getReferent(key) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "La scheda del referente non è stata recuperata" })
            return null
        }
        try {
            const query = new JGQLComposer('query', 'getReferent', {key: 'ID!'})
            const oegQ = query.addField('referent', { key: '$key' })
            oegQ.addFields(this.referentGetMap)
            let response = this.sendServerCommand({ query: query, variables: {key: key} })
                .then(response => {
                    if (response === null || !response.referent || !response.referent.key) {
                        return failResponse()
                    } else {
                        return response.referent
                    }
                })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async updateReferent(key, data) {
        let failResponse = (name, error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "La scheda del referente " + name + ' non è stata aggiornata' })
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'updateReferent', {
                key: 'ID!',
                tax_code: 'PersonTaxCode',
                name: 'ProperName',
                surname: 'ProperName',
                phone: 'PhoneNumber',
                email: 'Email',
                fullAddress: 'String',
             })
            const oegQ = query.addField('referent', { key: '$key' })
            oegQ.addFields({
                tax_code: { args: { set: "$tax_code" } },
                name: { args: { set: "$name" } },
                surname: { args: { set: "$surname" } },
                phone: { args: { set: "$phone" } },
                email: { args: { set: "$email" } },
                fullAddress: { args: { set: "$fullAddress" } },
            })
            oegQ.addField('persists').addInlineFragment('Referent').addFields(this.referentGetMap)
            let response = this.sendServerCommand({ mutation: query, variables: {
                key: key,
                tax_code: data.tax_code,
                name: data.name,
                surname: data.surname,
                phone: data.phone,
                email: data.email,
                fullAddress: data.fullAddress
            } })
                .then(response => {
                    if (response === null || !response.referent.persists || !response.referent.persists.key) {
                        return failResponse(data.name + ' ' + data.surname)
                    } else {
                        this.notifyMsgToGui({
                            'kind': 'success',
                            'text': "La scheda del referente " + data.name + ' ' + data.surname + ' è stata aggiornata'
                        })
                        return response.referent.persists.key
                    }
                })
                .catch(error => { return failResponse(data.name, error) })
            return response
        } catch (error) { return failResponse(data.name, error) }
    }

    async addReferentToBurial(burialKey, referentData) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Il referente non è stato associato alla sepoltura" })
            return false
        }
        try {
            const subjProms = []
            subjProms.push(referentData.key ? referentData.key : this.newReferent(referentData))
            let response = Promise.all(subjProms)
                .then(pVals => {
                    const query = new JGQLComposer('mutation', 'addReferentToBurial', { burialKey: 'ID!', referentID: 'ID' })
                    query.addField('burial', { key: '$burialKey' })
                    .addFields({
                        key: {},
                        addReferent: {args: {referent: "$referentID"}, fields: this.referentGetMap},
                        relatedReferent: {fields: this.referentGetMap}
                    })
                    let response = this.sendServerCommand({ mutation: query, variables: {
                        burialKey: burialKey,
                        referentID: pVals[0]
                    } })
                        .then(response => {
                            if (response === null || !response.burial.addReferent || !response.burial.addReferent.key) {
                                return failResponse()
                            } else {
                                this.notifyMsgToGui({
                                    'kind': 'success',
                                    'text': "Il referente è stato associato alla sepoltura"
                                })
                                return true
                            }
                        })
                        return response
                })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async updateExtRefBurial(burialKey, refData) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Il referente non è stato aggiornare i riferimenti esterni" })
            return false
        }
        try {
            const query = new JGQLComposer('mutation', 'updateExtRefBurial', { burialKey: 'ID!', extRef: 'Json' })
            const burial = query.addField('burial', { key: '$burialKey' })
            burial.addFields({
                key: {},
                external_references: {args: {set: '$extRef'}}
            })
            burial.addField('persists').addInlineFragment('Burial').addFields(this.burialGetMap)
            return this.sendServerCommand({ mutation: query, variables: {
                burialKey: burialKey,
                extRef: JSON.stringify(Array.isArray(refData)?refData.map(item => {return {label: item.label, id: item.id, notes: item.notes}}):null)
            } })
                .then(response => { return (response === null || !response.burial.persists || !response.burial.persists.key ? failResponse() : true) })
                .catch(error => { return failResponse(error) })
        } catch (error) { return failResponse(error) }
    }

    async removeReferentFromBurial(burialKey, referentKey) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile rimuovere il referente" })
            return false
        }
        try {
            const query = new JGQLComposer('mutation', 'removeReferentFromBurial', { burialKey: 'ID!', referentKey: 'ID' })
            query.addField('burial', { key: '$burialKey' })
            .addFields({
                key: {},
                removeReferent: {args: {key: "$referentKey"}},
                relatedReferent: {fields: this.referentGetMap}
            })
            let response = this.sendServerCommand({ mutation: query, variables: {
                burialKey: burialKey,
                referentKey: referentKey }
            })
                .then(response => { return (response === null ? failResponse() : response.burial.removeReferent) })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async recordExhumation(burialKey, data) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile registrare l'esumazione" })
            return false
        }
        try {
            const burMap = {
                ...this.burialGetMap,
                grave: {fields: {key:{}, canAcceptNewBurial: {}, gravespace: {fields: {key: {}, graveyard: {fields: this.graveyardUnnormalizedDiagramGetMap}}}, relatedBurial: {args: { params: { conds: { current: true } } }, fields: {...this.burialGetMap }}}}
            }
            const query = new JGQLComposer('mutation', 'recordExhumation', { burialKey: 'ID!', exhumationData: 'InputExhumation' })
            const burObj = query.addField('burial', { key: '$burialKey' })
            burObj.addField('exhume', { exhumationData: "$exhumationData" }).addFields({ success: {}, destBurial: { fields: burMap } })
            burObj.addFields(burMap)
            let response = this.sendServerCommand({ mutation: query, variables: { burialKey: burialKey, exhumationData: data } })
                .then(response => {
                    if(process.env.NODE_ENV !== 'production') {
                        console.log(response)
                    }
                    return (response === null || !response.burial || !response.burial.exhume ? failResponse() : response.burial.exhume) })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async planExhumation(burialKey, data) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile registrare la pianificazione" })
            return false
        }
        try {
            const query = new JGQLComposer('mutation', 'planExhumation', { burialKey: 'ID!', plannedExhumationDate: 'Date', annotaion: 'ItalianText' })
            const burial = query.addField('burial', { key: '$burialKey' })
            burial.addField('planned_exhumation_date', { set: '$plannedExhumationDate' })
            burial.addField('appendAnnotation', { annotation: '$annotaion' })
            burial.addField('persists').addInlineFragment('Burial').addFields(this.burialGetMap)
            let response = this.sendServerCommand({ mutation: query, variables: { burialKey: burialKey, plannedExhumationDate: data.plannedExhumationDate, annotaion: data.annotation } })
                .then(response => { return (response === null || !response.burial.persists || !response.burial.persists.key ? failResponse() : true) })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async revokeBurialReservation(burialKey, data) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile registrare la revoca" })
            return false
        }
        try {
            const query = new JGQLComposer('mutation', ' revokeBurialReservation', { burialKey: 'ID!', revokeDate: 'Date', annotaion: 'ItalianText' })
            const burial = query.addField('burial', { key: '$burialKey' }).addFields({key: {}})
            burial.addField('actual_exhumation_date', { set: '$revokeDate' })
            burial.addField('appendAnnotation', { annotation: '$annotaion' })
            burial.addField('persists').addInlineFragment('Burial').addFields({
                ...this.burialGetMap,
                grave: {fields: {key:{}, gravespace: {fields: {key: {}, graveyard: {fields: this.graveyardUnnormalizedDiagramGetMap}}}, canAcceptNewBurial: {}, relatedBurial: {args: { params: { conds: { current: true } } }, fields: {...this.burialGetMap }}}}
            })
            let response = this.sendServerCommand({ mutation: query, variables: { burialKey: burialKey, revokeDate: data.revokeDate, annotaion: data.annotation } })
                .then(response => { return (response === null || !response.burial || !response.burial.persists || !response.burial.persists.key ? failResponse() : true) })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async reservationToBurial(burialKey, data) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Non è stato possibile passare dalla prenotazione alla sepoltura" })
            return false
        }
        try {
            let response = this.getBurial(burialKey).then(burial => {
                if (burial) {
                    if (!burial) {
                        return failResponse()
                    }
                    if (!burial.isReservation) {
                        this.notifyMsgToGui({ 'kind': 'error', 'text': "Non si tratta di una prenotazione." })
                        return false
                    }
                    if (burial.actual_exhumation_date) {
                        this.notifyMsgToGui({ 'kind': 'error', 'text': "Il termine della prenotazione è già segnato." })
                        return false
                    }
                    let revokeData = {
                        revokeDate: data.burial_date,
                        annotation: data.annotation
                    }
                    let response = this.revokeBurialReservation(burialKey, revokeData)
                        .then(response => {
                            if (response === true) {
                                let burialData = data
                                burialData.deceased = data.deceased
                                let response = this.newBurial(burial.grave.key, burialData).then(response => {
                                    return response
                                })
                                .catch(error => { return failResponse(error) })
                                return response
                            } else {
                                return failResponse()
                            }
                        })
                        .catch(error => { return failResponse(error) })
                    return response
                } else {
                    return failResponse()
                }
            }).catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async getFileDownloadLink(key) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Impossibile predisporre il collegamento per il download del file" })
            return {}
        }
        try {
            const query = new JGQLComposer('query', 'getFileDownloadLink', { key: 'ID!'})
            query.addField('file', { key: '$key' }).addField('getDownloadURI')
            let response = this.sendServerCommand({ mutation: query, variables: {
                key: key
              }, fetchPolicy: 'no-cache' })
                .then(response => {
                    if(response === null || ! response.file || ! response.file.getDownloadURI ) {
                        failResponse()
                    } else {
                        return response.file.getDownloadURI
                    }
                 })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async getFileUploadLink(key) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Impossibile predisporre il caricamento del file" })
            return {}
        }
        try {
            const query = new JGQLComposer('mutation', 'getFileUploadLink', { key: 'ID!'})
            query.addField('file', { key: '$key' }).addField('generateURIToUploadBlob')
            let response = this.sendServerCommand({ mutation: query, variables: {
                key: key
              }, fetchPolicy: 'no-cache' })
                .then(response => {
                    if(response === null || ! response.file || ! response.file.generateURIToUploadBlob ) {
                        failResponse()
                    } else {
                        return response.file.generateURIToUploadBlob
                    }
                 })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async renameFile(key, name) {
        let failResponse = (error) => {
            if (error) console.error(error)
            this.notifyMsgToGui({ 'kind': 'error', 'text': "Il file non è stato rinominato"})
            return null
        }
        try {
            const query = new JGQLComposer('mutation', 'renameFile', {
                key: 'ID!',
                name: 'ProperName'
             })
            let oegQ = query.addField('file', { key: '$key' })
            oegQ.addField('key')
            oegQ.addFields({
                name: { args: { set: "$name" } }
             })
            oegQ.addField('persists').addInlineFragment('File').addFields(this.fileGetMap);
            let response = this.sendServerCommand({ mutation: query, variables: {
                key: key,
                name: name
             } })
                .then(response => {
                    if (response === null || !response.file.persists) {
                        return failResponse()
                    } else {
                        return response.file.persists.name
                    }
                })
                .catch(error => { return failResponse(error) })
            return response
        } catch (error) { return failResponse(error) }
    }

    async sendServerCommand(command, addRenewToken = true, noCache = false) {
        if (typeof command === 'object') {
            const verb = (typeof command.query === 'object' ? { name: 'query', exec: 'query' } : (typeof command.mutation === 'object' ? { name: 'mutation', exec: 'mutate' } : null))
            if (typeof verb === 'object') {
                if(addRenewToken) {
                    command[verb.name].addFields({ renewToken: {}, fetchMsgs: { fields: { type: [], content: { fields: ['text', 'errn'] } } } })
                }
                if(noCache) {
                    command.fetchPolicy = 'network-only'
                }
                if(process.env.NODE_ENV !== 'production') {
                    console.log(command[verb.name].toString())
                    console.log(JSON.stringify(command.variables))
                }
                command[verb.name] = gql`${command[verb.name].toString()}`
                let response = this.GQLClient[verb.exec](command).then(
                    response => {
                        if(response && response.data) {
                            if (Array.isArray(response.data.fetchMsgs)) {
                                for (let message of response.data.fetchMsgs) {
                                    this.notifyMsgToGui({
                                        'kind': message.type,
                                        'text': message.content.text + '\n(' + message.content.errn + ')'
                                    })
                                }
                            }
                            if (typeof response.data.renewToken === 'string') {
                                sessionStorage.setItem(this.authTokenName, response.data.renewToken);
                            }
                            console.warn(response)
                            return response.data
                        } else {
                            throw new Error({ msg: 'empty response from remote', n: 10000 })
                        }
                    }
                ).catch(
                    error => {
                        if (Array.isArray(error.graphQLErrors)) {
                            for (let err of error.graphQLErrors) {
                                if (err.category === "businessLogic") {
                                    switch (err.message) {
                                        case "The JWT has expired.":
                                            this.notifyMsgToGui({
                                                'kind': 'error',
                                                'text': "L'autenticazione è terminata a causa di un prolungato inutilizzo. Si tratta di una precauzione di sicurezza."
                                            })
                                            return this.logout();
                                        default:
                                            break;
                                    }
                                }
                                this.notifyMsgToGui({
                                    'kind': 'error',
                                    'text': err.message
                                })
                            }
                        } else {
                            if(process.env.NODE_ENV !== 'production') {
                                console.log(error)
                            }
                            this.notifyMsgToGui({
                                'kind': 'error',
                                'text': "Si è verificato un problema inaspettato. Spiacente ma non posso completare l'operazione"
                            })
                        }
                        return null
                    }
                )
                return response
            }
            else {
                throw new Error({ msg: 'missing body of the command to send to the server', n: 10001 })
            }
        } else {
            throw new Error({ msg: 'missing command to send to the server', n: 10000 })
        }
    }
}

export default JBLClient