import { createStyles } from "@material-ui/core";
import α from 'color-alpha'

const JFileStyle = ((theme) => createStyles({
    box: {
        borderRadius: 16,
        border: "1px solid",
        borderColor: "transparent",
        margin: "8px",
        '&:hover': {
            backgroundColor: α(theme.palette.secondary.light, 0.5),
            borderColor: theme.palette.secondary.light,
            cursor: "pointer"
        },
    },
    boxContent: {
        display: "contents"
    },
    filename: {
        textOverflow: 'ellipsis', /* enables ellipsis */
        whiteSpace: 'nowrap', /* keeps the text in a single line */
        overflow: 'hidden', /* keeps the element from overflowing its parent */
        textAlign: 'center'
    }
}))

export default JFileStyle