import React, { Component } from 'react'
import { Chip, Grid } from '@material-ui/core'
import RotateLeftRoundedIcon from '@material-ui/icons/RotateLeftRounded';
import JSelect from '../../../JForm/controls/JSelect';
import WarningIcon from '@material-ui/icons/Warning';

/** Navigate through graveyards spaces and graves */
class FormGraveyardNav extends Component {

    state = {
        graveyardKey: null,
        pathStack: [],
        unnormalizedDiagram: {}
    }

    /** Initialize the path inside the given graveyard
     * @param graveyardKey the identifier of the graveyard
     */
    setPathRoot(graveyardKey) {
        this.props.appMng.props.JBLClient.getGraveyard(graveyardKey)
            .then(response => {
                var pathStack = [
                    {
                        kind: 'root',
                        items: response.unnormalizedDiagram.items
                    }
                ]
                if(this.props.siblingsOf && this.pathToSiblingsOf(pathStack, this.props.siblingsOf, response.unnormalizedDiagram)){
                    console.log(pathStack)
                }
                this.setState({ graveyardKey: graveyardKey, pathStack: pathStack, unnormalizedDiagram: response.unnormalizedDiagram})
            });
    }

    /** Try to find the path to the siblings of the given item
     *
    */
    pathToSiblingsOf(pathStack, itemID, unnormalizedDiagram){
        var step = null
        if(Array.isArray(pathStack) && pathStack.length) {
            step = pathStack[pathStack.length - 1]          //last entry in path
            if(step.items) {
                var kpos = 0
                for (const item of step.items) {
                    if(item.type === itemID.kind && item.id === itemID.key) {   //found!!!
                        return true
                    } else if(Array.isArray(item.items) && item.items.length) {
                        pathStack.push(
                            {
                                kind: item.type,
                                k: kpos,
                                n: unnormalizedDiagram.models[item.model].name + ' ' + item.name,
                                a: item.available,
                                items: item.items,
                                lock: true
                            }
                        )
                        if(!this.pathToSiblingsOf(pathStack, itemID, unnormalizedDiagram)) {
                            pathStack.pop()
                        } else {
                            return true
                        }
                    }
                    kpos++
                }
            }
        }
        return false
    }

    /** Fix the current step of the path accordingly with the selected option and adds a new step
     * @param step the data of the previous step
     * @param kpos the index key of the selected option
     */
    addStep(step, kpos) {
        if (kpos) {
            var pathStack = this.state.pathStack
            if(step.items[kpos]) {      //the selected sub-item exist at the current step
                const sItem = step.items[kpos]
                pathStack.push(
                    {
                        kind: sItem.type,
                        k: kpos,
                        n: this.state.unnormalizedDiagram.models[sItem.model].name + ' ' + sItem.name,
                        a: sItem.available,
                        items: sItem.items
                    }
                )
                this.setState({ pathStack: pathStack })
                if(sItem.type === 'g' && typeof this.props.onChange === 'function'){
                    this.props.onChange(sItem.id)
                }
            }
        }
    }

    /** Reset the given step and remove all the following ones
     * @param kpos the positional index in the path
    */
    removeStep(kpos) {
        if (kpos >= 0 && this.state.pathStack[kpos]) {         //it the given step exists
            const pathStack = this.state.pathStack
            pathStack.splice(kpos)                  //delete the steps starting from the given position
            this.setState({ pathStack: pathStack })     //update the state
            if(typeof this.props.onChange === 'function'){
                this.props.onChange(null)
            }
        }
    }

    /** Draw the interactive path */
    drawPath() {
        return this.state.pathStack.map((item, pos) => { //for each step in the path
            let elements = []                            //the element which will be shown for the current step
            if (item.kind !== 'root') {                              //if the option of the current step has already been choose, draw it as a ...
                elements.push(<Grid item key={pos}><Chip
                    color={item.kind === 'g' ? "secondary" : "primary"}
                    icon={item.a === false ? <WarningIcon/> : null}
                    label={item.n}
                    onDelete={!item.lock ? () => this.removeStep(pos) : null}
                    deleteIcon={!item.lock ? <RotateLeftRoundedIcon /> : null}
                /></Grid>)
            }
            if (pos === (this.state.pathStack.length - 1) && item.items && item.items.length > 0) { //the last step with sub-items has a select
                let opList = [                               //add an empty option for the select
                    <option key="none" value={null}></option>
                ]
                for (let spos in item.items) {         //add them to option list
                    let space = item.items[spos]
                    if((space.type === 'g' && (!this.props.availableOnly || space.available) && (!this.props.siblingsOf || !(this.props.siblingsOf.kind==='g' && this.props.siblingsOf.key === space.id)))|| (space.type === 's' && space.items && space.items.length > 0)) {
                        opList.push(<option key={spos} value={spos}>{this.state.unnormalizedDiagram.models[space.model].name + ' ' + space.name}</option>)
                    }
                }
                elements.push(<Grid item key={pos + 'S'}><JSelect
                    value={null}
                    label='Opzioni'
                    onChange={value => this.addStep(item, value)}
                >
                    {opList}
                </JSelect></Grid>)
            }
            return elements     //returns the element
        })
    }

    /** Render the path */
    render() {
        if (this.state.graveyardKey !== this.props.graveyardKey) {      //if the graveyard has changed reset the path
            this.setPathRoot(this.props.graveyardKey)
        }
        return <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>{this.drawPath()}</Grid>
    }

}

export default FormGraveyardNav