import { createStyles } from "@material-ui/core";

const AppActionSpcModelsStyle = ((theme) => createStyles({
    fabAdd: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: 'auto',
        borderRadius: theme.spacing(4),
    },
    fabIconExtended: {
        marginRight: theme.spacing(1),
    }
}))

export default AppActionSpcModelsStyle