import React, { Component } from 'react'
import LoggedInApp from '../LoggedInApp';
import SignIn from '../SignIn';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/styles';
import MainViewStyle from './MainViewStyle';

class MainView extends Component {

  state = {
    loggedIn: false
  }

  constructor(props) {
    super(props)
    this.state.loggedIn = this.props.JBLClient.loginStatus()
  }

  displayNotify(e) {

    const action = (key) => (
      <IconButton key="close" aria-label="close" color="inherit" onClick={() => { this.props.closeSnackbar(key); }}>
        <CloseIcon />
      </IconButton>)

    let boxDef = {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      action
    }

    switch (e.detail.kind) {
      case 'confirmation':
        boxDef.variant = 'success';
        break;
      case 'error':
      case 'warning':
      case 'info':
      case 'success':
        boxDef.variant = e.detail.kind;
        break;
      default:
        boxDef.variant = 'default';
    }
    this.props.enqueueSnackbar(e.detail.text, boxDef)
  }

  closeNotify(key) {
    this.props.closeSnackbar(key)
  }

  componentDidMount() {
    document.body.addEventListener('notifyMsgToGui', e => this.displayNotify(e));
    document.body.addEventListener('login', e => this.setState({ loggedIn: this.props.JBLClient.loginStatus() }));
  }

  componentWillUnmount() {
    document.body.removeEventListener('notifyMsgToGui', e => this.displayNotify(e));
    document.body.removeEventListener('login', e => this.setState({ loggedIn: this.props.JBLClient.loginStatus() }));
  }

  render() {
    const loggedIn = this.state.loggedIn
    let GUIvariant
    if (loggedIn) {
      GUIvariant = (<LoggedInApp JBLClient={this.props.JBLClient} />)
    } else {
      GUIvariant = (<SignIn JBLClient={this.props.JBLClient} />)
    }
    return (
      <div className={this.props.classes.root}>
        {GUIvariant}
      </div>
    )
  }
}
export default withSnackbar(withStyles(MainViewStyle)(MainView));
