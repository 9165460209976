import React from 'react'

const JPolyIconZoomIn = (props) => {
    const defColor = props.color ? props.color : 'black'
    const bgColor = props.bgColor ? props.bgColor : 'white'
    return <g onClick={(e) => props.onClick(e)} transform={`translate(${props.x || 0} ${props.y || 0})scale(${((props.size || 24) / 1000)})`} vectorEffect="non-scaling-stroke">
        <circle cx="500" cy="500" r="500" fill={bgColor} fillOpacity="0.6" stroke={defColor} strokeLinejoin="round" strokeWidth="90" paintOrder="markers stroke fill" />
        <path transform="scale(.26458)" d="m2150.6 298.89a1097.9 1097.9 0 0 0-760.26 321.43 1097.9 1097.9 0 0 0-119.85 1410.4l-782.54 782.54c-16.436 16.436-9.8216 49.516 14.832 74.17l173.06 173.06c24.654 24.654 57.732 31.268 74.168 14.832l783.32-783.32a1097.9 1097.9 0 0 0 1409.6-119.06 1097.9 1097.9 0 0 0-2e-3 -1552.6 1097.9 1097.9 0 0 0-792.33-321.43zm27.877 286.67a811.15 811.15 0 0 1 561.73 237.49 811.15 811.15 0 0 1 0.0019 1147.1 811.15 811.15 0 0 1-1147.1 0 811.15 811.15 0 0 1 2e-3 -1147.1 811.15 811.15 0 0 1 585.41-237.49zm-104.78 207.27c-22.461 0-40.545 12.055-40.545 27.029v445.97h-445.97c-14.974 0-27.029 18.082-27.029 40.543v162.17c0 22.461 12.055 40.543 27.029 40.543h445.97v445.97c0 14.974 18.084 27.029 40.545 27.029h162.17c22.461 0 40.543-12.055 40.543-27.029v-445.97h445.97c14.974 0 27.029-18.082 27.029-40.543v-162.17c0-22.461-12.055-40.543-27.029-40.543h-445.97v-445.97c0-14.974-18.082-27.029-40.543-27.029h-162.17z"
            fill={defColor}
            paintOrder="markers stroke fill"
        />
    </g>
}

export default JPolyIconZoomIn