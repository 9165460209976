import React, { Component, Fragment } from 'react'
import { DialogContentText, Button, DialogContent, Grid } from '@material-ui/core'
import CardMembershipRoundedIcon from '@material-ui/icons/CardMembershipRounded';
import FormOUnitSelector from '../../Forms/FormOUnitSelector';
import FormRoleSelector from '../../Forms/FormRoleSelector';

class DialogAddRole extends Component {

    showStatus = false

    fields = {
        OUnitKey: null,
        roleKey: null
    }

    /** It says if all the required infromation has been collected */
    canProcede() {
        return (this.fields.OUnitKey !== null && this.fields.roleKey !== null)
    }

    /** Performs the recording of exhumation */
    procede() {
        if (this.canProcede()) {
            this.props.appMng.props.JBLClient.addRoleSysUser(this.props.userKey, this.fields.OUnitKey, this.fields.roleKey).then(
                (result) => {
                    if(result){
                        this.fields = {
                            OUnitKey: null,
                            roleKey: null
                        }
                        this.close()
                    }
                }
            );
        } else {
            this.props.appMng.notifyMsgToGui({
                'kind': 'error',
                'text': "Scegliere tutti i parametri."
            })
        }
    }

    /** Set the value of a field
     * @param fname name of the field
     * @param fvalue new value of the field
     */
    setField(fname, fvalue) {
        switch (fname) {
            case 'OUnitKey':
                this.fields.OUnitKey = (fvalue ? fvalue : null)
                break
            case 'roleKey':
                this.fields.roleKey = (fvalue ? fvalue : null)
                break
            default:
                return false
        }
        return this.show(this.props.open)
    }

    /** Show or hide the content of the dialog box
     * @param status dialog opened o closed
    */
    show(status) {
        if (status) {
            this.props.appMng.openDialog(<Fragment>Assegnazione ruolo in unità operativa <CardMembershipRoundedIcon/></Fragment>, (
                <Fragment>
                    <DialogContent>
                        <DialogContentText id="main-dialog-description">
                            Assegnare ruoli all'interno di un'unità organizzativa, definisce ciò che l'operatore potrà fare e non fare.
                            Poni molta attenzione alla scelta del ruolo che stai assegnando.
                        </DialogContentText>
                        <Grid container spacing={1} direction="column" justify="flex-start" alignItems="stretch">
                            <Grid item>
                                <FormRoleSelector
                                    value={this.fields.roleKey}
                                    appMng={this.props.appMng}
                                    onChange={(value) => this.setField('roleKey', value)}
                                />
                            </Grid>
                            <Grid item>
                                presso
                            </Grid>
                            <Grid item>
                                <FormOUnitSelector
                                    appMng={this.props.appMng}
                                    onChange={(value) => this.setField('OUnitKey', value)}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Fragment>),
                (<Fragment>
                    <Button aria-label="procedi" disabled={!this.canProcede()} onClick={() => this.procede()}>Procedi</Button>
                    <Button aria-label="annulla" onClick={() => this.close()}>Annulla</Button>
                </Fragment>))
        } else {
            this.props.appMng.closeDialog()
        }
        this.showStatus = status
        return true
    }

    close() {
        if (typeof this.props.onClose === "function") { this.props.onClose() }
        this.showStatus = false
        this.props.appMng.closeDialog()
    }

    render() {
        if (this.props.open !== this.showStatus) {
            this.show(this.props.open)
        }
        return null
    }

}

export default DialogAddRole