import { createTheme } from '@material-ui/core';
import indigo from '@material-ui/core/colors/indigo';
import lime from '@material-ui/core/colors/lime';

const theme = createTheme({
    palette: {
        primary: {
            light: indigo[200],
            main: indigo[500],
            dark: indigo[700],
        },
        secondary: {
            light: lime[500],
            main: lime[700],
            dark: lime[800],
        },
    },
    status: {
        danger: 'orange',
    },
});

export default theme