import React from 'react'

const JPolyIconMove = (props) => {
    const defColor = props.color ? props.color : 'black'
    const bgColor = props.bgColor ? props.bgColor : 'white'
    return <g onClick={(e) => props.onClick(e)} transform={`translate(${props.x || 0} ${props.y || 0})scale(${((props.size || 24) / 1000)})`} vectorEffect="non-scaling-stroke">
        <circle cx="500" cy="500" r="500" fill={bgColor} fillOpacity="0.6" stroke={defColor} strokeLinejoin="round" strokeWidth="90" paintOrder="markers stroke fill" />
        <path d="m500 81.254-72.441 112.67-61.353 119.07 133.79 6.4014 133.79-6.4014-61.353-119.07zm-187.01 284.95-119.07 61.353-112.67 72.441 112.67 72.441 119.07 61.353 6.402-133.79zm374.02 0-6.4014 133.79 6.4014 133.79 119.07-61.353 112.67-72.441-112.67-72.441zm-187.01 34.119a99.675 99.675 0 0 0-99.675 99.675 99.675 99.675 0 0 0 99.675 99.675 99.675 99.675 0 0 0 99.675-99.675 99.675 99.675 0 0 0-99.675-99.675zm0 280.28-133.79 6.4014 61.353 119.07 72.441 112.67 72.441-112.67 61.353-119.07z"
            fill={defColor}
            paintOrder="markers stroke fill"
        />
    </g>
}

export default JPolyIconMove