import React, { Component, Fragment } from 'react'
import { Card, CardContent, Typography, CardActions, Button, List, CardMedia, Box, Grid, ListItem, ListItemText, DialogContentText, Divider, Menu, MenuItem, Fab, Avatar, ListItemAvatar, Link, ListItemIcon } from '@material-ui/core'
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import AppActionOUStyle from './AppActionOUStyle';
import mediaHeader from '../../../../images/companies_1248.webp'
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import { withStyles } from '@material-ui/styles';

class AppActionOU extends Component {

    state = {
        actionsMenu: {
            target: null
        },
        loaded: false,
        data: {
            name: '',
            isGraveyardOwner: false,
            inheritReferences: {
                mainFullAddress: false,
                mainPhone: false,
                mainEmail: false,
            },
            mainFullAddress: '',
            mainPhone: '',
            mainEmail: '',
            directReferent: '',
            directPhone: '',
            directEmail: '',
            relatedGraveyard: [],
            relatedSysUser: []
        },
        orgData: {
            name: '',
            mainFullAddress: false
        }
    }

    constructor(props) {
        super(props)
        console.log('initiating ' + this.props.id)
        this.actionData = this.props.appMng.actionsStack[this.props.id]
        this.props.appMng.setTitle('Unità organizzativa')
        this.getData()
    }

    getData() {
        if (this.actionData.params) {
            this.props.appMng.props.JBLClient.getOUnit(this.actionData.params.orgKey, this.actionData.params.key)
                .then(response => {
                    if(response){
                        this.props.appMng.props.JBLClient.getOrg(this.actionData.params.orgKey)
                        .then(response => {
                            this.setState({ loaded: true, orgData: response })
                        });
                        this.setState({ data: response })
                    } else {
                        this.props.appMng.notifyMsgToGui({
                            'kind': 'error',
                            'text': "Errore nel recupero dei dati."
                        })
                    }
                });
        }
    }

    editMe = () => {this.props.appMng.openAction('OUEditor', { orgKey: this.actionData.params.orgKey, key: this.actionData.params.key })}

    deleteMe = () => {
        this.setState((state => {
            state.actionsMenu.target = null
            return state
        }));
        if((Array.isArray(this.state.data.relatedOrganizationalUnit) && this.state.data.relatedOrganizationalUnit.length > 0) || (Array.isArray(this.state.data.relatedSysUser) && this.state.data.relatedSysUser.length > 0)){
            this.props.appMng.notifyMsgToGui({
                'kind': 'error',
                'text': "Per poter procedere è prima necessario rimovere tutti gli operatori e tutti i cimiteri dell'unità organizzative."
            })
        } else {
            this.props.appMng.openDialog('Eliminazione unità organizzativa', (
                <Fragment>
                    <DialogContentText id="main-dialog-description">
                        Confermi di voler eliminare definitivamente l'unità organizzativa?
                    </DialogContentText>
                </Fragment>),
                (<Fragment>
                    <Button aria-label="rimuovi" onClick={() => {
                        this.props.appMng.props.JBLClient.deleteOUnit(this.actionData.params.orgKey, this.actionData.params.key)
                            .then(response => {
                                if (response) {
                                    this.props.appMng.closeDialog()
                                    this.closeMe()
                                }
                                return response
                            });
                        this.props.appMng.closeDialog()
                    }} >Conferma</Button>
                    <Button aria-label="Mantieni" onClick={() => this.props.appMng.closeDialog()}>Annulla</Button>
                </Fragment>))
        }
    }

    closeMe = () => {this.props.appMng.closeAction(this.props.id)}

    addGraveyard = () => {this.props.appMng.openAction('graveyardEditor', { orgKey: this.actionData.params.orgKey, unitKey: this.actionData.params.key })}

    addSysUser = () => {this.props.appMng.openAction('sysUserEditor', { OUnitKey: this.actionData.params.key })}

    showActionsMenu(e) {
        let items = []
        items.push(<MenuItem key="1" onClick={this.editMe}><ListItemIcon><EditRoundedIcon/></ListItemIcon><ListItemText>Modifica dati</ListItemText></MenuItem>)
        if(this.state.data.isGraveyardOwner){
            items.push(<MenuItem key="2" onClick={this.addGraveyard}><ListItemIcon><AddBoxRoundedIcon/></ListItemIcon><ListItemText>Aggiungi cimitero</ListItemText></MenuItem>)
        }
        items.push(<MenuItem key="3" onClick={this.addSysUser}><ListItemIcon><PersonAddRoundedIcon /></ListItemIcon><ListItemText>Aggiungi operatore</ListItemText></MenuItem>)
        items.push(
            <MenuItem key="4" onClick={this.deleteMe}><ListItemIcon><DeleteForeverRoundedIcon /></ListItemIcon><ListItemText>Elimina</ListItemText></MenuItem>,
            <MenuItem key="300" onClick={this.closeMe}><ListItemIcon><HighlightOffRoundedIcon /></ListItemIcon><ListItemText>Chiudi</ListItemText></MenuItem>
        )
        this.setState({
            actionsMenu: {
                target: e.target,
                items: items,
            }
        })
    }

    hideActionsMenu() {
        this.setState({ actionsMenu: { target: null } })
    }

    drawGraveyard(item, pos) {
        return <Fragment key={pos}>
            {pos > 0 ? <Divider variant="inset" component="li" /> : ''}
            <ListItem button onClick={() => this.props.appMng.openAction('graveyard', { key: item.key })}>
                <ListItemAvatar>
                    <Avatar className={this.props.classes.avatarGyActive}>
                        <AccountBalanceRoundedIcon/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={item.name}/>
            </ListItem>
        </Fragment>
    }

    drawSysUser(item, pos) {
        return <Fragment key={pos}>
            {pos > 0 ? <Divider variant="inset" component="li" /> : ''}
            <ListItem button onClick={() => this.props.appMng.openAction('sysUser', { key: item.key })}>
                <ListItemAvatar>
                    <Avatar className={item.status?this.props.classes.avatarUserActive:this.props.classes.avatarUserInactive}>
                        <PersonRoundedIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={item.name} secondary={item.email}/>
            </ListItem>
        </Fragment>
    }


    render() {
        let mainFullAddress, mainPhone, mainEmail, directPhone, directEmail, directReferent = ''
        if(this.state.loaded){
            mainFullAddress =
            this.state.data.inheritReferences.mainFullAddress ?
            this.state.orgData.mainFullAddress :
            this.state.data.mainFullAddress
            mainPhone = this.state.data.inheritReferences.mainPhone ? this.state.orgData.mainPhone : this.state.data.mainPhone
            mainEmail = this.state.data.inheritReferences.mainEmail ? this.state.orgData.mainEmail : this.state.data.mainEmail
            directPhone = this.state.data.directPhone
            directEmail = this.state.data.directEmail
            directReferent = this.state.data.directReferent
        }

        return (
            <Fragment>
                <Card>
                    <CardMedia className={this.props.classes.headMedia} image={mediaHeader}>
                        <Box p={3} className={this.props.classes.itemsHeaderText}>
                            <Typography variant="h4" color="inherit">
                                {this.state.loaded ? this.state.data.myNameIs : <Skeleton height="32px" width="100px" />}
                            </Typography>
                        </Box>
                        {this.state.loaded ?
                            <Fab aria-label="modifica dati ente" title="modifica i dati" color="secondary" className={this.props.classes.fabEdit} onClick={this.editMe}>
                                <EditRoundedIcon/>
                            </Fab>:<Fragment></Fragment>
                        }
                        {(this.state.loaded)?
                            <Fab aria-label="più opzioni" title="più opzioni" color="primary" className={this.props.classes.fabMenu} onClick={(e) => this.showActionsMenu(e)}>
                                <MenuRoundedIcon/>
                            </Fab>:<Fragment></Fragment>
                        }
                    </CardMedia>
                    {this.state.loaded ?
                        <Fragment>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid container item xs={12} md={12} lg={12} className={this.props.classes.entityLine} alignItems="flex-start">
                                        <Grid item xs={10} md={10} lg={11}>
                                            <Typography variant="body1">Unità organizzativa {this.state.data.isGraveyardOwner?'con responsabilità diretta di cimiteri':'senza propri cimiteri'}</Typography>
                                            <Typography variant="body1" className={this.props.classes.parentLink} onClick={() => this.props.appMng.openAction('org', { key: this.actionData.params.orgKey })}>
                                                di {this.state.data.organization.myNameIs} <OpenInNewRoundedIcon />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} md={12} lg={12} className={this.props.classes.entityLine} spacing={2}>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Typography variant="caption">Informazioni per il pubblico:</Typography>
                                        </Grid>
                                        <Grid item>
                                            <LocationOnRoundedIcon/> {mainFullAddress?<Link variant="body1" target="_blank" rel="noopener noreferrer" href={"http://maps.google.com/?q=" + encodeURIComponent(mainFullAddress).replace(/['()]/g, escape).replace(/\*/g, '%2A').replace(/%(?:7C|60|5E)/g, unescape)}>{mainFullAddress}</Link>:<Typography variant="caption">indirizzo principale mancante</Typography>}
                                        </Grid>
                                        <Grid item>
                                            <PhoneRoundedIcon/> {mainPhone?<Link variant="body1" href={"tel:" + encodeURIComponent(mainPhone)}>{mainPhone}</Link>:<Typography variant="caption">numero telefonico per il pubblico mancante</Typography>}
                                        </Grid>
                                        <Grid item>
                                            <EmailRoundedIcon/> {mainEmail?<Link variant="body1" href={"mailto:" + mainEmail}>{mainEmail}</Link>:<Typography variant="caption">indirizzo email per il pubblico mancante</Typography>}
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} md={12} lg={12} className={this.props.classes.entityLine} spacing={2}>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Typography variant="caption">Riferimenti per il servizio:</Typography>
                                        </Grid>
                                        <Grid item title="nome del referente principale">
                                            <Typography variant="body1">
                                                <PersonRoundedIcon/> {directReferent? directReferent : <Typography variant="caption">nome del principale referente mancante</Typography>}
                                            </Typography>
                                        </Grid>
                                        <Grid item title="numero diretto per il servizio">
                                            <PhoneRoundedIcon/> {directPhone?<Link variant="body1" href={"tel:" + encodeURIComponent(directPhone)}>{directPhone}</Link>:<Typography variant="caption">numero diretto per il servizio mancante</Typography>}
                                        </Grid>
                                        <Grid item title="email diretta per il servizio">
                                            <EmailRoundedIcon/> {directEmail?<Link variant="body1" href={"mailto:" + directEmail}>{directEmail}</Link>:<Typography variant="caption">indirizzo email diretto per il servizio mancante</Typography>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            {this.state.data.isGraveyardOwner ?
                            <CardContent>
                                <Typography variant="h6" component="h4">Cimiteri dell'unità organizzativa:</Typography>
                                {(Array.isArray(this.state.data.relatedGraveyard) && this.state.data.relatedGraveyard.length > 0) ?
                                    <Card elevation={1}>
                                        <List>{this.state.data.relatedGraveyard.map((item, pos) => this.drawGraveyard(item, pos))}</List>
                                        <CardActions>
                                            <Button onClick={this.addGraveyard}><AddBoxRoundedIcon /> Aggiungi altro cimitero</Button>
                                        </CardActions>
                                    </Card> :
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <Typography color="primary" variant="h5">Non ci sono ancora cimiteri gestiti da questa unità.</Typography>
                                        </Grid>
                                        <Grid item>
                                        <Button onClick={this.addGraveyard}><AddBoxRoundedIcon /> Aggiungi il primo cimitero</Button>
                                        </Grid>
                                    </Grid>
                                }
                            </CardContent>:<Fragment></Fragment>}
                            <CardContent>
                                <Typography variant="h6" component="h4">Operatori dell'unità organizzativa:</Typography>
                                {(Array.isArray(this.state.data.relatedSysUser) && this.state.data.relatedSysUser.length > 0) ?
                                    <Card elevation={1}>
                                        <List>{this.state.data.relatedSysUser.map((item, pos) => this.drawSysUser(item, pos))}</List>
                                        <CardActions>
                                            <Button onClick={this.addSysUser}><AddBoxRoundedIcon /> Aggiungi altro operatore</Button>
                                        </CardActions>
                                    </Card> :
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <Typography color="primary" variant="h5">Non ci sono ancora operatori autorizzati per questa unità.</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={this.addSysUser}><AddBoxRoundedIcon /> Aggiungi il primo operatore</Button>
                                        </Grid>
                                    </Grid>
                                }
                            </CardContent>
                            <CardActions>
                                <Button onClick={this.closeMe}><HighlightOffRoundedIcon /> Chiudi scheda</Button>
                            </CardActions>
                        </Fragment>
                        :
                        <CardContent>
                            <Skeleton height="60px" width="100%" />
                        </CardContent>
                    }
                </Card >
                <Menu
                    anchorEl={this.state.actionsMenu.target}
                    open={Boolean(this.state.actionsMenu.target)}
                    keepMounted
                    onClose={() => this.hideActionsMenu()}
                >
                    {this.state.actionsMenu.items}
                </Menu>
            </Fragment>
        )
    }
}

export default withStyles(AppActionOUStyle)(AppActionOU)