import React from 'react'

const JPolyIconDone = (props) => {
    const defColor = props.color ? props.color : 'black'
    const bgColor = props.bgColor ? props.bgColor : 'white'
    return <g onClick={(e) => props.onClick(e)} transform={`translate(${props.x || 0} ${props.y || 0})scale(${((props.size || 24) / 1000)})`} vectorEffect="non-scaling-stroke">
        <circle cx="500" cy="500" r="500" fill={bgColor} fillOpacity="0.6" stroke={defColor} strokeLinejoin="round" strokeWidth="90" paintOrder="markers stroke fill" />
        <path d="m789.6 219.89c-6.871 2.0164-13.33 5.812-18.661 11.371l-382.01 398.34-147.56-141.51c-16.943-16.248-43.663-15.689-59.911 1.2539l-84.321 87.924c-16.248 16.943-15.689 43.663 1.2539 59.911l266.07 255.16 1.4e-4 4.8e-4c2.6472 2.5386 5.5329 4.6669 8.5803 6.3865 1e-4 6e-5 3e-5 4.3e-4 1.4e-4 4.9e-4 4.2664 2.4075 8.8499 4.014 13.537 4.8238 0.66963 0.11568 1.341 0.21527 2.0143 0.29845 3.703 0.45757 7.4518 0.42367 11.144-0.0994 1.7e-4 -2e-5 3.3e-4 -1.2e-4 5e-4 -1.4e-4 8.3904-1.189 16.487-4.9053 23.082-11.123 0.5276-0.49745 1.0455-1.0109 1.5533-1.5404l84.32-87.924 5e-3 -5e-3 411.33-428.91c16.248-16.943 15.689-43.663-1.2534-59.911l-87.924-84.32c-11.383-10.917-27.18-14.246-41.25-10.117z"
            fill={defColor}
            paintOrder="markers stroke fill"
        />
    </g>
}

export default JPolyIconDone