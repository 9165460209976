import React, { Component, Fragment } from 'react'
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Divider, withStyles, Fab, Paper } from '@material-ui/core'
import ImageIcon from '@material-ui/icons/Image';
import AddIcon from '@material-ui/icons/Add';
import AppActionSpcModelsStyle from './AppActionSpcModelsStyle';

class AppActionSpcModels extends Component {

    state = {
        objList: (
            <div>Recupero elenco</div>
        )
    }

    constructor(props) {
        super(props)
        this.props.appMng.setTitle('Modelli di aree cimiteriali')
        this.updateList()
    }

    updateList() {
        this.props.appMng.props.JBLClient.getSpaceModels().then(objs => {
            let listObjs = (
                <List>
                    {objs.map((itemData, itemPos) =>
                        (<Fragment key={itemData.key} >
                            {itemPos > 0 ? <Divider variant="inset" component="li" /> : ''}
                            < ListItem button onClick={() => this.props.appMng.openAction('spaceModelEditor', { key: itemData.key })}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <ImageIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={itemData.myNameIs} secondary={itemData.description} />
                            </ListItem>
                        </Fragment>
                        ))}
                </List>
            )
            this.setState({ objList: listObjs })
        })
    }

    render() {
        return (
            <Paper>
                {this.state.objList}
                <Fab aria-label="aggiungi nuovo" color="primary" className={this.props.classes.fabAdd} onClick={() => this.props.appMng.openAction('spaceModelEditor')}>
                    <AddIcon className={this.props.classes.fabIconExtended} />Nuovo modello
                </Fab>
            </Paper>
        )
    }
}

export default withStyles(AppActionSpcModelsStyle)(AppActionSpcModels)