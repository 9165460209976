import React from 'react'

const JPolyIconAdd = (props) => {
    const defColor = props.color ? props.color : 'black'
    const bgColor = props.bgColor ? props.bgColor : 'white'
    return <g onClick={(e) => props.onClick(e)} transform={`translate(${props.x || 0} ${props.y || 0})scale(${((props.size || 24) / 1000)})`} vectorEffect="non-scaling-stroke">
        <circle cx="500" cy="500" r="500" fill={bgColor} fillOpacity="0.6" stroke={defColor} strokeLinejoin="round" strokeWidth="90" paintOrder="markers stroke fill" />
        <path d="m441.74 97.458c-23.475 0-42.373 18.899-42.373 42.373v259.53h-259.53c-23.475 0-42.373 18.898-42.373 42.373v116.53c0 23.475 18.898 42.373 42.373 42.373h259.53v259.53c0 23.475 18.899 42.373 42.373 42.373h116.53c23.475 0 42.373-18.898 42.373-42.373v-259.53h259.53c23.475 0 42.373-18.898 42.373-42.373v-116.53c0-23.475-18.899-42.373-42.373-42.373h-259.53v-259.53c0-23.475-18.899-42.373-42.373-42.373z"
            fill={defColor}
            paintOrder="markers stroke fill"
        />
    </g>
}

export default JPolyIconAdd