import React, { Component, Fragment } from 'react'
import { Card, CardContent, Typography, CardActions, Button, CardMedia, Box, Grid, ListItem, ListItemText, Divider, Menu, MenuItem, Fab, Avatar, Link,List, ListItemIcon, CardHeader, DialogContentText, ListItemSecondaryAction, IconButton } from '@material-ui/core'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import CardMembershipRoundedIcon from '@material-ui/icons/CardMembershipRounded';
import DomainIcon from '@material-ui/icons/Domain';
import BlockRoundedIcon from '@material-ui/icons/BlockRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import SettingsBackupRestoreRoundedIcon from '@material-ui/icons/SettingsBackupRestoreRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import VpnKeyRoundedIcon from '@material-ui/icons/VpnKeyRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import AppActionSysUserStyle from './AppActionSysUserStyle';
import mediaHeader from '../../../../images/operators_1248.webp'
import DialogAddRole from '../../Dialogs/DialogAddRole';
import { withStyles } from '@material-ui/styles';

class AppActionSysUser extends Component {

    state = {
        actionsMenu: {
            target: null
        },
        loaded: false,
        data: {
            myNameIs: '',
            name: ''
        },
        dialogs: {
            addRole: {
                open: false
            }
        }
    }

    constructor(props) {
        super(props)
        console.log('initiating ' + this.props.id)
        this.actionData = this.props.appMng.actionsStack[this.props.id]
        this.props.appMng.setTitle('Operatore di sistema')
        this.getData()
    }

    getData() {
        if (this.actionData.params) {
            this.props.appMng.props.JBLClient.getSysUser(this.actionData.params.key)
                .then(response => {
                    this.setState({ loaded: true, data: response })
                });
        }
    }

    editMe = () => {this.props.appMng.openAction('sysUserEditor', { key: this.actionData.params.key })}

    closeMe = () => {this.props.appMng.closeAction(this.props.id)}

    deleteMe = () => {
        this.hideActionsMenu()
        if(this.state.data.accessInfo.login_count > 0){
            if(this.state.data.status){
                this.disabEnabMe("Non è possibile cancellare la scheda di un operatore che ha già effettuato operazioni sul sistema. E' però possibile disabilitarne l'accesso.")
            } else {
                this.props.appMng.notifyMsgToGui({
                    'kind': 'error',
                    'text': "Non è possibile cancellare la scheda di un operatore che ha già effettuato operazioni sul sistema, anche se disabilitata."
                })
            }
        } else {
            this.props.appMng.openDialog('Eliminazione scheda operatore', (
                <Fragment>
                    <DialogContentText id="main-dialog-description">
                        Confermi di voler cancellare definitivamente i dati dell'operatore?
                    </DialogContentText>
                </Fragment>),
                (<Fragment>
                    <Button aria-label="rimuovi" onClick={() => {
                        this.props.appMng.props.JBLClient.deleteSysUser(this.actionData.params.key)
                            .then(response => {
                                if (response) {
                                    this.props.appMng.closeDialog()
                                    this.closeMe()
                                }
                                return response
                            });
                        this.props.appMng.closeDialog()
                    }} >Conferma</Button>
                    <Button aria-label="Mantieni" onClick={() => this.props.appMng.closeDialog()}>Annulla</Button>
                </Fragment>))
        }
    }

    resetPassword = () => {
        this.hideActionsMenu()
        this.props.appMng.openDialog(<Fragment><VpnKeyRoundedIcon/>Reset della password</Fragment>, (
        <Fragment>
            <DialogContentText id="main-dialog-description">
                <b>La password verrà sostituita da una temporanea generata dal sistema ed un'email verrà inviata all'operatore.</b>
            </DialogContentText>
            <DialogContentText>
                Confermi di voler reimpostare la password?
            </DialogContentText>
        </Fragment>),
        (<Fragment>
            <Button aria-label="Conferma" onClick={() => {
                this.props.appMng.props.JBLClient.resetPasswordSysUser(this.actionData.params.key)
                .then(response => {
                    if(response){
                        this.props.appMng.closeDialog()
                    }
                });
            }} >Procedi</Button>
            <Button aria-label="Annulla" onClick={() => {
                this.props.appMng.closeDialog()
            }}>Annulla</Button>
        </Fragment>))
    }

    disabEnabMe = (prefix) => {
        const newStatus = !this.state.data.status
        this.hideActionsMenu()
        this.props.appMng.openDialog(newStatus?"Abilitazione operatore":"Disabilitazione operatore", (
            <Fragment>
                <DialogContentText id="main-dialog-description">
                    {prefix?<Typography variant="body2" component="span">{prefix}</Typography>:null}
                    Confermi di voler {newStatus?"abilitare l'operatore all'":"disabilitare l'operatore dall'"}accesso al sistema?
                </DialogContentText>
            </Fragment>),
            (<Fragment>
                <Button aria-label="rimuovi" onClick={() => {
                    this.props.appMng.props.JBLClient.enabDisabSysUser(this.actionData.params.key,newStatus)
                        .then(response => {
                            if (response) {
                                this.getData()
                            }
                            return response
                        });
                    this.props.appMng.closeDialog()
                }} >Conferma</Button>
                <Button aria-label="Mantieni" onClick={() => this.props.appMng.closeDialog()}>Annulla</Button>
            </Fragment>))
    }

    addRoleInOUnit = () => {
        this.hideActionsMenu()
        this.setState((state => {
            state.dialogs.addRole.open = true
            return state
        }))
    }

    removeRoleInOUnit(OUnitKey, roleKey){
        this.props.appMng.openDialog("Rimozione ruolo in unità operativa", (
            <Fragment>
                <DialogContentText id="main-dialog-description">
                    Confermi di voler sollevare l'operatore dal ruolo che ricpore nell'unità operativa scelta?
                </DialogContentText>
            </Fragment>),
            (<Fragment>
                <Button aria-label="rimuovi" onClick={() => {
                    this.props.appMng.props.JBLClient.remRoleSysUser(this.actionData.params.key,OUnitKey, roleKey)
                            .then(response => {
                                if(response){
                                    this.getData()
                                }
                                return response
                            });
                    this.props.appMng.closeDialog()
                }} >Conferma</Button>
                <Button aria-label="Mantieni" onClick={() => this.props.appMng.closeDialog()}>Annulla</Button>
            </Fragment>))
    }

    showActionsMenu(e) {
        let items = []
        items.push(
        <MenuItem key="1" onClick={this.editMe}><ListItemIcon><EditRoundedIcon/></ListItemIcon><ListItemText>Modifica dati</ListItemText></MenuItem>,
        <MenuItem key="2" onClick={this.resetPassword}><ListItemIcon><VpnKeyRoundedIcon /></ListItemIcon><ListItemText>Reimposta password</ListItemText></MenuItem>,
        <MenuItem key="3" onClick={() => this.disabEnabMe()}><ListItemIcon>{this.state.data.status?<BlockRoundedIcon />:<SettingsBackupRestoreRoundedIcon/>}</ListItemIcon><ListItemText>{this.state.data.status?'Disabilita':'Abilita'} operatore</ListItemText></MenuItem>,
        <MenuItem key="4" onClick={this.addRoleInOUnit}><ListItemIcon><CardMembershipRoundedIcon /></ListItemIcon><ListItemText>Aggiungi ruolo</ListItemText></MenuItem>,
        <MenuItem key="5" onClick={this.deleteMe}><ListItemIcon><DeleteForeverRoundedIcon /></ListItemIcon><ListItemText>Elimina</ListItemText></MenuItem>,
        <MenuItem key="300" onClick={this.closeMe}><ListItemIcon><HighlightOffRoundedIcon /></ListItemIcon><ListItemText>Chiudi scheda</ListItemText></MenuItem>
        )
        this.setState({
            actionsMenu: {
                target: e.target,
                items: items,
            }
        })
    }

    hideActionsMenu() {
        this.setState({ actionsMenu: { target: null } })
    }

    drawRoles(){
        const remap = []
        for (const OUAgent of this.state.data.rolesInOrganizationalUnit) {
            for (const role of OUAgent.roles) {
                if(typeof remap[role.key] === "undefined"){
                    remap[role.key] = {...role, Orgs: [] }
                }
                if(typeof remap[role.key].Orgs[OUAgent.OUnit.organization.key] === "undefined"){
                    remap[role.key].Orgs[OUAgent.OUnit.organization.key] = {...OUAgent.OUnit.organization, OUnits: [] }
                }
                remap[role.key].Orgs[OUAgent.OUnit.organization.key].OUnits.push(OUAgent.OUnit)
            }
        }
        return <Card elevation={1}>
            <List>{remap.map((role, pos) => {
                return <CardContent key={pos}>
                    <Typography variant="h5" color="primary">{role.myNameIs}</Typography>
                    <Typography variant="caption">{role.description}</Typography><br/>
                    <Typography variant="body1"><b>Presso:</b></Typography>
                    <List>{role.Orgs.map((item, pos) => {
                        return <Fragment key={pos}>
                            <CardHeader
                                onClick={() => this.props.appMng.openAction('org', { key: item.key })}
                                avatar={<Avatar><DomainIcon/></Avatar>}
                                title={<Typography variant="h6" color="primary">{item.myNameIs}</Typography>}
                            />
                            <List className={this.props.classes.listIndent}>{item.OUnits.map((ounit, pos) => {
                                return <ListItem key={pos} button onClick={() => this.props.appMng.openAction('OUnit', { orgKey: item.key, key: ounit.key })}>
                                    <ListItemIcon><OpenInNewRoundedIcon/></ListItemIcon>
                                    <ListItemText primary={ounit.myNameIs}></ListItemText>
                                    <ListItemSecondaryAction onClick={() => {this.removeRoleInOUnit(ounit.key, role.key)}}>
                                        <IconButton edge="end" aria-label="rimuovi ruolo in unità organizzativa">
                                            <DeleteRoundedIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            })}</List>
                        </Fragment>})}
                    </List>
                    <Divider/>
                </CardContent>
            })}</List>
        </Card>
    }

    render() {
        const LastLoginDate = (this.state.loaded ? (this.state.data.accessInfo.last_login !== null? new Date(this.state.data.accessInfo.last_login.time) : 'mai acceduto') : null)
        return (
            <Fragment>
                <Card>
                    <CardMedia className={this.props.classes.headMedia + ((this.state.loaded && !this.state.data.status)?' ' + this.props.classes.disabledHeader : '')} image={mediaHeader}>
                        <Box p={3} className={this.props.classes.itemsHeaderText}>
                            <Typography variant="h4" color="inherit">
                                {this.state.loaded ? this.state.data.name : <Skeleton height="32px" width="100px" />}
                            </Typography>
                            {(this.state.loaded && !this.state.data.status)?
                            <Typography variant="subtitle1" color="inherit">
                                accesso disabilitato
                            </Typography>:null}
                        </Box>
                        {(this.state.loaded)?
                            <Fab aria-label="modifica i dati" title="modifica i dati" color="secondary" className={this.props.classes.fabEdit} onClick={this.editMe}>
                                <EditRoundedIcon/>
                            </Fab>:<Fragment></Fragment>
                        }
                        {(this.state.loaded)?
                            <Fab aria-label="più opzioni" title="più opzioni" color="primary" className={this.props.classes.fabMenu} onClick={(e) => this.showActionsMenu(e)}>
                                <MenuRoundedIcon/>
                            </Fab>:<Fragment></Fragment>
                        }
                    </CardMedia>
                    {this.state.loaded ?
                        <Fragment>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3} lg={2}>
                                        <PhoneRoundedIcon/>{this.state.data.phone?
                                        <Link variant="body1" href={"tel:" + encodeURIComponent(this.state.data.phone)}>
                                            {this.state.data.phone}
                                        </Link>:<Typography variant="caption">numero telefonico mancante</Typography>}
                                    </Grid>
                                    <Grid item xs={12} md={9} lg={10}>
                                        <EmailRoundedIcon/>{this.state.data.email?
                                        <Link variant="body1" href={"mailto:" + this.state.data.email}>
                                                {this.state.data.email}
                                        </Link>:<Typography variant="caption">indirizzo email mancante</Typography>}
                                    </Grid>
                                </Grid>
                            </CardContent>
                            {(this.state.loaded)?
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography variant="h6">Dati di accesso:</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <Typography variant="body1" color="secondary" component="span">Ultimo login:</Typography>&nbsp;
                                        <Typography variant="body1" component="span">{typeof LastLoginDate === "object" ? LastLoginDate.toLocaleString('it-IT',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: "2-digit", minute: '2-digit', second: '2-digit'}) : <Typography variant="caption">{typeof LastLoginDate === "string" ? LastLoginDate: "data indefinita"}</Typography>}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <Typography variant="body1" color="secondary" component="span">Numero di login:</Typography>&nbsp;
                                        <Typography variant="body1" component="span">{this.state.data.accessInfo.login_count}</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>: null}
                            {(this.state.loaded)?
                            <CardContent>
                                <Grid container direction="column">
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography variant="h6">Ruoli ricoperti:</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                    {(Array.isArray(this.state.data.rolesInOrganizationalUnit) && this.state.data.rolesInOrganizationalUnit.length > 0) ?
                                        <Fragment>
                                            {this.drawRoles()}
                                            <Button onClick={this.addRoleInOUnit}><AddBoxRoundedIcon /> Aggiungi ruolo in unità organizzativa</Button>
                                        </Fragment> :
                                        <Grid container spacing={3}>
                                            <Grid item>
                                                <Typography color="primary" variant="h6">Non ha alcun ruolo</Typography>
                                            </Grid>
                                            <Button onClick={this.addRoleInOUnit}><AddBoxRoundedIcon /> Aggiungi il primo ruolo</Button>
                                        </Grid>
                                    }
                                    </Grid>
                                </Grid>
                            </CardContent>: null}
                            <CardActions>
                                <Button onClick={this.closeMe}><ExitToAppRoundedIcon /> Chiudi scheda</Button>
                            </CardActions>
                        </Fragment>
                        :
                        <CardContent>
                            <Skeleton height="300px" width="100%" />
                        </CardContent>
                    }
                </Card >
                <Menu
                    anchorEl={this.state.actionsMenu.target}
                    open={Boolean(this.state.actionsMenu.target)}
                    keepMounted
                    onClose={() => this.hideActionsMenu()}
                >
                    {this.state.actionsMenu.items}
                </Menu>
                <DialogAddRole
                    appMng={this.props.appMng}
                    open={this.state.dialogs.addRole.open}
                    userKey={this.actionData.params.key}
                    onClose={() => {
                        this.getData()
                        this.setState((state => {
                            state.dialogs.addRole.open = false
                            return state
                        }));
                    }}
                />
            </Fragment>
        )
    }
}

export default withStyles(AppActionSysUserStyle)(AppActionSysUser)