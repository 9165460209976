import React, { Component, Fragment } from 'react'
import { Card, CardContent, Typography, CardActions, Button, CardMedia, Box, Grid, ListItem, ListItemText, DialogContentText, Divider, Menu, MenuItem, Fab, Avatar, Link, FormControl, InputLabel, OutlinedInput, FormHelperText, InputAdornment, List, ListItemIcon, CardHeader } from '@material-ui/core'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import RestoreRoundedIcon from '@material-ui/icons/RestoreRounded';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import DomainIcon from '@material-ui/icons/Domain';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import VpnKeyRoundedIcon from '@material-ui/icons/VpnKeyRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import AppActionAccountStyle from './AppActionAccountStyle';
import mediaHeaderViewer from '../../../../images/operators_1248.webp'
import mediaHeaderEditor from '../../../../images/editor_opt.svg'
import DialogChangePWD from '../../Dialogs/DialogChangePWD';
import { withStyles } from '@material-ui/styles';

class AppActionAccount extends Component {

    state = {
        mode: 'viewer',
        actionsMenu: {
            target: null
        },
        loaded: false,
        data: {
            myNameIs: '',
            name: ''
        },
        originalEmail: '',
        dialogs: {
            changePWD: {
                open: false
            }
        }
    }

    constructor(props) {
        super(props)
        console.log('initiating ' + this.props.id)
        this.actionData = this.props.appMng.actionsStack[this.props.id]
        this.props.appMng.setTitle('Il tuo account')
        this.getData()
    }

    getData() {
        this.props.appMng.props.JBLClient.getMe()
            .then(response => {
                this.setState({ loaded: true, data: {...response}, originalEmail: response.email })
                this.props.appMng.setActionPersistence(this.props.id, true)
            })
    }

    persistsData(ignoreEmail = false) {
        if(!ignoreEmail && this.state.originalEmail !== this.state.data.email){
            this.props.appMng.openDialog(<Fragment><WarningRoundedIcon/>Indirizzo eMail modificato</Fragment>, (
                <Fragment>
                    <DialogContentText id="main-dialog-description">
                        <p><b>Modificando il tuo indirizzo email, stai modificando anche il tuo indentificiativo per l'accesso al sistema.</b></p>
                        <p>Confermi di voler salvare le modifiche?</p>
                    </DialogContentText>
                </Fragment>),
                (<Fragment>
                    <Button aria-label="Conferma" onClick={() => {
                        this.persistsData(true)
                        this.props.appMng.closeDialog()
                    }} >Conferma</Button>
                    <Button aria-label="Annulla" onClick={() => {
                        this.abortEditMe()
                        this.props.appMng.closeDialog()
                    }}>Annulla</Button>
                </Fragment>))
        } else {
            let peristPromise = this.props.appMng.props.JBLClient.updateMe(this.state.data)
            peristPromise
                .then(result => {
                    if (result !== null) {
                        this.abortEditMe()
                        this.props.appMng.setActionPersistence(this.props.id, true)
                    }
                })
                .catch(error => {
                    console.error(error)
                    this.props.appMng.notifyMsgToGui({
                        'kind': 'error',
                        'text': "Si è verificato un errore imprevisto durante la registrazione dei dati."
                    })
                })
        }
    }

    setField(name, value) {
        let data = this.state.data
        let stepData = data
        let path = (Array.isArray(name)?name:[name])
        let lastName = path.splice(-1,1)
        for(let step of path){
            stepData = stepData[step]
        }
        if (stepData[lastName] !== value) {
            this.props.appMng.setActionPersistence(this.props.id, false)
            stepData[lastName] = value
            this.setState({ data: data })
        }
    }

    editMe = () => { this.setState({ mode: "dataEditor" })}

    abortEditMe = () => {
        this.getData()
        this.setState({ mode: "viewer" })
    }

    closeMe = () => {this.props.appMng.closeAction(this.props.id)}

    changePassword = () => {
        this.hideActionsMenu()
        this.setState((state => {
            state.dialogs.changePWD.open = true
            return state
        }))
    }

    showActionsMenu(e) {
        let items = []
        items.push(
        <MenuItem key="1" onClick={this.editMe}><ListItemIcon><EditRoundedIcon/></ListItemIcon><ListItemText>Modifica dati</ListItemText></MenuItem>,
        <MenuItem key="2" onClick={this.changePassword}><ListItemIcon><VpnKeyRoundedIcon/></ListItemIcon><ListItemText>Modifica password</ListItemText></MenuItem>,
        <MenuItem key="4" onClick={() => this.props.appMng.handleLogout()}><ListItemIcon><ExitToAppRoundedIcon/></ListItemIcon><ListItemText>Termina sessione</ListItemText></MenuItem>,
        <MenuItem key="300" onClick={this.closeMe}><ListItemIcon><HighlightOffRoundedIcon/></ListItemIcon><ListItemText>Chiudi scheda</ListItemText></MenuItem>
        )
        this.setState({
            actionsMenu: {
                target: e.target,
                items: items,
            }
        })
    }

    hideActionsMenu() {
        this.setState({ actionsMenu: { target: null } })
    }

    mediaHeader() {
        switch (this.state.mode) {
            case 'dataEditor':
                return mediaHeaderEditor
            case 'viewer':
            default:
                return mediaHeaderViewer
        }
    }

    drawFields(fname) {
        switch (fname) {
            case 'name':
                switch (this.state.mode) {
                    case 'dataEditor':
                        return <FormControl fullWidth variant="outlined" margin="normal">
                                <InputLabel htmlFor="outlined-adornment-name">Nome e cognome</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-name"
                                    placeholder="es. Johnny Cash"
                                    value={this.state.data.name? this.state.data.name : ''}
                                    onChange={e => this.setField('name', e.target.value)}
                                    startAdornment={<InputAdornment position="start"><PersonRoundedIcon/></InputAdornment>}
                                    labelWidth={150}
                                />
                                <FormHelperText>tuo nome completo</FormHelperText>
                               </FormControl>
                    case 'viewer':
                    default:
                        return null
                }
            case 'phone':
                switch (this.state.mode) {
                    case 'dataEditor':
                        return <FormControl fullWidth variant="outlined" margin="normal">
                                <InputLabel htmlFor="outlined-adornment-phone">Telefono</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-phone"
                                    placeholder="es. +390244456321"
                                    value={this.state.data.phone? this.state.data.phone : ''}
                                    onChange={e => this.setField('phone', e.target.value)}
                                    startAdornment={<InputAdornment position="start"><PhoneRoundedIcon/></InputAdornment>}
                                    labelWidth={100}
                                />
                                <FormHelperText>tuo numero di telefono</FormHelperText>
                               </FormControl>
                    case 'viewer':
                    default:
                        return <Fragment><PhoneRoundedIcon/>{this.state.data.phone?
                                    <Link variant="body1" href={"tel:" + encodeURIComponent(this.state.data.phone)}>
                                        {this.state.data.phone}
                                    </Link>:<Typography variant="caption">numero telefonico mancante</Typography>}
                                </Fragment>
                }
                case 'email':
                    switch (this.state.mode) {
                        case 'dataEditor':
                            return <FormControl fullWidth variant="outlined" margin="normal">
                                    <InputLabel htmlFor="outlined-adornment-email">eMail</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-email"
                                        placeholder="es. johnny.cash@songs.music"
                                        value={this.state.data.email? this.state.data.email : ''}
                                        onChange={e => this.setField('email', e.target.value)}
                                        startAdornment={<InputAdornment position="start"><EmailRoundedIcon/></InputAdornment>}
                                        labelWidth={70}
                                    />
                                    <FormHelperText>tuo indirizzo email</FormHelperText>
                                   </FormControl>
                        case 'viewer':
                        default:
                            return <Fragment><EmailRoundedIcon/> {this.state.data.email?
                                        <Link variant="body1" href={"mailto:" + this.state.data.email}>
                                            {this.state.data.email}
                                        </Link>:<Typography variant="caption">indirizzo email mancante</Typography>}
                                    </Fragment>
                    }
            default:
                break;
        }
    }

    drawActions(){
        const save = <Button key="1" onClick={() => this.persistsData()}><SaveRoundedIcon />Salva modifiche</Button>
        const abort = <Button key="2" onClick={this.abortEditMe}><RestoreRoundedIcon /> Annulla</Button>
        const exit = <Button key="3" onClick={this.closeMe}><ExitToAppRoundedIcon /> Chiudi scheda</Button>

        switch (this.state.mode) {
            case 'dataEditor':
                return [save, abort]
            case 'viewer':
            default:
                return [exit]
        }
    }

    drawRoles(){
        const remap = []
        for (const OUAgent of this.state.data.rolesInOrganizationalUnit) {
            for (const role of OUAgent.roles) {
                if(typeof remap[role.key] === "undefined"){
                    remap[role.key] = {...role, Orgs: [] }
                }
                if(typeof remap[role.key].Orgs[OUAgent.OUnit.organization.key] === "undefined"){
                    remap[role.key].Orgs[OUAgent.OUnit.organization.key] = {...OUAgent.OUnit.organization, OUnits: [] }
                }
                remap[role.key].Orgs[OUAgent.OUnit.organization.key].OUnits.push(OUAgent.OUnit)
            }
        }
        return <Card elevation={1}>
            <List>{remap.map((item, pos) => {
                return <CardContent key={pos}>
                    <Typography variant="h5" color="primary">{item.myNameIs}</Typography>
                    <Typography variant="caption">{item.description}</Typography><br/>
                    <Typography variant="body1"><b>Presso:</b></Typography>
                    <List>{item.Orgs.map((item, pos) => {
                        return <Fragment key={pos}>
                            <CardHeader
                                onClick={() => this.props.appMng.openAction('org', { key: item.key })}
                                avatar={<Avatar><DomainIcon/></Avatar>}
                                title={<Typography variant="h6" color="primary">{item.myNameIs}</Typography>}
                            />
                            <List className={this.props.classes.listIndent}>{item.OUnits.map((ounit, pos) => {
                                return <ListItem key={pos} button onClick={() => this.props.appMng.openAction('OUnit', { orgKey: item.key, key: ounit.key })}>
                                    <ListItemIcon><OpenInNewRoundedIcon/></ListItemIcon>
                                    <ListItemText primary={ounit.myNameIs}></ListItemText>
                                </ListItem>
                            })}</List>
                        </Fragment>})}
                    </List>
                    <Divider/>
                </CardContent>
            })}</List>
        </Card>
    }

    render() {
        const LastLoginDate = (this.state.loaded ? new Date(this.state.data.accessInfo.last_login.time) : null)
        return (
            <Fragment>
                <Card>
                    <CardMedia className={this.props.classes.headMedia} image={this.mediaHeader()}>
                        <Box p={3} className={this.props.classes.itemsHeaderText}>
                            <Typography variant="h6" color="inherit" component="span">
                                Tu sei
                            </Typography>&nbsp;
                            <Typography variant="h4" color="inherit" component="span">
                                {this.state.loaded ? (this.state.data.name?this.state.data.name:<i style={{color: "red"}}>nome mancante!</i>) : <Skeleton height="32px" width="100px" />}
                            </Typography>
                        </Box>
                        {(this.state.loaded && this.state.mode === 'viewer')?
                            <Fab aria-label="modifica i tuoi dati" title="modifica i dati" color="secondary" className={this.props.classes.fabEdit} onClick={this.editMe}>
                                <EditRoundedIcon/>
                            </Fab>:<Fragment></Fragment>
                        }
                        {(this.state.loaded && this.state.mode === 'viewer')?
                            <Fab aria-label="più opzioni" title="più opzioni" color="primary" className={this.props.classes.fabMenu} onClick={(e) => this.showActionsMenu(e)}>
                                <MenuRoundedIcon/>
                            </Fab>:<Fragment></Fragment>
                        }
                    </CardMedia>
                    {this.state.loaded ?
                        <Fragment>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        {this.drawFields('name')}
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={2}>
                                        {this.drawFields('phone')}
                                    </Grid>
                                    <Grid item xs={12} md={9} lg={10}>
                                        {this.drawFields('email')}
                                    </Grid>
                                </Grid>
                            </CardContent>
                            {(this.state.loaded && this.state.mode === 'viewer')?
                            <CardActions>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography variant="h6">Dati di accesso:</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <Typography variant="body1" color="secondary" component="span">Ultimo login:</Typography>&nbsp;
                                        <Typography variant="body1" component="span">{LastLoginDate? LastLoginDate.toLocaleString('it-IT',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: "2-digit", minute: '2-digit', second: '2-digit'}) : <Typography variant="caption">data indefinita</Typography>}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <Typography variant="body1" color="secondary" component="span">Numero di login:</Typography>&nbsp;
                                        <Typography variant="body1" component="span">{this.state.data.accessInfo.login_count}</Typography>
                                    </Grid>
                                </Grid>
                            </CardActions>: null}
                            {(this.state.loaded && this.state.mode === 'viewer')?
                            <CardActions>
                                <Grid container direction="column">
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography variant="h6">Ruoli ricoperti:</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                    {(Array.isArray(this.state.data.rolesInOrganizationalUnit) && this.state.data.rolesInOrganizationalUnit.length > 0) ?
                                        this.drawRoles() :
                                        <Grid container spacing={3}>
                                            <Grid item>
                                                <Typography color="primary" variant="h6">Non hai ruoli associati</Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                    </Grid>
                                </Grid>
                            </CardActions>: null}
                            <CardActions>
                                {this.drawActions()}
                            </CardActions>
                        </Fragment>
                        :
                        <CardContent>
                            <Skeleton height="300px" width="100%" />
                        </CardContent>
                    }
                </Card >
                <Menu
                    anchorEl={this.state.actionsMenu.target}
                    open={Boolean(this.state.actionsMenu.target)}
                    keepMounted
                    onClose={() => this.hideActionsMenu()}
                >
                    {this.state.actionsMenu.items}
                </Menu>
                <DialogChangePWD
                    appMng={this.props.appMng}
                    open={this.state.dialogs.changePWD.open}
                    onClose={() => {
                        this.setState((state => {
                            state.dialogs.changePWD.open = false
                            return state
                        }));
                    }}
                />
            </Fragment>
        )
    }
}

export default withStyles(AppActionAccountStyle)(AppActionAccount)