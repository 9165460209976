import React, { Component } from 'react'
import FileGenericIcon from '../../images/file-generic-opt.svg'
import { Grid, Typography, withStyles, Menu, MenuItem, ListItemIcon, ListItemText, ListItem, ClickAwayListener, TextField } from '@material-ui/core'
import myStyle from './style';
import HelperFileTransfer from '../LoggedInApp/Helpers/HelperFileTransfer'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

class JFile extends Component {

    state = {
        actionsMenu: {
            target: null
        },
        renaming: false,
        filename: ''
    }

    nameTextBoxRef = null

    constructor(props) {
        super(props)
        this.state.filename = this.props.data ? this.props.data.name : ''
    }

    showActionsMenu(e) {
        let items = []
        const classes = this.props.classes
        const filename = (this.props.data && this.props.data.name) ? this.props.data.name : <i>unnamed file</i>
        const fileTransfer = new HelperFileTransfer(this.props.appMng)
        fileTransfer.setUploadURIGenerator(file => this.props.appMng.props.JBLClient.getFileUploadLink(this.props.data.key))
        fileTransfer.setDownloadURIGenerator(() => this.props.appMng.props.JBLClient.getFileDownloadLink(this.props.data.key))
        items.push(
            <ListItem key={0}><Typography className={classes.filename}><b>{filename}</b></Typography></ListItem>,
            <MenuItem key={1} onClick={() => {
                this.setState({renaming: true},() => setTimeout(()=> {if(this.nameTextBoxRef){this.nameTextBoxRef.focus()}},200))
                this.hideActionsMenu()
            }}><ListItemIcon><EditIcon/></ListItemIcon><ListItemText>Rinomina</ListItemText></MenuItem>,
            <MenuItem key={2} onClick={() => {
                fileTransfer.download(this.props.data.name).then(()=>this.hideActionsMenu())
            }}><ListItemIcon><CloudDownloadIcon/></ListItemIcon><ListItemText>Scarica</ListItemText></MenuItem>,
            <MenuItem key={3} component="label">
                <ListItemIcon><CloudUploadIcon/></ListItemIcon><ListItemText>
                Sostituisci/Aggiorna
                <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={e => {
                        fileTransfer.upload(e.target.files[0]).then(() => {
                            if(this.props.onChange === 'function') {
                                this.props.onChange(this.props.data.key)
                            }
                            this.hideActionsMenu()
                        })
                    }}
                />
                </ListItemText>
            </MenuItem>,
            (typeof this.props.onDelete === 'function' ? <MenuItem key={4} onClick={() => {
                    this.props.onDelete(this.props.data.key).then(() => this.hideActionsMenu())
                }}><ListItemIcon><DeleteForeverIcon/></ListItemIcon><ListItemText>Elimina</ListItemText></MenuItem> : null
            ),
        )
        this.setState({
            actionsMenu: {
                target: e.target,
                items: items,
            }
        })
    }

    hideActionsMenu() {
        this.setState({ actionsMenu: { target: null } })
    }

    rename = async () => {
        const newName = this.state.filename.trim()
        if(this.props.data && this.props.data.name !== newName) {
            this.props.appMng.props.JBLClient.renameFile(this.props.data.key, newName).then(
                response => {
                    if(this.props.onChange === 'function') {
                        this.props.onChange(this.props.data.key)
                    }
                    this.setState({renaming: false, filename: response ? response : this.props.data.name})
                }
            )
        }
    }

    render() {
        const classes = this.props.classes
        const filename = this.state.filename ? this.state.filename : <i>senza nome</i>
        return <div className={classes.box}>
            <Grid container spacing={2} direction="column" alignItems="center" className={classes.boxContent} onClick={e => this.state.renaming ? null : this.showActionsMenu(e)}>
                <Grid item>
                    <img src={FileGenericIcon} alt='generic file' style={{width: "100%"}}/>
                </Grid>
                <Grid item>{this.state.renaming ?
                    <ClickAwayListener onClickAway={this.rename}>
                        <TextField
                            inputRef={ref => this.nameTextBoxRef = ref}
                            value={this.state.filename}
                            onChange={e=>this.setState({filename: e.target.value})}
                            onBlur={this.rename}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    this.rename()
                                } else if(e.key === 'Escape') {
                                    this.setState({renaming: false, filename: this.props.data.name})
                                }
                            }}
                        />
                    </ClickAwayListener>
                        : <Typography className={classes.filename} title={filename}>{filename}</Typography>
                }</Grid>
            </Grid>
            <Menu
                anchorEl={this.state.actionsMenu.target}
                open={Boolean(this.state.actionsMenu.target)}
                keepMounted
                onClose={() => this.hideActionsMenu()}
            >
                {this.state.actionsMenu.items}
            </Menu>
        </div>
    }
}

export default withStyles(myStyle)(JFile)