import axios from 'axios';

class HelperFileTransfer {

    appMng = null
    UploadURIGenerator = null
    DownloadURIGenerator = null

    constructor(appMng){
        this.appMng = appMng
    }

    setUploadURIGenerator(URIGenerator) {
        if(typeof URIGenerator === "function") {
            this.UploadURIGenerator = URIGenerator
            return true
        } else {
            console.error("missing upload URI generator")
            return false
        }
    }

    setDownloadURIGenerator(URIGenerator) {
        if(typeof URIGenerator === "function") {
            this.DownloadURIGenerator = URIGenerator
            return true
        } else {
            console.error("missing download URI generator")
            return false
        }
    }

    async upload(file) {
        return new Promise((resolve) => {
            if(!(typeof file === "object" && file instanceof File)) {
                this.appMng.notifyMsgToGui({
                    'kind': 'info',
                    'text': "Nessun file da caricare"
                })
                resolve(null)
            }
            if(typeof this.UploadURIGenerator !== "function") {
                console.error("missing upload URI generator")
                resolve(null)
            }
            this.UploadURIGenerator(file)
            .then(uploadURL => {
                if(uploadURL) {
                    axios.put(uploadURL, file, {
                        headers: {
                            'Content-Type': file.type
                        }
                    }).then(res => { // then print response status
                        if(typeof res === "object" && res.statusText === "OK") {
                            console.log('file sent...')
                            resolve(true)
                        }
                    }).catch(error => {
                        console.log(error)
                        resolve(null)
                    })
                } else {
                    resolve(null)
                }
            })
        })
    }

    async download(name) {
        return this.DownloadURIGenerator()
            .then(response => {
                if(typeof response === "string") {
                    return fetch(response).then(response => {
                        return response.blob().then(blob => {
                                let url = window.URL.createObjectURL(blob);
                                let a = document.createElement('a');
                                a.href = url;
                                a.download = name;
                                return a.click();
                            })
                    });
                }
        })
    }
}

export default HelperFileTransfer