import axios from 'axios';

class HelperGraveyardMapImage {

    key = null
    appMng = null
    virtPath = null

    constructor(appMng){
        this.appMng = appMng
    }

    setKey(key) {
        this.key = key
        this.virtPath = '/planimetries/' + this.key
    }

    async load(options = {}) {

        const fetchImage = (resolve, cache) => {
            console.log('try to fetch: ')
            this.appMng.props.JBLClient.getGraveyardMapLink(this.key)
            .then(objectLink => {
                if(typeof objectLink === "string") {
                    fetch(objectLink, {cache: "no-store"})
                    .then(imgRes => {
                        if(typeof imgRes === "object" && imgRes.ok) {
                            console.log('got from fetch ')
                            if(typeof options.imageDateAfter === "object") {
                                const fetchTime = new Date(imgRes.headers.get('date'))
                                if(fetchTime < options.imageDateAfter) {
                                    console.log('fetchTries: ' + options.fetchTries)
                                    if(options.fetchTries > 10) {
                                        console.log('max fetchTries! ' + options.fetchTries)
                                        resolve(null)
                                    }
                                    setTimeout(() => this.load({...options, fetchTries: options.fetchTries + 1}).then(result => resolve(result)), (options.fetchTries * 1000) + 1000)
                                }
                            }
                            cache.delete(this.virtPath).then(() =>
                                cache.put(this.virtPath, imgRes).then(() => this.load().then(result => resolve(result)))
                            )
                        } else {
                            console.log('faild to fetch image from ' + objectLink)
                            resolve(null)
                        }
                    })
                } else {
                    console.log('no link to image')
                    resolve(null)
                }
            })
        }

        return new Promise((resolve) => {
            if(typeof this.key !== "string") {
                resolve(null)
            } else {
                this.appMng.props.JBLClient.getGraveyard(this.key)
                .then(graveyardData => {
                    if(typeof graveyardData === "object" && graveyardData.map.type === 'systemHostedImage') {
                        caches.open('planimetries').then(cache => {
                            console.log('loading image for: ' + this.virtPath)
                            if(! options.noCache) {
                                cache.match(this.virtPath)
                                .then(imgRes => {
                                    if(typeof imgRes === "object" && imgRes.ok) {
                                        console.log('found in cache: ' + imgRes.ok)
                                         const fetchTime = new Date(imgRes.headers.get('date'))
                                         if((typeof options.imageDateAfter === "object" && fetchTime < options.imageDateAfter) && fetchTime < new Date('-1 hour')) {
                                            if(fetchTime < options.imageDateAfter) {
                                                fetchImage(resolve, cache)
                                            }
                                         } else {
                                            imgRes.blob().then(blob => {
                                                resolve(URL.createObjectURL(blob))
                                             })
                                         }

                                    } else {
                                        fetchImage(resolve, cache)
                                    }
                                })
                            } else {
                                fetchImage(resolve, cache)
                            }
                        })
                    } else {
                        resolve(null)
                    }
                })
            }
        })
    }

    async upload(e) {
        return new Promise((resolve) => {
            if(typeof this.key !== "string") {
                resolve(null)
            } else {
                const file = e.target.files[0]
                if(typeof file === "undefined") {
                    this.appMng.notifyMsgToGui({
                        'kind': 'info',
                        'text': "Nessuna immagine da caricare"
                    })
                    resolve(null)
                }
                if(file.type !== "image/png" && file.type !== "image/jpeg"){
                    this.appMng.notifyMsgToGui({
                        'kind': 'error',
                        'text': "Sono supportate solo immagini PNG o JPG"
                    })
                    resolve(null)
                }
                if(file.size > 12582912){
                    this.appMng.notifyMsgToGui({
                        'kind': 'error',
                        'text': "La dimensione del file immagine da caricare non può superare i 12MB. Il file da te scelto è di " + (Math.ceil( file.size / 10485.76 ) / 100) + "MB"
                    })
                    resolve(null)
                }
                this.appMng.props.JBLClient.getGraveyardUploadMapLink(this.key, {name: file.name, type: file.type, size: file.size})
                .then(uploadURL => {
                    if(uploadURL) {
                        const upTime = new Date();
                        axios.put(uploadURL, file, {
                            headers: {
                                'Content-Type': file.type
                            }
                        }).then(res => { // then print response status
                            if(typeof res === "object" && res.statusText === "OK") {
                                console.log('image sent...')
                                setTimeout(() => this.load({noCache: true, imageDateAfter: upTime}).then(result => resolve(result)), 1000)
                            }
                        }).catch(error => {
                            console.log(error)
                            resolve(null)
                        })
                    } else {
                        resolve(null)
                    }
                })
            }
        })
    }
}

export default HelperGraveyardMapImage