/** Generic query element
 *
 */
class queryItem {
    constructor(name) {
        if (typeof name === 'string' && name) {
            this.name = name;
            this.items = []
        } else {
            throw new Error({ msg: "missing name of the GraphQL query'item", n: 500002 })
        }
    }

    getName() {
        return this.name
    }

    writeItems() {
        let segment = '';
        if (this.items.length > 0) {
            segment = segment + '{';
            for (let item of this.items) {
                segment = segment + item.toString();
            }
            segment = segment + '}';
        }
        return segment;
    }

    addField(fieldName, args = [], alias = null) {
        let newItem = new queryField(fieldName, args, alias);
        this.items.push(newItem);
        return newItem;
    }

    addFields(fieldsConf) {
        if (typeof fieldsConf === 'object' || Array.isArray(fieldsConf)) {
            for (let fieldName in fieldsConf) {
                let fConfig = fieldsConf[fieldName]
                if (typeof fConfig === 'string') {
                    fieldName = fConfig;
                    fConfig = {};
                }
                let newItem = new queryField(fieldName, fConfig.args, fConfig.alias);
                if (typeof fConfig.fields === 'object' || Array.isArray(fieldsConf)) {
                    newItem.addFields(fConfig.fields);
                }
                this.items.push(newItem);
            }
        }
        return this;
    }

    addInlineFragment(typeName) {
        let newItem = new queryInlineFragment(typeName);
        this.items.push(newItem);
        return newItem;
    }

}


class queryField extends queryItem {
    constructor(name, args = {}, alias = null) {
        super(name)
        this.args = {}
        if (typeof args == 'object' || Array.isArray(args)) {
            for (let argName in args) {
                let argVal = args[argName]
                this.args[argName] = argVal;
            }
        }
        if (typeof alias === 'string') {
            this.alias = alias;
        }
    }

    toString() {
        return ((typeof this.alias === 'string' && this.alias) ? this.alias + ': ' : '') + this.name + this.writeArgs() + this.writeItems() + ' ';
    }

    writeArgs() {
        let segment = [];
        for (let argName in this.args) {
            let argVal = this.args[argName]
            segment.push(argName + ': ' + (typeof argVal === 'object' || Array.isArray(argVal) ? this.writeObjArgs(argVal) : this.writeScalarArgs(argVal)));
        }
        return (segment.length > 0 ? '(' + segment.join(", ") + ')' : '');
    }

    writeObjArgs(objArg) {
        let ret = [];
        for (let argName in objArg) {
            let argVal = objArg[argName]
            let argStr = (typeof argVal === 'object' || Array.isArray(argVal) ? this.writeObjArgs(argVal) : this.writeScalarArgs(argVal))            
            ret.push(Array.isArray(objArg)?argStr:  argName + ': ' + argStr);
        }
        return (Array.isArray(objArg)?"[":"{") + ret.join(', ') + (Array.isArray(objArg)?"]":"}");
    }

    writeScalarArgs(argVal) {
        let prefix = (typeof argVal === 'string' ? argVal.substring(0, 1) : '');
        return (prefix === '$' ? argVal : (prefix === '&' ? argVal.substring(1) : JSON.stringify(argVal)));
    }
}

class queryInlineFragment extends queryItem {

    toString() {
        return '... on ' + this.name + this.writeItems() + ' ';
    }

}

class JGQLComposer extends queryItem {

    constructor(name = 'query', operationName = null, vars = {}) {
        super(name);
        this.vars = {}
        if (name === 'query' || name === 'mutation') {
            if (operationName) {
                this.setOperationName(operationName);
                this.setVars(vars);
            } else if (vars && !operationName) {
                throw new Error({ msg: "in order to define variables the operation name must be given", n: 500003 })
            }
        } else {
            throw new Error({ msg: 'unsupported root query', n: 500001 })
        }
    }

    setOperationName(operationName) {
        if (typeof operationName === 'string') {
            this.operationName = operationName;
            return true;
        } else {
            throw new Error({ msg: "unsupported GraphQL operation name", n: 500004 })
        }
    }

    setVars(vars) {
        if (typeof vars === 'object') {
            this.vars = vars;
            return true;
        } else {
            throw new Error({ msg: "unsupported GraphQL varaibles list", n: 500005 })
        }
    }

    toString() {
        let vars = []
        if (typeof this.vars === 'object') {
            for (let varname in this.vars) {
                let varDef = this.vars[varname]
                vars.push("$" + varname + ": " + (((typeof varDef === 'object' && typeof varDef.type === 'string')) ? varDef.type : (typeof varDef === 'string' ? varDef : 'undefined')));
            }
        }
        return this.name + ' ' + (this.operationName ? this.operationName + (vars.length > 0 ? '(' + vars.join(', ') + ')' : '') : '') + this.writeItems();
    }
}

export default JGQLComposer