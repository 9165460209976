import { createStyles } from "@material-ui/core";

const AppActionGraveyardStyle = ((theme) => createStyles({
    headMedia: {
        position: "relative",
        height: '160px',
        color: theme.palette.primary.contrastText
    },
    hiddenElement: {
        display: 'none'
    },
    itemsHeader: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText
    },
    itemsHeaderText: {
        background: "linear-gradient(180deg, rgba(0,0,0,0.6194852941176471) 0%, rgba(0,0,0,0.09287464985994398) 88%, rgba(0,0,0,0) 100%)",
        color: theme.palette.primary.contrastText
    },
    fabEdit: {
        position: "absolute",
        right: theme.spacing(2),
        bottom: -1 * theme.spacing(2),
    },
    fabMenu: {
        position: "absolute",
        right: theme.spacing(10),
        bottom: -1 * theme.spacing(2),
    },
    entityLine: {
        marginTop: theme.spacing(4),
        borderBottom: "1px solid lightgray"
    },
    parentLink: {
        cursor: "pointer",
        textDecoration: "underline"
    },
    elementLabel: {
        backgroundColor: "rgba(240,240,240,0.9)",
        padding: theme.spacing(0.8),
        borderRadius: theme.spacing(0.5),
        border: "1px solid rgba(230,230,230,0.3)",
        boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
        position: "relative",
        top: theme.spacing(-1.2),
        left: theme.spacing(-1),
        '&:before': {
            content: '""',
            borderTop: "11px solid " + theme.palette.primary.dark,
            borderLeft: "8px solid transparent",
            borderRight: "8px solid transparent",
            position: "absolute",
            bottom: "-12px",
            left: theme.spacing(0.8)
        }
    },
    element: {
        '&:hover': {
            strokeWidth: "1",
            stroke: theme.palette.primary.dark
        }
    },
    treeItemElement: {
        '&#treeRoot': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        },
        borderRadius: theme.spacing(2),
        width: "fit-content",
        backgroundColor: theme.palette.grey['200'],
        margin: theme.spacing(0.5),
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
        '&>div:first-child': {
            display: "inline-block",
            width: theme.spacing(2),
            height: theme.spacing(2),
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(0.25),
            borderRadius: '50%',
            border: '1px solid ' + theme.palette.grey['500'],
            verticalAlign: 'middle'
        }
    }
}))

export default AppActionGraveyardStyle