import React, { Component, Fragment } from 'react'
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Divider, Fab, withStyles, Card, CardMedia, Box, Typography, CardContent } from '@material-ui/core'
import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';
import AddIcon from '@material-ui/icons/Add';
import AppActionGraveyardsStyle from './AppActionGraveyardsStyle';
import mediaHeader from '../../../../images/cemetery_1248.webp'

class AppActionGraveyards extends Component {

    state = {
        objList: (
            <div>Recupero elenco</div>
        )
    }

    constructor(props) {
        super(props)
        this.props.appMng.setTitle('Cimiteri')
        this.updateList()
    }

    updateList() {
        this.props.appMng.props.JBLClient.getGraveyards().then(orgs => {
            let listOrgs = (
                <List>
                    {orgs.map((itemData, itemPos) =>
                        (<Fragment key={itemData.key} >
                            {itemPos > 0 ? <Divider variant="inset" component="li" /> : ''}
                            < ListItem button onClick={() => this.props.appMng.openAction('graveyard', { key: itemData.key })}>
                                <ListItemAvatar>
                                    <Avatar className={this.props.classes.avatarActive}>
                                        <AccountBalanceRoundedIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={itemData.myNameIs} secondary={itemData.fullAddress ? itemData.fullAddress : itemData.sys_name} />
                            </ListItem>
                        </Fragment>
                        ))}
                </List>
            )
            this.setState({ objList: listOrgs })
        })
    }

    render() {
        return (
            <Card >
                <CardMedia className={this.props.classes.headMedia} image={mediaHeader} title="Elenco dei cimiteri gestiti dal sistema">
                    <Box p={3} className={this.props.classes.itemsHeaderText}>
                        <Typography variant="h4" color="inherit">
                            Elenco dei cimiteri gestiti dal sistema
                        </Typography>
                    </Box>
                    <Fab aria-label="registra nuovo cimitero" title="registra nuovo cimitero" color="secondary" className={this.props.classes.fabAdd} onClick={() => this.props.appMng.openAction('graveyardEditor',{bgPolys: []})}>
                        <AddIcon/>
                    </Fab>
                </CardMedia>
                <CardContent>
                    {this.state.objList}
                </CardContent>
            </Card>
        )
    }
}

export default withStyles(AppActionGraveyardsStyle)(AppActionGraveyards)