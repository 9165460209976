import React, { Component, Fragment } from 'react'
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import SignInStyle from './SignInStyle';
import { withSnackbar } from 'notistack';
import { withStyles, IconButton, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Logo from '../../images/logo_mono.svg'
import LogoGiardy from '../../images/logo-il-giardinonep.png'
import { ReactComponent as LogoBlys } from '../../images/blysBlys.svg'

class SignIn extends Component {
    state = {
        mode: 'login',
        userid: '',
        password: '',
        newPassword: '',
        repeatNewPassword: '',
        email: ''
    }

    displayError(message) {
        const action = (key) => (
          <IconButton key="close" aria-label="close" color="inherit" onClick={() => { this.props.closeSnackbar(key); }}>
            <CloseIcon />
          </IconButton>)

        let boxDef = {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          action
        }
        boxDef.variant = 'error';
        this.props.enqueueSnackbar(message, boxDef)
      }


    _confirm(event) {
        event.preventDefault();
        switch (this.state.mode) {
            case 'login':
                this.props.JBLClient.login(this.state.userid, this.state.password).then(
                    (retCodeList) => this.evalRetCode(retCodeList)
                );
                break;
            case 'changePassword':
                this.props.JBLClient.changePassword(this.state.userid, this.state.password, this.state.newPassword, this.state.repeatNewPassword).then(
                    (retCodeList) => this.evalRetCode(retCodeList)
                );
                break;
            case 'lostPassword':
                this.props.JBLClient.answerQuestion(this.state.email, this.state.email).then(
                    () => this._reset()
                );
                break;
            default:
                this.displayError('azione indefinita in modalità ' + this.state.mode);
        }
    }

    evalRetCode(retCodeList){
        let mode = this.state.mode
        if(retCodeList){
            for (const retCode of retCodeList) {
                switch (retCode) {
                    case 650:
                        mode = 'changePassword'
                        break;
                    default:
                        break;
                }
                if(mode !== this.state.mode){
                    this.setState({ mode: mode })
                    break
                }
            }
        }
    }

    _reset(event){
        if(event){
            event.preventDefault();
        }
        const defaultState = {
            ...this.state,
            mode: 'login',
            password: '',
            newPassword: '',
            repeatNewPassword: '',
            email: ''
        }
        this.setState(defaultState)
    }

    render() {
        const { mode, userid, password, newPassword, repeatNewPassword, email } = this.state
        const classes = this.props.classes
        return (
            <Container component="main" maxWidth="xs" className={classes.container}>
                <CssBaseline />
                <Paper elevation={3} className={classes.paper}>
                    <img src={Logo} alt="Koiman" style={{width: "80%"}}/>
                    <Typography component="h1" variant="h5">
                        {mode === 'login' ? "Accesso al sistema" :
                        (mode === 'changePassword' ? "Modifica la password":
                        (mode === 'lostPassword' ? "Recupera password": null))}
                    </Typography>
                    {mode === 'login' ?
                    <Typography variant="body1">
                        inserisci le tue credenziali per accedere al sistema
                    </Typography> :
                    (mode === 'changePassword' ?
                    <Typography variant="body1">
                        è necessario cambiare la parola segreta.<br/>
                        La nuova password deve essere lunga <b>almeno 8 caratteri</b>, contenere <b>almeno una lettera maiuscola, una minusola e una cifra numerica</b>.<br/>
                        Scegli una password sicura.
                        <Link variant="body1" target="_blank" href="https://www.wikihow.it/Scegliere-una-Password-Sicura">Guarda qualche sugerimento</Link>
                    </Typography> :
                    (mode === 'lostPassword' ?
                    <Typography variant="body1">
                        riporta l'indirizzo email associato al tuo account e richiedi di reimpostare la password.<br/>
                        <b>Ti invieremo un messaggio contenente le istruzioni su come proseguire.</b>
                    </Typography>: null))}
                    <form className={classes.form} noValidate>
                        {mode === 'lostPassword' ?
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="indirizzo email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={e => this.setState({ email: e.target.value })}
                        />: null
                        }
                        {(mode === 'login' || mode === 'changePassword') ?
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            disabled={mode === 'changePassword'}
                            id="userid"
                            label="Identificativo"
                            name="userid"
                            autoComplete="userid"
                            autoFocus
                            value={userid}
                            onChange={e => this.setState({ userid: e.target.value })}
                        />: null
                        }
                        {mode === 'login' ?
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={e => this.setState({ password: e.target.value })}
                        />:
                        (mode === 'changePassword' ?
                            <Fragment>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="newpassword"
                                    label="Nuova password"
                                    type="password"
                                    id="newpassword"
                                    autoComplete="off"
                                    value={newPassword}
                                    onChange={e => this.setState({ newPassword: e.target.value })}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="newpassword"
                                    label="Ripeti nuova password"
                                    type="password"
                                    id="repeatnewpassword"
                                    autoComplete="off"
                                    value={repeatNewPassword}
                                    onChange={e => this.setState({ repeatNewPassword: e.target.value })}
                                />
                            </Fragment>
                        : null)}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={(e) => this._confirm(e)}
                        >
                            {mode === 'login' ? "Accedi" :
                            (mode === 'changePassword' ? "Cambia":
                            (mode === 'lostPassword' ? "Reimposta": null))}
                        </Button>
                        {(mode === 'changePassword' || mode === 'lostPassword')?
                        <Button
                            type="submit"
                            fullWidth
                            variant="text"
                            onClick={(e) => this._reset(e)}
                        >
                            Annulla
                        </Button>: null}
                        <Grid container>
                            {mode === 'login' ?
                            <Grid item xs>
                                <Link href="#" variant="body2" onClick={e => this.setState({ mode: 'lostPassword', email: this.state.userid })}>
                                    Hai dimenticato la password?
                                </Link>
                            </Grid> : null}
                        </Grid>
                    </form>
                    <Grid container spacing={1} alignItems="center" direction="column" style={{marginTop:"32px"}}>
                        <Grid item xs={12}><Typography variant="caption">{`${process.env.REACT_APP_NAME} v${process.env.REACT_APP_VERSION}`}</Typography></Grid>
                        <Grid item xs={12}><Typography variant="caption">servizio di <Link href="https://www.ilgiardinone.it/">il Giardinone Soc. Coop. Soc. <img src={LogoGiardy} alt="Logo il Giardinone" style={{maxHeight:"40px",verticalAlign: 'middle'}}/></Link></Typography></Grid>
                        <Grid item xs={12}><Typography variant="caption">made by <Link href="https://www.blys.it">Blys - Gianluigi Belli <LogoBlys style={{verticalAlign: 'middle'}} id="logo Blys"/></Link></Typography></Grid>
                    </Grid>
                </Paper>
            </Container>
        );
    }
}

export default withSnackbar(withStyles(SignInStyle)(SignIn));