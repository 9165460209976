import React, { Component, Fragment } from 'react'
import { withStyles, TextField, Button, CardContent, Typography, CardActions, Card, CardMedia, Grid, Box, InputAdornment, FormControl, InputLabel, OutlinedInput, FormHelperText, DialogContentText } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import PhoneRounded from '@material-ui/icons/PhoneRounded';
import EmailRounded from '@material-ui/icons/EmailRounded';
import SaveIcon from '@material-ui/icons/Save'
import AppActionSysUserEditorStyle from './AppActionSysUserEditorStyle';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import mediaHeader from '../../../../images/editor_opt.svg'
import FormRoleSelector from '../../Forms/FormRoleSelector';
import FormOUnitSelector from '../../Forms/FormOUnitSelector';

class AppActionSysUserEditor extends Component {

    state = {
        loaded: false,
        isNew: false,
        originalEmail: '',
        data: {
            name: '',
            email: '',
            phone: '',
            status: true,
            firstRole: '',
            firstOUnit: ''
        },
        OUnitKey: null,
        failedFields: {
            email: false
        }
    }

    constructor(props) {
        super(props)
        this.actionData = this.props.appMng.actionsStack[this.props.id]
        if (typeof this.actionData.params === 'object' && this.actionData.params.key) {
            this.getData()
        } else {
            this.state.isNew = true
        }
        this.props.appMng.setTitle((this.state.isNew ? 'Nuovo' : 'Modifica') + ' operatore di sistema')
    }

    componentDidMount() {
        if(this.actionData.params && this.actionData.params.OUnitKey){
            this.setState({ data: {...this.state.data, firstOUnit: this.actionData.params.OUnitKey} })
        }
    }

    getData() {
        if (this.actionData.params.key) {
            this.props.appMng.props.JBLClient.getSysUser(this.actionData.params.key)
                .then(response => {
                    this.setState({ loaded: true, data: {...response}, originalEmail: response.email })
                });
        }
    }

    persistsData(ignoreEmail = false) {
        if(!ignoreEmail && !this.state.isNew && this.state.originalEmail !== this.state.data.email){
            this.props.appMng.openDialog(<Fragment><WarningRoundedIcon/>Indirizzo eMail modificato</Fragment>, (
                <Fragment>
                    <DialogContentText id="main-dialog-description">
                        <p><b>Modificando l'indirizzo email, stai modificando anche l'indentificiativo per l'accesso al sistema.</b></p>
                        <p>Confermi di voler salvare le modifiche?</p>
                    </DialogContentText>
                </Fragment>),
                (<Fragment>
                    <Button aria-label="Conferma" onClick={() => {
                        this.persistsData(true)
                        this.props.appMng.closeDialog()
                    }} >Conferma</Button>
                    <Button aria-label="Annulla" onClick={() => {
                        this.props.appMng.closeDialog()
                    }}>Annulla</Button>
                </Fragment>))
        } else {
            if(this.state.isNew && (!this.state.data.firstOUnit || !this.state.data.firstRole)) {
                this.props.appMng.notifyMsgToGui({
                    'kind': 'error',
                    'text': "Prima di poter registrare il nuovo operatore è necessario specificare quale ruolo dovrà ricoprire e in quale unità organizzativa"
                })
                return false
            }
            let peristPromise = (this.state.isNew ? this.props.appMng.props.JBLClient.newSysUser(this.state.data, this.state.data.firstOUnit, this.state.data.firstRole) : this.props.appMng.props.JBLClient.updateSysUser(this.actionData.params.key, this.state.data))
            peristPromise
                .then(result => {
                    if (result !== null) {
                        this.props.appMng.setActionPersistence(this.props.id, true)
                        this.props.appMng.openAction('sysUser', { key: result })
                    }
                })
                .catch(error => {
                    console.error(error)
                    this.props.appMng.notifyMsgToGui({
                        'kind': 'error',
                        'text': "Si è verificato un errore imprevisto durante la registrazione dei dati."
                    })
                })
        }
    }

    setField(name, value) {
        let data = this.state.data
        let stepData = data
        let path = (Array.isArray(name)?name:[name])
        let lastName = path.splice(-1,1)[0]
        for(let step of path){
            stepData = stepData[step]
        }
        if (stepData[lastName] !== value) {
            this.props.appMng.setActionPersistence(this.props.id, false)
            stepData[lastName] = value
            this.setState({ data: data })
        }
    }

    verifyEmail = () => {
        let fieldError = false
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if(this.state.data.email){
            if (!pattern.test(this.state.data.email)) {
                fieldError = true
                this.props.appMng.notifyMsgToGui({
                    'kind': 'error',
                    'text': "L'indizzo email contiene un errore. Per favore ricontrolla."
                })
            } else {
                if(this.state.isNew || this.state.originalEmail !== this.state.data.email){
                    this.props.appMng.props.JBLClient.getSysUsers({email: this.state.data.email}).then(result => {
                        if (result !== null && result.length > 0 && (this.state.isNew || result[0].key !== this.state.data.key)) {
                            this.props.appMng.openDialog(<Fragment><WarningRoundedIcon/>Indirizzo email già presente</Fragment>, (
                                <Fragment>
                                    <DialogContentText id="main-dialog-description">
                                        <b>Esiste già un operatore con il medesimo indirizzo email.</b><br/>
                                        Non sarà possibile registrare i dati.
                                        {this.state.isNew?<b><br/>Usa la scheda dell operatore già registrata per assegnargli un nuovo ruolo nell'unità organizzativa desiderata.</b>: null}
                                    </DialogContentText>
                                </Fragment>),
                                (<Fragment>
                                    <Button aria-label="Annulla" onClick={() => {
                                        this.props.appMng.closeDialog()
                                    }}>Annulla</Button>
                                </Fragment>))
                        }
                    })
                }
            }
        }
        this.setState({failedFields: {...this.state.failedFields, email: fieldError}})
    }

    render() {
        return (
            <Card >
                <CardMedia className={this.props.classes.headMedia} image={mediaHeader}>
                    <Box p={3} className={this.props.classes.itemsHeaderText}>
                        <Typography variant="h4" color="inherit">
                            { this.state.isNew ? 'Nuovo operatore di sistema' : 'Modifica ' + this.state.data.name }
                        </Typography>
                    </Box>
                </CardMedia>
                {(this.state.isNew || this.state.loaded) ?
                    <Fragment>
                        <CardContent>
                            <Grid container spacing={2} direction="column">
                                <Grid container item spacing={1}>
                                    <Grid item xs={12} md={3} lg={2}>
                                        <Typography variant="h6">Identità</Typography>
                                    </Grid>
                                    <Grid item container xs={12} md={9} lg={10} spacing={1} alignItems="center">
                                        <Grid item xs={12} md={12} lg={12}>
                                            <TextField
                                                label="Nome"
                                                value={this.state.data.name ? this.state.data.name : ''}
                                                margin="normal"
                                                variant="outlined"
                                                helperText="nome completo dell'operatore"
                                                placeholder="es. Marco Maria Rovanelli"
                                                fullWidth
                                                required
                                                onChange={e => this.setField('name', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={7}>
                                            <FormControl fullWidth variant="outlined" margin="normal">
                                                <InputLabel htmlFor="outlined-adornment-email" required>eMail operatore</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-email"
                                                    placeholder="es. a.rossi@example.org"
                                                    error={this.state.failedFields.email}
                                                    value={this.state.data.email ? this.state.data.email : ''}
                                                    onChange={e => this.setField('email', e.target.value)}
                                                    onBlur={this.verifyEmail}
                                                    startAdornment={<InputAdornment position="start"><EmailRounded /></InputAdornment>}
                                                    labelWidth={150}
                                                />
                                                <FormHelperText>indirizzo email personale</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={5}>
                                            <FormControl fullWidth variant="outlined" margin="normal">
                                                <InputLabel htmlFor="outlined-adornment-phone">Telefono operatore</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-phone"
                                                    placeholder="es. +39347123456"
                                                    value={this.state.data.phone ? this.state.data.phone : ''}
                                                    onChange={e => this.setField('phone', e.target.value)}
                                                    startAdornment={<InputAdornment position="start"><PhoneRounded /></InputAdornment>}
                                                    labelWidth={150}
                                                />
                                                <FormHelperText>numero di telefono diretto</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        { this.state.isNew?
                                            <Fragment>
                                                <Grid item xs={12} md={9} lg={10}>
                                                    <FormRoleSelector
                                                        value={this.state.data.firstRole}
                                                        appMng={this.props.appMng}
                                                        label='Primo ruolo'
                                                        onChange={value => this.setField('firstRole', value)}
                                                    />
                                                </Grid>
                                                {(!this.actionData.params || !this.actionData.params.OUnitKey)?
                                                    <Fragment>
                                                        <Grid item xs={12} md={9} lg={10}>
                                                            presso
                                                        </Grid>
                                                        <Grid item xs={12} md={9} lg={10}>
                                                            <FormOUnitSelector
                                                                value={this.state.data.firstOUnit}
                                                                appMng={this.props.appMng}
                                                                onChange={value => this.setField('firstOUnit', value)}
                                                            />
                                                        </Grid>
                                                    </Fragment>:null}
                                            </Fragment>:null
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Box m={1}>
                                <Button onClick={() => this.persistsData()}><SaveIcon />{(this.state.isNew ? 'Registra' : 'Salva modifiche')}</Button>
                                <Button onClick={() => this.props.appMng.closeAction(this.props.id)}><CancelRoundedIcon />Annulla</Button>
                            </Box>
                        </CardActions>
                    </Fragment >
                    :
                    <CardContent>
                        <Skeleton height="25pt" width="100%" />
                    </CardContent>
                }
            </Card>
        )
    }
}

export default withStyles(AppActionSysUserEditorStyle)(AppActionSysUserEditor)