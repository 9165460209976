import { createStyles } from "@material-ui/core";
import α from 'color-alpha'

const AppActionGraveStyle = ((theme) => createStyles({
    headMedia: {
        position: "relative",
        height: '160px',
        color: theme.palette.primary.contrastText
    },
    hiddenElement: {
        display: 'none'
    },
    itemsHeader: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText
    },
    itemsSubHeader: {
        color: theme.palette.grey['200']
    },
    itemsHeaderText: {
        background: "linear-gradient(180deg, rgba(0,0,0,0.6194852941176471) 0%, rgba(0,0,0,0.09287464985994398) 88%, rgba(0,0,0,0) 100%)",
        color: theme.palette.primary.contrastText
    },
    fabEdit: {
        position: "absolute",
        right: theme.spacing(2),
        bottom: -1 * theme.spacing(2),
    },
    fabMenu: {
        position: "absolute",
        right: theme.spacing(10),
        bottom: -1 * theme.spacing(2),
    },
    fabMap: {
        position: "absolute",
        right: theme.spacing(18),
        bottom: -1 * theme.spacing(2),
    },
    exumatedHeader: {
        backgroundColor: theme.palette.warning.dark,
    },
    itemsHeaderReservation: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.contrastText
    },
    entityLine: {
        marginTop: theme.spacing(4),
        borderBottom: "1px solid lightgray"
    },
    parentLink: {
        cursor: "pointer",
        textDecoration: "underline"
    },
    box: {
        borderRadius: 16,
        border: "1px solid",
        borderColor: "transparent",
        margin: "8px",
        '&:hover': {
            backgroundColor: α(theme.palette.secondary.light, 0.5),
            borderColor: theme.palette.secondary.light,
            cursor: "pointer"
        }
    },
    boxContent: {
        display: "contents"
    },
    filename: {
        textOverflow: 'ellipsis', /* enables ellipsis */
        whiteSpace: 'nowrap', /* keeps the text in a single line */
        overflow: 'hidden', /* keeps the element from overflowing its parent */
        textAlign: 'center'
    },
    fileWaiter: {
        position: 'absolute',
        left: "calc(50% - 12px)",
        top: "calc(50% - 12px)"
    }
}))

export default AppActionGraveStyle