import { createStyles } from "@material-ui/core";

const MainViewStyle = ((theme) => createStyles({
    '@global': {
        body: {
            backgroundColor: theme.palette.background.default,
        },
    },
    root: {
        display: 'flex'
    },
}))

export default MainViewStyle