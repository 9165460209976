import React, { Component, Fragment } from 'react'
import { withStyles, TextField, Button, CardContent, Typography, CardActions, Card, Grid, Switch, FormControlLabel, Box, CardMedia } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import AppActionSpcModelEditorStyle from './AppActionSpcModelEditorStyle';
import JPolyDraw from '../../../JPoly';
import mediaHeader from '../../../../images/editor_opt.svg'

class AppActionSpcModelEditor extends Component {

    state = {
        loaded: false,
        isNew: false,
        data: {
            name: '',
            description: '',
            isGrave: false,
            allowGraves: true,
            allowSpaces: true,
            planimetry: null,
            defaultGrantDuration: 0
        },
        counts: [],
        defaultGrantDurationFocused: false
    }

    constructor(props) {
        super(props)
        this.actionData = this.props.appMng.actionsStack[this.props.id]
        if (typeof this.actionData.params === 'object' && this.actionData.params.key) {
            this.getData()
        } else {
            this.state.isNew = true
        }
        this.props.appMng.setTitle((this.state.isNew ? 'Nuovo' : 'Modifica') + ' modello di area cimiteriale')
    }

    getData() {
        if (this.actionData.params.key) {
            this.props.appMng.props.JBLClient.getSpaceModel(this.actionData.params.key)
                .then(response => {
                    let counts = {}
                    if(Array.isArray(response.getCountOf)){
                        for(let item of response.getCountOf){
                            counts[item.key]=item.value
                        }
                    }
                    this.setState({ loaded: true, data: {...response}, counts: counts})
                });
        }
    }

    persistsData() {
        let peristPromise = (this.state.isNew ? this.props.appMng.props.JBLClient.newSpaceModel(this.state.data) : this.props.appMng.props.JBLClient.updateSpaceModel(this.actionData.params.key, this.state.data))
        peristPromise
            .then(result => {
                if (result !== null) {
                    this.props.appMng.setActionPersistence(this.props.id, true)
                    this.props.appMng.closeAction(this.props.id)
                }
            })
            .catch(error => {
                console.error(error)
                this.props.appMng.notifyMsgToGui({
                    'kind': 'error',
                    'text': "Si è verificato un errore imprevisto durante la registrazione dei dati."
                })
            })
    }

    setField(name, value) {
        const data = this.state.data
        if (data[name] !== value) {
            this.props.appMng.setActionPersistence(this.props.id, false)
            data[name] = value
            this.setState({ data: data })
        }
    }

    render() {
        const polyEditorParams = (this.state.data.planimetry !== null ? { iKey: (typeof this.actionData.params === "object" && this.actionData.params.key)?this.actionData.params.key:'new space model', color: this.state.data.planimetry.color, path: this.state.data.planimetry.path } : { color: "gray", path: [] })
        return (
            <Card >
                <CardMedia className={this.props.classes.headMedia} image={mediaHeader} title="Editor modelli di aree cimiteriali">
                    <Box p={3}>
                        <Typography variant="h4" color="inherit">
                            Modello di {this.state.data.name ? this.state.data.name : (this.state.data.isGrave ? 'sepolcro' : 'area cimiteriale')}
                        </Typography>
                    </Box>
                </CardMedia>
                {(this.state.isNew || this.state.loaded) ?
                    <Fragment>
                        <CardContent>
                            <Grid container spacing={2} direction="column">
                                <Grid container item spacing={1}>
                                    {!this.state.isNew &&
                                        <Fragment>
                                            <Grid item xs={12} md={3} lg={2}>
                                                <Typography variant="h6">Informazioni</Typography>
                                            </Grid>
                                            <Grid container item xs={12} md={9} lg={10} direction="column" alignItems="stretch">
                                                <Grid item>
                                                    {this.state.data.isGrave?
                                                        <Typography variant="body1">sepolcri di questo tipo: <em>{this.state.counts['grave']}</em></Typography>:
                                                        <Typography variant="body1">aree di questo tipo: <em>{this.state.counts['gravespace']}</em></Typography>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Fragment>
                                    }
                                    <Grid item xs={12} md={3} lg={2}>
                                        <Typography variant="h6">Definizione</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9} lg={10}>
                                        <Grid container spacing={1} direction="column" alignItems="stretch">
                                            {!(this.state.counts['grave'] || this.state.counts['gravespace']) &&
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={this.state.data.isGrave}
                                                            onChange={e => this.setField('isGrave', e.target.checked)}
                                                        />
                                                    }
                                                    label="è un sepolcro"
                                                />
                                            </Grid>}
                                            <Grid item>
                                                <TextField
                                                    label="Tipologia"
                                                    value={this.state.data.name}
                                                    onChange={e => this.setField('name', e.target.value)}
                                                    variant="outlined"
                                                    required
                                                    autoFocus
                                                    helperText="es. Campo, Ossario, Fossa, ..."
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    label="Descrizione"
                                                    value={this.state.data.description}
                                                    onChange={e => this.setField('description', e.target.value)}
                                                    variant="outlined"
                                                    multiline
                                                    rowsMax="4"
                                                    fullWidth
                                                    helperText="es. area per inumazione a terra, ..."
                                                />
                                            </Grid>
                                            {this.state.data.isGrave ? <Grid item container alignItems="center">
                                                <Grid item xs={9} sm={5} md={3}>
                                                    { (this.state.data.defaultGrantDuration > 0 || this.state.defaultGrantDurationFocused) ? <TextField
                                                        label="Anni"
                                                        value={this.state.data.defaultGrantDuration}
                                                        onFocus={()=> this.setState({defaultGrantDurationFocused: true})}
                                                        onBlur={()=> this.setState({defaultGrantDurationFocused: false})}
                                                        onChange={e => this.setField('defaultGrantDuration', e.target.value.length > 0? parseInt(e.target.value): '')}
                                                        variant="outlined"
                                                        type="number"
                                                        inputProps={{min: 0, max: 999}}
                                                        fullWidth
                                                        helperText="durata predefinita della concessione in anni"
                                                    />: <Typography>Durata predefinita concessione illimitata</Typography>}
                                                </Grid>
                                                <Grid item xs={3} sm={3} md={2}>
                                                    <Switch
                                                        checked={!this.state.data.defaultGrantDuration || this.state.data.defaultGrantDuration === "0"}
                                                        onChange={e => this.setField('defaultGrantDuration', e.target.checked?0:10)}
                                                        inputProps={{
                                                            'aria-label': "senza limite",
                                                            'title': "senza limite",
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid> : null}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={1} className={this.state.data.isGrave ? this.props.classes.hiddenElement : ''}>
                                    <Grid item xs={12} md={3} lg={2}>
                                        <Typography variant="h6">Opzioni</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9} lg={10}>
                                        <Grid container spacing={1} direction="column" alignItems="stretch">
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={this.state.data.allowGraves}
                                                            onChange={e => this.setField('allowGraves', e.target.checked)}
                                                        />
                                                    }
                                                    label="può contenere sepolcri"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={this.state.data.allowSpaces}
                                                            onChange={e => this.setField('allowSpaces', e.target.checked)}
                                                        />
                                                    }
                                                    label="può contenere sotto-aree"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardContent>
                            <Grid container spacing={2} direction="column">
                                <Grid container item spacing={1} direction="column">
                                    <Grid item>
                                        <Typography variant="h6">Modello grafico</Typography>
                                        <Typography variant="body1">Disengna il perimetro del modello</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box m={1}>
                                <JPolyDraw onChange={data => this.setField('planimetry', data)} settings={polyEditorParams} />
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Box m={1}>
                                <Button onClick={() => this.persistsData()}><SaveIcon />{(this.state.isNew ? 'Crea' : 'Salva modifiche')}</Button>
                                <Button onClick={() => this.props.appMng.closeAction(this.props.id)}><CancelRoundedIcon />Annulla</Button>
                            </Box>
                        </CardActions>
                    </Fragment>
                    :
                    <CardContent>
                        <Skeleton height="25pt" width="100%" />
                    </CardContent>}
            </Card>
        )
    }
}

export default withStyles(AppActionSpcModelEditorStyle)(AppActionSpcModelEditor)